import styled from 'styled-components';
import { Form } from '@unform/web';
import bg from '../../assets/signin2.jpg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${bg});
  img {
    width: 100%;
    max-width: 300px;
  }

  h1 {
    color: #000;
    margin-bottom: 10px;
  }
`;

export const FormContainer = styled(Form)`
  background: #fff;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
