import React, {
  useState,
  useEffect,
  useCallback,
  ButtonHTMLAttributes,
  useRef,
} from 'react';
import MaterialTable from 'material-table';
import { FormHandles, SubmitHandler } from '@unform/core';
import { FiTrash2 } from 'react-icons/fi';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import api from '../../services/api';
import { Container, Header, Box, FormSty, ButtonIcon, BoxAdd } from './styles';
import { useAuth } from '../../hooks/auth';
import { useMenu } from '../../hooks/menu';
import ModalTab from '../../components/Modal';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import getValidationErrors from '../../utils/getValidationErrors';
import formatDate from '../../utils/formatDate';

export interface NotificationsData {
  tipo?: string;
  titulo?: string;
  msg?: ButtonHTMLAttributes<HTMLButtonElement>;
  data?: Date | string;
  nome_empresa?: string;
  info?: ButtonHTMLAttributes<HTMLButtonElement>;
  remove?: any;
}

interface ReturnNotifications {
  cod_notificacao?: number;
  tipo?: string;
  titulo?: string;
  company?: string;
  msg?: string;
  data?: Date;
  nome_empresa?: string;
}

interface Companies {
  cod: number;
  empresa: string;
  enquadramento_fiscal?: number;
  estado?: string;
  endereco?: string;
  cidade?: string;
  telefone?: string;
  conta: number;
  created_at?: Date;
  updated_at?: Date;
}

const createData = ({
  tipo,
  titulo,
  msg,
  data,
  nome_empresa,
  info,
  remove,
}: NotificationsData): NotificationsData => {
  return { tipo, titulo, msg, data, info, nome_empresa, remove };
};

const rowHead = [
  {
    title: 'Tipo',
    field: 'tipo',
  },
  {
    title: 'Título',
    field: 'titulo',
  },
  {
    title: 'Mensagem',
    field: 'msg',
  },
  {
    title: 'Data de Expiração',
    field: 'data',
  },
  {
    title: 'Empresa',
    field: 'nome_empresa',
  },
  {
    title: 'Excluir',
    field: 'remove',
  },
];

const Notifications: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [companies, setCompanies] = useState<Companies[]>([]);
  const [allCompanys, setAllCompanys] = useState(false);
  const [notifications, setNotifications] = useState<ReturnNotifications[]>();
  const [message, setMessage] = useState('');

  const formAddRef = useRef<FormHandles>(null);
  const { id, conta, perfil } = useAuth().user;
  const { expansedFalse } = useMenu();
  const token = sessionStorage.getItem('@MaxiFrete:token');

  const listNotifications = useCallback(async (): Promise<void> => {
    try {
      let response;

      if (perfil !== 'admin') {
        response = await api.get(`/api/v1/notificacoes/${id}`, {
          headers: {
            'x-access-token': token,
          },
        });
      } else {
        response = await api.get(`/api/v1/notificacoes`, {
          headers: {
            'x-access-token': token,
          },
        });
      }

      setNotifications(response.data.data);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao listar empresas');
    }
  }, [token, perfil, id]);

  const listEmpresas = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get(
        `/api/v1/empresas?conta=${conta}&user=${id}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      console.log(response);

      const formatSelectCompanies = response.data.data.map(
        (company: Companies) => ({
          label: company.empresa,
          value: company.cod,
        }),
      );

      setCompanies(formatSelectCompanies);
    } catch (err) {
      toast.error('Erro ao listar empresas');
    }
  }, [conta, id, token]);

  const handleAddSubmit: SubmitHandler<ReturnNotifications> = useCallback(
    async (data) => {
      try {
        formAddRef.current?.setErrors({});
        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
          data: Yup.date().required('Data obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post(
          `/api/v1/notificacoes`,
          {
            user_request: id,
            conta,
            tipo: data.tipo,
            titulo: data.titulo,
            msg: message,
            data: data.data,
            conta_notificacao: allCompanys ? '1' : data.company,
            primeiro_acesso: '1',
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        listNotifications();
        toast.success('Notificação criada com sucesso');
        window.location.reload();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formAddRef.current?.setErrors(errors);
          return;
        }
        toast.error('Erro ao criar notificação');
      }
    },
    [listNotifications, conta, id, message, token, allCompanys],
  );

  const deleteUser = useCallback(
    async ({ cod_notificacao }: ReturnNotifications) => {
      try {
        await api.delete(
          `/api/v1/notificacoes/${cod_notificacao}?user_request=${id}&conta=${conta}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        toast.success('Notificação deletada com sucesso.');
        listNotifications();
      } catch (err) {
        toast.error('Erro ao deletar notificação');
      }
    },
    [token, listNotifications, conta, id],
  );

  useEffect(() => {
    if (window.screen.width <= 500) {
      expansedFalse();
    }
    listEmpresas();
    setModal(false);
    listNotifications();
  }, [listNotifications, listEmpresas, expansedFalse]);

  const isAdm = perfil === 'admin';
  const rowsList = notifications
    ? notifications.map((data) => {
        const {
          tipo,
          titulo,
          msg,
          data: DataUser,
          nome_empresa,
          cod_notificacao,
        } = data;
        const row = createData({
          tipo,
          titulo,
          msg: <ModalTab close={modal} modalContent={   <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<pre wrap>${msg}</pre>`,
            }}
          />} icon="book" />,
          data: formatDate(DataUser),
          nome_empresa: nome_empresa === null ? 'Todas' : nome_empresa,
          info: <ModalTab close={modal} modalContent={msg} icon="view" />,
          remove: isAdm ? 
            <ButtonIcon
              onClick={() => {
                return (
                  window.confirm('Deseja remover a notificação?') &&
                  deleteUser({ cod_notificacao })
                );
              }}
              type="button"
            >
              <FiTrash2 size={18} />
            </ButtonIcon>
          :'',
        });
        return row;
      })
    : [];

  const options = [
    { value: 'Urgente', label: 'Urgente' },
    { value: 'Informativo', label: 'Informativo' },
  ];
  const minDate = new Date().toISOString().split('T')[0];
  return (
    <Container>
      <Header>
        <h1>Notificações</h1>
      </Header>
      {perfil === 'admin' && (
        <BoxAdd>
          <FormSty ref={formAddRef} onSubmit={handleAddSubmit}>
            <div className="allCompanys">
              <h4>Todas as empresas</h4>
              <input
                name="allCompanys"
                onChange={() => {
                  return allCompanys
                    ? setAllCompanys(false)
                    : setAllCompanys(true);
                }}
                type="checkbox"
                defaultChecked={allCompanys}
              />
            </div>
            <article>
              <Input border name="titulo" placeholder="Título" />
              {!allCompanys && (
                <>
                  <Select
                    name="company"
                    options={companies}
                    placeholder="Empresas"
                    isClearable={false}
                  />
                </>
              )}
            </article>
            <article>
              <Input
                border
                name="data"
                placeholder="Data de expiração"
                type="date"
                min={minDate}
              />
              <Select
                name="tipo"
                options={options}
                placeholder="Tipo"
                isClearable={false}
              />
            </article>
            <article>
              <textarea
                value={message}
                rows={5}
                cols={63}
                onChange={(e) => {
                  console.log(e.target.value);
                  setMessage(e.target.value);
                }}
                placeholder="Mensagem"
              />
              <Button type="submit">Adicionar</Button>
            </article>
          </FormSty>
        </BoxAdd>
      )}

      <Box>
        <MaterialTable
          isLoading={loading}
          columns={rowHead}
          data={rowsList}
          title=""
          localization={{
            pagination: {
              labelRowsSelect: 'linhas',
            },
            toolbar: {
              searchPlaceholder: 'Pesquisar',
              searchTooltip: 'Pesquisar',
            },
            body: {
              emptyDataSourceMessage: 'Nenhum registro encontrado',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
            },
          }}
        />
      </Box>
    </Container>
  );
};

export default Notifications;
