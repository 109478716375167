import styled from 'styled-components';

export const Container = styled.nav`
  grid-area: NB;

  background: var(--grey);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  button {
    background: transparent;
    border: 0;
  }
  @media screen and (max-width: 500px) {
    flex: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 16px;
  }
`;
