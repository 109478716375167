import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  button {
    background: var(--text);
    width: 200px;
    padding: 10px 5px;
    color: #fff;
    margin: auto;
  }

  a {
    position: absolute;
    right: 50px;
    top: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 30px;
`;

export const TitlePrint = styled.h1`
  display: none;

  @media print {
    display: block;
    text-align: center;
    color: #000;
  }
`;

export const Box = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--blue-b);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--yellow);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue-b);
  }

  padding: 20px;
  display: block;
  margin: 30px 10px;
  width: calc(100% - 53%);
  overflow: auto;
  border: 1px solid var(--blue-b);

  @media print {
    margin: 10px;
  }

  @media screen and (max-width: 959px) {
    width: 100%;
    max-height: max-content;
    overflow: hidden;
  }

  section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }

  input {
    flex: 1;
  }

  h2 {
    color: var(--blue-b);
    margin-bottom: 20px;
  }

  h5 {
    font-size: 18px;
    margin: 4px;
    flex: 1;
    color: #000;
  }

  p {
    color: #000;
  }
`;

export const Header = styled.h2`
  text-align: center;
  color: var(--blue-b);
  font-size: 23px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;

  button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    width: 40px;
  }

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
