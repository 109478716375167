export default function formatMoney(money: any, isFormate?: boolean): string {
  if (isFormate) {
    const a = Number(money);
    return a.toString();
  }
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(money));
}
