/* eslint-disable eqeqeq */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler, Scope } from '@unform/core';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Header } from './styles';
import { Box } from '../components';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import { useTimeline } from '../../../../hooks/timeline';
import Loading from '../../../../components/Loading';
import formatMoney from '../../../../utils/formatMoney';

interface IArrayZero {
  subtipo: string;
  itens: [
    {
      agrupamento: number;
      subtipo: string;
      cod: number;
      campo: string;
      valor: any;
      elemento: string | number;
      info: string;
      read_only: number;
    },
  ];
}

interface IModalidade {
  agrupamento: number;
  subtipo: string;
  cod: number;
  campo: string;
  valor: any;
  elemento: string | number;
  info: string;
  read_only: number;
}

const Elements: React.FC = () => {
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');

  const formRef = useRef<FormHandles>(null);
  const [totalVeiculos, setTotalVeiculos] = useState(0);
  const [loading, setLoading] = useState(true);

  const [arrayPositionZeroPattern, setArrayPositionZeroPattern] = useState<
    IArrayZero[]
  >([]);

  const [arrayPositionZero, setArrayPositionZero] = useState<IArrayZero[]>([]);

  const [arrayPositionTwo, setArrayPositionTwo] = useState<IModalidade[]>([]);

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      const response = await api.get(
        `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Elementos&user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      console.log(response.data);
      setArrayPositionZeroPattern(response.data.data[0][0]);
      setArrayPositionZero(response.data.data[0][1]);
      setArrayPositionTwo(response.data.data[2].itens);
      setLoading(false);
    };

    loadTabs();
  }, [conta, id, token, temp]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        const array = data.modules.filter(
          (e: any) => e !== '' && e.valor !== '0',
        );
        await api.put(
          `/api/v1/tabelas/elementos`,
          {
            user_request: id,
            conta,
            calculo: temp,
            itens: array.map((e: any) => ({
              valor: e.valor
                .replace('R$', '')
                .trim()
                .replaceAll('.', '')
                .replace(',', '.'),
              cod: e.cod,
            })),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        next();
      } catch (err) {
        console.error(err);
      }
    },
    [next, conta, id, token, temp],
  );

  return (
    <>
      {loading ? (
        <Loading isLoading={loading} />
      ) : (
        <Container>
          <Header>Elementos</Header>
          <Form ref={formRef} onSubmit={handleAddSubmit}>
            {arrayPositionZeroPattern.map((tab, idxtab) => {
              return (
                <Box key={tab.subtipo}>
                  <h2>{tab.subtipo}</h2>
                  {tab.itens
                    ? tab.itens.map((item, index) => {
                        return (
                          <Scope key={item.campo} path={`modules[${item.cod}]`}>
                            <section>
                              <h5>{item.campo}</h5>
                              {item.subtipo.toUpperCase() !==
                              'QUANTIDADE DE VEÍCULOS' ? (
                                <Input
                                  name="valor"
                                  type="text"
                                  value={
                                    item.elemento === 32
                                      ? formatMoney(item.valor)
                                          .split(/[R$\\/]/)
                                          .join('')
                                      : formatMoney(item.valor)
                                  }
                                  readOnly={item.read_only !== 0}
                                  disabled={item.read_only !== 0}
                                  info={item.info && item.info}
                                  onChange={(e): void => {
                                    const newTelements = [
                                      ...arrayPositionZeroPattern,
                                    ];
                                    newTelements[idxtab].itens[
                                      index
                                    ].valor = Number(
                                      Number(
                                        e.target.value
                                          .split(/[R$\\/,/./]/)
                                          .join('')
                                          .split(/[^\d]+/g)
                                          .join('')
                                          .trim(),
                                      ) / 100,
                                    );
                                    const idxTotal = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.findIndex(
                                      (x) => x.campo.toUpperCase() === 'TOTAL',
                                    );

                                    const TVei = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.findIndex((x) => x.cod === 188);

                                    if (TVei !== -1) {
                                      setTotalVeiculos(
                                        arrayPositionZeroPattern[idxtab].itens[
                                          TVei
                                        ].valor,
                                      );
                                    }

                                    const index_encargos_percent = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.findIndex(
                                      (x) => x.elemento == '32',
                                    );

                                    if (
                                      item.campo.toUpperCase() ===
                                      'TOTAL DE SALÁRIOS'
                                    ) {
                                      const index_encargos_valor = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.findIndex(
                                        (x) => x.elemento == '33',
                                      );

                                      if (
                                        index_encargos_valor !== -1 &&
                                        index_encargos_percent !== -1
                                      ) {
                                        arrayPositionZeroPattern[idxtab].itens[
                                          index_encargos_valor
                                        ].valor =
                                          (parseFloat(
                                            Number(
                                              Number(
                                                e.target.value
                                                  .split(/[R$\\/,/./]/)
                                                  .join('')
                                                  .split(/[^\d]+/g)
                                                  .join('')
                                                  .trim(),
                                              ) / 100,
                                            ).toString(),
                                          ) *
                                            arrayPositionZeroPattern[idxtab]
                                              .itens[index_encargos_percent]
                                              .valor) /
                                          100;
                                      }
                                    }

                                    if (idxTotal !== -1) {
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxTotal
                                      ].valor = 0;
                                      const total = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.reduce((acc, value) => {
                                        let a = 0;
                                        a = acc + Number(value.valor);
                                        return a;
                                      }, 0);

                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxTotal
                                      ].valor =
                                        index_encargos_percent !== -1
                                          ? (arrayPositionZeroPattern[idxtab]
                                              .itens[index_encargos_percent]
                                              .valor -
                                              Number(total)) *
                                            -1
                                          : Number(total);
                                    }

                                    setArrayPositionZeroPattern(newTelements);
                                  }}
                                />
                              ) : (
                                <Input
                                  name="valor"
                                  type="text"
                                  value={item.valor}
                                  readOnly={item.read_only !== 0}
                                  disabled={item.read_only !== 0}
                                  info={item.info && item.info}
                                  onChange={(e): void => {
                                    const newTelements = [
                                      ...arrayPositionZeroPattern,
                                    ];
                                    newTelements[idxtab].itens[
                                      index
                                    ].valor = Number(e.target.value);
                                    const idxTotal = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.findIndex(
                                      (x) => x.campo.toUpperCase() === 'TOTAL',
                                    );

                                    const TVei = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.findIndex((x) => x.cod === 188);

                                    if (TVei !== -1) {
                                      setTotalVeiculos(
                                        arrayPositionZeroPattern[idxtab].itens[
                                          TVei
                                        ].valor,
                                      );
                                    }

                                    const index_encargos_percent = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.findIndex(
                                      (x) => x.elemento == '32',
                                    );

                                    if (
                                      item.campo.toUpperCase() ===
                                      'TOTAL DE SALÁRIOS'
                                    ) {
                                      const index_encargos_valor = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.findIndex(
                                        (x) => x.elemento == '33',
                                      );

                                      if (
                                        index_encargos_valor !== -1 &&
                                        index_encargos_percent !== -1
                                      ) {
                                        arrayPositionZeroPattern[idxtab].itens[
                                          index_encargos_valor
                                        ].valor =
                                          (parseFloat(
                                            Number(
                                              Number(
                                                e.target.value
                                                  .split(/[R$\\/,/./]/)
                                                  .join('')
                                                  .split(/[^\d]+/g)
                                                  .join('')
                                                  .trim(),
                                              ) / 100,
                                            ).toString(),
                                          ) *
                                            arrayPositionZeroPattern[idxtab]
                                              .itens[index_encargos_percent]
                                              .valor) /
                                          100;
                                      }
                                    }

                                    if (idxTotal !== -1) {
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxTotal
                                      ].valor = 0;
                                      const total = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.reduce((acc, value) => {
                                        let a = 0;
                                        a = acc + Number(value.valor);
                                        return a;
                                      }, 0);
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxTotal
                                      ].valor =
                                        index_encargos_percent !== -1
                                          ? (arrayPositionZeroPattern[idxtab]
                                              .itens[index_encargos_percent]
                                              .valor -
                                              Number(total)) *
                                            -1
                                          : Number(total);
                                    }

                                    setArrayPositionZeroPattern(newTelements);
                                  }}
                                />
                              )}

                              <Input
                                name="cod"
                                type="hidden"
                                value={item.cod}
                              />
                            </section>
                          </Scope>
                        );
                      })
                    : []}
                </Box>
              );
            })}
            {arrayPositionZero.map((tab, idxtab) => {
              return (
                <Box key={tab.subtipo}>
                  <h2>{tab.subtipo}</h2>
                  {tab.itens
                    ? tab.itens.map((item, index) => {
                        return (
                          <Scope key={item.campo} path={`modules[${item.cod}]`}>
                            <section>
                              <h5>{item.campo}</h5>
                              <Input
                                name="valor"
                                type="text"
                                value={
                                  item.elemento === 47
                                    ? formatMoney(item.valor)
                                        .split(/[R$\\/]/)
                                        .join('')
                                    : formatMoney(item.valor)
                                }
                                readOnly={item.read_only !== 0}
                                info={item.info && item.info}
                                onChange={(e): void => {
                                  const newTelements = [...arrayPositionZero];
                                  console.log(newTelements);
                                  newTelements[idxtab].itens[
                                    index
                                  ].valor = Number(
                                    Number(
                                      e.target.value
                                        .split(/[R$\\/,/./]/)
                                        .join('')
                                        .split(/[^\d]+/g)
                                        .join('')
                                        .trim(),
                                    ) / 100,
                                  );
                                  const idxTotal = arrayPositionZero[
                                    idxtab
                                  ].itens.findIndex(
                                    (x) => x.campo.toUpperCase() === 'TOTAL',
                                  );

                                  const idxTVei = arrayPositionZero[
                                    idxtab
                                  ].itens.findIndex(
                                    (x) =>
                                      x.campo.toUpperCase() ===
                                      'TOTAL DE VEÍCULOS',
                                  );

                                  if (idxTVei !== -1) {
                                    arrayPositionZero[idxtab].itens[
                                      idxTVei
                                    ].valor = totalVeiculos;
                                  }

                                  const index_encargos_percent = arrayPositionZero[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento == '47');

                                  if (item.elemento === 46) {
                                    const index_encargos_valor = arrayPositionZero[
                                      idxtab
                                    ].itens.findIndex(
                                      (x) => x.elemento == '48',
                                    );

                                    if (
                                      index_encargos_valor !== -1 &&
                                      index_encargos_percent !== -1
                                    ) {
                                      arrayPositionZero[idxtab].itens[
                                        index_encargos_valor
                                      ].valor =
                                        (parseFloat(
                                          Number(
                                            Number(
                                              e.target.value
                                                .split(/[R$\\/,/./]/)
                                                .join('')
                                                .split(/[^\d]+/g)
                                                .join('')
                                                .trim(),
                                            ) / 100,
                                          ).toString(),
                                        ) *
                                          arrayPositionZero[idxtab].itens[
                                            index_encargos_percent
                                          ].valor) /
                                        100;
                                    }
                                  }

                                  if (idxTotal !== -1) {
                                    arrayPositionZero[idxtab].itens[
                                      idxTotal
                                    ].valor = 0;
                                    const total = arrayPositionZero[
                                      idxtab
                                    ].itens.reduce((acc, value) => {
                                      let a = 0;
                                      a = acc + Number(value.valor);
                                      return a;
                                    }, 0);
                                    arrayPositionZero[idxtab].itens[
                                      idxTotal
                                    ].valor =
                                      index_encargos_percent !== -1
                                        ? (arrayPositionZero[idxtab].itens[
                                            index_encargos_percent
                                          ].valor -
                                            Number(total)) *
                                          -1
                                        : Number(total);
                                  }

                                  setArrayPositionZero(newTelements);
                                }}
                              />
                              <Input
                                name="cod"
                                type="hidden"
                                value={item.cod}
                              />
                            </section>
                          </Scope>
                        );
                      })
                    : []}
                </Box>
              );
            })}
            <Box>
              <h2>Modalidade de Receita</h2>
              {arrayPositionTwo
                ? arrayPositionTwo.map((item, index) => {
                    return (
                      <React.Fragment key={item.campo}>
                        <Scope key={item.campo} path={`modules[${item.cod}]`}>
                          <section>
                            <h5>{item.campo}</h5>
                            <Input
                              name="valor"
                              value={formatMoney(item.valor)}
                              type="text"
                              style={{width:'10%'}}
                              readOnly={item.read_only !== 0}
                              info={item.info && item.info}
                              onChange={(e): void => {
                                const modT = [...arrayPositionTwo];
                                modT[index].valor = Number(
                                  Number(
                                    e.target.value
                                      .split(/[R$\\/,/./]/)
                                      .join('')
                                      .split(/[^\d]+/g)
                                      .join('')
                                      .trim(),
                                  ) / 100,
                                );

                                const idxTotal = modT.findIndex(
                                  (x) => x.campo.toUpperCase() === 'TOTAL',
                                );

                                if (idxTotal !== -1) {
                                  modT[idxTotal].valor = 0;
                                  const total = modT.reduce((acc, value) => {
                                    let a = 0;
                                    a = acc + value.valor;
                                    return a;
                                  }, 0);
                                  modT[idxTotal].valor = total;

                                  // const idxFrota = modT.findIndex(
                                  //   (x) => x.campo.toUpperCase() === 'FROTA',
                                  // );
                                  // const a =
                                  // (modT[idxFrota].valor /
                                  //   modT[idxTotal].valor) *
                                  // 100;

                                  setArrayPositionTwo(modT);
                                }
                              }}
                            />
                            <Input name="cod" type="hidden" value={item.cod} />
                          </section>
                        </Scope>
                      </React.Fragment>
                    );
                  })
                : []}
            </Box>
            <button type="submit">Salvar</button>
          </Form>
        </Container>
      )}
    </>
  );
};

export default Elements;
