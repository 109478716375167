import styled from 'styled-components';

export const StyledModal = styled.div`
  background: #fff;
  max-width: 500px;
  width: 100%;
  max-height: 600px;
  height: 100%;
  border-radius: 0.25rem;
  z-index: 16;
  overflow: hidden;
  display: flex;

  @media screen and (max-width: 500px) {
    border-radius: 0;
    min-height: 100vh;
  }
`;

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15;
`;
