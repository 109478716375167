import React from 'react';
import formatMoney from '../../../../../utils/formatMoney';

interface IDespesasVariaveis {
  despesas_variaveis: {
    arla: number;
    combustivel: number;
    impostos_despesas_variaveis: number;
    lavagem: number;
    manutencao_cavalo: number;
    manutencao_semireboque: number;
    pneus: number;
    taxa_lucro_despesas_variaveis: number;
    total_despesas_variaveis: number;
  };
}
const format4casas = (value: any): any => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    
    minimumFractionDigits:3,
    maximumFractionDigits:3
    
  })
    .format(Number(value))
    .split('-')
    .join('');
};

const format2casas = (value: any): any => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    
    minimumFractionDigits:2,
    maximumFractionDigits:2
    
  })
    .format(Number(value))
    .split('-')
    .join('');
};

const ParamDespesasVariaveis: React.FC<IDespesasVariaveis> = ({
  despesas_variaveis,
}) => {
  return (
    <>
      <h2>Despesas Variáveis</h2>
      <section>
        <h5>Arla</h5>
        <p>{format2casas(despesas_variaveis.arla)}</p>
      </section>
      <section>
        <h5>Combustível</h5>
        <p>{format2casas(despesas_variaveis.combustivel)}</p>
      </section>
      <section>
        <h5>Pneus</h5>
        <p>{format2casas(despesas_variaveis.pneus)}</p>
      </section>
      <section>
        <h5>Manutenção do veículo</h5>
        <p>{format2casas(despesas_variaveis.manutencao_cavalo)}</p>
      </section>
      <section>
        <h5>Manutenção do semi-reboque ou complemento</h5>
        <p>{format2casas(despesas_variaveis.manutencao_semireboque)}</p>
      </section>
      <section>
        <h5>Lavagem</h5>
        <p>{format2casas(despesas_variaveis.lavagem)}</p>
      </section>
      <section>
        <h5>Impostos federais sobre despesas variáveis</h5>
        <p>{format2casas(despesas_variaveis.impostos_despesas_variaveis)}</p>
      </section>
      <section>
        <h5>Lucro sobre despesas variáveis</h5>
        <p>{format2casas(despesas_variaveis.taxa_lucro_despesas_variaveis)}</p>
      </section>
      <section>
        <h5>Total das despesas variáveis</h5>
        <p>{format2casas(despesas_variaveis.total_despesas_variaveis)}</p>
      </section>
    </>
  );
};

export default ParamDespesasVariaveis;
