export default {
  elementos: [
    {
      value: 'Despesas Administrativas',
      label: 'Despesas Administrativas',
    },
    {
      value: 'Pessoal Administrativo',
      label: 'Pessoal Administrativo',
    },
    {
      value: 'Segurança',
      label: 'Segurança',
    },
    {
      value: 'EPIs Motorista',
      label: 'EPIs Motorista',
    },
  ],
  motoristas: [
    {
      value: 'Benefícios',
      label: 'Benefícios',
    },
  ],
  gerenciamentoDeRisco: [
    {
      value: 'Equipe própria',
      label: 'Equipe própria',
    },
  ],
  pneu: [
    {
      value: 'Pneus',
      label: 'Pneus',
    },
  ],
};
