import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;

  .input {
    margin-top: 8px;
    
  }
 
  article {
    min-width: 100%;
  }
  .article {
    
    width: 100%;
  }
  .input {
    width: 100%;
  }
  
  .react-autosuggest__container {
    position: relative;
    margin-top: 0;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 40px;
    
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;

    border: 1px solid #232129;
    border-width: 1px;
    border-radius: 10px;
    color: #000;

    &::placeholder {
      color: #232129;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .select{
    width:100%;
  }
  > p {
    padding: 6px 0;
    
    border-top: 10px solid var(--blue-b);
    border-bottom: 10px solid var(--blue-b);
    margin-bottom: 1px;
    font-size: 15px;
    text-align: center;
  }

  .react-select__control,
  .css-2b097c-container {
      width: 100%;
      min-width: 100%;
  
      height: 40px;
      margin-top: 28px;
      border-radius: 10px;
      font-size: 16px;
      margin-bottom: 8px;
    }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
`;
