import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 20px;
  > span {
    padding-bottom: 30px;
    text-align: center;
    font-size: 25px;
    border-radius: 15px;
    width: 100%;
    color: var(--blue-b);
  }
  article {
    padding: 0px;
    text-align: center;
    border-radius: 15px;

    width: 100%;

    input,
    select {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;
      padding: 10px 16px;
      font-size: 16px;
      width: 100%;

      border-radius: 10px;
    }
    h4 {
      color: var(--blue-b);
      margin-bottom: 10px;
    }

    p {
      color: #000;
    }
  }
`;
