import React from 'react';
import {
  FiSettings,
  FiUser,
  FiCompass,
  FiBell,
  FiClipboard,
  FiPlus,
} from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { FaRoute } from 'react-icons/fa';
import descriptionSystem from './descritivo do sistema.pdf';
import { Container } from './styles';
import { useMenu } from '../../hooks/menu';

import Dropdown from './Dropdown';
import { useTimeline } from '../../hooks/timeline';
import { useAuth } from '../../hooks/auth';

const ListMenu: React.FC = () => {
  const { expansed } = useMenu();
  const { perfil } = useAuth().user;
  const { reset } = useTimeline();

  const conditionOfNotification =
    perfil === 'admin' ;

  return (
    <Container isExpansed={expansed}>
      <li>
        <NavLink
          to="/calculos"
          onClick={() => {
            reset();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }}
        >
          <FiClipboard size={18} />
          <p>Cálculos Efetuados</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/rentabilidade"
          onClick={() => {
            reset();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }}
        >
          <FaRoute size={18} />
          <p>Rentabilidade</p>
        </NavLink>
      </li>
      <Dropdown icon={FiSettings} title="Cadastros">
        <li>
          <NavLink to="users">
            <FiUser size={18} />
            <p>Usuários</p>
          </NavLink>
        </li>
        <li>
          <NavLink to="empresa">
            <FiCompass size={18} />
            <p>Conta</p>
          </NavLink>
        </li>
        <li>
          <NavLink to="addField">
            <FiPlus size={18} />
            <p>Adicionar campo</p>
          </NavLink>
        </li>
      </Dropdown>
      {conditionOfNotification && (
        <li>
          <NavLink to="/notificacoes">
            <FiBell size={18} />
            <p>Notificações</p>
          </NavLink>
        </li>
      )}

      <li>
        <button
          type="button"
          onClick={() => {
            window.open(descriptionSystem);
          }}
        >
          <FiClipboard size={18} />
          <p>Tutorial</p>
        </button>
      </li>
    </Container>
  );
};

export default ListMenu;
