import React, { useState, useCallback } from 'react';

import { FiInfo, FiAlertTriangle, FiChevronLeft } from 'react-icons/fi';
import { Container, Header, Body } from './styles';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

interface DropDownBoxProps {
  typeNot: 'Informativo' | 'Urgente';
  title: string;
  msg: string;
  cod: number;
}

const DropDownBox: React.FC<DropDownBoxProps> = ({
  typeNot,
  title,
  msg,
  cod,
}: DropDownBoxProps) => {
  const [open, setOpen] = useState(false);
  const [read, setRead] = useState(false);
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const { id, conta } = useAuth().user;

  const handleOpen = useCallback(async () => {
    if (open) {
      setOpen(false);
    } else {
      setRead(true);
      setOpen(true);
    }

    if (!read) {
      try {
        await api.post(
          '/api/v1/notificacoes/read',
          {
            conta,
            user_request: id,
            cod_notificacao: cod,
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
      } catch (err) {
        console.log(err);
      }
    }
  }, [open, read, cod, conta, id, token]);
  return (
    <Container typeNot={typeNot} onClick={handleOpen}>
      <Header isVisible={open}>
        {typeNot === 'Informativo' && <FiInfo size={20} />}
        {typeNot === 'Urgente' && <FiAlertTriangle size={20} />}
        <div>
          <h2>{title}</h2>
        </div>
        <FiChevronLeft size={18} />
      </Header>
      <Body isVisible={open}>
        <p>{msg}</p>
      </Body>
    </Container>
  );
};

export default DropDownBox;
