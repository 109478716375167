import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled.div`
  form {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 30px;

    div[type='text'] {
      padding: 6px;
      border-radius: 5px;
      width: 100%;
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }

    button[type='button'] {
      width: 200px;
      padding: 10px 5px;
      border: 0;
      background-color: var(--blue-b);
      border-radius: 5px;
      color: #fff;
      height: 100%;
      margin: auto;

      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }

    button[type='submit'] {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 0;
      width: 200px;
      padding: 10px 5px;
      border: 0;
      background-color: var(--text);
      border-radius: 5px;
      color: #fff;
    }
  }
`;

export const Table = styled(animated.div)``;

export const Box = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--blue-b);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--yellow);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue-b);
  }

  padding: 20px;
  display: block;
  margin: 30px 10px;
  max-height: 400px;
  
  width: calc(100% - 53%);
  overflow: auto;
  border: 1px solid var(--blue-b);

  @media screen and (max-width: 959px) {
    width: 100%;
    max-height: max-content;
    overflow: hidden;
  }

  section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }

  input {
    flex: 1;
  }

  h2 {
    color: var(--blue-b);
    margin-bottom: 20px;
  }

  h5 {
    font-size: 18px;
    margin: 4px;
    flex: 1;
  }
`;

export const Header = styled.h2`
  text-align: center;
  color: var(--blue-b);
  font-size: 23px;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
