import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler, Scope } from '@unform/core';
import ReactSelect from 'react-select/creatable';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Header, Box } from './styles';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import { useTimeline } from '../../../../hooks/timeline';
import Loading from '../../../../components/Loading';
import formatMoney from '../../../../utils/formatMoney';
import estados from './states';

import Select from '../../../../components/Select';

interface IArrayZero {
  subtipo: string;
  itens: [
    {
      agrupamento: number;
      subtipo: string;
      cod: number;
      campo: string;
      valor: number;
      info: string;
      read_only: number;
      elemento: string | number;
    },
  ];
}

interface IModalidade {
  cod: number;
  valor: string;
}

const Cargoinsurance: React.FC = () => {
  const { id, conta } = useAuth().user;
  /**
   * Tipo seguro de carga:
   *  0 === Não Escolhida
   *  1 === modelo
   *  2 === taxa
   */
  const [type, setType] = useState(0);

  const [origemDefault, setOrigemDefault] = useState(0);
  const [destinoDefault, setDestinoDefault] = useState(0);
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);

  const [arrayPositionZeroPattern, setArrayPositionZeroPattern] = useState<
    IArrayZero[]
  >([]);

  const format3casas = (value: any): any => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      
      minimumFractionDigits:3,
      
    })
      .format(Number(value))
      .split('-')
      .join('');
  };
  const formatDecimal = (value: any): any => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      
      minimumFractionDigits:2,
      
    })
      .format(Number(value))
      .split('-')
      .join('');
      };

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      setLoading(true);
      const response = await api.get(
        `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Seguros de carga&user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      console.log(response.data);
      setArrayPositionZeroPattern(response.data.data);
      const config = response.data.data.find((e: any) => e.subtipo === 'conf');

      const configValue = config.itens.map((e: any): void => e.valor)[0];
      
      const origemDResponse = response.data.data[0].itens.find(
        (e: any) => e.elemento === 153,
      );
      const destinoDResponse = response.data.data[0].itens.find(
        (e: any) => e.elemento === 154,
      );

      setType(configValue === 0 ? 1 : configValue);
      setOrigemDefault(origemDResponse.valor);
      setDestinoDefault(destinoDResponse.valor);
      setLoading(false);
    };

    loadTabs();
  }, [conta, id, token, temp]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        const array = data.modules.filter(
          (e: any) => e !== '' && e.valor !== undefined,
        );


        
        console.log(array);
        await api.put(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Seguros de carga&user_request=${id}&conta=${conta}`,
          {
            user_request: id,
            conta,
            calculo: temp,
            itens: array.map((e: any) => ({
              valor: e.valor
                .toString()
                .replace('R$', '')
                .trim()
                .replaceAll('.', '')
                .replace(',', '.'),
              cod: e.cod,
            })),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
         next();
      } catch (err) {
        console.error(err);
      }
    },
    [next, conta, id, token, temp],
  );

  // Estrada Origem: 153
  // Estrada Destino: 154
  const typeOptions = [
    { label: 'Modelo 1 - desconto na tabela nacional', value: 1 },
    { label: 'Modelo 2 - taxa única', value: 2 },
  ];

  let origem = 'SP';
  let destino = 'SP';

  const valueInput = useCallback((value: number, element: string | number):
    | string
    | number => {
    if (element === 130) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumSignificantDigits: 2,
      })
        .format(Number(value))
        .split('-')
        .join('');
    }

    if (element === 165 || element === 155) {
      return new Intl.NumberFormat('pt-BR')
        .format(Number(value))
        .split('-')
        .join('');
    }

    return formatMoney(value);
  }, []);

  return (
    <Container>
      {loading ? (
        <Loading isLoading={loading} />
      ) : (
        <>
          <Header>Seguro de Carga</Header>
          <ReactSelect
            name="type"
            className="type"
            value={
              type === 1
                ? typeOptions.filter((option) => option.value === 1)
                : typeOptions.filter((option) => option.value === 2)
            }
            options={typeOptions}
            placeholder="Tipo"
            onChange={(e: any) => {
              setType(e.value);
              //    arrayPositionZeroPattern[0].itens.map(item =>);
              const newTelements = [...arrayPositionZeroPattern];

              const idxCustoViagemModDesconto = arrayPositionZeroPattern[0].itens.find(
                (x) => x.elemento === 158,
              );

              const idxCustoViagemSeguroRcf = arrayPositionZeroPattern[2].itens.find(
                (x) => x.elemento === 163,
              );

              const idxCustoViagemSeguroAmb = arrayPositionZeroPattern[3].itens.find(
                (x) => x.elemento === 166,
              );

              const idxCustoViagemOutrosSeg = arrayPositionZeroPattern[4].itens.find(
                (x) => x.elemento === 167,
              );

              const qtdMedia = arrayPositionZeroPattern[5].itens.find(
                (x) => x.elemento === 168,
              );

              const idxCustoTotalSeguroGeral = arrayPositionZeroPattern[5].itens.find(
                (x) => x.elemento === 169,
              );
              const idxCustoTotalTaxa = arrayPositionZeroPattern[1].itens.find(
                (x) => x.elemento === 161,
              );

              if (
                idxCustoViagemModDesconto &&
                idxCustoViagemSeguroRcf &&
                idxCustoViagemSeguroAmb &&
                idxCustoViagemOutrosSeg &&
                qtdMedia &&
                idxCustoTotalSeguroGeral &&
                idxCustoTotalTaxa
              ) {
                const tCustoModelo =
                  (idxCustoViagemModDesconto.valor +
                    idxCustoViagemSeguroRcf.valor +
                    idxCustoViagemSeguroAmb.valor +
                    idxCustoViagemOutrosSeg.valor) *
                  qtdMedia.valor;

                const tCustoTaxa =
                  (idxCustoTotalTaxa.valor +
                    idxCustoViagemSeguroRcf.valor +
                    idxCustoViagemSeguroAmb.valor +
                    idxCustoViagemOutrosSeg.valor) *
                  qtdMedia.valor;

                idxCustoTotalSeguroGeral.valor =
                  type === 1 ? tCustoModelo : tCustoTaxa;
              }

              const modifyRCTR: any = newTelements.map((element) => {
                if (
                  element.subtipo ===
                  'Seguro RCTR-C (Modelo desconto na tabela de taxas de seguro)'
                ) {
                  return {
                    ...element,
                    itens: element.itens.map((items) => ({
                      ...items,
                      valor: 0,
                    })),
                  };
                }
                return {
                  ...element,
                  itens: element.itens,
                };
              });

              const modifyTaxa: any = newTelements.map((element) => {
                if (element.subtipo === 'Modelo taxa única') {
                  return {
                    ...element,
                    itens: element.itens.map((items) => ({
                      ...items,
                      valor: 0,
                    })),
                  };
                }
                return {
                  ...element,
                  itens: element.itens,
                };
              });
              setArrayPositionZeroPattern(
                e.value === 1 ? modifyTaxa : modifyRCTR,
              );
            }}
            isClearable={false}
          />
          <Form ref={formRef} onSubmit={handleAddSubmit}>
            {arrayPositionZeroPattern.map((tab, idxtab) => {
              return (
                <>
                  <Box
                    key={tab.subtipo}
                    none={
                      tab.subtipo === 'conf' ||
                      (tab.subtipo ===
                        'Seguro RCTR-C (Modelo desconto na tabela de taxas de seguro)' &&
                        type == 2) ||
                      (tab.subtipo === 'Modelo taxa única' && type == 1)
                    }
                  >
                    <h2>{tab.subtipo}</h2>
                    {tab.itens
                      ? tab.itens.map((item, index) => {
                          if (tab.subtipo === 'Seguro ambiental (se houver)') {
                            return (
                              <Scope
                                key={item.campo}
                                path={`modules[${item.elemento}]`}
                              >
                                <section>
                                  <h5>{item.campo}</h5>
                                  <Input
                                    name="valor"
                                    type="text"
                                    value={valueInput(
                                      item.valor,
                                      item.elemento,
                                    )}
                                    readOnly={item.read_only !== 0}
                                    disabled={item.read_only !== 0}
                                    info={item.info && item.info}
                                    onChange={(e): void => {
                                      const newTelements = [
                                        ...arrayPositionZeroPattern,
                                      ];
                                      if (item.elemento === 165) {
                                        newTelements[idxtab].itens[
                                          index
                                        ].valor = Number(
                                          e.target.value
                                            .split(/[^\d]+/g)
                                            .join('')
                                            .trim(),
                                        );
                                      } else {
                                        newTelements[idxtab].itens[
                                          index
                                        ].valor = Number(
                                          Number(
                                            e.target.value
                                              .split(/[R$\\/,/./]/)
                                              .join('')
                                              .split(/[^\d]+/g)
                                              .join('')
                                              .trim(),
                                          ) / 100,
                                        );
                                      }

                                      const idxApolice = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 164);

                                      const qtdViagens = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 165);

                                      const custoViagens = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 166);

                                      if (
                                        idxApolice &&
                                        qtdViagens &&
                                        custoViagens
                                      ) {
                                        if (qtdViagens.valor) {
                                          custoViagens.valor =
                                            idxApolice.valor / qtdViagens.valor;
                                        }
                                      }

                                      const idxCustoViagemModDesconto = arrayPositionZeroPattern[0].itens.find(
                                        (x) => x.elemento === 158,
                                      );

                                      const idxCustoViagemSeguroRcf = arrayPositionZeroPattern[2].itens.find(
                                        (x) => x.elemento === 163,
                                      );

                                      const idxCustoViagemSeguroAmb = arrayPositionZeroPattern[3].itens.find(
                                        (x) => x.elemento === 166,
                                      );

                                      const idxCustoViagemOutrosSeg = arrayPositionZeroPattern[4].itens.find(
                                        (x) => x.elemento === 167,
                                      );

                                      const qtdMedia = arrayPositionZeroPattern[5].itens.find(
                                        (x) => x.elemento === 168,
                                      );

                                      const idxCustoTotalSeguroGeral = arrayPositionZeroPattern[5].itens.find(
                                        (x) => x.elemento === 169,
                                      );
                                      const idxCustoTotalTaxa = arrayPositionZeroPattern[1].itens.find(
                                        (x) => x.elemento === 161,
                                      );

                                      if (
                                        idxCustoViagemModDesconto &&
                                        idxCustoViagemSeguroRcf &&
                                        idxCustoViagemSeguroAmb &&
                                        idxCustoViagemOutrosSeg &&
                                        qtdMedia &&
                                        idxCustoTotalSeguroGeral &&
                                        idxCustoTotalTaxa
                                      ) {
                                        const tCustoModelo =
                                          (idxCustoViagemModDesconto.valor +
                                            idxCustoViagemSeguroRcf.valor +
                                            idxCustoViagemSeguroAmb.valor +
                                            idxCustoViagemOutrosSeg.valor) *
                                          qtdMedia.valor;

                                        const tCustoTaxa =
                                          (idxCustoTotalTaxa.valor +
                                            idxCustoViagemSeguroRcf.valor +
                                            idxCustoViagemSeguroAmb.valor +
                                            idxCustoViagemOutrosSeg.valor) *
                                          qtdMedia.valor;

                                        idxCustoTotalSeguroGeral.valor =
                                          type === 1
                                            ? tCustoModelo
                                            : tCustoTaxa;
                                      }

                                      setArrayPositionZeroPattern(newTelements);
                                    }}
                                  />
                                  <Input
                                    name="cod"
                                    type="hidden"
                                    value={item.cod}
                                  />
                                </section>
                              </Scope>
                            );
                          }

                          if (tab.subtipo === 'Seguro RCF-DC (se houver)') {
                            return (
                              <Scope
                                key={item.campo}
                                path={`modules[${item.elemento}]`}
                              >
                                <section>
                                  <h5>{item.campo}</h5>
                                  <Input
                                    name="valor"
                                    type="text"
                                    value={
                                      item.elemento === 162
                                        ? format3casas(item.valor)
                                            .split(/[R$\\/]/)
                                            .join('')
                                        : formatMoney(item.valor)
                                    }
                                    readOnly={item.read_only !== 0}
                                    disabled={item.read_only !== 0}
                                    info={item.info && item.info}
                                    onChange={(e): void => {
                                      const newTelements = [
                                        ...arrayPositionZeroPattern,
                                      ];
                                      newTelements[idxtab].itens[
                                        index
                                      ].valor = 
                                      item.elemento === 162
                                      ? Number(
                                          Number(
                                            e.target.value
                                              .split(/[R$\\/,/./]/)
                                              .join('')
                                              .split(/[^\d]+/g)
                                              .join('')
                                              .trim(),
                                          ) / 1000,
                                        ):
                                      Number(
                                              Number(
                                                e.target.value
                                                  .split(/[R$\\/,/./]/)
                                                  .join('')
                                                  .split(/[^\d]+/g)
                                                  .join('')
                                                  .trim(),
                                              ) / 100,
                                            );
                                      
                                      
                                      Number(
                                        Number(
                                          e.target.value
                                            .split(/[R$\\/,/./]/)
                                            .join('')
                                            .split(/[^\d]+/g)
                                            .join('')
                                            .trim(),
                                        ) / 100,
                                      );

                                      const idxMediaMercadoria = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 174);

                                      const idxCustoViagem = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 163);

                                      const idxTaxaNegociada = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 162);

                                      if (
                                        idxMediaMercadoria &&
                                        idxTaxaNegociada &&
                                        idxCustoViagem
                                      ) {
                                        idxCustoViagem.valor =
                                          (idxMediaMercadoria.valor *
                                            idxTaxaNegociada.valor) /
                                          100;
                                      }

                                      const idxCustoViagemModDesconto = arrayPositionZeroPattern[0].itens.find(
                                        (x) => x.elemento === 158,
                                      );

                                      const idxCustoViagemSeguroRcf = arrayPositionZeroPattern[2].itens.find(
                                        (x) => x.elemento === 163,
                                      );

                                      const idxCustoViagemSeguroAmb = arrayPositionZeroPattern[3].itens.find(
                                        (x) => x.elemento === 166,
                                      );

                                      const idxCustoViagemOutrosSeg = arrayPositionZeroPattern[4].itens.find(
                                        (x) => x.elemento === 167,
                                      );

                                      const qtdMedia = arrayPositionZeroPattern[5].itens.find(
                                        (x) => x.elemento === 168,
                                      );

                                      const idxCustoTotalSeguroGeral = arrayPositionZeroPattern[5].itens.find(
                                        (x) => x.elemento === 169,
                                      );
                                      const idxCustoTotalTaxa = arrayPositionZeroPattern[1].itens.find(
                                        (x) => x.elemento === 161,
                                      );

                                      if (
                                        idxCustoViagemModDesconto &&
                                        idxCustoViagemSeguroRcf &&
                                        idxCustoViagemSeguroAmb &&
                                        idxCustoViagemOutrosSeg &&
                                        qtdMedia &&
                                        idxCustoTotalSeguroGeral &&
                                        idxCustoTotalTaxa
                                      ) {
                                        const tCustoModelo =
                                          (idxCustoViagemModDesconto.valor +
                                            idxCustoViagemSeguroRcf.valor +
                                            idxCustoViagemSeguroAmb.valor +
                                            idxCustoViagemOutrosSeg.valor) *
                                          qtdMedia.valor;

                                        const tCustoTaxa =
                                          (idxCustoTotalTaxa.valor +
                                            idxCustoViagemSeguroRcf.valor +
                                            idxCustoViagemSeguroAmb.valor +
                                            idxCustoViagemOutrosSeg.valor) *
                                          qtdMedia.valor;

                                        idxCustoTotalSeguroGeral.valor =
                                          type === 1
                                            ? tCustoModelo
                                            : tCustoTaxa;
                                      }

                                      setArrayPositionZeroPattern(newTelements);
                                    }}
                                  />
                                  <Input
                                    name="cod"
                                    type="hidden"
                                    value={item.cod}
                                  />
                                </section>
                              </Scope>
                            );
                          }

                          if (tab.subtipo === 'Custo total de seguro') {
                            return (
                              <Scope
                                key={item.campo}
                                path={`modules[${item.elemento}]`}
                              >
                                <section>
                                  <h5>{item.campo}</h5>
                                  <Input
                                    name="valor"
                                    type="text"
                                    value={
                                      item.elemento === 168
                                        ? item.valor
                                        : formatMoney(item.valor)
                                    }
                                    readOnly={item.read_only !== 0}
                                    disabled={item.read_only !== 0}
                                    info={item.info && item.info}
                                    onChange={(e): void => {
                                      const newTelements = [
                                        ...arrayPositionZeroPattern,
                                      ];
                                      newTelements[idxtab].itens[index].valor =
                                        item.elemento === 168
                                          ? Number(
                                              e.target.value
                                                .split(/[^\d]+/g)
                                                .join('')
                                                .trim(),
                                            )
                                          : Number(
                                              Number(
                                                e.target.value
                                                  .split(/[R$\\/,/./]/)
                                                  .join('')
                                                  .split(/[^\d]+/g)
                                                  .join('')
                                                  .trim(),
                                              ) / 100,
                                            );

                                      const idxCustoViagemModDesconto = arrayPositionZeroPattern[0].itens.find(
                                        (x) => x.elemento === 158,
                                      );

                                      const idxCustoViagemSeguroRcf = arrayPositionZeroPattern[2].itens.find(
                                        (x) => x.elemento === 163,
                                      );

                                      const idxCustoViagemSeguroAmb = arrayPositionZeroPattern[3].itens.find(
                                        (x) => x.elemento === 166,
                                      );

                                      const idxCustoViagemOutrosSeg = arrayPositionZeroPattern[4].itens.find(
                                        (x) => x.elemento === 167,
                                      );

                                      const qtdMedia = arrayPositionZeroPattern[5].itens.find(
                                        (x) => x.elemento === 168,
                                      );

                                      const idxCustoTotalSeguroGeral = arrayPositionZeroPattern[5].itens.find(
                                        (x) => x.elemento === 169,
                                      );
                                      const idxCustoTotalTaxa = arrayPositionZeroPattern[1].itens.find(
                                        (x) => x.elemento === 161,
                                      );

                                      if (
                                        idxCustoViagemModDesconto &&
                                        idxCustoViagemSeguroRcf &&
                                        idxCustoViagemSeguroAmb &&
                                        idxCustoViagemOutrosSeg &&
                                        qtdMedia &&
                                        idxCustoTotalSeguroGeral &&
                                        idxCustoTotalTaxa
                                      ) {
                                        const tCustoModelo =
                                          (idxCustoViagemModDesconto.valor +
                                            idxCustoViagemSeguroRcf.valor +
                                            idxCustoViagemSeguroAmb.valor +
                                            idxCustoViagemOutrosSeg.valor) *
                                          qtdMedia.valor;

                                        const tCustoTaxa =
                                          (idxCustoTotalTaxa.valor +
                                            idxCustoViagemSeguroRcf.valor +
                                            idxCustoViagemSeguroAmb.valor +
                                            idxCustoViagemOutrosSeg.valor) *
                                          qtdMedia.valor;

                                        idxCustoTotalSeguroGeral.valor =
                                          type === 1
                                            ? tCustoModelo
                                            : tCustoTaxa;
                                      }

                                      setArrayPositionZeroPattern(newTelements);
                                    }}
                                  />
                                  <Input
                                    name="cod"
                                    type="hidden"
                                    value={item.cod}
                                  />
                                </section>
                              </Scope>
                            );
                          }

                          return (
                            <Scope
                              key={item.campo}
                              path={`modules[${item.elemento}]`}
                            >
                               <section>
                                <h5>{item.campo}</h5>
                                {item.elemento === 153 ||
                                item.elemento === 154 ? (
                                  <Select
                                    name="valor"
                                    options={estados}
                                    defaultValue={
                                      item.elemento === 153
                                        ? estados.filter(
                                            (option) =>
                                              option.value === origemDefault,
                                          )
                                        : estados.filter(
                                            (option) =>
                                              option.value === destinoDefault,
                                          )
                                    }
                                    onChange={(e: any) => {
                                      const getValue = async (): Promise<
                                        void
                                      > => {

                                       

                                            
                                        if (item.elemento === 153) {
                                          origem = e.label;
                                          setOrigemDefault(e.value);
                                          item.valor =  e.value;
                                          destino = estados.filter(
                                                          (option) =>
                                                          option.value === destinoDefault
                                                        )[0].label;
                                        }
                                        if (item.elemento === 154) {
                                          destino = e.label;
                                          item.valor =  e.value;
                                          setDestinoDefault(e.value);
                                          origem = estados.filter(
                                            (option) =>
                                              option.value ===origemDefault)[0].label;
                                        
                                         
                                        }

                                        
                                        const response = await api.get(
                                          `api/v1/utilities/segurocarga?user_request=${id}&conta=${conta}&origem=${origem}&destino=${destino}`,
                                          {
                                            headers: {
                                              'x-access-token': token,
                                            },
                                          },
                                        );


                                        const valueTaxa =
                                          response.data.data[0].valor;

                                        const newArray = [
                                          ...arrayPositionZeroPattern,
                                        ];
                                        const idxTaxaSegudo = newArray[
                                          idxtab
                                        ].itens.find((x) => x.elemento === 155);
                                        let vlTxSeguroFormatado = 0;
                                        if (idxTaxaSegudo) {
                                          idxTaxaSegudo.valor = valueTaxa.replace(
                                            ',',
                                            '.',
                                          );
                                          vlTxSeguroFormatado = valueTaxa.replace(
                                            ',',
                                            '.',
                                          );
                                        }
                                        const idxMediaMercadoria = newArray[
                                          idxtab
                                        ].itens.find((x) => x.elemento === 152);

                                        const idxSeguroDeTab = newArray[
                                          idxtab
                                        ].itens.find((x) => x.elemento === 156);

                                        if (
                                          idxMediaMercadoria &&
                                          idxTaxaSegudo &&
                                          idxSeguroDeTab
                                        ) {
                                          idxSeguroDeTab.valor =
                                            (idxMediaMercadoria.valor *
                                              vlTxSeguroFormatado) /
                                            100;
                                        }

                                        const idxCustoViagemModDesconto = arrayPositionZeroPattern[0].itens.find(
                                          (x) => x.elemento === 158,
                                        );

                                        






                                        
                                        const idxCustoViagemSeguroRcf = arrayPositionZeroPattern[2].itens.find(
                                          (x) => x.elemento === 163,
                                        );

                                        const idxCustoViagemSeguroAmb = arrayPositionZeroPattern[3].itens.find(
                                          (x) => x.elemento === 166,
                                        );

                                        const idxCustoViagemOutrosSeg = arrayPositionZeroPattern[4].itens.find(
                                          (x) => x.elemento === 167,
                                        );

                                        const qtdMedia = arrayPositionZeroPattern[5].itens.find(
                                          (x) => x.elemento === 168,
                                        );

                                        const idxCustoTotalSeguroGeral = arrayPositionZeroPattern[5].itens.find(
                                          (x) => x.elemento === 169,
                                        );
                                        const idxCustoTotalTaxa = arrayPositionZeroPattern[1].itens.find(
                                          (x) => x.elemento === 161,
                                        );

  
                                        const idxCustoViagem = arrayPositionZeroPattern[
                                          idxtab
                                        ].itens.find((x) => x.elemento === 158);
  
                                        const idxApolice = arrayPositionZeroPattern[
                                          idxtab
                                        ].itens.find((x) => x.elemento === 157);
  

                                        if (
                                          idxCustoViagem &&
                                          idxSeguroDeTab &&
                                          idxApolice
                                        ) {
                                          idxCustoViagem.valor =
                                            idxSeguroDeTab.valor -
                                            idxSeguroDeTab.valor *
                                              (idxApolice.valor / 100);
                                        }                                      
  

                                        
                                        if (
                                          idxCustoViagemModDesconto &&
                                          idxCustoViagemSeguroRcf &&
                                          idxCustoViagemSeguroAmb &&
                                          idxCustoViagemOutrosSeg &&
                                          qtdMedia &&
                                          idxCustoTotalSeguroGeral &&
                                          idxCustoTotalTaxa
                                        ) {
                                          const tCustoModelo =
                                            (idxCustoViagemModDesconto.valor +
                                              idxCustoViagemSeguroRcf.valor +
                                              idxCustoViagemSeguroAmb.valor +
                                              idxCustoViagemOutrosSeg.valor) *
                                            qtdMedia.valor;

                                          const tCustoTaxa =
                                            (idxCustoTotalTaxa.valor +
                                              idxCustoViagemSeguroRcf.valor +
                                              idxCustoViagemSeguroAmb.valor +
                                              idxCustoViagemOutrosSeg.valor) *
                                            qtdMedia.valor;

                                          idxCustoTotalSeguroGeral.valor =
                                            type === 1
                                              ? tCustoModelo
                                              : tCustoTaxa;
                                        }

                                        setArrayPositionZeroPattern(newArray);
                                      };
                                      getValue();
                                    }}
                                    placeholder="Estado"
                                    isClearable={false}
                                  />
                                ) : (
                                  <Input
                                    name="valor"
                                    type="text"
                                    value={
                                      item.elemento === 160
                                      ? format3casas(item.valor)
                                          .split(/[R$\\/]/)
                                          .join('')
                                      :
                                      item.elemento === 157 
                                      ?  formatDecimal(item.valor)
                                      : item.elemento === 155
                                        ? formatDecimal(item.valor)
                                        : formatMoney(item.valor)
                                    }
                                    readOnly={item.read_only !== 0}
                                    disabled={item.read_only !== 0}
                                    info={item.info && item.info}
                                    onChange={(e): void => {
                                      const newTelements = [
                                        ...arrayPositionZeroPattern,
                                      ];
                                      newTelements[idxtab].itens[index].valor =
                                      item.elemento === 160
                                      ? Number(
                                          Number(
                                            e.target.value
                                              .split(/[R$\\/,/./]/)
                                              .join('')
                                              .split(/[^\d]+/g)
                                              .join('')
                                              .trim(),
                                          ) / 1000,
                                        ):
                                       
                                      item.elemento === 157
                                          ? Number(
                                              e.target.value
                                                .split(/[^\d]+/g)
                                                .join('')
                                                .trim(),
                                            )/100
                                          : Number(
                                              Number(
                                                e.target.value
                                                  .split(/[R$\\/,/./]/)
                                                  .join('')
                                                  .split(/[^\d]+/g)
                                                  .join('')
                                                  .trim(),
                                              ) / 100,
                                            );

                                      const idxMediaMercadoria = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 152);

                                      const idxTaxaSegudo = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 155);

                                      const idxSeguroDeTab = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 156);

                                      const idxCustoViagem = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 158);

                                      const idxApolice = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 157);

                                      if (
                                        idxMediaMercadoria &&
                                        idxTaxaSegudo &&
                                        idxSeguroDeTab
                                      ) {
                                        idxSeguroDeTab.valor =
                                          (idxMediaMercadoria.valor *
                                            idxTaxaSegudo.valor) /
                                          100;
                                      }

                                      if (
                                        idxCustoViagem &&
                                        idxSeguroDeTab &&
                                        idxApolice
                                      ) {
                                        idxCustoViagem.valor =
                                          idxSeguroDeTab.valor -
                                          idxSeguroDeTab.valor *
                                            (idxApolice.valor / 100);
                                      }

                                      // Taxa

                                      const mediaMercadoria = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 159);

                                      const taxaNegociada = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 160);

                                      const custoViagemTaxa = arrayPositionZeroPattern[
                                        idxtab
                                      ].itens.find((x) => x.elemento === 161);

                                      if (
                                        mediaMercadoria &&
                                        taxaNegociada &&
                                        custoViagemTaxa
                                      ) {
                                        custoViagemTaxa.valor =
                                          (mediaMercadoria.valor *
                                            taxaNegociada.valor) /
                                          100;
                                      }

                                      const idxCustoViagemModDesconto = arrayPositionZeroPattern[0].itens.find(
                                        (x) => x.elemento === 158,
                                      );

                                      const idxCustoViagemSeguroRcf = arrayPositionZeroPattern[2].itens.find(
                                        (x) => x.elemento === 163,
                                      );

                                      const idxCustoViagemSeguroAmb = arrayPositionZeroPattern[3].itens.find(
                                        (x) => x.elemento === 166,
                                      );

                                      const idxCustoViagemOutrosSeg = arrayPositionZeroPattern[4].itens.find(
                                        (x) => x.elemento === 167,
                                      );

                                      const qtdMedia = arrayPositionZeroPattern[5].itens.find(
                                        (x) => x.elemento === 168,
                                      );

                                      const idxCustoTotalSeguroGeral = arrayPositionZeroPattern[5].itens.find(
                                        (x) => x.elemento === 169,
                                      );
                                      const idxCustoTotalTaxa = arrayPositionZeroPattern[1].itens.find(
                                        (x) => x.elemento === 161,
                                      );

                                      if (
                                        idxCustoViagemModDesconto &&
                                        idxCustoViagemSeguroRcf &&
                                        idxCustoViagemSeguroAmb &&
                                        idxCustoViagemOutrosSeg &&
                                        qtdMedia &&
                                        idxCustoTotalSeguroGeral &&
                                        idxCustoTotalTaxa
                                      ) {
                                        const tCustoModelo =
                                          (idxCustoViagemModDesconto.valor +
                                            idxCustoViagemSeguroRcf.valor +
                                            idxCustoViagemSeguroAmb.valor +
                                            idxCustoViagemOutrosSeg.valor) *
                                          qtdMedia.valor;

                                        const tCustoTaxa =
                                          (idxCustoTotalTaxa.valor +
                                            idxCustoViagemSeguroRcf.valor +
                                            idxCustoViagemSeguroAmb.valor +
                                            idxCustoViagemOutrosSeg.valor) *
                                          qtdMedia.valor;

                                        idxCustoTotalSeguroGeral.valor =
                                          type === 1
                                            ? tCustoModelo
                                            : tCustoTaxa;
                                      }
                                      setArrayPositionZeroPattern(newTelements);
                                    }}
                                  />
                                )}

                                <Input
                                  name="cod"
                                  type="hidden"
                                  value={item.cod}
                                />
                              </section>
                            </Scope>
                          );
                        })
                      : []}
                  </Box>
                </>
              );
            })}
            <button type="submit">Salvar</button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default Cargoinsurance;
