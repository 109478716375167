import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SubmitHandler } from '@unform/core';
import api from '../../services/api';
import login from '../../assets/logo.png';

import { Container, FormContainer } from './styles';

import Loading from '../../components/Loading';
import Input from '../../components/Input';
import Button from '../../components/Button';

const Forgot: React.FC = () => {
  const hash = useLocation().search;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleForgot: SubmitHandler = async (data) => {
    try {
      setLoading(true);
      await api.post('/api/v1/usuarios/reset_password', {
        hash: hash.split('=')[1],
        new_password: data.new_password,
      });
      history.push('/');
      toast.success('Senha alterada com sucesso.');
      setLoading(false);
    } catch {
      toast.error('Erro ao mudar senha.');
    }
  };

  return (
    <Container>
      <FormContainer onSubmit={handleForgot}>
        <img src={login} alt="Logon" />
        <h1>Digite a sua nova senha</h1>
        <Input name="new_password" />
        <Button loading={loading} type="submit">
          Confirmar
        </Button>
      </FormContainer>
    </Container>
  );
};

export default Forgot;
