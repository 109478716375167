import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler, Scope } from '@unform/core';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Header } from './styles';
import { Box } from '../components';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import Loading from '../../../../components/Loading';
import { useTimeline } from '../../../../hooks/timeline';
import formatMoney from '../../../../utils/formatMoney';

interface IArrayZero {
  subtipo: string;
  itens: [
    {
      agrupamento: number;
      subtipo: string;
      cod: number;
      campo: string;
      valor: number;
      info: string;
      read_only: number;
      elemento: string | number;
    },
  ];
}

interface IModalidade {
  agrupamento: number;
  subtipo: string;
  cod: number;
  campo: string;
  valor: number;
  info: string;
  read_only: number;
  elemento: string | number;
}

const RiskManagement: React.FC = () => {
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const [totalVeic, setTotal] = useState(0);
  const [gerenciamento, setGerenciamento] = useState(0);

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);

  const [arrayPositionZeroPattern, setArrayPositionZeroPattern] = useState<
    IArrayZero[]
  >([]);

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      setLoading(true);
      const response = await api.get(
        `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Gerenciamento de Riscos&user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      setArrayPositionZeroPattern(response.data.data[0]);
      setTotal(response.data.data[1].total_veiculo);
      setGerenciamento(
        response.data.data[1].custo_unitario_gerenciamento_riscos === null
          ? 0
          : response.data.data[1].custo_unitario_gerenciamento_riscos,
      );
      console.log('Data', response.data.data);
      setLoading(false);
    };

    loadTabs();
  }, [conta, id, token, temp]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        const array = data.modules.filter(
          (e: any) => e !== '' && e.valor !== '0',
        );

        await api.put(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Gerenciamento de Riscos&user_request=${id}&conta=${conta}`,
          {
            user_request: id,
            conta,
            calculo: temp,
            itens: array.map((e: any) => ({
              valor: e.valor
                .replace('R$', '')
                .trim()
                .replace('.', '')
                .replace(',', '.'),
              cod: e.cod,
            })),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        next();
      } catch (err) {
        console.error(err);
      }
    },
    [next, conta, id, token, temp],
  );

  return (
    <Container>
      {loading ? (
        <Loading isLoading={loading} />
      ) : (
        <>
          <Header>Gerenciamento de Risco</Header>
          <Form ref={formRef} onSubmit={handleAddSubmit}>
            {arrayPositionZeroPattern.map((tab, idxtab) => {
              return (
                <Box key={tab.subtipo}>
                  <h2>{tab.subtipo}</h2>
                  {tab.itens
                    ? tab.itens.map((item, index) => {
                        return (
                          <Scope key={item.campo} path={`modules[${item.cod}]`}>
                            <section>
                              <h5>{item.campo}</h5>
                              <Input
                                name="valor"
                                type="text"
                                value={
                                  item.elemento === 76
                                    ? formatMoney(item.valor)
                                        .split(/[R$\\/]/)
                                        .join('')
                                    : formatMoney(item.valor)
                                }
                                readOnly={item.read_only !== 0}
                                disabled={item.read_only !== 0}
                                info={item.info && item.info}
                                onChange={(e): void => {
                                  const newTelements = [
                                    ...arrayPositionZeroPattern,
                                  ];
                                  newTelements[idxtab].itens[
                                    index
                                  ].valor = Number(
                                    Number(
                                      e.target.value
                                        .split(/[R$\\/,/./]/)
                                        .join('')
                                        .split(/[^\d]+/g)
                                        .join('')
                                        .trim(),
                                    ) / 100,
                                  );
                                  const idxTotal = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex(
                                    (x) => x.campo.toUpperCase() === 'TOTAL',
                                  );

                                  const idxTotalTributado = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex(
                                    (x) =>
                                      x.campo.toUpperCase() ===
                                      'TOTAL TRIBUTADO',
                                  );

                                  const index_encargos_percent = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 76);

                                  if (item.elemento === 75) {
                                    const index_encargos_valor = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.findIndex((x) => x.elemento === 77);

                                    if (
                                      index_encargos_valor !== -1 &&
                                      index_encargos_percent !== -1
                                    ) {
                                      arrayPositionZeroPattern[idxtab].itens[
                                        index_encargos_valor
                                      ].valor =
                                        (parseFloat(
                                          Number(
                                            Number(
                                              e.target.value
                                                .split(/[R$\\/,/./-/]/)
                                                .join('')
                                                .split(/[^\d]+/g)
                                                .join('')
                                                .trim(),
                                            ) / 100,
                                          ).toString(),
                                        ) *
                                          arrayPositionZeroPattern[idxtab]
                                            .itens[index_encargos_percent]
                                            .valor) /
                                        100;
                                    }
                                  }

                                  if (idxTotal !== -1) {
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotal
                                    ].valor = 0;
                                    const total = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.reduce((acc, value) => {
                                      let a = 0;
                                      a = acc + value.valor;
                                      return a;
                                    }, 0);
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotal
                                    ].valor =
                                      index_encargos_percent !== -1
                                        ? (arrayPositionZeroPattern[idxtab]
                                            .itens[index_encargos_percent]
                                            .valor -
                                            Number(total)) *
                                          -1
                                        : Number(total);

                                    console.log(
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxTotal
                                      ].valor,
                                    );

                                    setGerenciamento(
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxTotal
                                      ].valor / totalVeic,
                                    );
                                  }

                                  setArrayPositionZeroPattern(newTelements);
                                }}
                              />
                              <Input
                                name="cod"
                                type="hidden"
                                value={item.cod}
                              />
                            </section>
                          </Scope>
                        );
                      })
                    : []}
                </Box>
              );
            })}
            <Box>
              <h2>Resultado</h2>
              <section>
                <h5>Custo unitário de gerenciamento de risco</h5>
                <Input
                  name="total"
                  type="text"
                  value={formatMoney(gerenciamento)}
                  readOnly
                />
              </section>
              <section>
                <h5>Total Veículos</h5>
                <Input name="total" type="text" readOnly value={totalVeic} />
              </section>
            </Box>
            <button type="submit">Salvar</button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default RiskManagement;
