import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Header, GroudInput, Inputs, Box } from './styles';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import Loading from '../../../../components/Loading';
import { useTimeline } from '../../../../hooks/timeline';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import formatMoney from '../../../../utils/formatMoney';

interface IFormData {
  pis: number;
  ir: number;
  cofins: number;
  tipo: string;
  cssl: number;
  aliquota_unica: number;
}

const Taxes: React.FC = () => {
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [select, setSelect] = useState('lucro_real_a');

  const [inputD, setInputD] = useState({
    aliquota_unica: 0,
  });

  const [inputA, setInputA] = useState({
    pis: 1.65,
    cofins: 7.6,
    ir: 0,
    cssl: 0,
  });
  const [inputB, setInpuB] = useState({
    pis: 0,
    cofins: 0,
    ir: 0,
    cssl: 0,
  });
  const [inputC, setInpuC] = useState({
    pis: 0.65,
    cofins: 3.0,
    ir: 0,
    cssl: 0,
  });

  const [totalA, setTotalA] = useState(0);
  const [totalB, setTotalB] = useState(0);
  const [totalC, setTotalC] = useState(0);

  const formatTotal = (value: any): any => {
    return new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value);
  };

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      try {
        setLoading(true);
        const response = await api.get(
          `/api/v1/tabelas/impostos?calculo=${temp}&user_request=${id}&conta=${conta}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        setSelect(response.data.data[0].tipo);
        response.data.data[0].tipo === 'lucro_real_a' &&
          setInputA({
            cofins: response.data.data[0].cofins,
            ir: response.data.data[0].ir,
            cssl: response.data.data[0].cssl,
            pis: response.data.data[0].pis,
          });

        response.data.data[0].tipo === 'lucro_real_b' &&
          setInpuB({
            cofins: response.data.data[0].cofins,
            ir: response.data.data[0].ir,
            cssl: response.data.data[0].cssl,
            pis: response.data.data[0].pis,
          });

        response.data.data[0].tipo === 'lucro_presumido' &&
          setInpuC({
            cofins: response.data.data[0].cofins,
            ir: response.data.data[0].ir,
            cssl: response.data.data[0].cssl,
            pis: response.data.data[0].pis,
          });
        response.data.data[0].tipo === 'simples_nacional' &&
          setInputD({ aliquota_unica: response.data.data[0].aliquota_unica });
        console.log('Tota', response.data.data[0]);

        const calcTotal = [
          response.data.data[0].cofins,
          response.data.data[0].ir,
          response.data.data[0].cssl,
          response.data.data[0].pis,
        ];
        const valor = calcTotal.reduce((acc, value) => acc + value);

        switch (response.data.data[0].tipo) {
          case 'lucro_real_a':
            setTotalA(valor);
            break;
          case 'lucro_real_b':
            setTotalB(valor);
            break;
          case 'lucro_presumido':
            setTotalC(valor);
            break;
          default:
            console.log('');
            break;
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    loadTabs();
  }, [conta, id, token, temp]);

  const handleAddSubmit: SubmitHandler<IFormData> = useCallback(
    async (data) => {
      console.log(data);
      try {
        await api.post(
          `/api/v1/tabelas/impostos`,
          {
            user_request: id,
            conta,
            calculo: Number(temp),
            tipo: select,
            pis: Number(data.pis ? data.pis.toString().replace(',', '.') : 0),
            cofins: Number(
              data.cofins ? data.cofins.toString().replace(',', '.') : 0,
            ),
            ir: Number(data.ir ? data.ir.toString().replace(',', '.') : 0),
            cssl: Number(
              data.cssl ? data.cssl.toString().replace(',', '.') : 0,
            ),
            aliquota_unica: Number(
              data.aliquota_unica
                ? data.aliquota_unica.toString().replace(',', '.')
                : 0,
            ),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        next();
      } catch (err) {
        console.error(err);
      }
    },
    [next, conta, id, token, temp, select],
  );

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const formatGroupLabel = (data: any): any => (
    <div style={groupStyles}>
      <h4>{data.label}</h4>
      <span>{data.options.length}</span>
    </div>
  );
  const groupedOptions = [
    { value: 'lucro_real_a', label: 'Lucro Real: Opção A' },
    { value: 'lucro_real_b', label: 'Lucro Real: Opção B' },
    { value: 'lucro_presumido', label: 'Lucro Presumido' },
    { value: 'simples_nacional', label: 'Simples Nacional' },
  ];

  return (
    <Container>
      {loading && <Loading isLoading={loading} />}
      <Header>Impostos</Header>
      <Form ref={formRef} onSubmit={handleAddSubmit}>
        <Select
          name="tipo"
          style={{ minWidth: '360px' }}
          placeholder="Tipo"
          options={groupedOptions}
          value={groupedOptions.filter((option) => option.value === select)}
          formatGroupLabel={formatGroupLabel}
          onChange={(e: any) => {
            setSelect(e.value);
          }}
        />
        {select === 'lucro_real_a' && (
          <Box>
            <h4>
              Aplicados os percentuais cheios de Pis/Cofins sem considerar os
              créditos, formato bastante usado pelas transportadoras:
            </h4>
            <Inputs>
              <GroudInput>
                <p>Pis</p>
                <Input
                  name="pis"
                  min="0"
                  type="number"
                  readOnly
                  value={inputA.pis}
                />
              </GroudInput>
              <GroudInput>
                <p>Cofins %</p>
                <Input
                  name="cofins"
                  min="0"
                  type="number"
                  readOnly
                  value={inputA.cofins.toFixed(2)}
                />
              </GroudInput>
              <GroudInput>
                <p>IR</p>
                <Input
                  name="ir"
                  value={formatMoney(inputA.ir)
                    .split(/[R$\\/]/)
                    .join('')}
                  type="text"
                  onChange={(e) => {
                    const att = { ...inputA };
                    att.ir = Number(
                      Number(
                        e.target.value
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) / 100,
                    );
                    setInputA(att);
                    const valor = Object.values(att).reduce(
                      (acc, value) => acc + value,
                    );
                    setTotalA(valor);
                  }}
                />
              </GroudInput>
              <GroudInput>
                <p>Cssl%</p>
                <Input
                  name="cssl"
                  type="text"
                  value={formatMoney(inputA.cssl)
                    .split(/[R$\\/]/)
                    .join('')}
                  onChange={(e) => {
                    const att = { ...inputA };
                    att.cssl = Number(
                      Number(
                        e.target.value
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) / 100,
                    );
                    setInputA(att);
                    const valor = Object.values(att).reduce(
                      (acc, value) => acc + value,
                    );
                    setTotalA(valor);
                  }}
                />
              </GroudInput>
            </Inputs>
            <GroudInput>
              <p>Total</p>
              <Input name="total" value={formatTotal(totalA)} type="text" />
            </GroudInput>
          </Box>
        )}
        {select === 'lucro_real_b' && (
          <Box>
            <h4>
              Considera os créditos das NFs de entrada e outras permissões para
              redução do Pis/Cofins para se chegar nos percentuais que
              correspondem à realidade do custo com estes dois impostos. Este é
              o formato recomendado pelo Maxifrete.
            </h4>
            <Inputs>
              <GroudInput>
                <p>Pis</p>
                <Input
                  name="pis"
                  type="text"
                  value={formatMoney(inputB.pis)
                    .split(/[R$\\/]/)
                    .join('')}
                  onChange={(e) => {
                    const att = { ...inputB };
                    att.pis = Number(
                      Number(
                        e.target.value
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) / 100,
                    );
                    setInpuB(att);
                    const valor = Object.values(att).reduce(
                      (acc, value) => acc + value,
                    );
                    setTotalB(valor);
                  }}
                />
              </GroudInput>
              <GroudInput>
                <p>Cofins %</p>
                <Input
                  name="cofins"
                  type="text"
                  value={formatMoney(inputB.cofins)
                    .split(/[R$\\/]/)
                    .join('')}
                  onChange={(e) => {
                    const att = { ...inputB };
                    att.cofins = Number(
                      Number(
                        e.target.value
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) / 100,
                    );
                    setInpuB(att);
                    const valor = Object.values(att).reduce(
                      (acc, value) => acc + value,
                    );
                    setTotalB(valor);
                  }}
                />
              </GroudInput>
              <GroudInput>
                <p>IR</p>
                <Input
                  name="ir"
                  type="text"
                  value={formatMoney(inputB.ir)
                    .split(/[R$\\/]/)
                    .join('')}
                    onChange={(e) => {
                      const att = { ...inputB };
                      att.ir = Number(
                        Number(
                          e.target.value
                            .split(/[^\d]+/g)
                            .join('')
                            .trim(),
                        ) / 100,
                      );
                      setInpuB(att);
                      const valor = Object.values(att).reduce(
                        (acc, value) => acc + value,
                      );
                      setTotalB(valor);
                    }}
                  />
                </GroudInput>





              <GroudInput>
                <p>Cssl</p>
                <Input
                  name="cssl"
                  type="text"
                  value={formatMoney(inputB.cssl)
                    .split(/[R$\\/]/)
                    .join('')}
                  onChange={(e) => {
                    const att = { ...inputB };
                    att.cssl = Number(
                      Number(
                        e.target.value
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) / 100,
                    );
                    setInpuB(att);
                    const valor = Object.values(att).reduce(
                      (acc, value) => acc + value,
                    );
                    setTotalB(valor);
                  }}
                />
              </GroudInput>
            </Inputs>
            <GroudInput>
              <p>Total %</p>
              <Input name="total" value={formatTotal(totalB)} type="text" />
            </GroudInput>
          </Box>
        )}
        {select === 'lucro_presumido' && (
          <Box>
            <Inputs>
              <GroudInput>
                <p>Pis</p>
                <Input
                  name="pis"
                  type="text"
                  readOnly
                  value={formatMoney(inputC.pis)
                    .split(/[R$\\/]/)
                    .join('')}
                />
              </GroudInput>
              <GroudInput>
                <p>Cofins %</p>
                <Input
                  name="cofins"
                  type="text"
                  readOnly
                  value={formatMoney(inputC.cofins)
                    .split(/[R$\\/]/)
                    .join('')}
                />
              </GroudInput>
              <GroudInput>
                <p>IR</p>
                <Input
                  name="ir"
                  type="text"
                  value={formatMoney(inputC.ir)
                    .split(/[R$\\/]/)
                    .join('')}
                  onChange={(e) => {
                    const att = { ...inputC };
                    att.ir = Number(
                      Number(
                        e.target.value
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) / 100,
                    );
                    setInpuC(att);
                    const valor = Object.values(att).reduce(
                      (acc, value) => acc + value,
                    );
                    setTotalC(valor);
                  }}
                />
              </GroudInput>
              <GroudInput>
                <p>Cssl</p>
                <Input
                  name="cssl"
                  type="text"
                  value={formatMoney(inputC.cssl)
                    .split(/[R$\\/]/)
                    .join('')}
                  onChange={(e) => {
                    const att = { ...inputC };
                    att.cssl = Number(
                      Number(
                        e.target.value
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) / 100,
                    );
                    setInpuC(att);
                    const valor = Object.values(att).reduce(
                      (acc, value) => acc + value,
                    );
                    setTotalC(valor);
                  }}
                />
              </GroudInput>
            </Inputs>
            <GroudInput>
              <p>Total</p>
              <Input name="total" value={formatTotal(totalC)} type="text" />
            </GroudInput>
          </Box>
        )}
        {select === 'simples_nacional' && (
          <Box>
            <h4>
              Sendo optante do regime Simples Nacional, deverá aplicar a
              alíquota de acordo com seu faturamento.
            </h4>
            <Inputs>
              <GroudInput>
                <p>Alíquota única</p>
                <Input
                  name="aliquota_unica"
                  type="text"
                  value={formatMoney(inputD.aliquota_unica)
                    .split(/[R$\\/]/)
                    .join('')}
                  onChange={(e) => {
                    const att = { ...inputD };
                    att.aliquota_unica = Number(
                      Number(
                        e.target.value
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) / 100,
                    );
                    setInputD(att);
                  }}
                />
              </GroudInput>
            </Inputs>
          </Box>
        )}
        <Button type="submit">Salvar</Button>
      </Form>
    </Container>
  );
};

export default Taxes;
