import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;

  article {
    min-width: 100%;
  }
  input{
    width: 600px;
  }
  .input {
    min-width: 100%;
    width: 100%;
  }
  
.select{
  width:100%;
}

  .react-select__control,
  .css-2b097c-container {
    width: 100%;
    min-width: 100%;

    height: 40px;
    margin-top: 0;
    border-radius: 10px;
    font-size: 16px;
    margin-bottom: 8px;
  }
`;
