import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { FiAlertCircle, FiInfo } from 'react-icons/fi';

import { useField } from '@unform/core';

import { Container, Error, Info, Prefix, Label, Main } from './styles';

interface InputsProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  border?: boolean;
  info?: string;
  margin?: boolean;
  readOnly?: boolean;
  prefix?: string;
  title?: string;
}

const Input: React.FC<InputsProps> = ({
  name,
  icon: Icon,
  type,
  border,
  info,
  margin,
  prefix,
  readOnly,
  title,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Main hidden={type === 'hidden'}>
      <Container
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        type={type}
        border={border}
        className="input"
        margin={margin}
        readOnly={readOnly}
      >
        {title && <Label>{title}</Label>}
        {Icon && <Icon size={20} />}
        {prefix && <Prefix>{prefix}</Prefix>}
        <input
          defaultValue={defaultValue}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={inputRef}
          readOnly={readOnly}
          {...rest}
          type={type}
        />
        {info && (
          <Info title={info}>
            <FiInfo size={20} color="#008f85" />
          </Info>
        )}
        {!!error && (
          <Error title={error}>
            <FiAlertCircle size={20} color="#c53030" />
          </Error>
        )}
      </Container>
    </Main>
  );
};

export default Input;
