import React, { useEffect } from 'react';

import { Container } from './styles';
import { useTimeline } from '../../hooks/timeline';

const Breadcrumbs: React.FC = () => {
  const { transition, goTransition } = useTimeline();

  const positions = [
    {
      position: 1,
      name: 'Elementos',
      scroll: 0,
    },
    {
      position: 2,
      name: 'Rateios',
      scroll: 150,
    },
    {
      position: 3,
      name: 'Motorista',
      scroll: 300,
    },
    {
      position: 4,
      name: 'Impostos',
      scroll: 450,
    },
    {
      position: 5,
      name: 'Gerenciamento de Riscos',
      scroll: 650,
    },
    {
      position: 6,
      name: 'Encargos Sociais',
      scroll: 800,
    },
    {
      position: 7,
      name: 'Diesel Arla',
      scroll: 950,
    },
    {
      position: 8,
      name: 'Pneus',
      scroll: 1100,
    },
    {
      position: 9,
      name: 'Manutenção',
      scroll: 1250,
    },
    {
      position: 10,
      name: 'Lavagem',
      scroll: 1500,
    },
    {
      position: 11,
      name: 'Seguro de carga',
      scroll: 1650,
    },
    {
      position: 12,
      name: 'Custo Frete',
      scroll: 1800,
    },
    {
      position: 13,
      name: 'Resumo',
      scroll: 1950,
    },
    {
      position: 14,
      name: 'Cálculo Frete',
      scroll: 2100,
    },
  ];
  const listScroll = document.querySelector('.MuiBreadcrumbs-ol');

  useEffect(() => {
    console.log();
    const item = positions.find((position) => position.position === transition);
    if (item && listScroll) {
      listScroll.scroll(item.scroll, 0);
    }
  }, [transition, positions]);

  return (
    <>
      <Container maxItems={21} separator=" ">
        {positions.map((e) => (
          <button
            type="button"
            className={transition === e.position ? 'activeBread' : ''}
            onClick={() => {
              if (listScroll) {
                listScroll.scroll(e.scroll, 0);
              }

              goTransition(e.position);
            }}
          >
            {e.name}
          </button>
        ))}
      </Container>
    </>
  );
};

export default Breadcrumbs;
