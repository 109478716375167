import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import formatMoney from '../../../../utils/formatMoney';
import { Container, Header } from './styles';
import { useTimeline } from '../../../../hooks/timeline';
import Button from '../../../../components/Button';
import Loading from '../../../../components/Loading';
import { Box } from '../components';

interface IRateios {
  rateios: {
    Despesas_Administrativas: number;
    Pessoal_admnistrativo: number;
    Rateio_Seguranca: number;
    Rateio_Seguranca_por_veiculo: number;
    Rateio_administrativo: number;
    Terceiros_esporadicos: number;
    Total_Administrativo: number;
    Total_veiculos: number;
  };
}

const Rateios: React.FC = () => {
  const [arrayPositionOne, setArrayPositionOne] = useState<IRateios>(
    {} as IRateios,
  );

  const [loading, setLoading] = useState(false);

  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        const response = await api.get(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Elementos&user_request=${id}&conta=${conta}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setArrayPositionOne(response.data.data[1]);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    load();
  }, [conta, id, temp, token]);

  return (
    <>
      {loading ? (
        <Loading isLoading={loading} />
      ) : (
        <Container>
          <Header>Rateios</Header>
          <Box style={{ border: '2', width: '100%' }}>
            <ul>
              <li>
                <h5>Despesas Administrativas:</h5>
                <p>
                  {arrayPositionOne.rateios
                    ? formatMoney(
                        arrayPositionOne.rateios.Despesas_Administrativas,
                      )
                    : ''}
                </p>
              </li>
              <li>
                <h5>Rateio Segurança (por veículo):</h5>
                <p>
                  {arrayPositionOne.rateios
                    ? formatMoney(
                        arrayPositionOne.rateios.Rateio_Seguranca_por_veiculo,
                      )
                    : ''}
                </p>
              </li>
              <li>
                <h5>Rateio para o custo administrativo:</h5>
                <p>
                  {arrayPositionOne.rateios
                    ? formatMoney(
                        arrayPositionOne.rateios.Rateio_administrativo,
                      )
                    : ''}
                </p>
              </li>
              <li>
                <h5>Pessoal Administrativo:</h5>
                <p>
                  {arrayPositionOne.rateios
                    ? formatMoney(
                        arrayPositionOne.rateios.Pessoal_admnistrativo,
                      )
                    : ''}
                </p>
              </li>

              <li>
                <h5>Total de veículos:</h5>
                <p>
                  {arrayPositionOne.rateios
                    ? arrayPositionOne.rateios.Total_veiculos
                    : ''}
                </p>
              </li>
              <li>
                <h5>(-) Rateio da participação de terceiros esporádicos:</h5>
                <p>
                  {arrayPositionOne.rateios
                    ? formatMoney(
                        arrayPositionOne.rateios.Terceiros_esporadicos,
                      )
                    : ''}
                </p>
              </li>
              <li>
                <h5>Total das Despesas:</h5>
                <p>
                  {arrayPositionOne.rateios
                    ? formatMoney(arrayPositionOne.rateios.Total_Administrativo)
                    : ''}
                </p>
              </li>
            </ul>
            <Button onClick={next}>Próximo</Button>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Rateios;
