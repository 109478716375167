import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';
import { Container, ContainerInput, Tool, Error, Label } from './styles';

interface IProps extends InputProps {
  name: string;
  title?: string;
  border?: boolean;
  validation?: string;
}
const InputMask: React.FC<IProps> = ({
  name,
  title,
  border,
  validation,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  return (
    <ContainerInput
      isErrored={!!error}
      isFocused={isFocused}
      isBlur={isFocused}
      border={border}
      className="input"
    >
      {title && <Label>{title}</Label>}
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        maskChar={null}
        {...rest}
      />
      {!!error && window.screen.width < 728 && (
        <Tool title={error}>
          <FiAlertTriangle size={20} color="#c53030" />
        </Tool>
      )}
      {!!error && window.screen.width > 728 && (
        <Error>
          <FiAlertCircle size={20} color="#c53030" />
          <span>{error}</span>
        </Error>
      )}
      {!!validation && (
        <Error>
          <FiAlertCircle size={20} color="#c53030" />
          <span>{validation}</span>
        </Error>
      )}
    </ContainerInput>
  );
};
export default InputMask;
