import React from 'react';
import ReactDOM from 'react-dom';
import { StyledModal, StyledModalOverlay } from './styles';

interface ModalProps {
  show: boolean;
  children: React.ReactNode;
}

export const Modal = ({ show, children }: ModalProps): any => {
  const modalContent = show ? (
    <StyledModalOverlay>
      <StyledModal>{children}</StyledModal>
    </StyledModalOverlay>
  ) : null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalDiv: any = document.getElementById('modal-footer');

  return ReactDOM.createPortal(modalContent, modalDiv);
};
