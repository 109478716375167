import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler, Scope } from '@unform/core';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Header } from './styles';
import { Box } from '../components';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import { useTimeline } from '../../../../hooks/timeline';
import Loading from '../../../../components/Loading';
import formatMoney from '../../../../utils/formatMoney';

interface IArrayZero {
  subtipo: string;
  itens: {
    agrupamento: number;
    subtipo: string;
    cod: number;
    campo: string;
    valor: number;
    info: string;
    read_only: number;
    elemento: string | number;
  }[];
}

const Wash: React.FC = () => {
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);

  const [arrayPositionZeroPattern, setArrayPositionZeroPattern] = useState<
    IArrayZero[]
  >([]);

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      setLoading(true);
      const response = await api.get(
        `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Lavagem&user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      setArrayPositionZeroPattern(response.data.data);
      console.log('Data', response.data.data);
      setLoading(false);
    };

    loadTabs();
  }, [conta, id, token, temp]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        const array = data.modules.filter(
          (e: any) => e !== '' && e.valor !== '0',
        );
        await api.put(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Lavagem&user_request=${id}&conta=${conta}`,
          {
            user_request: id,
            conta,
            calculo: temp,
            itens: array.map((e: any) => ({
              valor: e.valor
                .replace('R$', '')
                .trim()
                .replace('.', '')
                .replace(',', '.'),
              cod: e.cod,
            })),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        next();
      } catch (err) {
        console.error(err);
      }
    },
    [next, conta, id, token, temp],
  );

  const handlePrice = useCallback(async () => {
    const response = await api.get(
      `/api/v1/tabelas/customercado?user_request=${id}&conta=${conta}`,
      {
        headers: {
          'x-access-token': token,
        },
      },
    );

    const newTelements = [...arrayPositionZeroPattern];
    newTelements.map((e) => {
      e.itens.map((i) => {
        if (i.elemento !== 151) {
          i.valor = 0;
        }
        return '';
      });
      return '';
    });
    newTelements[0].itens[2].valor = response.data.data.find(
      (item: any) => item.nome === 'lavagem',
    ).valor;
    setArrayPositionZeroPattern(newTelements);
  }, [conta, id, token, arrayPositionZeroPattern]);

  const valueInput = useCallback((value: number, element: string | number):
    | string
    | number => {
    if (element === 150) {
      return new Intl.NumberFormat('pt-BR')
        .format(Number(value))
        .split('-')
        .join('');
    }

    if (element === 151) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      })
        .format(Number(value))
        .split('-')
        .join('');
    }

    return formatMoney(value);
  }, []);

  return (
    <Container>
      {loading ? (
        <Loading isLoading={loading} />
      ) : (
        <>
          <Header>Lavagem</Header>
          <Form ref={formRef} onSubmit={handleAddSubmit}>
            {arrayPositionZeroPattern.map((tab, idxtab) => {
              return (
                <Box key={tab.subtipo}>
                  <h2>{tab.subtipo}</h2>
                  {tab.itens
                    ? tab.itens.map((item, index) => {
                        return (
                          <Scope key={item.campo} path={`modules[${item.cod}]`}>
                            <section>
                              <h5>{item.campo}</h5>
                              <Input
                                name="valor"
                                type="text"
                                value={valueInput(item.valor, item.elemento)}
                                readOnly={item.read_only !== 0}
                                disabled={item.read_only !== 0}
                                info={item.info && item.info}
                                onChange={(e): void => {
                                  const newTelements = [
                                    ...arrayPositionZeroPattern,
                                  ];
                                  if (item.elemento === 150) {
                                    newTelements[idxtab].itens[
                                      index
                                    ].valor = Number(
                                      e.target.value
                                        .split(/[^\d]+/g)
                                        .join('')
                                        .trim(),
                                    );
                                  } else {
                                    newTelements[idxtab].itens[
                                      index
                                    ].valor = Number(
                                      Number(
                                        e.target.value
                                          .split(/[R$\\/,/./]/)
                                          .join('')
                                          .split(/[^\d]+/g)
                                          .join('')
                                          .trim(),
                                      ) / 100,
                                    );
                                  }

                                  const idxTotal = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 151);

                                  const frota = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.find((x) => x.elemento === 149);
                                  const media = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.find((x) => x.elemento === 150);

                                  if (idxTotal !== -1) {
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotal
                                    ].valor = 0;

                                    if (frota && media) {
                                      if (media.valor) {
                                        arrayPositionZeroPattern[idxtab].itens[
                                          idxTotal
                                        ].valor = frota.valor / media.valor;
                                      }
                                    }
                                  }

                                  setArrayPositionZeroPattern(newTelements);
                                }}
                              />
                              <Input
                                name="cod"
                                type="hidden"
                                value={item.cod}
                              />
                            </section>
                          </Scope>
                        );
                      })
                    : []}
                </Box>
              );
            })}
            <button
              type="button"
              onClick={() => {
                if (
                  window.confirm('Deseja utilizar o preço médio do sistema?')
                ) {
                  handlePrice();
                }
              }}
              style={{ display: 'none' }}
            >
              Consultar Preço Médio
            </button>
            <button type="submit">Salvar</button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default Wash;
