import styled from 'styled-components';
import { animated } from 'react-spring';

// SB - SideBar
// NB - NiveBar
// CC - Content Container
// FT - Footer

export const Container = styled(animated.section)`
  display: grid;

  grid-template-rows: 56px auto 32px;
  grid-template-areas:
    'SB NB'
    'SB CC'
    'SB FT';

  height: 100vh;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Routes = styled.main`
  grid-area: CC;
  width: 100%;
  @media screen and (max-width: 500px) {
    flex: 1;
  }
`;
