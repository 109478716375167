import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.maxifrete.com.br',
  //baseURL: 'http://localhost:3002',
  // baseURL: 'http://13.57.190.222:3002',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
