import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: -63px;
  top: 40px;
  padding: 30px;
  overflow: hidden;
  z-index: 999999999999;
`;
