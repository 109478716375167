import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { useHistory } from 'react-router-dom';
import { useMenu } from '../../hooks/menu';

import { Container } from './styles';

import ListMenu from '../ListMenu';

import logo from '../../assets/logo-mini.png';
import { useAuth } from '../../hooks/auth';

const SideBar: React.FC = () => {
  const { expansed, expansedFalse } = useMenu();
  const { user } = useAuth();
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (window.screen.width <= 500) {
      expansedFalse();
    }
  });
  const nome = user !== null ? user.nome : '';
  return (
    <>
      <Container isExpansed={expansed} ref={ref}>
        <img src={logo} alt="logo" />
        <h3>{nome || '...'}</h3>
        <h4>Bem vindo(a)</h4>
        <ListMenu />
      </Container>
    </>
  );
};

export default SideBar;
