import React, { useState, ReactNode, useEffect } from 'react';
import { Aria } from 'react-modal';
import { FiX } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons';
import { Container, ModalSty, Header } from './styles';

interface ModalProps extends Aria {
  content: ReactNode;
  close: boolean;
  text: string;
  icon?: React.ComponentType<IconBaseProps>;
  bg: string;
  title: string;
}

const Modal: React.FC<ModalProps> = ({
  content,
  close,
  text,
  bg,
  title,
  icon: Icon,
}: ModalProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = (): void => {
    setIsOpen(true);
  };

  const closeModal = (): void => {
    setIsOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    close && setIsOpen(false);
  }, [close]);

  return (
    <Container bg={bg}>
      <button type="button" onClick={openModal}>
        {Icon && <Icon size={20} />}
        {text}
      </button>
      <ModalSty
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: { backgroundColor: 'rgba(82, 81, 81, 0.75)', zIndex: 2 },
        }}
      >
        <Header>
          <h3>{title}</h3>
          <button type="button" onClick={closeModal}>
            <FiX size={22} />
          </button>
        </Header>
        <main>{content}</main>
      </ModalSty>
    </Container>
  );
};

export default Modal;
