/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { usePosition } from 'use-position';
import debounce from 'debounce-promise';
import Async from 'react-select/async';
import { Map, TileLayer, Marker } from 'react-leaflet';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import Leaflet from 'leaflet';
import { FiPrinter } from 'react-icons/fi';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Invalid from '../../../../components/Invalid';
import { Container, Header } from './styles';
import api from '../../../../services/api';
import markerA from '../../../../assets/markerA.svg';
import markerB from '../../../../assets/markerB.svg';
import { useAuth } from '../../../../hooks/auth';

import formatMoney from '../../../../utils/formatMoney';
import { useTabs } from '../../../../hooks/tabs';
import Loading from '../../../../components/Loading';

interface SearchData {
  distancia: number;
  pedagios: number;
}

interface Inputs {
  name: string;
  value: string | number;
}

const mapIconA = Leaflet.icon({
  iconUrl: markerA,
  iconSize: [35, 35],
  iconAnchor: [17.5, 35],
});
const mapIconB = Leaflet.icon({
  iconUrl: markerB,
  iconSize: [35, 35],
  iconAnchor: [17.5, 35],
});

const CalculoFrete: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isNotValid, setIsNotValid] = useState(false);
  const [loadDefaulValues, setLoadDefaultValues] = useState(true);
  const { id, conta } = useAuth().user;
  const [origem, setOrigem] = useState('');
  const [destino, setDestino] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [searchData, setSearchData] = useState<SearchData>({} as SearchData);
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const { temp } = useTabs();

  const { latitude, longitude } = usePosition(true);

  const [metricasOrigem, setMetricasOrigem] = useState({
    long: longitude || 0,
    lati: latitude || 0,
  });
  const [metricasDestino, setMetricasDestino] = useState({
    long: 0,
    lati: 0,
  });

  const [inputs, setInputs] = useState<Inputs[]>([
    { name: 'kmida', value: '' },
    { name: 'pedagios', value: '' },
    { name: 'freteRecomendado', value: 0 },
    { name: 'icmsIss', value: 0 },
    { name: 'custoKm', value: '' },
    { name: 'freteAtual', value: '' },
    { name: 'freteIcms', value: '' },
    { name: 'freteTrajeto', value: 0 },
    { name: 'diferencaRS', value: 0 },
    { name: 'diferencaPorc', value: 0 },
    { name: 'faturamentoPorKMIda', value: 0 },
    { name: 'faturamentoPorKMIdaVolta', value: 0 },
  ]);

  const calculoFreteRecomendado = (inputsValues: any): void => {
    const inputsArray = inputsValues ? [...inputsValues] : [...inputs];
    const freteRecomendado = inputsArray.findIndex(
      (item) => item.name === 'freteRecomendado',
    );
    const kmIda = inputsArray.findIndex((item) => item.name === 'kmida');
    const custoKm = inputsArray.findIndex((item) => item.name === 'custoKm');

    if (custoKm !== -1 && kmIda !== -1 && freteRecomendado !== -1) {
      inputsArray[freteRecomendado].value =
        Number(inputsArray[kmIda].value) * Number(inputsArray[custoKm].value);
    }
    setInputs([...inputsArray]);
  };

  const calculoFreteParaOTrajeto = (inputsValues: any): void => {
    const inputsArray = inputsValues ? [...inputsValues] : [...inputs];
    const freteIcms = inputsArray.findIndex(
      (item) => item.name === 'freteIcms',
    );
    const freteTrajeto = inputsArray.findIndex(
      (item) => item.name === 'freteTrajeto',
    );
    const pedagios = inputsArray.findIndex((item) => item.name === 'pedagios');

    if (pedagios !== -1 && freteTrajeto !== -1 && freteIcms !== -1) {
      inputsArray[freteTrajeto].value =
        Number(inputsArray[freteIcms].value) +
        Number(inputsArray[pedagios].value);
    }
    setInputs([...inputsArray]);
  };

  const calculoFreteIcms = (inputsValues: any): void => {
    const inputsArray = inputsValues ? [...inputsValues] : [...inputs];
    const freteIcms = inputsArray.findIndex(
      (item) => item.name === 'freteIcms',
    );
    const icmsIss = inputsArray.findIndex((item) => item.name === 'icmsIss');
    const freteRecomendado = inputsArray.findIndex(
      (item) => item.name === 'freteRecomendado',
    );
    const pedagios = inputsArray.findIndex((item) => item.name === 'pedagios');

    if (
      icmsIss !== -1 &&
      freteIcms !== -1 &&
      freteRecomendado !== -1 &&
      pedagios !== -1
    ) {
      const freteRecomendadoValue = Number(inputsArray[freteRecomendado].value);
      const icmsValue = Number(inputsArray[icmsIss].value);
      const calculo = freteRecomendadoValue / ((100 - icmsValue) / 100);
      inputsArray[freteIcms].value = calculo;
    }
    setInputs([...inputsArray]);
  };

  const calculoDiferencaRS = (inputsValues: any): void => {
    const inputsArray = inputsValues ? [...inputsValues] : [...inputs];
    const diferencaRS = inputsArray.findIndex(
      (item) => item.name === 'diferencaRS',
    );
    const freteAtual = inputsArray.findIndex(
      (item) => item.name === 'freteAtual',
    );
    const freteTrajeto = inputsArray.findIndex(
      (item) => item.name === 'freteTrajeto',
    );

    if (diferencaRS !== -1 && freteAtual !== -1 && freteTrajeto !== -1) {
      const calculo =
        Number(inputsArray[freteAtual].value) -
        Number(inputsArray[freteTrajeto].value);
      inputsArray[diferencaRS].value = calculo.toFixed(2);
    }
    setInputs([...inputsArray]);
  };

  const calculoDiferencaPorc = (inputsValues: any): void => {
    const inputsArray = inputsValues ? [...inputsValues] : [...inputs];
    const diferencaPorc = inputsArray.findIndex(
      (item) => item.name === 'diferencaPorc',
    );
    const freteAtual = inputsArray.findIndex(
      (item) => item.name === 'freteAtual',
    );
    const freteTrajeto = inputsArray.findIndex(
      (item) => item.name === 'freteTrajeto',
    );

    if (diferencaPorc !== -1 && freteAtual !== -1 && freteTrajeto !== -1) {
      const calculo =
        (Number(inputsArray[freteAtual].value) /
          Number(inputsArray[freteTrajeto].value) -
          1) *
        100;
      inputsArray[diferencaPorc].value = calculo.toFixed(2);
    }
    setInputs([...inputsArray]);
  };

  const calculoFaturamentoPorKMIda = (inputsValues: any): void => {
    const inputsArray = inputsValues ? [...inputsValues] : [...inputs];
    const faturamentoPorKMIda = inputsArray.findIndex(
      (item) => item.name === 'faturamentoPorKMIda',
    );
    const freteAtual = inputsArray.findIndex(
      (item) => item.name === 'freteAtual',
    );
    const kmida = inputsArray.findIndex((item) => item.name === 'kmida');

    if (faturamentoPorKMIda !== -1 && freteAtual !== -1 && kmida !== -1) {
      const calculo =
        Number(inputsArray[freteAtual].value) /
        Number(inputsArray[kmida].value);
      inputsArray[faturamentoPorKMIda].value = calculo.toFixed(2);
    }
    setInputs([...inputsArray]);
  };

  const calculoFaturamentoPorKMIdaVolta = (inputsValues: any): void => {
    const inputsArray = inputsValues ? [...inputsValues] : [...inputs];
    const faturamentoPorKMIdaVolta = inputsArray.findIndex(
      (item) => item.name === 'faturamentoPorKMIdaVolta',
    );
    const freteAtual = inputsArray.findIndex(
      (item) => item.name === 'freteAtual',
    );

    const kmida = inputsArray.findIndex((item) => item.name === 'kmida');

    if (faturamentoPorKMIdaVolta !== -1 && freteAtual !== -1 && kmida !== -1) {
      const calculo =
        Number(inputsArray[freteAtual].value) /
        Number(inputsArray[kmida].value) /
        2;
      inputsArray[faturamentoPorKMIdaVolta].value = calculo.toFixed(2);
    }
    setInputs([...inputsArray]);
  };

  const calcularTodos = (inputsValues?: any): void => {
    calculoFreteRecomendado(inputsValues);
    calculoFreteIcms(inputsValues);
    calculoFreteParaOTrajeto(inputsValues);
    calculoDiferencaRS(inputsValues);
    calculoDiferencaPorc(inputsValues);
    calculoFaturamentoPorKMIda(inputsValues);
    calculoFaturamentoPorKMIdaVolta(inputsValues);
  };

  useEffect(() => {
    const registers = async (): Promise<void> => {
      try {
        const response = await api.get(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=ModuloCalculoFrete&user_request=${id}&conta=${conta}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        const values = response.data.data[0].itens;
        const { subtipo } = response.data.data[0];
        if (values.custo_km <= 0) {
          setIsNotValid(true);
        }

        const inputsValues = [
          { name: 'kmida', value: values.km },
          { name: 'pedagios', value: values.pedagios },
          { name: 'freteRecomendado', value: subtipo.frete_recomendado },
          { name: 'icmsIss', value: values.icms },
          { name: 'custoKm', value: values.custo_km },
          { name: 'freteAtual', value: values.frete_atual },
          { name: 'freteIcms', value: subtipo.freteIcms },
          { name: 'freteTrajeto', value: subtipo.freteTrajeto },
          { name: 'diferencaRS', value: subtipo.diferenca_rs },
          { name: 'diferencaPorc', value: subtipo.diferenca_porc },
          { name: 'faturamentoPorKMIda', value: 0 },
          { name: 'faturamentoPorKMIdaVolta', value: 0 },
        ];

        setInputs(inputsValues);
        setOrigem(values.origem);
        setDestino(values.destino);
        calcularTodos(inputsValues);
        setLoadDefaultValues(false);
      } catch (error) {
        console.log(error);
      }
    };

    registers();
  }, [conta, id, temp, token]);

  const handleSearchCitys = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await api.get(
        `api/v1/utilities/getkmpedagios/?user_request=${id}&calculo=${temp}&conta=${conta}&origem=${origem}&destino=${destino}&calculavolta=nao&eixos=''`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );

      setSearchData(response.data.data);
      const values = response.data.data;
      const inputsArray = [...inputs];
      const kmida = inputsArray.findIndex((item) => item.name === 'kmida');

      const pedagios = inputsArray.findIndex(
        (item) => item.name === 'pedagios',
      );

      const icms = inputsArray.findIndex((item) => item.name === 'icmsIss');

      if (icms !== -1) {
        inputsArray[icms].value = values.icms;
      }

      if (kmida !== -1) {
        inputsArray[kmida].value = values.distancia;
      }

      if (pedagios !== -1) {
        inputsArray[pedagios].value = values.pedagios;
      }
      console.log('inputsArray', inputsArray);
      setInputs([...inputsArray]);
      calcularTodos(inputsArray);

      setLoading(false);
    } catch (err) {
      toast.error('Erro ao obter dados da rota. Tente novamente.');
      setLoading(false);
    }
  };

  const loadSuggestions = async (
    inputValue: string,
    callback: any,
  ): Promise<any> => {
    const request = await api.get(
      `api/v1/utilities/cidades/?user_request=${id}&conta=${conta}&cidade=${inputValue}`,
      {
        headers: {
          'x-access-token': token,
        },
      },
    );
    const returnData = request.data;
    const suggestions = returnData.data.map((sug: any) => {
      return {
        value: sug.cidade,
        label: sug.cidade,
      };
    });
    return suggestions;
  };

  const loadOptions = (inputValue: string, callback: any): any =>
    loadSuggestions(inputValue, callback);

  const debouncedLoadOptions = debounce(loadOptions, 2000, {
    leading: true,
  });

  const getInputValue = (name: string): any => {
    return inputs.find((input) => input.name === name)?.value;
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setLoadingSave(true);
      await api.put(
        `/api/v1/tabelas/ModuloCalculo/${temp}`,
        {
          user_request: id,
          conta,
          cidade_origem: origem,
          cidade_destino: destino,
          km: getInputValue('kmida'),
          custo_km: getInputValue('custoKm'),
          frete_recomendado: getInputValue('freteRecomendado'),
          icms_iss: getInputValue('icmsIss'),
          frete_trajeto: getInputValue('freteTrajeto'),
          pedagios: getInputValue('pedagios'),
          frete_icms: getInputValue('freteIcms'),
          frete_atual: getInputValue('freteAtual'),
          diferenca_rs: getInputValue('diferencaRS'),
          diferenca_porc: getInputValue('diferencaPorc'),
          faturamento_ida: getInputValue('faturamentoPorKMIda'),
          faturamento_ida_volta: getInputValue('faturamentoPorKMIdaVolta'),
        },
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      toast('Cálculo efetuado com sucesso.');
      setLoadingSave(false);
      window.location.reload();
    } catch (err) {
      toast.error('Erro ao salvar.');
      setLoadingSave(false);
    }
  };

  const changeInput = (
    inputValue: string,
    nameInput: string,
    isFormat = true,
  ): void => {
    const changeInputs = [...inputs];
    const input = changeInputs.findIndex((item) => item.name === nameInput);
    if (isFormat) {
      changeInputs[input].value = Number(
        Number(
          inputValue
            .split(/[R$\\/,/./]/)
            .join('')
            .split(/[^\d]+/g)
            .join('')
            .trim(),
        ) / 100,
      );
    } else {
      changeInputs[input].value = inputValue;
    }
    setInputs(changeInputs);
  };

  const formatDecimal = (value: string | number | undefined): string => {
    return formatMoney(value)
      .split(/[R$\\/]/)
      .join('');
  };

  const [loadingPrint, setLoadingPrint] = useState(false);

  const handlePrint = async (): Promise<void> => {
    try {
      setLoadingPrint(true);
      const req = await api.get(
        `api/v1/relatorios/calculofrete?calculo=${temp}&tabela=Resumo&user_request=${id}&conta=${conta}`,
        {
          responseType: 'blob',
          headers: {
            'x-access-token': token,
          },
        },
      );

      const file = new Blob([req.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      setLoadingPrint(false);
    } catch (error) {
      toast.error('Ocorreu um erro, por favor tente novamente.');
    }
  };

  return (
    <>
      {loadDefaulValues && <Loading isLoading={loadDefaulValues} />}
      {isNotValid && (
        <Invalid message="Módulo cálculo não pode ser iniciado, pois há pendências de preenchimento nas tabelas anteriores." />
      )}
      {!isNotValid && (
        <Container>
          <Header>
            Módulo Cálculo do Frete{' '}
            <Button
              loading={loadingPrint}
              type="button"
              onClick={handlePrint}
              disabled={loadingPrint}
            >
              <FiPrinter size={30} color="#008f85" />
            </Button>
          </Header>
          <p>
            O sistema faz simulação de frete, sendo normal que fique acima do
            que a transportadora pratica com seus clientes. A plataforma
            determina o custo técnico, que compreende TODAS as despesas que o
            frete deve recuperar. Faça diversas simulações, alterando elementos
            que nem sempre o cliente está disposto a pagar, como por exemplo
            Remuneração de Capital. Esses expurgos, embora justos, são comuns na
            definição dos fretes para torná-los mais competitivos, em detrimento
            de sua rentabilidade. O Maxifrete sugere que o usuário faça diversas
            simulações entre as variáveis que permitem flexibilizar o custo, de
            modo que a empresa conheça, com exatidão, o limite mínimo do frete
            que pode praticar.
          </p>
          <section>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <header>
                <Async
                  loadOptions={debouncedLoadOptions}
                  cacheOptions
                  value={{ label: origem, value: origem }}
                  noOptionsMessage={() => 'Nenhuma opção encontrada'}
                  placeholder="Origem"
                  className="select"
                  onChange={(value: any) => {
                    setOrigem(value.value);
                  }}
                />
                <Async
                  loadOptions={debouncedLoadOptions}
                  cacheOptions
                  value={{ label: destino, value: destino }}
                  noOptionsMessage={() => 'Nenhuma opção encontrada'}
                  placeholder="Destino"
                  className="select"
                  onChange={(value: any) => {
                    setDestino(value.value);
                  }}
                />
                <Button loading={loading} onClick={handleSearchCitys}>
                  Obter KM
                </Button>
                <Input
                  style={{ width: '140%' }}
                  margin={false}
                  name="kmida"
                  min={0}
                  value={inputs.find((input) => input.name === 'kmida')?.value}
                  defaultValue={searchData.distancia}
                  title="Km (ida)"
                  onChange={(e) => {
                    changeInput(e.target.value, 'kmida', false);
                    calcularTodos();
                  }}
                />
                <Input
                  margin={false}
                  name="pedagios"
                  min={0}
                  value={formatDecimal(
                    inputs.find((input) => input.name === 'pedagios')?.value,
                  )}
                  defaultValue={searchData.pedagios}
                  title="(+) Pedágios"
                  onChange={(e) => {
                    changeInput(e.target.value, 'pedagios');
                    calcularTodos();
                  }}
                />
                <Input
                  margin={false}
                  name="freteAtual"
                  title="Frete atual"
                  value={formatDecimal(
                    inputs.find((input) => input.name === 'freteAtual')?.value,
                  )}
                  onChange={(e) => {
                    changeInput(e.target.value, 'freteAtual');
                    calcularTodos();
                  }}
                />
              </header>

              <aside>
                <Input
                  margin={false}
                  name="custoKm"
                  title="Custo por km"
                  readOnly
                  value={formatDecimal(
                    inputs.find((input) => input.name === 'custoKm')?.value,
                  )}
                />
                <Input
                  margin={false}
                  prefix="R$"
                  name="freteRecomendado"
                  readOnly
                  title="Frete recomendado pelo sistema"
                  value={formatDecimal(
                    inputs.find((input) => input.name === 'freteRecomendado')
                      ?.value,
                  )}
                />
                <Input
                  margin={false}
                  value={formatDecimal(
                    inputs.find((input) => input.name === 'icmsIss')?.value,
                  )}
                  prefix="%"
                  name="icmsIss"
                  title="(+) ICMS/ISS"
                  onChange={(e) => {
                    changeInput(e.target.value, 'icmsIss');
                    calcularTodos();
                  }}
                />
                <Input
                  margin={false}
                  prefix="R$"
                  value={formatDecimal(
                    inputs.find((input) => input.name === 'freteIcms')?.value,
                  )}
                  readOnly
                  name="freteIcms"
                  title="(+) Frete + ICMS/ISS"
                />
                <Input
                  margin={false}
                  prefix="R$"
                  value={formatDecimal(
                    inputs.find((input) => input.name === 'freteTrajeto')
                      ?.value,
                  )}
                  name="freteTrajeto"
                  title="Frete para o trajeto"
                  readOnly
                />
                <Input
                  margin={false}
                  name="diferencaRS"
                  prefix="R$"
                  onChange={(e) => {
                    changeInput(e.target.value, 'diferencaRS');
                    calcularTodos();
                  }}
                  value={formatDecimal(
                    inputs.find((input) => input.name === 'diferencaRS')?.value,
                  )}
                  title="Diferença em R$"
                  readOnly
                />
                <Input
                  margin={false}
                  onChange={(e) => {
                    changeInput(e.target.value, 'diferencaPorc');
                    calcularTodos();
                  }}
                  value={
                    inputs.find((input) => input.name === 'diferencaPorc')
                      ?.value
                  }
                  name="diferencaPorc"
                  title="Diferença em %"
                  readOnly
                />
                <Input
                  margin={false}
                  onChange={(e) => {
                    changeInput(e.target.value, 'faturamentoPorKMIda');
                    calcularTodos();
                  }}
                  name="faturamentoPorKMIda"
                  title="Faturamento por km (somente ida)"
                  value={
                    inputs.find((input) => input.name === 'faturamentoPorKMIda')
                      ?.value
                  }
                  info="Aqui vai a info do input"
                  readOnly
                />
                <Input
                  margin={false}
                  onChange={(e) => {
                    changeInput(e.target.value, 'faturamentoPorKMIdaVolta');
                    calcularTodos();
                  }}
                  name="faturamentoPorKMIdaVolta"
                  value={
                    inputs.find(
                      (input) => input.name === 'faturamentoPorKMIdaVolta',
                    )?.value
                  }
                  title="Faturamento por km (ida e volta)"
                  info="Aqui vai a info do input"
                  readOnly
                />
                <Button type="submit" loading={loadingSave}>
                  Salvar
                </Button>
              </aside>
              <Map
                center={[-23.550651, -46.633382]}
                bounds={[
                  [-32.89754919745616, -52.521500781101906],
                  [-1.06121867815292, -56.349007286718205],
                ]}
                zoom={4}
                minZoom={3}
                scrollWheelZoom={false}
              >
                <Marker
                  position={[metricasDestino.lati, metricasDestino.long]}
                  icon={mapIconB}
                />
                <Marker
                  position={[metricasOrigem.lati, metricasOrigem.long]}
                  icon={mapIconA}
                />
                {/* <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
                <TileLayer
                  url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                />
              </Map>
            </Form>
          </section>
        </Container>
      )}
    </>
  );
};

export default CalculoFrete;
