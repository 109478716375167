import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface SideBarProps {
  isExpansed: boolean;
}

export const Container = styled.ul<SideBarProps>`
  width: 100%;

  > li {
    display: flex;
    align-items: center;

    &:hover {
      background: ${shade(0.1, '#004970')};
    }

    svg {
      margin: 0 16px;
    }

    a,
    button {
      display: flex;
      background: transparent;
      border: 0;
      align-items: center;
      color: var(--grey);
      text-decoration: none;
      font-size: 13px;
      flex: 1;
      height: 50px;
      transition: background 0.4s;

      ${(props) =>
        props.isExpansed &&
        css`
          justify-content: center;
          @media screen and (max-width: 500px) {
            justify-content: flex-start;
          }
        `}

      p {
        ${(props) =>
          props.isExpansed &&
          css`
            display: none;

            @media screen and (max-width: 500px) {
              display: block;
            }
          `}
      }
    }
  }
`;
