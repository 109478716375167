import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { FiPrinter } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Header, Content, TitlePrint } from './styles';
import { Box } from '../components';
import api from '../../../../services/api';
import { useTimeline } from '../../../../hooks/timeline';
import Loading from '../../../../components/Loading';
import ParamDespesas from './ParamDespesas';
import DespesasFixas from './DespesasFixas';
import ParamDespesasVariaveis from './ParamDespesasVariaveis';
import ParamGerais from './ParamGerais';
import DespesasVariaveis from './DespesasVariaveis';
import ResumoDespesas from './ResumoDespesas';
import Button from '../../../../components/Button';

interface IArrayResumo extends HTMLDivElement {
  paramentros_despesas_fixas: {
    Rateio_Seguranca: number;
    Rateio_administrativo: number;
    beneficios_motoristas: number;
    capacidade_carga: number;
    desoneracao_folha: number;
    diarias_pernoites: number;
    elementos_adicionais_veiculo: number;
    encargos_sociais_trabalhistas: number;
    gerenciamento_riscos: number;
    idade_media_semireboque: number;
    idade_media_veiculo: number;
    perc_deprec_semireboque: number;
    perc_deprec_veiculo: number;
    perc_documentacao_ipva: number;
    perc_impostos_federais: number;
    perc_remuneracao_capital: number;
    perc_seguro_casco: number;
    perc_taxa_lucro_fixas: number;
    preco_semireboque: number;
    preco_veiculo: number;
    premio_variavel: number;
    quantidade_motoristas: number;
    remuneracao_motorista: number;
    seguro_carga_ambiental: number;
    tempo_espera: number;
  };
  despesas_fixas: {
    Rateio_administrativo: number;
    beneficios_motoristas: number;
    deprec_semireboque: number;
    deprec_veiculo: number;
    desoneracao_folha: number;
    diarias_motoristas: number;
    encargos_sociais_trabalhistas_fixas: number;
    gerenciamento_riscos: number;
    impostos_despesas_fixas: number;
    ipva_dpvat: number;
    lucro_despesas_fixas: number;
    premios_variaveis: number;
    remuneracao_capital: number;
    salario_motorista: number;
    seguranca: number;
    seguro_carga_ambiental: number;
    seguro_do_casco: number;
    tempo_espera: number;
    total_despesas_fixas: number;
  };
  parametros_despesas_variaveis: {
    arla_litro: number;
    combustivel_litro: number;
    custo_km_cavalo_caminhao: number;
    custo_km_cavalo_caminhao_semi: number;
    km_pneu: number;
    lavagem: number;
    media_km_litro: number;
    perc_taxa_lucro_variaveis: number;
  };
  parametros_gerais: {
    numero_viagens: number;
    qtd_pneu_ida: number;
    qtd_pneu_volta: number;
    total_km_rodado: number;
  };
  despesas_variaveis: {
    arla: number;
    combustivel: number;
    impostos_despesas_variaveis: number;
    lavagem: number;
    manutencao_cavalo: number;
    manutencao_semireboque: number;
    pneus: number;
    taxa_lucro_despesas_variaveis: number;
    total_despesas_variaveis: number;
  };
  resumo: {
    custo_fixo_km: number;
    custo_por_tonelada: number;
    custo_total_km: number;
    custo_variavel_km: number;
    participacao_despesas_fixas: number;
    participacao_despesas_variaveis: number;
    total_desp_fixa: number;
    total_desp_variaveis: number;
    total_geral: number;
  };
}

const Resumo: React.FC = () => {
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const history = useHistory();
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const [loading, setLoading] = useState(true);
  const [arrayPositionZeroPattern, setArrayPositionZeroPattern] = useState<
    IArrayResumo[]
  >([]);

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      setLoading(true);
      try {
        const response = await api.get(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Resumo&user_request=${id}&conta=${conta}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setArrayPositionZeroPattern(response.data.data);
        console.log('Dados');
        console.log('Data', response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        history.push('/calculos');
      }
    };

    loadTabs();
  }, [conta, id, token, temp, history]);

  const [loadingPrint, setLoadingPrint] = useState(false);

  const handlePrint = async (): Promise<void> => {
    try {
      setLoadingPrint(true);
      const req = await api.get(
        `api/v1/relatorios/resumo?calculo=${temp}&tabela=Resumo&user_request=${id}&conta=${conta}`,
        {
          responseType: 'blob',
          headers: {
            'x-access-token': token,
          },
        },
      );

      const file = new Blob([req.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      setLoadingPrint(false);
    } catch (error) {
      toast.error('Ocorreu um erro, por favor tente novamente.');
    }
  };

  return (
    <Container>
      {loading && <Loading isLoading={loading} />}
      <Header>
        Resumo
        <Button
          loading={loadingPrint}
          type="button"
          onClick={handlePrint}
          disabled={loadingPrint}
        >
          <FiPrinter size={30} color="#008f85" />
        </Button>
      </Header>
      {arrayPositionZeroPattern.length !== 0 && (
        <Component data={arrayPositionZeroPattern} />
      )}
      <Button type="button" onClick={next}>
        Próximo
      </Button>
    </Container>
  );
};
const Component: React.FC<{ data: IArrayResumo[] }> = ({ data }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const reactToPrintContent = React.useCallback(() => {
    return divRef.current;
  }, []);
  return (
    <>
      <div ref={divRef} style={{ overflow: 'hidden', color: '#000' }}>
        <TitlePrint>Cálculo</TitlePrint>
        <Content>
          <Box>
            <ParamDespesas
              paramentros_despesas_fixas={data[0].paramentros_despesas_fixas}
            />
          </Box>
          <Box>
            <DespesasFixas despesas_fixas={data[1].despesas_fixas} />
          </Box>
          <Box>
            <ParamDespesasVariaveis
              parametros_despesas_variaveis={
                data[2].parametros_despesas_variaveis
              }
            />
          </Box>
          <Box>
            <ParamGerais parametros_gerais={data[3].parametros_gerais} />
          </Box>
          <Box>
            <DespesasVariaveis
              despesas_variaveis={data[4].despesas_variaveis}
            />
          </Box>
          <Box>
            <ResumoDespesas resumo={data[5].resumo} />
          </Box>
        </Content>
      </div>
    </>
  );
};

export default Resumo;
