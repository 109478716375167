import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  padding: 20px;
  height: 100%;
  background: #fff;
  color: var(--text);

  h6 {
    font-size: 17px !important;
  }

  .cancel {
    background: transparent;
    margin-top: 10px;
    border: 0;
    font-size:10px;
    text-align:right;
    width: 100%;
  }
`;

export const Header = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 23px;
    text-align: center;
    flex: 1;
  }

  button {
    background: #c53030;
    border: 0;
    border-radius: 5px;
    color: #fff;
    padding: 10px 20px;
  }
`;
export const Box = styled.div``;

export const FormSty = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 1000px;
  .input {
    min-width: 1000px;
  }
  .react-select__control,
  .css-2b097c-container {
    width: 1000px;
    height: 40px;
    margin-top: 0;
    border-radius: 10px;
  }
`;

export const ButtonIcon = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c53030;

  svg {
    color: #fff;
  }
`;
