/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useEffect,
  useCallback,
  ButtonHTMLAttributes,
  useRef,
} from 'react';
import MaterialTable from 'material-table';
import {
  FiSearch,
  FiTrash2,
  FiArrowLeft,
  FiPlus,
  FiEdit,
  FiInfo,
  FiAlertTriangle,
  FiCheckCircle,
} from 'react-icons/fi';
import { useTransition, animated } from 'react-spring';
import { toast } from 'react-toastify';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import { size } from 'polished';
import Select from '../../components/Select';
import { useTimeline } from '../../hooks/timeline';
import formatDate from '../../utils/formatDate';
import api from '../../services/api';
import {
  Container,
  Header,
  Box,
  ButtonIcon,
  ButtonEdit,
  ButtonAdd,
  ModalNotification,
  List,
  FormSty,
  ContainerEnquadramento,
} from './styles';
import { useAuth } from '../../hooks/auth';
import { useMenu } from '../../hooks/menu';
import Elements from './Tables/Elements';
import AddCa from './Tables/AddCa';
import Rateios from './Tables/Rateios';
import Driver from './Tables/Driver';
import Taxes from './Tables/Taxes';
import RiskManagement from './Tables/RiskManagement';
import Breadcrumbs from '../../components/Breadcrumbs';
import SocialCharges from './Tables/SocialCharges';
import { useTabs } from '../../hooks/tabs';
import Diesel from './Tables/Diesel';
import Tires from './Tables/Tires';
import Maintenance from './Tables/Maintenance';
import Wash from './Tables/Wash';
import Cargoinsurance from './Tables/Cargoinsurance';
import CustoFrete from './Tables/CustoFrete';
import Resumo from './Tables/Resumo';
import CalculoFrete from './Tables/CalculoFrete';
import ModalTab from '../../components/Modal';
import Loading from '../../components/Loading';
import Input from '../../components/Input';
import getValidationErrors from '../../utils/getValidationErrors';
import Button from '../../components/Button';

export interface CalcData {
  cod: number;
  veiculo: ButtonHTMLAttributes<HTMLButtonElement>;
  eixos: string;
  data: Date | string;
  cliente: string;
  obs: string;
  visualizar: ButtonHTMLAttributes<HTMLButtonElement>;
  editar: ButtonHTMLAttributes<HTMLButtonElement>;
  remove: ButtonHTMLAttributes<HTMLButtonElement>;
}

interface NotificationImp {
  cod_notificacao: number;
  titulo: string;
  msg: string;
  tipo: 'Informativo' | 'Urgente';
}

interface ReturnCalc {
  cod: number;
  veiculo: string;
  eixos: string;
  data: Date;
  cliente: string;
  obs: string;
}
interface ReturnEditCalc {
  cod?: number;
  veiculo: string;
  eixos: string;
  cliente: string;
  obs: string;
}

const createData = ({
  cod,
  veiculo,
  eixos,
  data,
  cliente,
  obs,
  visualizar,
  editar,
  remove,
}: CalcData): CalcData => {
  return {
    cod,
    veiculo,
    eixos,
    data,
    cliente,
    obs,
    visualizar,
    editar,
    remove,
  };
};

const rowHead = [
  {
    title: 'Id',
    field: 'cod',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Veículo',
    field: 'veiculo',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },

  {
    title: 'Eixos',
    field: 'eixos',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },

  },
  {
    title: 'Data',
    field: 'data',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },

  },
  {
    title: 'Cliente',
    field: 'cliente',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Obs',
    field: 'obs',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },

  },
  {
    title: 'Visualizar',
    field: 'visualizar',
  },
  {
    title: 'Editar',
    field: 'editar',
    cellStyle: (): any => {
      return { fontSize: '10px', maxWidth: '10' };
    },
  },
  {
    title: 'Excluir',
    field: 'remove',
    cellStyle: (): any => {
      return { fontSize: '10px', maxWidth: '10' };
    },
  },
];

const Calculations: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingNotification, setLoadingNotification] = useState(true);
  const [notificationReady, setNotificationReady] = useState(false);
  const [line, setLine] = useState(false);
  const [notification, setNotification] = useState([]);
  const [calcs, setCalcs] = useState<ReturnCalc[]>([]);
  const { transition, goTransition, reset } = useTimeline();
  const { handleTemp } = useTabs();
  const formEditRef = useRef<FormHandles>(null);

  const [firstAccess, setFirstAccess] = useState(() => {
    const showModal = sessionStorage.getItem('@MaxiFrete:showModal');

    if (showModal === null) {
      sessionStorage.setItem('@MaxiFrete:showModal', 'show');
      return true;
    }
    return false;
  });

  const transitions = useTransition(transition, (p) => p, {
    from: { display: 'block' },
    leave: { display: 'none' },
  });

  const { user } = useAuth();
  const { expansedFalse } = useMenu();

  const token = sessionStorage.getItem('@MaxiFrete:token');
  const { id, conta } = user;

  const handleSubmit: SubmitHandler = useCallback(async (data) => {
    try {
      formEditRef.current?.setErrors({});
      const schema = Yup.object().shape({
        veiculo: Yup.string().required('Veiculo obrigatório'),
        eixos: Yup.string().required('Eixos obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      console.log(data);

      await api.put(
        `/api/v1/tabelas/calculo/${data.cod}`,
        {
          user_request: id,
          conta,
          veiculo: data.veiculo,
          eixos: data.eixos,
          cliente: data.cliente,
          obs: data.obs,
        },
        {
          headers: {
            'x-access-token': token,
          },
        },
      );

      setModal(true);

      window.location.reload();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formEditRef.current?.setErrors(errors);

        return;
      }
      toast.error(
        'Ocorreu um erro ao atualizar este cálculo, cheque as crendênciais.',
      );
    }
  }, []);

  const FormEdit: React.FC<ReturnEditCalc> = ({
    veiculo,
    obs,
    eixos,
    cliente,
    cod,
  }: ReturnEditCalc) => (
    <FormSty ref={formEditRef} onSubmit={handleSubmit}>
      <Header>Alteração do Cálculo</Header>

      <Input
        placeholder="Veículo"
        icon={FiInfo}
        defaultValue={veiculo}
        name="veiculo"
      />
      <Input
        icon={FiInfo}
        defaultValue={eixos}
        name="eixos"
        placeholder="Eixos"
      />
      <Input
        icon={FiInfo}
        name="cliente"
        defaultValue={cliente}
        placeholder="Cliente"
      />

      <Input
        icon={FiInfo}
        name="obs"
        defaultValue={obs}
        placeholder="Descricao"
      />
      <Input type="hidden" value={cod} name="cod" />
      <Button type="submit">Editar</Button>
    </FormSty>
  );

  const listNotifications = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get(`/api/v1/notificacoes/${id}`, {
        headers: {
          'x-access-token': token,
        },
      });
      setNotification(
        response.data.data.filter((item: any) => item.data_leitura === null),
      );
      setLoadingNotification(false);
    } catch (err) {
      toast.error('Erro ao listar notificações');
      setLoadingNotification(false);
    }
  }, [id, token]);

  const list = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get(
        `api/v1/tabelas/calculos?user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      // console.log(response.data.data);
      setCalcs(response.data.data);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao listar calculos.');
    }
  }, [conta, id, token]);

  const deleteUser = useCallback(
    async (cod: number): Promise<void> => {
      try {
        await api.delete(
          `/api/v1/tabelas/calculos?calculo=${cod}&user_request=${id}&conta=${conta}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        toast.info('Deletando calculo.');
        list();
      } catch (err) {
        toast.error('Erro ao deletar calculo.');
      }
    },
    [list, token, conta, id],
  );

  useEffect(() => {
    if (window.screen.width <= 500) {
      expansedFalse();
    }
    list();
  }, [list, expansedFalse]);

  useEffect(() => {
    listNotifications();
  }, [listNotifications]);

  const handelEdit = (cod: number): void => {
    setLine(true);
    handleTemp(cod);
    goTransition(1);
  };
  const [modal, setModal] = useState(false);

  const rowsList = calcs
    ? calcs.map((data) => {
        const { cod, veiculo, data: date, cliente, eixos, obs } = data;
        const row = createData({
          cod,
          veiculo: (
            <ButtonEdit type="button" onClick={() => handelEdit(cod)}>
              {veiculo}
            </ButtonEdit>
          ),
          eixos,
          data: formatDate(date),
          cliente,
          obs,
          visualizar: (
            <ButtonIcon type="button" onClick={() => handelEdit(cod)}>
              <FiSearch size={18} />
            </ButtonIcon>
          ),
          editar: (
            <ModalTab
              close={modal}
              modalContent={(
                <FormEdit
                  veiculo={veiculo}
                  eixos={eixos}
                  cliente={cliente}
                  obs={obs}
                  cod={cod}
                />
              )}
              icon="edit"
            />
          ),
          remove: (
            <ButtonIcon
              onClick={() => {
                return (
                  window.confirm('Deseja deletar o cálculo?') && deleteUser(cod)
                );
              }}
              type="button"
            >
              <FiTrash2 size={18} />
            </ButtonIcon>
          ),
        });
        return row;
      })
    : [];

  const tabs = [
    () => <AddCa />,
    () => <Elements />,
    () => <Rateios />,
    () => <Driver />,
    () => <Taxes />,
    () => <RiskManagement />,
    () => <SocialCharges />,
    () => <Diesel />,
    () => <Tires />,
    () => <Maintenance />,
    () => <Wash />,
    () => <Cargoinsurance />,
    () => <CustoFrete />,
    () => <Resumo />,
    () => <CalculoFrete />,
  ];

  const handleReadyNotification = useCallback(
    async (cod_notificacao: number): Promise<void> => {
      try {
        await api.post(
          '/api/v1/notificacoes/read',
          {
            cod_notificacao,
            conta,
            user_request: id,
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        toast.success('Marcada com sucesso.');
      } catch (error) {
        toast.error('Erro ao marcar notificação como lida.');
      }
    },
    [conta, id, token],
  );

  const ModalNotificationContent: React.FC = () => {
    return (
      <ModalNotification>
        {notification.map((item: NotificationImp) => (
          <List type={item.tipo} ready={notificationReady}>
            {item.tipo === 'Informativo' && <FiInfo size={25} />}
            {item.tipo === 'Urgente' && <FiAlertTriangle size={25} />}
            <aside>
              <section>
                <h3>{item.titulo}</h3>
                <button
                  type="button"
                  onClick={() => handleReadyNotification(item.cod_notificacao)}
                  disabled={notificationReady}
                >
                  {notificationReady ? (
                    <FiCheckCircle size={25} />
                  ) : (
                    'Marcar como lida'
                  )}
                </button>
              </section>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `<pre wrap>${item.msg}</pre>`,
                }}
              />
            </aside>
          </List>
        ))}
      </ModalNotification>
    );
  };

  const onSubmit = async (data: any): Promise<void> => {
    try {
      await api.put(
        `/api/v1/empresas/${user.conta}`,
        {
          user_request: user.id,
          conta: user.conta,
          enquadramento_fiscal: data.enquadramento_fiscal,
        },
        {
          headers: {
            'x-access-token': token,
          },
        },
      );

      window.sessionStorage.setItem(
        '@MaxiFrete:user',
        JSON.stringify({
          ...user,
          enquadramento_fiscal: data.enquadramento_fiscal,
        }),
      );

      toast.success('Encargos definido com sucesso.');

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast.error('Ocorreu um erro.');
    }
  };

  const options = [
    {
      value: 1,
      label:
        '1. Encargos Sociais para empresa optante pela Desoneração da Folha',
    },
    {
      value: 2,
      label:
        '2. Encargos Sociais para empresa NÃO optante pela Desoneração da Folha',
    },
    {
      value: 3,
      label:
        '3. Encargos Sociais para empresa optante do Regime Simples Nacional',
    },
  ];
  const refEnquadramentoFiscal = useRef<any>(null);
  if (user.enquadramento_fiscal === null) {
    return (
      <ContainerEnquadramento ref={refEnquadramentoFiscal} onSubmit={onSubmit}>
        <h1 style={{ color: 'red' }}>Encargos Sociais e Trabalhistas</h1>
        <h3>
          A incidência dos encargos sociais depende do tipo de enquadramento
          fiscal que a empresa se encontra.
        </h3>

        <Select
          name="enquadramento_fiscal"
          placeholder="Defina abaixo a opção de sua empresa"
          options={options}
        />
        <button type="submit">Selecionar</button>
      </ContainerEnquadramento>
    );
  }

  return (
    <Container bg={!!line}>
      <Loading isLoading={loadingNotification} />
      {notification.length > 0 && (
        <ModalTab
          modalContent={<ModalNotificationContent />}
          openInLoadingPage={firstAccess}
          hiddenButton
        />
      )}
      {line && (
        <Header>
          <h1>Cálculos</h1>
          <button
            type="button"
            onClick={() => {
              setLine(false);
              reset();
            }}
          >
            <FiArrowLeft size={20} color="#fff" />
          </button>
        </Header>
      )}
      {transition > 0 && <Breadcrumbs />}
      <Box>
        {line ? (
          <>
            {transitions.map(({ item, props, key }) => {
              const Screens: React.FC = tabs[item];
              return (
                <animated.div key={key} style={props}>
                  <Screens />
                </animated.div>
              );
            })}
          </>
        ) : (
          <>
            <MaterialTable
              isLoading={loading}
              columns={rowHead}
              data={rowsList}
              components={{
                Actions: () => {
                  return (
                    <ButtonAdd type="button" onClick={() => setLine(true)}>
                      <FiPlus size={10} color="#fff" />
                    </ButtonAdd>
                  );
                },
              }}
              options={{
                headerStyle: {
                  backgroundColor: '#4682B4',
                  color: '#fff',
                  textAlign: 'left',
                  maxWidth:'30',
                  width:'30',
                  

                },
                pageSize: 6,
                sorting: true,
                searchFieldAlignment: 'left',
                rowStyle: (x: any): any => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: '#f2f2f2' };
                  }
                },
              }}
              title=""
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                },
                toolbar: {
                  searchPlaceholder: 'Pesquisar',
                  searchTooltip: 'Pesquisar',
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum registro encontrado',
                  filterRow: {
                    filterTooltip: 'Filtrar',
                  },
                },
              }}
            />
          </>
        )}
      </Box>
    </Container>
  );
};

export default Calculations;
