import styled from 'styled-components';

export const Container = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  button {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    width: 200px;
    padding: 10px 5px;
    border: 0;
    background-color: var(--text);
    border-radius: 5px;
    color: #fff;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--text);
  }

  display: block;

  width: 100%;
  overflow: auto;
  border: 0px;
  border-radius: 5px;

  @media screen and (max-width: 959px) {
    width: 100%;
    max-height: max-content;
    overflow: hidden;
  }

  h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  h5 {
    font-size: 16px;
    margin: 4px;

    font-weight: 700;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 425px) {
      grid-template-columns: 1fr;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
  }
`;
export const Header = styled.h2`
  text-align: center;
  color: var(--blue-b);
  font-size: 23px;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
