import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { FaSpinner } from 'react-icons/fa';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.button`
  background: var(--yellow);
  height: 40px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: var(--primary);
  width: 100%;
  font-weight: 500;
  margin-top: 10px;
  transition: background-color 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${shade(0.2, '#FFD400')};
  }
`;

export const LoadIcon = styled(FaSpinner)`
  width: 22px;
  height: 22px;
  animation: ${rotate} 2s linear infinite;
`;
