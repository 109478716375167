import React from 'react';
import { useTransition } from 'react-spring';

import { Container, Routes } from './styles';

import { useMenu } from '../../hooks/menu';

import SideBar from '../SideBar';
import NavBar from '../NavBar';
import Footer from '../Footer';

const Layout: React.FC = ({ children }) => {
  const { expansed } = useMenu();

  const menu = useTransition(SideBar, null, {
    initial: { gridTemplateColumns: '15% 85%' },
    update: {
      gridTemplateColumns: !expansed ? '15% 85%' : '5%  95%',
    },
  });

  return (
    <>
      {menu.map(({ key, props }) => {
        return (
          <Container key={key} style={props}>
            <SideBar />
            <NavBar />
            <Routes>{children}</Routes>
            <Footer />
          </Container>
        );
      })}
    </>
  );
};

export default Layout;
