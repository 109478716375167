import React from 'react';
import { Switch } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import Route from './Route';
import Layout from '../components/Layout';
import Home from '../pages/Home';
import SignIn from '../pages/SignIn';
import Forgot from '../pages/Forgot';
import AddField from '../pages/AddField';

import Users from '../pages/Users';
import Company from '../pages/Company';
import Notifications from '../pages/Notifications';
import Calculations from '../pages/Calculations';
import Rentabilidades from '../pages/Rentabilidades';

const Routes: React.FC = () => {
  return (
    <>
      <ToastContainer autoClose={2500} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/usuarios/reset_password" component={Forgot} />
        <Route path="/sign" component={SignIn} />
        <Layout>
          <Route path="/users" isPrivate component={Users} />
          <Route path="/empresa" isPrivate component={Company} />
          <Route path="/notificacoes" isPrivate component={Notifications} />
          <Route path="/calculos" isPrivate component={Calculations} />
          <Route path="/addField" isPrivate component={AddField} />
          <Route path="/rentabilidade" isPrivate component={Rentabilidades} />
        </Layout>
      </Switch>
    </>
  );
};

export default Routes;
