/* eslint-disable eqeqeq */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler, Scope } from '@unform/core';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Box, Header, List, Li } from './styles';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import Loading from '../../../../components/Loading';
import { useTimeline } from '../../../../hooks/timeline';
import formatMoney from '../../../../utils/formatMoney';

interface IArrayZero {
  agrupamento: number;
  subtipo: string;
  cod: number;
  campo: string;
  elemento: number;
  valor: number;
  info: string;
  read_only: number;
}

interface ITab {
  cod: number;
  enquadramento_fiscal: number;
  item: string;
  valor: number;
  tipo: string;
}

const SocialCharges: React.FC = () => {
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [enquadramento, setEnquadramento] = useState(0);
  const [tabEnquadramentos, setTabEnquadramentos] = useState<ITab[]>([]);
  const [inputs, setInputs] = useState<IArrayZero[]>([]);

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      setLoading(true);
      const response = await api.get(
        `/api/v1/empresas?user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      const responseTabs = await api.get(
        `/api/v1/empresas/encargosST?user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      setTabEnquadramentos(responseTabs.data.data);
      console.log(response.data.data);
      setEnquadramento(response.data.data[0].enquadramento_fiscal);

      if (response.data.data[0].enquadramento_fiscal == 1) {
        const { data } = await api.get(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Desoneração da Folha&user_request=${id}&conta=${conta}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        console.log('data', data.data[0].itens);
        setInputs(data.data[0].itens);
      }
      setLoading(false);
    };

    loadTabs();
  }, [conta, id, token, temp]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        const array = data.modules.filter(
          (e: any) => e !== '' && e.valor !== '0',
        );
        await api.put(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Desoneração da Folha&user_request=${id}&conta=${conta}`,
          {
            user_request: id,
            conta,
            calculo: temp,
            itens: array.map((e: any) => ({
              valor: e.valor
                .replace('R$', '')
                .trim()
                .replaceAll('.', '')
                .replace(',', '.'),
              cod: e.cod,
            })),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        next();
      } catch (err) {
        console.error(err);
      }
    },
    [next, conta, id, token, temp],
  );

  return (
    <Container>
      {loading ? (
        <Loading isLoading={loading} />
      ) : (
        <>
          <Header>Encargos Sociais</Header>
          <Form ref={formRef} onSubmit={handleAddSubmit}>
            <Box>
              {enquadramento === 1 && (
                <h2>
                  Encargos Sociais para empresa optante pela Desoneração da
                  Folha:
                </h2>
              )}
              {enquadramento === 2 && (
                <h2>
                  Encargos Sociais para empresa NÃO optante pela Desoneração da
                  Folha:
                </h2>
              )}
              {enquadramento === 3 && (
                <h2>
                  Encargos Sociais para empresa optante do Regime Simples
                  Nacional:
                </h2>
              )}
              <List>
                {tabEnquadramentos
                  ? tabEnquadramentos.map((item) => {
                      console.log(item);
                      return (
                        <Li
                          spotlight={
                            !!(
                              item.item === 'Total Encargos Sociais' ||
                              item.item === 'Total Encargos Trabalhistas' ||
                              item.item ===
                                'Total Encargos Sociais e Trabalhistas'
                            )
                          }
                          key={item.cod}
                        >
                          <h5>{item.item}:</h5>
                          <span>{`${item.valor}%`}</span>
                        </Li>
                      );
                    })
                  : []}
              </List>
             {inputs ? <Header>Desoneração da Folha</Header>:''}
              {inputs
                ? inputs.map((item, index) => {
                    return (
                      
                      <Scope key={item.campo} path={`modules[${item.cod}]`}>
                        
                        <h5>{item.campo}</h5>
                        <Input
                          name="valor"
                          type="text"
                          value={
                            item.elemento === 90
                              ? item.valor
                              : formatMoney(item.valor)
                          }
                          readOnly={item.read_only !== 0}
                          disabled={item.read_only !== 0}
                          info={item.info && item.info}
                          onChange={(e): void => {
                            const newTelements = [...inputs];
                            console.log(newTelements);
                            newTelements[index].valor =
                              item.elemento === 90
                                ? Number(
                                    e.target.value
                                      .split(/[^\d]+/g)
                                      .join('')
                                      .trim(),
                                  )
                                : Number(
                                    Number(
                                      e.target.value
                                        .split(/[R$\\/,/./]/)
                                        .join('')
                                        .split(/[^\d]+/g)
                                        .join('')
                                        .trim(),
                                    ) / 100,
                                  );
                            const idxTotal = newTelements.findIndex(
                              (x) => x.elemento === 116,
                            );
                            const idxTotalFunc = newTelements.find(
                              (x) => x.elemento === 90,
                            )?.valor;
                            const darf = newTelements.find(
                              (x) => x.elemento === 89,
                            )?.valor;

                            if (idxTotal !== -1) {
                              newTelements[idxTotal].valor = 0;
                              if (darf && idxTotalFunc) {
                                newTelements[idxTotal].valor =
                                  darf / idxTotalFunc;
                              }
                            }
                            setInputs(newTelements);
                          }}
                        />
                        <Input name="cod" type="hidden" value={item.cod} />
                      </Scope>
                      
                    );
                  })
                : []}
            </Box>
            <button type="submit">Salvar</button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default SocialCharges;
