import { Form } from '@unform/web';
import styled, { css } from 'styled-components';
import bg from '../../assets/signin2.jpg';

interface IContainer {
  bg: boolean;
}

interface IListProps {
  type: 'Urgente' | 'Informativo';
  ready: boolean;
}

export const Container = styled.div<IContainer>`
  padding: 5px;
  height: 100%;
  background: url(${bg});
  ${(props) =>
    props.bg &&
    css`
      background: #fff;
    `}
  color: var(--text);

  h6 {
    font-size: 17px !important;
  }
  font-size: 14px;
`;
export const ButtonEdit = styled.button`
  border: 0;
  background: transparent;
  color: var(--blue);
  text-align: center;
`;

export const BgNone = styled.section`
  background-color: #fff;
`;

export const ButtonAdd = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #28a745;
`;

export const Header = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  h1 {
    font-size: 29px;
    margin-top: -45px;
    text-align: center;
    color: var(--blue-b);
    flex: 1;
  }

  button {
    border: 0;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #28a745;
    position: absolute;
    right: 0;
  }
`;

export const Box = styled.div``;

export const ButtonIcon = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c53030;

  svg {
    color: #fff;
  }
`;

export const ModalNotification = styled.ul`
  list-style: none;
`;

export const List = styled.li<IListProps>`
  color: #fff;
  padding: 12px 20px;
  border-radius: 4px;

  h3,
  p {
    word-break: break-all;
    padding-right: 10px;
  }

  p {
    padding-top: 20px;
    text-align: justify;
  }

  & + li {
    margin-top: 15px;
  }

  ${(props) =>
    props.type === 'Informativo' &&
    css`
      background: var(--grey);
      color: #000;
    `}

  ${(props) =>
    props.type === 'Urgente' &&
    css`
      background: var(--yellow);
      color: #000;
    `}

  display: flex;
  align-items: flex-start;

  > svg {
    margin-right: 15px;
  }

  aside {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    section {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    button {
      background: #fff;
      padding: 10px 15px;
      border-radius: 4px;
      border: 0;

      ${(props) =>
        props.type === 'Informativo' &&
        css`
          color: var(--blue);

          svg {
            color: var(--blue);
          }
        `}

      ${(props) =>
        props.type === 'Urgente' &&
        css`
          color: var(--red);
          svg {
            color: var(--red);
          }
        `}

        ${(props) =>
          props.ready &&
          css`
            background: transparent;
            padding: 0;

            svg {
              color: #fff;
            }
          `}
    }
  }
`;

export const ContainerEnquadramento = styled(Form)`
  background: url(${bg});
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  flex-direction: column;
  gap: 10%;

  h1 {
    font-size: 32px;
    margin-top:-50px;
  }

  h3 {
    font-size: 14px;
    
  }

  > div {
    width: 50%;
  }

  button {
    width: 30%;
    padding: 10px;
    border-radius: 5px;
    border: 0;
  }
`;

export const FormSty = styled(Form)`
  display: flex;
  width:1000px;
  flex-direction: column;
  .input{
    width:1000px;
  }
`;

export const ButtonIconEdit = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c53030;

  svg {
    color: #fff;
  }
`;
