import styled, { css } from 'styled-components';

export const Container = styled.div<{ hidden: boolean }>`
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}

  input,
  select {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff;
    padding: 10px 16px;
    width: 100%;

    border-radius: 5px;
  }
  #float-label {
    display: flex;
    flex-direction: column;
    
    position: relative;
  }
  
  #float-label input {
    width: 100%;
    height: 56px;
    padding: 14px 16px 0 10px;
    outline: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
  
  #float-label label {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 12px;
    color: #999;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 26px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }
  #float-label .Active {
    transform: translate(0, 10px) scale(0.75);
  }
`;
