import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  

  section:nth-child(1) {
    background: #120b4f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    min-height: 150px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    div {
      display: flex;
      align-items: center;
      gap: 25px;

      @media screen and (max-width: 425px) {
        flex-direction: column;
        margin-top: 20px;
      }
      a {
        font-size: 19px;
        display: flex;
        align-items: center;
        color: #fff;
        text-decoration: none;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  

  section:nth-child(2) {
    background: #000;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100px;
    padding: 20px;

    button {
      background: transparent;
      border: 0;
      color: #fff;
      display: flex;
    }
  }
`;

export const PrivacyPolicy = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  .titulo{
    color: rgb(21, 23, 143); 
    font-weight: bold;
    font-size=20px;
  }

`;
