import React, {
  ButtonHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet';
import Leaflet from 'leaflet';
import { FormHandles, SubmitHandler } from '@unform/core';
import debounce from 'debounce-promise';
import Async from 'react-select/async';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { FiTrash2 } from 'react-icons/fi';
import Input from '../../../components/Input';
import { useAuth } from '../../../hooks/auth';
import { useTabs } from '../../../hooks/tabs';
import api from '../../../services/api';

import { Container, FormSty, ButtonIcon } from './styles';
import marker from '../../../assets/pin.svg';
import ModalTab from '../../../components/Modal';
import Button from '../../../components/Button';
import formatMoney from '../../../utils/formatMoney';
import Loading from '../../../components/Loading';

interface ITable {
  id?: number;
  cliente: string;
  eixos: number;
  km: string;
  pedagio_total: string;
  pedagio_eixo: string;
  trecho_fim: string;
  trecho_inicio: string;
  valor_frete: string;
  edit?: any;
  remove?: ButtonHTMLAttributes<HTMLButtonElement>;
}

interface ITrechosLatLog {
  trecho_ini_lat: number;
  trecho_ini_long: number;
  trecho_fim_long: number;
  trecho_fim_lat: number;
}

const createData = ({
  cliente,
  eixos,
  km,
  pedagio_total,
  pedagio_eixo,
  trecho_fim,
  trecho_inicio,
  valor_frete,
  edit,
  remove,
}: ITable): ITable => {
  return {
    cliente,
    eixos,
    km,
    pedagio_total,
    pedagio_eixo,
    trecho_fim,
    trecho_inicio,
    valor_frete,
    edit,
    remove,
  };
};

const rowHead = [
  {
    title: 'Início Trecho',
    headStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
    field: 'trecho_inicio',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Fim do Trecho',
    field: 'trecho_fim',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Cliente/Vazio',
    field: 'cliente',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Valor frete sem ICMS',
    field: 'valor_frete',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'KM',
    field: 'km',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Pedágio por Eixo',
    field: 'pedagio_eixo',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Eixos',
    field: 'eixos',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Custo do pedágio',
    field: 'pedagio_total',
    cellStyle: (): any => {
      return { fontSize: '10px', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Editar',
    field: 'edit',
  },
  {
    title: 'Excluir',
    field: 'remove',
  },
];

interface ICalculo {
  custo_km: number;
  faturamento_com_pegagios: number;
  faturamento_por_km: number;
  faturamento_sem_pegagios: number;
  km_carregado: number;
  km_total: number;
  km_vazio: number;
  lucro_bruto_km: number;
  rentabilidade_porc: number;
  rentabilidade_rs: number;
}

const Rentabilidade: React.FC = () => {
  const formAddRef = useRef<FormHandles>(null);
  const formEditRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const [loading, setLoading] = useState(true);
  const { id, conta } = user;
  const { temp } = useTabs();
  const [trechos, setTrechos] = useState<ITable[]>([]);
  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [trechosLatLog, setTrechosLatLog] = useState<ITrechosLatLog[]>([]);
  const [calculo, setCalculo] = useState<ICalculo>();
  const [routes, setRoutes] = useState({
    origem: '',
    destino: '',
  });
  let trecho_inicio = '';
  let trecho_fim = '';

  const load = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await api.get(
        `/api/v1/tabelas/rentabilidade/${temp}?user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      console.log(response.data.data);
      setTrechos(response.data.data.Itens);
      setCalculo(response.data.data.Rentabilidade[0]);
      setRoutes({
        origem: response.data.data.Rentabilidade[0].origem,
        destino: response.data.data.Rentabilidade[0].destino,
      });
      setTrechosLatLog(response.data.data.Itens);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao listar rentabilidades.');
    }
  }, [conta, id, temp, token]);

  useEffect(() => {
    load();
  }, [conta, id, temp, token, load]);

  const loadSuggestions = async (inputValue: string): Promise<any> => {
    try {
      const request = await api.get(
        `api/v1/utilities/cidades/?user_request=${id}&conta=${conta}&cidade=${inputValue}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      const returnData = request.data;
      const suggestions = returnData.data.map((sug: any) => {
        return {
          value: sug.cidade,
          label: sug.cidade,
        };
      });
      return suggestions;
    } catch (error) {
      console.log(error);
    }
  };

  const loadOptions = (inputValue: string): any => loadSuggestions(inputValue);

  const debouncedLoadOptions = debounce(loadOptions, 2000, {
    leading: true,
  });

  const markerIcon = Leaflet.icon({
    iconUrl: marker,
    iconSize: [35, 35],
    iconAnchor: [17.5, 35],
  });

  const deleteUser = async (cod: number | undefined): Promise<void> => {
    await api.delete(`/api/v1/tabelas/rentabilidadeTrechos/${cod}`, {
      headers: {
        'x-access-token': token,
      },
    });
    load();
  };

  const FormEdit: React.FC<any> = ({ formValues }) => {
    const {
      id: idTrecho,
      km,
      pedagio_eixo,
      eixos: defaultEixos,
      pedagio_total,
      trecho_inicio: trecho_inicioForm,
      trecho_fim: trecho_fimForm,
      cliente,
      valor_frete,
    } = formValues;
    const [inputKM, setInputKM] = useState(km);
    const [pedagio, setPedagio] = useState(pedagio_eixo);
    const [custoPedagio, setCustoPedagio] = useState(pedagio_total);
    const [eixos, setEixos] = useState(defaultEixos);
    const [defaultOrigem, setDefaultOrigem] = useState(trecho_inicioForm);
    const [defaultDestino, setDefaultDestino] = useState(trecho_fimForm);

    const calcCustoPedagio = ({ eixoInput, pedagioInput }: any): void => {
      if (eixoInput) {
        const calc = pedagio * eixoInput;
        setCustoPedagio(calc);
      } else if (pedagioInput) {
        const calc = pedagioInput * eixos;
        console.log(`opaaa${calc}`);
        setCustoPedagio(calc);
      } else {
        const calc = pedagio * Number(eixos);
        setCustoPedagio(calc);
      }

      if (eixoInput == 0 || pedagioInput == 0) {
        setCustoPedagio(0);
      }
    };

    const getCustomKM = async (
      origem = 'Sao Paulo-SP',
      destino = 'Sao Paulo-SP',
    ): Promise<void> => {
      console.log(`${origem}des${destino}`);
      if (defaultOrigem !== '' && defaultDestino !== '') {
        const response = await api.get(
          `api/v1/utilities/getkmpedagios/?user_request=${id}&conta=${conta}&calculo=${temp}&origem=${origem}&destino=${destino}&calculavolta=nao&eixos=1`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setInputKM(response.data.data.distancia);
        setPedagio(response.data.data.pedagios/2);
        //console.log(eixos);
        eixos > 0
          ? calcCustoPedagio({
              pedagioInput: Number(
                response.data.data.pedagios ? response.data.data.pedagios : 0,
              ),
            })
          : calcCustoPedagio({});
      }
    };

    const handleEditSubmit: SubmitHandler = async (data) => {
      //console.log(data);
      try {
        await api.put(
          `/api/v1/tabelas/rentabilidadeTrechos`,
          {
            id: data.id,
            user_request: id,
            conta,
            rentabilidade: temp,
            origem: defaultOrigem,
            destino: defaultDestino,
            cliente: data.cliente,
            valor_frete: Number(
              data.valor_frete
                .replace('R$', '')
                .trim()
                .replace('.', '')
                .replace(',', '.'),
            ),
            km: data.km
              .replace('R$', '')
              .trim()
              .replace('.', '')
              .replace(',', '.'),
            pedagio_eixo: data.pedagio_eixo
              .replace('R$', '')
              .trim()
              .replace('.', '')
              .replace(',', '.'),
            eixos: data.eixos,
            pedagio_total: data.pedagio_total
              .replace('R$', '')
              .trim()
              .replace('.', '')
              .replace(',', '.'),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setModalEdit(true);
        load();
        toast.success('Atualizado com sucesso.');
      } catch (err) {
        toast.error('Ocorreu um erro ao atualizar.');
      }
    };

    const [valorFrete, setValorFrete] = useState(valor_frete);

    return (
      <FormSty ref={formEditRef} onSubmit={handleEditSubmit}>
        <Async
          loadOptions={debouncedLoadOptions}
          cacheOptions
          noOptionsMessage={() => 'Nenhuma opção encontrada'}
          placeholder="Trecho início"
          value={{ label: defaultOrigem, value: defaultOrigem }}
          onChange={async (value: any) => {
            setDefaultOrigem(value.value);
            console.log('origem');
            console.log(value.value);
            await getCustomKM(value.value, defaultDestino);
          }}
          className="selectModal"
        />
        <Async
          value={{ label: defaultDestino, value: defaultDestino }}
          loadOptions={debouncedLoadOptions}
          cacheOptions
          noOptionsMessage={() => 'Nenhuma opção encontrada'}
          placeholder="Trecho fim"
          onChange={async (value: any) => {
            console.log('origem');
            console.log(value.value);
            setDefaultDestino(value.value);
            await getCustomKM(defaultOrigem, value.value);
          }}
          className="selectModal"
        />
        <Input name="cliente" title="Cliente/Vazio" defaultValue={cliente} />
        <Input
          name="valor_frete"
          value={formatMoney(valorFrete)}
          onChange={(e) => {
            setValorFrete(
              +e.target.value
                .split(/[R$\\/,/./]/)
                .join('')
                .split(/[^\d]+/g)
                .join('')
                .trim() / 100,
            );
          }}
          title="Valor frete sem ICMS"
        />
        <Input
          name="km"
          title="Km"
          value={formatMoney(inputKM)
            .split(/[R$\\/]/)
            .join('')}
          onChange={(e) => {
            setInputKM(
              +e.target.value
                .split(/[R$\\/,/./]/)
                .join('')
                .split(/[^\d]+/g)
                .join('')
                .trim() / 100,
            );
          }}
        />

        <Input
          name="eixos"
          title="Eixos"
          value={eixos}
          onChange={(e) => {
            setEixos(+e.target.value.split(/[^\d]+/g).join(''));
            calcCustoPedagio({ eixoInput: Number(e.target.value) });
          }}
        />
        <Input
          name="pedagio_eixo"
          title="Pedágio por eixo"
          type="text"
          value={formatMoney(pedagio)}
          onChange={(e) => {
            setPedagio(
              Number(
                e.target.value
                  .split(/[R$\\/,/./]/)
                  .join('')
                  .split(/[^\d]+/g)
                  .join('')
                  .trim(),
              ) / 100,
            );
            calcCustoPedagio({
              pedagioInput:
                Number(
                  e.target.value
                    .split(/[R$\\/,/./]/)
                    .join('')
                    .split(/[^\d]+/g)
                    .join('')
                    .trim(),
                ) / 100,
            });
          }}
        />
        <Input
          name="pedagio_total"
          title="Custo do pedágio"
          value={formatMoney(custoPedagio)}
          onChange={(event) => {
            setCustoPedagio(
              Number(
                event.target.value
                  .split(/[R$\\/,/./]/)
                  .join('')
                  .split(/[^\d]+/g)
                  .join('')
                  .trim(),
              ) / 100,
            );
          }}
        />
        <Input type="hidden" name="id" value={idTrecho} />
        <Button type="submit">Adicionar</Button>
      </FormSty>
    );
  };
  // Custo pedagio = eixo * pedagio por eixo
  const rowsList = trechos
    ? trechos.map((data) => {
        const {
          id: cod,
          trecho_inicio: trecho_inicioData,
          trecho_fim: trecho_fimData,
          cliente,
          valor_frete,
          km,
          eixos,
          pedagio_total,
          pedagio_eixo,
        } = data;
        const row = createData({
          trecho_inicio: trecho_inicioData,
          trecho_fim: trecho_fimData,
          cliente,
          pedagio_eixo: String(formatMoney(String(pedagio_eixo))),
          valor_frete: formatMoney(valor_frete),
          km: Intl.NumberFormat('pt-BR').format(Number(km)).split('-').join(''),
          eixos,
          pedagio_total: formatMoney(pedagio_total),
          edit: (
            <ModalTab
              close={modalEdit}
              modalContent={<FormEdit formValues={data} />}
              icon="edit"
            />
          ),
          remove: (
            <ButtonIcon
              onClick={() => {
                return window.confirm('Deseja deletar o trecho?')
                  ? deleteUser(cod)
                  : '';
              }}
              type="button"
            >
              <FiTrash2 size={18} />
            </ButtonIcon>
          ),
        });
        return row;
      })
    : [];

  const handleAddSubmit: SubmitHandler = async (data) => {
    try {
      await api.post(
        `/api/v1/tabelas/rentabilidadeTrechos`,
        {
          user_request: id,
          conta,
          rentabilidade: temp,
          origem: trecho_inicio,
          destino: trecho_fim,
          cliente: data.cliente,
          valor_frete: data.valor_frete
            .replace('R$', '')
            .trim()
            .replace('.', '')
            .replace(',', '.'),
          km: +data.km
            .replace('R$', '')
            .trim()
            .replace('.', '')
            .replace(',', '.'),
          pedagio_total: data.pedagio_total
            .replace('R$', '')
            .trim()
            .replace('.', '')
            .replace(',', '.'),
          eixos: data.eixos,
          pedagio_eixo: data.pedagio_eixo
            .replace('R$', '')
            .trim()
            .replace('.', '')
            .replace(',', '.'),
        },
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      load();
      setModal(true);
      toast.success('Trecho inserido com sucesso.');
    } catch (err) {
      toast.error('Ocorreu um erro ao inserir.');
    }
  };

  const FormAdd: React.FC = () => {
    const [inputKM, setInputKM] = useState(0);
    const [pedagio, setPedagio] = useState(0);
    const [custoPedagio, setCustoPedagio] = useState(0);
    const [eixos, setEixos] = useState(0);

    const calcCustoPedagio = ({ eixoInput, pedagioInput }: any): void => {
      console.log(`passannnn${pedagio}eixo${eixoInput}`);

      if (eixoInput) {
        console.log(`passannnn${pedagio}eixo${eixoInput}`);
        const calc = pedagio * eixoInput;
        console.log(calc);
        setCustoPedagio(calc);
      } else if (pedagioInput) {
        const calc = pedagioInput * eixos;
        setCustoPedagio(calc);
      } else {
        const calc = pedagio * Number(eixos);
        setCustoPedagio(calc);
      }

      if (eixoInput == 0 || pedagioInput == 0) {
        setCustoPedagio(0);
      }
    };

    const getCustomKM = async (
      origem = 'Sao Paulo-SP',
      destino = 'Sao Paulo-SP',
    ): Promise<void> => {
      if (trecho_fim !== '' && trecho_inicio !== '') {
        const response = await api.get(
          `api/v1/utilities/getkmpedagios/?user_request=${id}&conta=${conta}&calculo=${temp}&origem=${origem}&destino=${destino}&calculavolta=nao&eixos=1`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        
        setInputKM(response.data.data.distancia);
        setPedagio(response.data.data.pedagios/2);
        calcCustoPedagio({});
      }
    };
    const [valorFrete, setValorFrete] = useState(0);
    return (
      <FormSty ref={formAddRef} onSubmit={handleAddSubmit}>
        <Async
          loadOptions={debouncedLoadOptions}
          cacheOptions
          noOptionsMessage={() => 'Nenhuma opção encontrada'}
          placeholder="Trecho Inicio"
          onChange={async (value: any) => {
            trecho_inicio = value.value;
            await getCustomKM(trecho_inicio, trecho_fim);
            calcCustoPedagio({});
          }}
          className="selectModal"
        />
        <Async
          loadOptions={debouncedLoadOptions}
          cacheOptions
          noOptionsMessage={() => 'Nenhuma opção encontrada'}
          placeholder="Trecho fim"
          onChange={async (value: any) => {
            trecho_fim = value.value;
            await getCustomKM(trecho_inicio, trecho_fim);
            calcCustoPedagio({});
          }}
          className="selectModal"
        />
        <Input name="cliente" title="Cliente/Vázio" />
        <Input
          name="valor_frete"
          value={formatMoney(valorFrete)}
          onChange={(e) => {
            setValorFrete(
              +e.target.value
                .split(/[R$\\/,/./]/)
                .join('')
                .split(/[^\d]+/g)
                .join('')
                .trim() / 100,
            );
          }}
          title="Valor frete sem ICMS"
        />
        <Input
          name="km"
          title="Km"
          value={formatMoney(inputKM)
            .split(/[R$\\/]/)
            .join('')}
          onChange={(e) => {
            setInputKM(
              +e.target.value
                .split(/[R$\\/,/./]/)
                .join('')
                .split(/[^\d]+/g)
                .join('')
                .trim() / 100,
            );
          }}
        />
        <Input
          name="eixos"
          title="Eixos"
          value={eixos}
          onChange={(e) => {
            setEixos(+e.target.value.split(/[^\d]+/g).join(''));
            calcCustoPedagio({ eixoInput: Number(e.target.value) });
          }}
        />
        <Input
          name="pedagio_eixo"
          title="Pedágio por eixo"
          type="text"
          value={formatMoney(pedagio)}
          onChange={(e) => {
            setPedagio(
              Number(
                e.target.value
                  .split(/[R$\\/,/./]/)
                  .join('')
                  .split(/[^\d]+/g)
                  .join('')
                  .trim(),
              ) / 100,
            );
            calcCustoPedagio({
              pedagioInput:
                Number(
                  e.target.value
                    .split(/[R$\\/,/./]/)
                    .join('')
                    .split(/[^\d]+/g)
                    .join('')
                    .trim(),
                ) / 100,
            });
          }}
        />
        <Input
          name="pedagio_total"
          title="Custo do pedágio"
          value={formatMoney(custoPedagio)}
          onChange={(e) => {
            setCustoPedagio(
              Number(
                +e.target.value
                  .split(/[R$\\/,/./]/)
                  .join('')
                  .split(/[^\d]+/g)
                  .join('')
                  .trim() / 100,
              ),
            );
          }}
        />
        <Button type="submit">Adicionar</Button>
      </FormSty>
    );
  };

  return (
    <>
      {loading && <Loading isLoading={loading} />}
      <Container>
        <header>
          <p>Rentabilidade da rota</p>
          <p>Origem: {routes.origem}</p>
          <p>Destino: {routes.destino}</p>
        </header>
        <article>
          <MaterialTable
            columns={rowHead}
            data={rowsList}
            title="Trechos cadastrados"
            options={{
              overflowY: 'auto',
              headerStyle: {
                height: 10,
                maxHeight: 10,
                backgroundColor: '#039be5',
                color: '#FFF',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
            components={{
              Actions: () => {
                return (
                  <ModalTab
                    close={modal}
                    modalContent={<FormAdd />}
                    icon="add"
                  />
                );
              },
            }}
            localization={{
              pagination: {
                labelRowsSelect: 'linhas',
              },
              toolbar: {
                searchPlaceholder: 'Pesquisar',
                searchTooltip: 'Pesquisar',
              },
              body: {
                emptyDataSourceMessage: 'Nenhum registro encontrado',
                filterRow: {
                  filterTooltip: 'Filtrar',
                },
              },
            }}
          />
        </article>
        <section className="mapContainer">
          <div>
            <h2>Resultados analíticos</h2>
            <ul>
              <li>
                <p>Faturamento total com pedágios:</p>
                {formatMoney(calculo?.faturamento_com_pegagios)}
              </li>
              <li>
                <p>Faturamento total sem pedágios:</p>
                {formatMoney(calculo?.faturamento_sem_pegagios)}
              </li>
              <li>
                <p> Faturamento por km sem pedágios:</p>
                {formatMoney(calculo?.faturamento_por_km)}
              </li>
              <li>
                <p>Km total:</p>
                {calculo?.km_total}
              </li>
              <li>
                <p>Custo por km:</p>
                {formatMoney(calculo?.custo_km)}
              </li>
              <li>
                <p>Lucro bruto por km:</p>
                {formatMoney(calculo?.lucro_bruto_km)}
              </li>
              <li>
                <p>Rentabilidade em R$:</p>
                {formatMoney(calculo?.rentabilidade_rs)}
              </li>
              <li>
                <p>Rentabilidade em %:</p>
                {formatMoney(calculo?.rentabilidade_porc).split(/[R$\\/]/)
                .join('')}%
              </li>
              <li>
                <p>Km carregado:</p>
                {calculo && formatMoney(calculo.km_carregado).split(/[R$\\/]/)
                .join('')}%
              </li>
              <li>
                <p>Km vazio:</p>
                {calculo&& formatMoney(calculo.km_vazio).split(/[R$\\/]/)
                .join('')}%
              </li>
            </ul>
          </div>
          <Map
            center={[-23.550651, -46.633382]}
            bounds={[
              [-32.89754919745616, -52.521500781101906],
              [-1.06121867815292, -56.349007286718205],
            ]}
            zoom={4}
            minZoom={3}
            scrollWheelZoom={false}
          >
            {trechosLatLog.map((trecho) => {
              return (
                <>
                  <Marker
                    position={[trecho.trecho_ini_lat, trecho.trecho_ini_long]}
                    icon={markerIcon}
                  />
                  <Marker
                    position={[trecho.trecho_fim_lat, trecho.trecho_fim_long]}
                    icon={markerIcon}
                  />
                </>
              );
            })}
            {/* <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
            />
          </Map>
        </section>
      </Container>
    </>
  );
};

export default Rentabilidade;
