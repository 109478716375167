import styled, { css } from 'styled-components';
import bg from '../../assets/signin.jpg';

interface IContainer {
  bg: boolean;
}

export const Container = styled.div<IContainer>`
  padding: 20px;
  height: 100%;
  background: url(${bg});
  ${(props) =>
    props.bg &&
    css`
      background: #fff;
    `}
  color: var(--text);

  h6 {
    font-size: 17px !important;
  }
  font-size: 14px;
`;
export const ButtonEdit = styled.button`
  border: 0;
  background: transparent;
  color: var(--blue);
  text-align: center;
`;

export const BgNone = styled.section`
  background-color: #fff;
`;

export const Button = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #28a745;
`;

export const Header = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  h1 {
    font-size: 29px;
    text-align: center;
    color: var(--blue-b);
    flex: 1;
  }

  button {
    border: 0;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #28a745;
    position: absolute;
    right: 0;
  }
`;

export const ButtonIcon = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c53030;

  svg {
    color: #fff;
  }
`;
