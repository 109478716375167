import React from 'react';

import { Container } from './styles';

import { ReactComponent as Fail } from '../../assets/fail.svg';

interface InvalidProps {
  message: string;
}

const Invalid: React.FC<InvalidProps> = ({ message }) => {
  return (
    <Container>
      <Fail />
      <h1>{message}</h1>
    </Container>
  );
};

export default Invalid;
