import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface IBoxProp {
  none: boolean;
}

export const Container = styled.div`
  .type {
    margin-top: 20px;
    width: 39%;
    margin-left: 10px;
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }

  form {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 30px;

    div[type='text'] {
      padding: 6px;
      border-radius: 5px;
      width: 100%;
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }

    button[type='submit'] {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 0;
      width: 200px;
      padding: 10px 5px;
      border: 0;
      background-color: var(--text);
      border-radius: 5px;
      color: #fff;
    }
  }
`;

export const Table = styled(animated.div)``;

export const Box = styled.div<IBoxProp>`
  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--blue-b);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--yellow);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue-b);
  }

  padding: 20px;
  display: block;
  margin: 30px 10px;
  max-height: 400px;
  width: calc(100% - 53%);
  overflow: auto;
  border: 1px solid var(--blue-b);

  ${(props) =>
    props.none &&
    css`
      display: none;
    `}

  @media screen and (max-width: 959px) {
    width: 100%;
    max-height: max-content;
    overflow: hidden;
  }

  section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }

  input {
    flex: 1;
  }

  h2 {
    color: var(--blue-b);
    margin-bottom: 20px;
    text-align: center;
  }

  h5 {
    font-size: 18px;
    margin: 4px;
    flex: 1;
  }

  .react-select__control,
  .css-2b097c-container {
    flex: 1.04;
    margin: 10px 0;
    border-radius: 5px;
  }
  .react-select__control {
    border: 1px solid #232129;
    height: 0;
  }
`;

export const Header = styled.h2`
  text-align: center;
  color: var(--blue-b);
  font-size: 23px;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
