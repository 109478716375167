import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler, Scope } from '@unform/core';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Header } from './styles';
import { Box } from '../components';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import { useTimeline } from '../../../../hooks/timeline';
import Loading from '../../../../components/Loading';
import formatMoney from '../../../../utils/formatMoney';

interface IArrayZero {
  subtipo: string;
  itens: {
    agrupamento: number;
    subtipo: string;
    cod: number;
    campo: string;
    valor: number;
    info: string;
    read_only: number;
    elemento: string | number;
  }[];
}

interface IModalidade {
  agrupamento: number;
  subtipo: string;
  cod: number;
  campo: string;
  valor: number;
  info: string;
  read_only: number;
  elemento: string | number;
}

const Maintenance: React.FC = () => {
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);

  const [arrayPositionZeroPattern, setArrayPositionZeroPattern] = useState<
    IArrayZero[]
  >([]);

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      setLoading(true);
      const response = await api.get(
        `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Manutencao&user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      setArrayPositionZeroPattern(response.data.data);
      console.log('Data', response.data.data);
      setLoading(false);
    };

    loadTabs();
  }, [conta, id, token, temp]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        const array = data.modules.filter(
          (e: any) => e !== '' && e.valor !== '0',
        );

        await api.put(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Manutencao&user_request=${id}&conta=${conta}`,
          {
            user_request: id,
            conta,
            calculo: temp,
            itens: array.map((e: any) => ({
              valor: e.valor
                .replace('R$', '')
                .trim()
                .replace('.', '')
                .replace(',', '.'),
              cod: e.cod,
            })),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        next();
      } catch (err) {
        console.error(err);
      }
    },
    [next, conta, id, token, temp],
  );

  const handlePrice = useCallback(async () => {
    const response = await api.get(
      `/api/v1/tabelas/customercado?user_request=${id}&conta=${conta}`,
      {
        headers: {
          'x-access-token': token,
        },
      },
    );
    const newTelements = [...arrayPositionZeroPattern];
    newTelements.map((e) => {
      e.itens.map((i) => {
        if (i.elemento !== 148 && i.elemento !== 133 && i.elemento !== 145) {
          i.valor = 0;
        }
        return '';
      });
      return '';
    });
    newTelements[1].itens[1].valor = response.data.data.find(
      (item: any) => item.nome === 'manutencao_cavalo_caminhao_van',
    ).valor;
    newTelements[2].itens[2].valor = response.data.data.find(
      (item: any) => item.nome === 'manutencao_semireboque',
    ).valor;
    setArrayPositionZeroPattern(newTelements);
  }, [conta, id, token, arrayPositionZeroPattern]);

  const valueInput = useCallback((value: number, element: string | number):
    | string
    | number => {
    if (element === 130) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumSignificantDigits: 2,
      })
        .format(Number(value))
        .split('-')
        .join('');
    }

    if (element === 144 || element === 147) {
      return new Intl.NumberFormat('pt-BR')
        .format(Number(value))
        .split('-')
        .join('');
    }

    if (element === 145 || element === 148) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      })
        .format(Number(value))
        .split('-')
        .join('');
    }

    return formatMoney(value);
  }, []);

  return (
    <Container>
      {loading ? (
        <Loading isLoading={loading} />
      ) : (
        <>
          <Header>Manutenção</Header>
          <Form ref={formRef} onSubmit={handleAddSubmit}>
            {arrayPositionZeroPattern.map((tab, idxtab) => {
              return (
                <Box key={tab.subtipo}>
                  <h2>{tab.subtipo}</h2>
                  {tab.itens
                    ? tab.itens.map((item, index) => {
                        return (
                          <Scope key={item.campo} path={`modules[${item.cod}]`}>
                            <section>
                              <h5>{item.campo}</h5>
                              <Input
                                name="valor"
                                type="text"
                                value={valueInput(item.valor, item.elemento)}
                                readOnly={item.read_only !== 0}
                                disabled={item.read_only !== 0}
                                info={item.info && item.info}
                                onChange={(e): void => {
                                  const newTelements = [
                                    ...arrayPositionZeroPattern,
                                  ];

                                  if (
                                    item.elemento === 147 ||
                                    item.elemento === 144
                                  ) {
                                    newTelements[idxtab].itens[
                                      index
                                    ].valor = Number(
                                      e.target.value
                                        .split(/[^\d]+/g)
                                        .join('')
                                        .trim(),
                                    );
                                  } else {
                                    newTelements[idxtab].itens[
                                      index
                                    ].valor = Number(
                                      Number(
                                        e.target.value
                                          .split(/[R$\\/,/./]/)
                                          .join('')
                                          .split(/[^\d]+/g)
                                          .join('')
                                          .trim(),
                                      ) / 100,
                                    );
                                  }

                                  const idxTotal = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex(
                                    (x) => x.campo.toUpperCase() === 'TOTAL',
                                  );
                                  // Adicional de Periculosidade (30%) = Salário fixo * 30 / 100

                                  // Encargos
                                  const idxTotalSal = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 132);
                                  const idxEncargosPorc = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 133);
                                  const idxEncargos = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 134);

                                  if (
                                    idxTotalSal !== -1 &&
                                    idxEncargosPorc !== -1
                                  ) {
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxEncargos
                                    ].valor =
                                      (arrayPositionZeroPattern[idxtab].itens[
                                        idxEncargosPorc
                                      ].valor /
                                        100) *
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxTotalSal
                                      ].valor;
                                  }

                                  if (idxTotal !== -1) {
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotal
                                    ].valor = 0;
                                    const total = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.reduce((acc, value) => {
                                      let a = 0;
                                      a = acc + value.valor;
                                      return a;
                                    }, 0);
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotal
                                    ].valor =
                                      total -
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxEncargosPorc
                                      ].valor;
                                  }

                                  const custoMedioCav = arrayPositionZeroPattern[1].itens.find(
                                    (x) => x.elemento === 145,
                                  );
                                  const kmMes = arrayPositionZeroPattern[1].itens.find(
                                    (x) => x.elemento === 144,
                                  );
                                  const t = arrayPositionZeroPattern[0].itens.findIndex(
                                    (x) => x.elemento === 143,
                                  );

                                  if (custoMedioCav && kmMes) {
                                    if (kmMes.valor) {
                                      custoMedioCav.valor =
                                        arrayPositionZeroPattern[0].itens[t]
                                          .valor / kmMes.valor;
                                    }
                                  }

                                  const custoM = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.find((x) => x.elemento === 146);
                                  const kmMedio = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.find((x) => x.elemento === 147);
                                  const custoKm = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.find((x) => x.elemento === 148);

                                  if (custoM && kmMedio && custoKm) {
                                    if (kmMedio.valor) {
                                      custoKm.valor =
                                        custoM.valor / kmMedio.valor;
                                    }
                                  }

                                  setArrayPositionZeroPattern(newTelements);
                                }}
                              />
                              <Input
                                name="cod"
                                type="hidden"
                                value={item.cod}
                              />
                            </section>
                          </Scope>
                        );
                      })
                    : []}
                </Box>
              );
            })}
            <button
              type="button"
              onClick={() => {
                if (
                  window.confirm('Deseja utilizar o preço médio do sistema?')
                ) {
                  handlePrice();
                }
              }}
              style={{ display: 'none' }}
            >
              Consultar Preço Médio
            </button>
            <button type="submit">Salvar</button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default Maintenance;
