import styled, { css } from 'styled-components';

interface Notification {
  typeNot: 'Informativo' | 'Urgente';
}

interface Body {
  isVisible: boolean;
}

export const Container = styled.div<Notification>`
  border-radius: 20px;
  ${(props) =>
    props.typeNot === 'Informativo' &&
    css`
      background: var(--blue);
    `}

  ${(props) =>
    props.typeNot === 'Urgente' &&
    css`
      background: var(--red);
    `}
`;

export const Header = styled.div<Body>`
  display: flex;
  align-items: center;
  line-height: 20px;
  width: 100%;
  padding: 15px;

  div {
    flex: 1;
  }

  svg {
    transition: all 0.2s;
  }

  h2 {
    font-size: 20px;
    margin-left: 15px;
  }

  p {
    font-size: 16px;
  }
  ${(props) =>
    props.isVisible &&
    css`
      svg:last-child {
        transform: rotate(-90deg);
      }
    `}
`;
export const Body = styled.div<Body>`
  padding: 0;
  overflow: 0;
  visibility: hidden;
  max-height: 0;
  transition: 0.1s;
  p {
    text-align: justify;
  }

  ${(props) =>
    props.isVisible &&
    css`
      overflow: auto;
      visibility: visible;
      max-height: 100%;
      padding: 0 15px 15px 15px;
    `}
`;
