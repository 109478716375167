import styled, { css } from 'styled-components';

interface iPropsLI {
  spotlight: boolean;
}

export const Container = styled.div`
  form {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 30px;

    div[type='text'] {
      padding: 6px;
      border-radius: 5px;
      width: 100%;
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }

    input {
      width: 190%;
    }
    button[type='submit'] {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 0;
      width: 200px;
      padding: 10px 5px;
      border: 0;
      background-color: var(--text);
      border-radius: 5px;
      color: #fff;
    }
  }
`;
export const List = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 16px;

  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  li {
    display: flex;
    align-items: center;

    @media screen and (max-width: 425px) {
      flex-direction: column;
    }

    h5 {
      font-weight: bold;
    }

    span {
      font-size: 18px;
      font-weight: 100;
      margin-left: 9px;
    }
  }
`;

export const Box = styled.div`
  &::-webkit-scrollbar {
    width: 1000px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--text);
  }

  padding: 20px;
  display: block;
  margin: 30px 10px;
  width: 100%;
  overflow: auto;
  border: 2px solid var(--text);
  border-radius: 5px;

  @media screen and (max-width: 959px) {
    width: 100%;
    max-height: max-content;
    overflow: hidden;
  }

  h2 {
    margin-bottom: 20px;
  }

  h5 {
    margin: 4px;
  }
`;

export const Loading = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export const Header = styled.h2`
  text-align: center;
  color: var(--blue-b);
  font-size: 23px;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;

export const Li = styled.li<iPropsLI>`
  h5 {
    font-size: 18px;
    ${(props) => props.spotlight && css``}
  }
`;
