import { InputHTMLAttributes } from 'react';
import styled, { css, keyframes } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps extends InputHTMLAttributes<HTMLInputElement> {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  border?: boolean;
  margin?: boolean;
  readOnly?: boolean;
}

const pulse = keyframes`
  0% {
		transform: translateY(0px);
	}
	70% {
    transform: translateY(-4px);
	}
	100% {
    transform: translateY(0px);
	}
`;

export const Label = styled.p`
  margin-left: 3px;
  color: var(--blue-b);
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 0px;
`;

export const Container = styled.div<ContainerProps>`
  ${(props) =>
    props.readOnly
      ? css`
          background: var(--grey);
        `
      : css`
          background: var(--white);
        `}
  margin: 0px 0;
  ${(props) =>
    props.margin === false &&
    css`
      margin: 0;
    `}
  color: #232129;
  border-radius: 10px;
  ${(props) =>
    !props.border &&
    css`
      border: 1px solid #232129;
    `}
  padding: 5px 8px;
  width: 100%;
  display: flex;

  align-items: center;
  ${(props) =>
    props.isErrored &&
    css`
      border-color: var(--red);
    `}
  ${(props) =>
    props.isFocused &&
    css`
      color: var(--yellow);
      border-color: var(--yellow);
    `}
  ${(props) =>
    props.isFilled &&
    css`
      color: var(--yellow);
    `}
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
    -webkit-text-fill-color: #f4ede8;
    -webkit-box-shadow: 0 0 0px 1000px #232129 inset;
    background: transparent;
  }
  input {
    flex: 1;
    width: 100%;
    border: 0;
    color: #232129;
    &::placeholder {
      color: #232129;
    }
  }
  svg {
    margin-right: 16px;
  }
  ${(props) =>
    props.type === 'hidden' &&
    css`
      opacity: 0;
      visibility: hidden;
      max-height: 0;
      padding: 0;
      width: 0;
    `}
`;

export const Prefix = styled.p`
  padding-right: 5px;
  color: #000;
`;

export const Info = styled(Tooltip)`
  animation: ${pulse} 0.3s linear;
  svg {
    margin: 0;
  }
  span {
    background: var(--blue);
    color: #fff;
    &::before {
      border-color: var(--blue) transparent;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  animation: ${pulse} 0.3s linear;
  svg {
    margin: 0;
  }
  span {
    background: var(--red);
    color: #fff;
    &::before {
      border-color: var(--red) transparent;
    }
  }
`;

export const Main = styled.article<{ hidden: boolean }>`
  width: 160px;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`;
