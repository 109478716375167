import React, { createContext, useContext, useCallback, useState } from 'react';

interface ITimeline {
  next(): void;
  reset(): void;
  transition: number;
  back(): void;
  goTransition(number: number): void;
}

const TimelineContext = createContext({} as ITimeline);

export const Timeline: React.FC = ({ children }) => {
  const [transition, setTransition] = useState(0);

  const next = useCallback(() => setTransition((state) => state + 1), []);
  const back = useCallback(() => setTransition((state) => state - 1), []);
  const reset = useCallback(() => setTransition(0), []);

  const goTransition = (number: number): void => {
    setTransition(number);
  };

  return (
    <TimelineContext.Provider
      value={{ next, reset, transition, back, goTransition }}
    >
      {children}
    </TimelineContext.Provider>
  );
};

export const useTimeline = (): ITimeline => {
  const context = useContext(TimelineContext);

  if (!context) {
    throw new Error('useMenu must be user within an AuthProvider');
  }

  return context;
};
