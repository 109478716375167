import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyles from './styles/global';

import Hooks from './hooks';
import Routes from './routes';
import Loading from './components/Loading';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading isLoading />}>
        <Hooks>
          <Routes />
        </Hooks>
        <GlobalStyles />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
