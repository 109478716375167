import React from 'react';

import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <p>Copyright © 2021 Maxifrete. Todos os direitos reservados.</p>
    </Container>
  );
};

export default Footer;
