import React from 'react';
import formatMoney from '../../../../../utils/formatMoney';

interface IParamDespesasVariaveisProps {
  parametros_despesas_variaveis: {
    arla_litro: number;
    combustivel_litro: number;
    custo_km_cavalo_caminhao: number;
    custo_km_cavalo_caminhao_semi: number;
    km_pneu: number;
    lavagem: number;
    media_km_litro: number;
    perc_taxa_lucro_variaveis: number;
  };
}
const format4casas = (value: any): any => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    
    minimumFractionDigits:3,
    maximumFractionDigits:3
    
  })
    .format(Number(value))
    .split('-')
    .join('');
};

const ParamDespesasVariaveis: React.FC<IParamDespesasVariaveisProps> = ({
  parametros_despesas_variaveis,
}) => {
  return (
    <>
      <h2>Parâmetros Despesas Variáveis</h2>
      <section>
        <h5>Arla por litro</h5>
        <p>{formatMoney(parametros_despesas_variaveis.arla_litro)}</p>
      </section>
      <section>
        <h5>Combustível por litro</h5>
        <p>{format4casas(parametros_despesas_variaveis.combustivel_litro)}</p>
      </section>
      <section>
        <h5>Média km/litro</h5>
        <p>{formatMoney(parametros_despesas_variaveis.media_km_litro)}</p>
      </section>
      <section>
        <h5>Custo/km 1 pneu</h5>
        <p>{format4casas(parametros_despesas_variaveis.km_pneu)}</p>
      </section>
    
      <section>
        <h5>Manutenção do veículo por km</h5>
        <p>{format4casas(parametros_despesas_variaveis.custo_km_cavalo_caminhao)}</p>
      </section>
      <section>
        <h5>Manutenção do semi-reboque ou complemento por Km</h5>
        <p>{format4casas(parametros_despesas_variaveis.custo_km_cavalo_caminhao_semi)}</p>
      </section>
      <section>
        <h5>Lavagem</h5>
        <p>{format4casas(parametros_despesas_variaveis.lavagem)}</p>
      </section>
      <section>
        <h5>Lucro sobre despesas variáveis(%)</h5>
        <p>{parametros_despesas_variaveis.perc_taxa_lucro_variaveis} %</p>
      </section>
    </>
  );
};

export default ParamDespesasVariaveis;
