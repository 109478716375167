import React, { ButtonHTMLAttributes } from 'react';

import { Container, LoadIcon } from './styles';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const Button: React.FC<IButtonProps> = ({ children, loading, ...rest }) => {
  return (
    <Container type="button" disabled={loading} {...rest}>
      {loading ? <LoadIcon /> : children}
    </Container>
  );
};

export default Button;
