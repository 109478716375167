import styled from 'styled-components';

export const Container = styled.div`
  form {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 30px;
    div[type='number'] {
      padding: 6px;
      border-radius: 5px;
      max-width: 300px;
    }
    button[type='submit'] {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 0;
      width: 200px;
      padding: 10px 5px;
      border: 0;
      background-color: var(--text);
      border-radius: 5px;
      color: #fff;
    }
    .react-select__control,
    .css-2b097c-container {
      width: 500px;
      height: 55px;
      margin-top: 10px;
      border-radius: 10px;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }
`;

export const GroudInput = styled.div`
  padding: 10px 0;
  margin-right: 10px;
  p {
    font-size: 18px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
`;

export const Inputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Header = styled.h2`
  text-align: center;
  color: var(--blue-b);
  font-size: 23px;
  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
export const Box = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--blue-b);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--yellow);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--blue-b);
  }
  padding: 20px;
  display: block;
  margin: 30px 0;
  max-height: 400px;
  width: 100%;
  overflow: auto;
  border-radius: 5px;
  border: 1px solid var(--blue-b);
  @media screen and (max-width: 959px) {
    width: 100%;
    max-height: max-content;
    overflow: hidden;
  }
  section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }
  input {
    flex: 1;
  }
  h2 {
    color: var(--blue-b);
    margin-bottom: 20px;
    text-align: center;
  }
  h5 {
    font-size: 18px;
    margin: 4px;
    flex: 1;
  }
`;
