import React, { useEffect, useState, useCallback } from 'react';
import { FiBell } from 'react-icons/fi';
import { Container } from './styles';
import Dropdown from '../Dropdown';
import DropDownBox from './DropDownBox';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

interface NotificationImp {
  cod_notificacao: number;
  titulo: string;
  msg: string;
  tipo: 'Informativo' | 'Urgente';
}

const Notifications: React.FC = () => {
  const [notification, setNotification] = useState([]);
  const { id } = useAuth().user;
  const token = sessionStorage.getItem('@MaxiFrete:token');

  const list = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get(`/api/v1/notificacoes/${id}`, {
        headers: {
          'x-access-token': token,
        },
      });
      setNotification(
        response.data.data.filter((item: any) => item.data_leitura === null),
      );
    } catch (err) {
      console.log(err);
    }
  }, [id, token]);

  useEffect(() => {
    list();
  }, [list]);

  return (
    <Dropdown icon={FiBell} notification={notification.length}>
      {notification.map((item: NotificationImp) => {
        return (
          <Container key={item.titulo}>
            <DropDownBox
              typeNot={item.tipo}
              title={item.titulo}
              msg={item.msg}
              cod={item.cod_notificacao}
            />
          </Container>
        );
      })}
    </Dropdown>
  );
};

export default Notifications;
