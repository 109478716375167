import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';

interface User {
  id: number;
  perfil: string;
  conta: number;
  nome: string;
  email: string;
  enquadramento_fiscal: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  emailUser: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = sessionStorage.getItem('@MaxiFrete:token');
    const user = sessionStorage.getItem('@MaxiFrete:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const history = useHistory();

  const signIn = useCallback(async ({ emailUser, password }) => {
    const response = await api.post('/api/v1/usuarios/login', {
      email: emailUser,
      password,
    });

    const {
      token,
      email,
      nome,
      perfil,
      id,
      conta,
      enquadramento_fiscal,
    } = response.data.data;

    const user = {
      email,
      nome,
      perfil,
      id,
      conta,
      enquadramento_fiscal,
    };

    sessionStorage.setItem('@MaxiFrete:token', token);
    sessionStorage.setItem('@MaxiFrete:user', JSON.stringify(user));

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    sessionStorage.clear();
    setData({} as AuthState);
    history.push('/');
  }, [history]);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be user within an AuthProvider');
  }

  return context;
}
