import React from 'react';

import { FiMenu, FiLogOut } from 'react-icons/fi';

import { useMenu } from '../../hooks/menu';
import { useAuth } from '../../hooks/auth';

import { Container, ButtonContainer } from './styles';
import Notifications from '../Notifications';

const NavBar: React.FC = () => {
  const { handleExpansed } = useMenu();
  const { signOut } = useAuth();

  return (
    <Container>
      <button type="button" onClick={() => handleExpansed()}>
        <FiMenu size={20} />
      </button>

      <ButtonContainer>
        <Notifications />

        <button
          type="button"
          onClick={() => {
            const condition = window.confirm(
              'Deseja realmente sair do sistema?',
            );
            if (condition) {
              signOut();
            }
          }}
        >
          <FiLogOut size={18} />
        </button>
      </ButtonContainer>
    </Container>
  );
};

export default NavBar;
