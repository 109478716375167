import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core';
import Slider from '@material-ui/core/Slider';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { useTimeline } from '../../../../hooks/timeline';

import { Container, Header, Box, InputsContainer } from './styles';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import formatMoney from '../../../../utils/formatMoney';
import Loading from '../../../../components/Loading';

interface IDiesel {
  calculo: number;
  cod: number;
  conta: number;
  fator_majoracao: any;
  preco_externo: number;
  preco_final: number;
  precoFinal: number;
  preco_interno: number;
  preco_medio: number;
  proporcao_consumo_externo: number;
  proporcao_consumo_interno: number;
  tipo: string;
  cod_arla: number;
  fator_majoracao_arla: number;
  precoFinal_arla: number;
  preco_externo_arla: number;
  preco_interno_arla: number;
  preco_medio_arla: number;
}

const Diesel: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [diesel, setDiesel] = useState<IDiesel>({} as IDiesel);
  const [arla, setArla] = useState<IDiesel>({} as IDiesel);
  const [propInterno, setPropInterno] = useState(0);
  const [propExterno, setPropExterno] = useState(100 - propInterno);
  const [propInternoArla, setPropInternoArla] = useState(0);
  const [propExternoArla, setPropExternoArla] = useState(100 - propInternoArla);
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const [inputsDiesel, setInputs] = useState([
    { input: 'preco_interno', value: diesel.preco_interno || 0 },
    { input: 'preco_medio', value: diesel.preco_medio || 0 },
    { input: 'fator_majoracao', value: diesel.fator_majoracao || 0 },
    { input: 'cod', value: diesel.cod || 0 },
    { input: 'preco_externo', value: diesel.preco_externo || 0 },
    { input: 'precoFinal', value: diesel.preco_final || 0 },
  ]);
  const [inputsArla, setInputsArla] = useState([
    { input: 'preco_interno_arla', value: arla.preco_interno || 0 },
    { input: 'preco_medio_arla', value: arla.preco_medio || 0 },
    { input: 'fator_majoracao_arla', value: arla.fator_majoracao || 0 },
    { input: 'cod_arla', value: arla.cod || 0 },
    { input: 'preco_externo_arla', value: arla.preco_externo || 0 },
    { input: 'precoFinal_arla', value: arla.preco_final || 0 },
  ]);
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const response = await api.get(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Dieselarla&user_request=${id}&conta=${conta}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setDiesel(response.data.data[0].itens[0]);
        const inputD = [...inputsDiesel];
        inputD[0].value = response.data.data[0].itens[0].preco_interno || 0;
        inputD[1].value = response.data.data[0].itens[0].preco_medio || 0;
        inputD[2].value = response.data.data[0].itens[0].fator_majoracao || 0;
        inputD[3].value = response.data.data[0].itens[0].cod || 0;
        inputD[4].value = response.data.data[0].itens[0].preco_externo || 0;
        inputD[5].value = response.data.data[0].itens[0].preco_final || 0;
        setInputs(inputD);
        setPropInterno(
          response.data.data[0].itens[0].proporcao_consumo_interno,
        );
        setPropExterno(
          100 - response.data.data[0].itens[0].proporcao_consumo_interno,
        );

        setPropInternoArla(
          response.data.data[1].itens[0].proporcao_consumo_interno,
        );

        setPropExternoArla(
          100 - response.data.data[1].itens[0].proporcao_consumo_interno,
        );

        setArla(response.data.data[1].itens[0]);
        const i = [...inputsArla];
        i[0].value = response.data.data[1].itens[0].preco_interno || 0;
        i[1].value = response.data.data[1].itens[0].preco_medio || 0;
        i[2].value = response.data.data[1].itens[0].fator_majoracao || 0;
        i[3].value = response.data.data[1].itens[0].cod || 0;
        i[4].value = response.data.data[1].itens[0].preco_externo || 0;
        i[5].value = response.data.data[1].itens[0].preco_final || 0;
        setInputsArla(i);
        setPropInternoArla(
          response.data.data[1].itens[0].proporcao_consumo_interno,
        );
        setPropExternoArla(
          100 - response.data.data[1].itens[0].proporcao_consumo_interno,
        );
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    load();
  }, [conta, id, temp, token]);

  const removeRS = (value: string | number): string => {
    return value.toString().replace('R$', '').trim().replace(',', '.');
  };

  const handleAddSubmit: SubmitHandler<IDiesel> = useCallback(
    async (data) => {
      console.log(data);
      try {
        await api.put(
          `/api/v1/tabelas/dieselarla?calculo=${temp}&user_request=${id}&conta=${conta}`,
          [
            {
              cod: data.cod,
              tipo: 'Diesel',
              preco_interno: removeRS(data.preco_interno),
              preco_externo: removeRS(data.preco_externo),
              proporcao_consumo_interno: propInterno,
              proporcao_consumo_externo: propExterno,
              fator_majoracao: data.fator_majoracao,
              preco_medio: removeRS(data.preco_medio),
              preco_final: removeRS(data.precoFinal),
            },
            {
              cod: data.cod_arla,
              tipo: 'Arla',
              preco_interno: removeRS(data.preco_interno_arla),
              preco_externo: removeRS(data.preco_externo_arla),
              proporcao_consumo_interno: propInternoArla,
              proporcao_consumo_externo: propExternoArla,
              fator_majoracao: data.fator_majoracao_arla,
              preco_medio: removeRS(data.preco_medio_arla),
              preco_final: removeRS(data.precoFinal_arla),
            },
          ],
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        next();
      } catch (err) {
        console.log(err);
        toast.error('Erro ao salvar.');
      }
    },
    [
      next,
      conta,
      id,
      token,
      temp,
      propExterno,
      propInterno,
      propExternoArla,
      propInternoArla,
    ],
  );

  useEffect(() => {
    console.log(inputsArla);
  }, [inputsArla]);

  return (
    <Container>
      {loading && <Loading isLoading={loading} />}
      <Header>Óleo Diesel e Arla</Header>
      <Form ref={formRef} onSubmit={handleAddSubmit}>
        <Box>
          <h2>Diesel</h2>
          <InputsContainer>
            <section>
              <h5>Preço interno</h5>
              <Input
                name="preco_interno"
                type="text"
                value={formatMoney(
                  inputsDiesel.find((i) => i.input === 'preco_interno')?.value,
                )}
                onChange={(e) => {
                  const inputs = [...inputsDiesel];
                  const current = inputs.findIndex(
                    (i) => i.input === 'preco_interno',
                  );
                  inputs[current].value =
                    Number(
                      e.target.value
                        .split(/[R$\\/,/./]/)
                        .join('')
                        .split(/[^\d]+/g)
                        .join('')
                        .trim(),
                    ) / 100;

                  const pMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio',
                  );
                  const pInterno = inputs.find(
                    (i) => i.input === 'preco_interno',
                  )?.value;

                  const pExterno = inputs.find(
                    (i) => i.input === 'preco_externo',
                  )?.value;

                  const t =
                    Number(pInterno) * Number(propInterno / 100) +
                    Number(pExterno) * Number(propExterno / 100);

                  inputs[pMedio].value = t;

                  const precoMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio',
                  );
                  const preco = inputs.findIndex(
                    (i) => i.input === 'precoFinal',
                  );

                  const fator_majoracao = inputs.findIndex(
                    (i) => i.input === 'fator_majoracao',
                  );

                  inputs[preco].value =
                    inputs[precoMedio].value +
                    inputs[precoMedio].value *
                      (inputs[fator_majoracao].value / 100);
                  setInputs(inputs);
                }}
              />
            </section>
            <article>
              <Typography id="discrete-slider-always" gutterBottom>
                Proporção de Consumo Interno
              </Typography>
              <Slider
                aria-labelledby="discrete-slider-always"
                value={propInterno}
                valueLabelFormat={(x) => `${x}%`}
                onChange={(_, value: any) => {
                  setPropInterno(value);
                  setPropExterno(100 - value);
                  console.log(value);
                  const inputs = [...inputsDiesel];
                  const pMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio',
                  );
                  const pInterno = inputs.find(
                    (i) => i.input === 'preco_interno',
                  )?.value;

                  const pExterno = inputs.find(
                    (i) => i.input === 'preco_externo',
                  )?.value;

                  const t =
                    Number(pInterno) * Number(value / 100) +
                    Number(pExterno) * Number((100 - value) / 100);

                  inputs[pMedio].value = t;
                  console.log('preçoMedio', t);
                  console.log('inputs[pMedio].value', inputs[pMedio].value);
                  const precoMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio',
                  );
                  const preco = inputs.findIndex(
                    (i) => i.input === 'precoFinal',
                  );

                  const fator_majoracao = inputs.findIndex(
                    (i) => i.input === 'fator_majoracao',
                  );
                  inputs[preco].value =
                    inputs[precoMedio].value +
                    inputs[precoMedio].value *
                      (inputs[fator_majoracao].value / 100);

                  setInputs(inputs);
                }}
                valueLabelDisplay="auto"
              />
            </article>
            <section>
              <h5>Preço externo</h5>
              <Input
                name="preco_externo"
                type="text"
                value={formatMoney(
                  inputsDiesel.find((i) => i.input === 'preco_externo')?.value,
                )}
                onChange={(e) => {
                  const inputs = [...inputsDiesel];
                  const current = inputs.findIndex(
                    (i) => i.input === 'preco_externo',
                  );
                  inputs[current].value =
                    Number(
                      e.target.value
                        .split(/[R$\\/,/./]/)
                        .join('')
                        .split(/[^\d]+/g)
                        .join('')
                        .trim(),
                    ) / 100;

                  const pMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio',
                  );
                  const pInterno = inputs.find(
                    (i) => i.input === 'preco_interno',
                  )?.value;

                  const pExterno = inputs.find(
                    (i) => i.input === 'preco_externo',
                  )?.value;

                  const t =
                    Number(pInterno) * Number(propInterno / 100) +
                    Number(pExterno) * Number(propExterno / 100);

                  inputs[pMedio].value = t;

                  const precoMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio',
                  );
                  const preco = inputs.findIndex(
                    (i) => i.input === 'precoFinal',
                  );

                  const fator_majoracao = inputs.findIndex(
                    (i) => i.input === 'fator_majoracao',
                  );
                  inputs[preco].value =
                    inputs[precoMedio].value +
                    inputs[precoMedio].value *
                      (inputs[fator_majoracao].value / 100);

                  setInputs(inputs);
                }}
              />
            </section>
            <article>
              <Typography id="discrete-slider-always" gutterBottom>
                Proporção de Consumo Externo
              </Typography>
              <Slider
                aria-labelledby="discrete-slider-always"
                value={propExterno}
                valueLabelFormat={(x) => `${x}%`}
                valueLabelDisplay="auto"
              />
            </article>
            <section>
              <h5>Preço médio</h5>
              <Input
                name="preco_medio"
                type="text"
                value={formatMoney(
                  inputsDiesel.find((i) => i.input === 'preco_medio')?.value,
                )}
                readOnly
              />
            </section>
            <section>
              <h5>Fator de majoração %</h5>
              <Input
                name="fator_majoracao"
                type="text"
                value={
                  inputsDiesel.find((i) => i.input === 'fator_majoracao')?.value
                }
                onChange={(e) => {
                  const inputs = [...inputsDiesel];
                  const current = inputs.findIndex(
                    (i) => i.input === 'fator_majoracao',
                  );

                  inputs[current].value = Number(
                    e.target.value
                      .split(/[^\d]+/g)
                      .join('')
                      .trim(),
                  );

                  const precoMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio',
                  );
                  const preco = inputs.findIndex(
                    (i) => i.input === 'precoFinal',
                  );

                  inputs[preco].value =
                    inputs[precoMedio].value +
                    inputs[precoMedio].value *
                      (Number(
                        e.target.value
                          .split(/[R$\\/,/./]/)
                          .join('')
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) /
                        100);
                  setInputs(inputs);
                }}
              />
            </section>
            <section>
              <h5>Preço final</h5>
              <Input
                name="precoFinal"
                type="text"
                value={formatMoney(
                  inputsDiesel.find((i) => i.input === 'precoFinal')?.value,
                )}
                readOnly
              />
            </section>
          </InputsContainer>
          <Input
            name="cod"
            type="hidden"
            value={inputsDiesel.find((i) => i.input === 'cod')?.value}
          />
        </Box>
        <Box>
          <h2>Arla</h2>
          <InputsContainer>
            <section>
              <h5>Preço interno</h5>
              <Input
                name="preco_interno_arla"
                type="text"
                value={formatMoney(
                  inputsArla.find((i) => i.input === 'preco_interno_arla')
                    ?.value,
                )}
                onChange={(e) => {
                  const inputs = [...inputsArla];
                  const current = inputs.findIndex(
                    (i) => i.input === 'preco_interno_arla',
                  );
                  inputs[current].value =
                    Number(
                      e.target.value
                        .split(/[R$\\/,/./]/)
                        .join('')
                        .split(/[^\d]+/g)
                        .join('')
                        .trim(),
                    ) / 100;

                  const pMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio_arla',
                  );
                  const pInterno = inputs.find(
                    (i) => i.input === 'preco_interno_arla',
                  )?.value;

                  const pExterno = inputs.find(
                    (i) => i.input === 'preco_externo_arla',
                  )?.value;

                  const precoMedio =
                    Number(pInterno) * Number(propInternoArla / 100) +
                    Number(pExterno) * Number(propExternoArla / 100);

                  inputs[pMedio].value = precoMedio;

                  const preco = inputs.findIndex(
                    (i) => i.input === 'precoFinal_arla',
                  );

                  const fator_majoracao = inputs.findIndex(
                    (i) => i.input === 'fator_majoracao_arla',
                  );
                  inputs[preco].value =
                    precoMedio +
                    precoMedio * (inputs[fator_majoracao].value / 100);

                  setInputsArla(inputs);
                }}
              />
            </section>
            <article>
              <Typography id="discrete-slider-always" gutterBottom>
                Proporção de Consumo Interno
              </Typography>
              <Slider
                aria-labelledby="discrete-slider-always"
                value={propInternoArla}
                valueLabelFormat={(x) => `${x}%`}
                onChange={(_, value: any) => {
                  setPropInternoArla(value);
                  setPropExternoArla(100 - propInternoArla);
                  const inputs = [...inputsArla];
                  const pMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio_arla',
                  );
                  const pInterno = inputs.find(
                    (i) => i.input === 'preco_interno_arla',
                  )?.value;

                  const pExterno = inputs.find(
                    (i) => i.input === 'preco_externo_arla',
                  )?.value;

                  const precoMedio =
                    Number(pInterno) * Number(propInternoArla / 100) +
                    Number(pExterno) * Number(propExternoArla / 100);

                  inputs[pMedio].value = precoMedio;

                  const preco = inputs.findIndex(
                    (i) => i.input === 'precoFinal_arla',
                  );

                  const fator_majoracao = inputs.findIndex(
                    (i) => i.input === 'fator_majoracao_arla',
                  );
                  inputs[preco].value =
                    precoMedio +
                    precoMedio * (inputs[fator_majoracao].value / 100);
                  setInputsArla(inputs);
                }}
                valueLabelDisplay="auto"
              />
            </article>
            <section>
              <h5>Preço externo</h5>
              <Input
                name="preco_externo_arla"
                type="text"
                value={formatMoney(
                  inputsArla.find((i) => i.input === 'preco_externo_arla')
                    ?.value,
                )}
                onChange={(e) => {
                  const inputs = [...inputsArla];
                  const current = inputs.findIndex(
                    (i) => i.input === 'preco_externo_arla',
                  );
                  inputs[current].value =
                    Number(
                      e.target.value
                        .split(/[R$\\/,/./]/)
                        .join('')
                        .split(/[^\d]+/g)
                        .join('')
                        .trim(),
                    ) / 100;

                  const pMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio_arla',
                  );
                  const pInterno = inputs.find(
                    (i) => i.input === 'preco_interno_arla',
                  )?.value;

                  const pExterno = inputs.find(
                    (i) => i.input === 'preco_externo_arla',
                  )?.value;

                  const precoMedio =
                    Number(pInterno) * Number(propInternoArla / 100) +
                    Number(pExterno) * Number(propExternoArla / 100);

                  inputs[pMedio].value = precoMedio;

                  const preco = inputs.findIndex(
                    (i) => i.input === 'precoFinal_arla',
                  );

                  const fator_majoracao = inputs.findIndex(
                    (i) => i.input === 'fator_majoracao_arla',
                  );
                  inputs[preco].value =
                    precoMedio +
                    precoMedio * (inputs[fator_majoracao].value / 100);

                  setInputsArla(inputs);
                }}
              />
            </section>
            <article>
              <Typography id="discrete-slider-always" gutterBottom>
                Proporção de Consumo Externo
              </Typography>
              <Slider
                aria-labelledby="discrete-slider-always"
                value={propExternoArla}
                valueLabelFormat={(x) => `${x}%`}
                valueLabelDisplay="auto"
              />
            </article>
            <section>
              <h5>Preço médio</h5>
              <Input
                name="preco_medio_arla"
                type="text"
                value={formatMoney(
                  inputsArla.find((i) => i.input === 'preco_medio_arla')?.value,
                )}
                readOnly
              />
            </section>
            <section>
              <h5>Fator de majoração %</h5>
              <Input
                name="fator_majoracao_arla"
                type="text"
                value={
                  inputsArla.find((i) => i.input === 'fator_majoracao_arla')
                    ?.value
                }
                onChange={(e) => {
                  const inputs = [...inputsArla];
                  const current = inputs.findIndex(
                    (i) => i.input === 'fator_majoracao_arla',
                  );

                  inputs[current].value = Number(
                    e.target.value
                      .split(/[^\d]+/g)
                      .join('')
                      .trim(),
                  );

                  const precoMedio = inputs.findIndex(
                    (i) => i.input === 'preco_medio_arla',
                  );
                  const preco = inputs.findIndex(
                    (i) => i.input === 'precoFinal_arla',
                  );

                  inputs[preco].value =
                    inputs[precoMedio].value +
                    inputs[precoMedio].value *
                      (Number(
                        e.target.value
                          .split(/[R$\\/,/./]/)
                          .join('')
                          .split(/[^\d]+/g)
                          .join('')
                          .trim(),
                      ) /
                        100);
                  setInputsArla(inputs);
                }}
              />
            </section>
            <section>
              <h5>Preço final</h5>
              <Input
                name="precoFinal_arla"
                type="text"
                value={formatMoney(
                  inputsArla.find((i) => i.input === 'precoFinal_arla')?.value,
                )}
                readOnly
              />
            </section>
          </InputsContainer>
          <Input
            name="cod_arla"
            type="hidden"
            value={inputsArla.find((i) => i.input === 'cod_arla')?.value}
          />
        </Box>
        <Button type="submit">Salvar</Button>
      </Form>
    </Container>
  );
};

export default Diesel;
