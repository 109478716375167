import React from 'react';

interface IParamGeraisProps {
  parametros_gerais: {
    numero_viagens: number;
    qtd_pneu_ida: number;
    qtd_pneu_volta: number;
    total_km_rodado: number;
  };
}

const ParamGerais: React.FC<IParamGeraisProps> = ({ parametros_gerais }) => {
  return (
    <>
      <h2>Parâmetros Gerais</h2>
      <section>
        <h5>Número de viagens</h5>
        <p>{parametros_gerais.numero_viagens}</p>
      </section>
      <section>
        <h5>Quantidade de pneus na ida</h5>
        <p>{parametros_gerais.qtd_pneu_ida}</p>
      </section>
      <section>
        <h5>Quantidade de pneus na volta</h5>
        <p>{parametros_gerais.qtd_pneu_volta}</p>
      </section>
      <section>
        <h5>Total de km rodados</h5>
        <p>{Intl.NumberFormat('pt-BR')
        .format(Number(parametros_gerais.total_km_rodado))
        .split('-')
        .join('')}</p>
      </section>
    </>
  );
};

export default ParamGerais;
