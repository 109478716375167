import styled from 'styled-components';
import { Form } from '@unform/web';
import CSV from 'react-csv-downloader';

export const Container = styled.div`
  padding: 20px;
  height: 100%;
  background: #fff;
  color: var(--text);

  h6 {
    font-size: 17px !important;
  }
`;

export const Header = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 23px;
    text-align: center;
    flex: 1;
  }
`;

export const Box = styled.div``;

export const FormSty = styled(Form)`
  display: flex;
  width: 1000px;
  flex-direction: column;
  .input {
    width: 1000px;
  }
`;

export const ButtonIcon = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c53030;

  svg {
    color: #fff;
  }
`;

export const CsvDownloader = styled(CSV)`
  margin-bottom: 20px;
  background: var(--yellow);
  border-radius: 10px;
  color: #fff;
  height: 50px;
  max-width: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-size: 19px;
`;
