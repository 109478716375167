import React, { useState, ReactNode, useEffect } from 'react';
import { Aria } from 'react-modal';
import { FiEdit, FiTrash2, FiPlus, FiEye, FiBookOpen } from 'react-icons/fi';
import { Container, ModalSty } from './styles';

interface ModalProps extends Aria {
  modalContent: ReactNode | string;
  icon?: 'edit' | 'delete' | 'add' | 'view' | 'book';
  close?: boolean;
  openInLoadingPage?: boolean;
  hiddenButton?: boolean;
  background?: string;
  label?: string;
  width?: number;
}

const ModalTab: React.FC<ModalProps> = ({
  modalContent,
  icon,
  close,
  openInLoadingPage,
  hiddenButton,
  label,
  background = '#ffff',
  width = 80,
  ...props
}: ModalProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = (): void => {
    setIsOpen(true);
  };

  const closeModal = (): void => {
    setIsOpen(false);
  };

  useEffect(() => {
    openInLoadingPage && setIsOpen(true);
    close && setIsOpen(false);
    console.log('Close', close);
  }, [close, openInLoadingPage]);

  return (
    <Container icon={icon}>
      {!hiddenButton && (
        <button
          type="button"
          onClick={openModal}
          className="btn btn-sm btn-danger float-right buttonIcon"
        >
          {icon === 'edit' && <FiEdit size={17} />}
          {icon === 'delete' && <FiTrash2 size={17} />}
          {icon === 'add' && <FiPlus size={17} />}
          {icon === 'view' && <FiEye size={17} />}
          {icon === 'book' && <FiBookOpen size={17} />}
        </button>
      )}
      {label && (
        <button className="labelDescription" type="button" onClick={openModal}>
          {label}
        </button>
      )}
      <ModalSty
        background={background}
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        width={width}
        {...props}
      >
        <button type="button" onClick={closeModal} className="closeButton">
          X
        </button>
        <div style={{ height: '100%' }}>{modalContent}</div>
      </ModalSty>
    </Container>
  );
};

export default ModalTab;
