import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  height: 100%;
  background: #fff;
  color: var(--text);
  margin-bottom:10px;
  padding:10px;  
  .input{
      width:1000px;
      padding:10px;
  }
  .react-select__control,
  .css-2b097c-container {
    flex: 1.04;
    width:1000px;
    margin: 10px 0;
    border-radius: 5px;
  }
  .react-select__control {
    border: 1px solid #232129;
    height: 0;
  }

  button[type='submit'] {
   
    width: 1000px;
  
  }
`;

export const Header = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 23px;
    text-align: center;
    flex: 1;
  }

  button {
    border: 0;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #28a745;
  }


`;
