import React, { createContext, useContext, useCallback, useState } from 'react';

interface TabsContextData {
  handleTemp(number: number): void;
  temp: number;
}

const TabsContext = createContext({} as TabsContextData);

export const TabsProvider: React.FC = ({ children }) => {
  const [temp, setTemp] = useState(0);

  const handleTemp = useCallback((number: number): void => {
    setTemp(number);
  }, []);

  return (
    <TabsContext.Provider value={{ handleTemp, temp }}>
      {children}
    </TabsContext.Provider>
  );
};

export function useTabs(): TabsContextData {
  const context = useContext(TabsContext);

  if (!context) {
    throw new Error('useTabs must be user within an AuthProvider');
  }

  return context;
}
