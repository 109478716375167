import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  padding: 20px;
  height: 100%;
  background: #fff;
  color: var(--text);
  h6 {
    font-size: 17px !important;
  }
`;

export const Header = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 23px;
    text-align: center;
    flex: 1;
  }
`;

export const Box = styled.div``;

export const FormSty = styled(Form)`
  display: flex;
  flex-direction: column;

  .allCompanys {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    input {
      margin-left: 10px;
      min-width:500px;
    }
  }

  article {
    justify-content: space-between;
    
      min-width:500px;
        
    
    .react-autosuggest__container,
    section {
      width: 50%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

export const ButtonIcon = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c53030;

  svg {
    color: #fff;
  }
`;

export const BoxAdd = styled.div`
  div {
    background: #fff;
  }

  article {
    display: flex;
    margin-bottom: 15px;

    input[type='date']:before {
      content: attr(placeholder) !important;
      color: #aaa;
      margin-right: 0.5em;
    }
    input[type='date']:focus:before,
    input[type='date']:valid:before {
      content: '';
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    button {
      margin: 0;
      max-width: 500px;
      margin-right: 10px;

      @media screen and (max-width: 500px) {
        margin: 10px 0;
      }
    }

    .react-autosuggest__container {
      position: relative;
      margin-top: 0;
    }

    .react-autosuggest__input {
      height: 40px;
    }

    .react-autosuggest__input,
    textarea {
      width: 500px;
      padding: 10px 20px;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 16px;
      border: 1px solid #aaa;
      border-width: 1px;
      border-radius: 10px;
      color: #000;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }

    .react-autosuggest__input--focused {
      outline: none;
    }

    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
      display: none;
    }

    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      top: 51px;
      width: 500px;
      border: 1px solid #aaa;
      background-color: #fff;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;
    }

    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #ddd;
    }

    div {
      max-width: 500px;
      margin-right: 10px;
      @media screen and (max-width: 500px) {
        margin: 0;
      }
    }

    .input {
      border: 1px solid #aaa;
      height: 40px;
      margin-top: 0;
      margin-bottom: 0;
      @media screen and (max-width: 500px) {
        width: 100%;
        max-width: auto;
        margin-bottom: 15px;
      }
      input {
        color: #000;
      }
    }

    .react-select__control,
    .css-2b097c-container {
      width: 100%;
      height: 40px;
      margin-top: 0;
      border-radius: 10px;
    }
  }
`;
