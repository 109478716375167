import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled.div`
  *::-webkit-scrollbar {
    width: 10px;
    background-color: var(--blue-b);
  }

  *::-webkit-scrollbar-track {
    background-color: var(--yellow);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--blue-b);
  }

  header {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 10px;

    p {
      font-size: 15px;
    }
  }

  .mapContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 35% 55%;
    padding: 20px 0;
    gap: 10%;

    padding: 20px 20px;
    border: 1px solid var(--blue-b);
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      gap: 3%;
    }

    h2 {
      text-align: center;
      margin-bottom: 20px;
    }

    ul {
      list-style: none;

      li {
        display: flex;
        align-items: center;
        font-size: 15px;
        padding-bottom: 17px;

        p {
          padding-right: 6px;
          font-weight: bold;
        }
      }
    }
  }

  .leaflet-container {
    width: 100%;
    height: 500px;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100vh;
    }
  }

  article {
    overflow: auto;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    width: 100%;
  }

  .select {
    margin: 0;
    flex: 1;
  }

  section {
    flex: 1;

    div {
      margin: 0;
    }
  }

  .btn-add {
    margin-left: auto;
    display: flex;
    background: var(--blue);
    border-radius: 50%;
    width: 40px;
    align-items: center;
    height: 40px;
    justify-content: center;
    border: 0;
  }

  .btn-del {
    background: var(--red);
    border: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  gap: 20px;

  section p {
    font-size: 11px;
  }
`;

export const FormSty = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;

  .selectModal {
    margin-bottom: 30px;
    width: 100%;
    height: 40px;
    margin-top: 0;
    border-radius: 10px;
    font-size: 16px;
    margin-bottom: 8px;
  }

  article {
    width: 100%;
  }
  .input {
    width: 100%;
  }
`;

export const ButtonIcon = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c53030;

  svg {
    color: #fff;
  }
`;
