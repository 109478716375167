import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler, Scope } from '@unform/core';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Header } from './styles';
import { Box } from '../components';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import { useTimeline } from '../../../../hooks/timeline';
import Loading from '../../../../components/Loading';
import formatMoney from '../../../../utils/formatMoney';

interface IArrayZero {
  subtipo: string;
  itens: [
    {
      agrupamento: number;
      subtipo: string;
      cod: number;
      campo: string;
      valor: number;
      info: string;
      read_only: number;
      elemento: string | number;
    },
  ];
}

interface IModalidade {
  agrupamento: number;
  subtipo: string;
  cod: number;
  campo: string;
  valor: number;
  info: string;
  read_only: number;
  elemento: string | number;
}

const Driver: React.FC = () => {
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);

  const [arrayPositionZeroPattern, setArrayPositionZeroPattern] = useState<
    IArrayZero[]
  >([]);

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      setLoading(true);
      const response = await api.get(
        `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Motoristas&user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      setArrayPositionZeroPattern(response.data.data);
      console.log('Data', response.data.data);
      setLoading(false);
    };

    loadTabs();
  }, [conta, id, token, temp]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        const array = data.modules.filter(
          (e: any) => e !== '' && e.valor !== '0',
        );
        await api.put(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Motoristas&user_request=${id}&conta=${conta}`,
          {
            user_request: id,
            conta,
            calculo: temp,
            itens: array.map((e: any) => ({
              valor: e.valor
                .replace('R$', '')
                .trim()
                .replace('.', '')
                .replace(',', '.'),
              cod: e.cod,
            })),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        next();
      } catch (err) {
        console.error(err);
      }
    },
    [next, conta, id, token, temp],
  );

  const formatValues = (item: IModalidade): string | number => {
    if (item.elemento === 60) {
      return formatMoney(item.valor)
        .split(/[R$\\/]/)
        .join('');
    }

    if (item.elemento === 66) {
      return item.valor;
    }
    return formatMoney(item.valor);
  };

  return (
    <Container>
      {loading ? (
        <Loading isLoading={loading} />
      ) : (
        <>
          <Header>Motorista</Header>
          <Form ref={formRef} onSubmit={handleAddSubmit}>
            {arrayPositionZeroPattern.map((tab, idxtab) => {
              return (
                <Box key={tab.subtipo}>
                  <h2>{tab.subtipo}</h2>
                  {tab.itens
                    ? tab.itens.map((item, index) => {
                        return (
                          <Scope key={item.campo} path={`modules[${item.cod}]`}>
                            <section>
                              <h5>{item.campo}</h5>
                              <Input
                                name="valor"
                                type="text"
                                value={formatValues(item)}
                                readOnly={item.read_only !== 0}
                                disabled={item.read_only !== 0}
                                info={item.info && item.info}
                                onChange={(e): void => {
                                  const newTelements = [
                                    ...arrayPositionZeroPattern,
                                  ];
                                  newTelements[idxtab].itens[index].valor =
                                    item.elemento === 66
                                      ? Number(
                                          e.target.value
                                            .split(/[^\d]+/g)
                                            .join('')
                                            .trim(),
                                        )
                                      : Number(
                                          Number(
                                            e.target.value
                                              .split(/[R$\\/,/./]/)
                                              .join('')
                                              .split(/[^\d]+/g)
                                              .join('')
                                              .trim(),
                                          ) / 100,
                                        );
                                  const idxTotal = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex(
                                    (x) => x.campo.toUpperCase() === 'TOTAL',
                                  );
                                  const diasUteis = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.find((x) => x.elemento === 66);

                                  // Adicional de Periculosidade (30%) = Salário fixo * 30 / 100

                                  const idxTotalTributado = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex(
                                    (x) =>
                                      x.campo.toUpperCase() ===
                                      'TOTAL TRIBUTADO',
                                  );

                                  const idxAdicionalPericu = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 60);
                                  const idxFixo = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 59);
                                  const extras = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 61);

                                  if (
                                    item.campo.toUpperCase() === 'SALÁRIO FIXO'
                                  ) {
                                    if (idxAdicionalPericu !== -1) {
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxAdicionalPericu
                                      ].valor =
                                        (arrayPositionZeroPattern[idxtab].itens[
                                          idxFixo
                                        ].valor *
                                          30) /
                                        100;
                                    }
                                  }

                                  if (idxTotal !== -1) {
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotal
                                    ].valor = 0;
                                    const total = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.reduce((acc, value) => {
                                      let a = 0;
                                      a = acc + value.valor;
                                      return a;
                                    }, 0);
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotal
                                    ].valor = total;
                                  }

                                  if (idxTotalTributado !== -1) {
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotalTributado
                                    ].valor = 0;

                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotalTributado
                                    ].valor =
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxAdicionalPericu
                                      ].valor +
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxFixo
                                      ].valor +
                                      arrayPositionZeroPattern[idxtab].itens[
                                        extras
                                      ].valor;
                                  }

                                  // Diarias/pernoite

                                  const idxTotalPernoites = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 113);

                                  const idxValorDiaria = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 64);

                                  const idxValorPernoite = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 65);

                                  const idxDiasUteis = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 66);

                                  if (
                                    idxTotalPernoites !== -1 &&
                                    idxValorDiaria !== -1 &&
                                    idxValorPernoite !== -1 &&
                                    idxDiasUteis
                                  ) {
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotalPernoites
                                    ].valor =
                                      (arrayPositionZeroPattern[idxtab].itens[
                                        idxValorDiaria
                                      ].valor +
                                        arrayPositionZeroPattern[idxtab].itens[
                                          idxValorPernoite
                                        ].valor) *
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxDiasUteis
                                      ].valor;
                                  }

                                  setArrayPositionZeroPattern(newTelements);
                                }}
                              />
                              <Input
                                name="cod"
                                type="hidden"
                                value={item.cod}
                              />
                            </section>
                          </Scope>
                        );
                      })
                    : []}
                </Box>
              );
            })}
            <button type="submit">Salvar</button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default Driver;
