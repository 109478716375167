import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler, Scope } from '@unform/core';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import { Container, Header } from './styles';
import { Box } from '../components';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import { useTimeline } from '../../../../hooks/timeline';
import Loading from '../../../../components/Loading';
import formatMoney from '../../../../utils/formatMoney';

interface IArrayZero {
  subtipo: string;
  itens: [
    {
      agrupamento: number;
      subtipo: string;
      cod: number;
      campo: string;
      valor: number;
      info: string;
      read_only: number;
      elemento: string | number;
    },
  ];
}

interface IModalidade {
  agrupamento: number;
  subtipo: string;
  cod: number;
  campo: string;
  valor: number;
  info: string;
  read_only: number;
  elemento: string | number;
}

const Tires: React.FC = () => {
  const { id, conta } = useAuth().user;
  const { temp } = useTabs();
  const { next } = useTimeline();
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const [price, setPrice] = useState(0);
  const [isPrice, setIsPrice] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);

  const [arrayPositionZeroPattern, setArrayPositionZeroPattern] = useState<
    IArrayZero[]
  >([]);

  useEffect(() => {
    const loadTabs = async (): Promise<void> => {
      setLoading(true);
      const response = await api.get(
        `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Pneus&user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      setArrayPositionZeroPattern(response.data.data);
      setLoading(false);
    };

    loadTabs();
  }, [conta, id, token, temp]);

  const handlePrice = useCallback(async () => {
    const response = await api.get(
      `/api/v1/tabelas/customercado?user_request=${id}&conta=${conta}`,
      {
        headers: {
          'x-access-token': token,
        },
      },
    );

    setPrice(
      response.data.data.find((item: any) => item.nome === 'pneu').valor,
    );

    setIsPrice(true);

    const newTelements = [...arrayPositionZeroPattern];

    newTelements.map((e) => {
      e.itens.map((i) => {
        if (i.elemento !== 130) {
          i.valor = 0;
        }
        return '';
      });
      return '';
    });

    newTelements[2].itens[0].valor = response.data.data.find(
      (item: any) => item.nome === 'pneu',
    ).valor;

    setArrayPositionZeroPattern(newTelements);
  }, [conta, id, token, arrayPositionZeroPattern]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        const array = data.modules.filter(
          (e: any) => e !== '' && e.valor !== '0',
        );

        await api.put(
          `/api/v1/tabelas/elementos?calculo=${temp}&tabela=Penus&user_request=${id}&conta=${conta}`,
          {
            user_request: id,
            conta,
            calculo: temp,
            itens: array.map((e: any) => ({
              valor: e.valor
                .replace('R$', '')
                .trim()
                .replace('.', '')
                .replace(',', '.'),
              cod: e.cod,
            })),
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        next();
      } catch (err) {
        console.error(err);
      }
    },
    [next, conta, id, token, temp],
  );

  const valueInput = useCallback((value: number, element: string | number):
    | string
    | number => {
    if (element === 130) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
      })
        .format(Number(value))
        .split('-')
        .join('');
    }

    if (
      element === 126 ||
      element === 127 ||
      element === 128 ||
      element === 129
    ) {
      return new Intl.NumberFormat('pt-BR')
        .format(Number(value))
        .split('-')
        .join('');
    }

    return formatMoney(value);
  }, []);

  return (
    <Container>
      {loading ? (
        <Loading isLoading={loading} />
      ) : (
        <>
          <Header>Pneus</Header>
          <Form ref={formRef} onSubmit={handleAddSubmit}>
            {arrayPositionZeroPattern.map((tab, idxtab) => {
              return (
                <Box key={tab.subtipo}>
                  <h2>{tab.subtipo}</h2>
                  {tab.itens
                    ? tab.itens.map((item, index) => {
                        return (
                          <Scope key={item.campo} path={`modules[${item.cod}]`}>
                            <section>
                              <h5>{item.campo}</h5>
                              <Input
                                name="valor"
                                type="text"
                                value={valueInput(item.valor, item.elemento)}
                                readOnly={item.read_only !== 0}
                                disabled={item.read_only !== 0}
                                info={item.info && item.info}
                                onChange={(e): void => {
                                  const newTelements = [
                                    ...arrayPositionZeroPattern,
                                  ];

                                  if (
                                    item.elemento === 126 ||
                                    item.elemento === 127 ||
                                    item.elemento === 128 ||
                                    item.elemento === 129
                                  ) {
                                    newTelements[idxtab].itens[
                                      index
                                    ].valor = Number(
                                      e.target.value
                                        .split(/[^\d]+/g)
                                        .join('')
                                        .trim(),
                                    );
                                  } else {
                                    newTelements[idxtab].itens[
                                      index
                                    ].valor = Number(
                                      Number(
                                        e.target.value
                                          .split(/[R$\\/,/./]/)
                                          .join('')
                                          .split(/[^\d]+/g)
                                          .join('')
                                          .trim(),
                                      ) / 100,
                                    );
                                  }

                                  const peneusIdxTotal = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 123);

                                  const idxCarcaca = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 124);

                                  const custoUmPneu = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 125);

                                  const idxTotalRodagem = arrayPositionZeroPattern[
                                    idxtab
                                  ].itens.findIndex((x) => x.elemento === 129);

                                  const totalPeneus = arrayPositionZeroPattern[0].itens.find(
                                    (x) => x.elemento === 125,
                                  );

                                  const TotalKM = arrayPositionZeroPattern[1].itens.find(
                                    (x) => x.elemento === 129,
                                  );

                                  if (peneusIdxTotal !== -1) {
                                    arrayPositionZeroPattern[idxtab].itens[
                                      peneusIdxTotal
                                    ].valor = 0;
                                    const total = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.reduce((acc, value) => {
                                      let a = 0;
                                      a = acc + value.valor;
                                      return a;
                                    }, 0);
                                    arrayPositionZeroPattern[idxtab].itens[
                                      peneusIdxTotal
                                    ].valor = total;

                                    let orTo = total;

                                    if (
                                      idxCarcaca !== -1 ||
                                      custoUmPneu !== -1
                                    ) {
                                      const caparaca =
                                        arrayPositionZeroPattern[idxtab].itens[
                                          idxCarcaca
                                        ].valor;
                                      const umPneu =
                                        arrayPositionZeroPattern[idxtab].itens[
                                          custoUmPneu
                                        ].valor;
                                      orTo = (caparaca + umPneu - orTo) * -1;
                                    }

                                    console.log(orTo);
                                    arrayPositionZeroPattern[idxtab].itens[
                                      peneusIdxTotal
                                    ].valor = orTo;
                                  }

                                  if (idxTotalRodagem !== -1) {
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotalRodagem
                                    ].valor = 0;
                                    const total = arrayPositionZeroPattern[
                                      idxtab
                                    ].itens.reduce((acc, value) => {
                                      let a = 0;
                                      a = acc + value.valor;
                                      return a;
                                    }, 0);
                                    arrayPositionZeroPattern[idxtab].itens[
                                      idxTotalRodagem
                                    ].valor = total;
                                  }

                                  if (custoUmPneu !== -1) {
                                    const carcaca =
                                      arrayPositionZeroPattern[idxtab].itens[
                                        idxCarcaca
                                      ].valor;

                                    const total =
                                      arrayPositionZeroPattern[idxtab].itens[
                                        peneusIdxTotal
                                      ].valor;

                                    arrayPositionZeroPattern[idxtab].itens[
                                      custoUmPneu
                                    ].valor = total - carcaca;
                                  }

                                  if (totalPeneus && TotalKM) {
                                    if (TotalKM.valor > 0) {
                                      arrayPositionZeroPattern[2].itens[0].valor =
                                        totalPeneus.valor / TotalKM.valor;
                                    }
                                  }

                                  setArrayPositionZeroPattern(newTelements);
                                }}
                              />
                              <Input
                                name="cod"
                                type="hidden"
                                value={item.cod}
                              />
                            </section>
                          </Scope>
                        );
                      })
                    : []}
                </Box>
              );
            })}
            <button
              type="button"
              onClick={() => {
                if (
                  window.confirm('Deseja utilizar o preço médio do sistema?')
                ) {
                  handlePrice();
                }
              }}
              style={{ display: 'none' }}
            >
              Consulte o preço médio
            </button>
            <button type="submit">Salvar</button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default Tires;