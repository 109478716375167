import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  h1 {
    text-align: center;
    margin-bottom: 15px;
    color: var(--blue-b);
  }

  .select {
    width: 40%;
    margin: 0;
    margin-right: 10px;
    z-index: 1001;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 10px 0;
    }
  }

  .input {
    width: 100%;
    margin-right: 10px;
  }
  > p {
    padding: 6px 0;
    border-top: 10px solid var(--blue-b);
    border-bottom: 10px solid var(--blue-b);
    margin-bottom: 22px;
    font-size: 15px;
    text-align: justify;
  }

  form {
    display: grid;
    grid-template-rows: auto;
    grid-template-areas:
      'HD HD'
      'FM MP';
    grid-template-columns: 15% 85%;

    @media screen and (max-width: 768px) {
      grid-template-areas:
        'HD'
        'FM'
        'MP';
      grid-template-columns: 100%;
    }

    header {
      grid-area: HD;
      display: flex;
      align-items: flex-end;
      margin-bottom: 25px;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      button {
        width: 15%;
        margin-right: 10px;
      }

      section {
        width: 20%;
        margin-right: 10px;
        margin-top: 10px;
      }

      button,
      section {
        @media screen and (max-width: 768px) {
          width: 100%;
          margin: 5px 0;
        }
      }
    }

    aside {
      grid-area: FM;

      article {
        margin: 10px 0;
        width: 100%;

        p {
          margin-bottom: 3px;
        }
      }

      section,
      button {
        margin-top: 10px;

        @media screen and (max-width: 768px) {
          margin: 5px 0;
        }
      }
    }

    .leaflet-container {
      width: auto;
      height: auto;
      margin: 20px;
      grid-area: MP;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 100vh;
        margin: 20px 0;
      }
    }
  }
`;

export const Header = styled.h5`
  text-align: center;
  color: var(--blue-b);
  font-size: 23px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 50px;
  margin-bottom: 20px;

  button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    width: 63px;
    margin: 0;
  }

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
