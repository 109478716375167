import React, { useRef, useState, useCallback } from 'react';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import {
  AiOutlineWhatsApp,
  AiFillMail,
  AiFillFacebook,
  AiFillLinkedin,
  AiFillInstagram,
  AiFillYoutube,
} from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Navbar, Content, Footer } from './styles';

import logo from '../../assets/logo-mini.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

interface FormData {
  user: string;
  password: string;
}

interface Data {
  value?: string;
  name?: string;
  labelName?: string;
  isSensible?: boolean;
  id?: string;
  type?: string;
  placeholder?: string;
  dataCheckout?: string;
  options?: {
    id: string;
    text: string;
  }[];
  hidden?: boolean;
  invalidMessage?: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRefForgot = useRef<FormHandles>(null);
  const [resetPassword, setResetPassword] = useState(true);
  const { signIn } = useAuth();
  const history = useHistory();

  const handleSubmit: SubmitHandler<FormData> = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          user: Yup.string()
            .required('Email obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          emailUser: data.user,
          password: data.password,
        });

        history.push('/calculos');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        toast.error('Ocorreu um erro ao fazer login, cheque as crendenciais.');
      }
    },
    [signIn, history],
  );

  const handleSubmitForgot: SubmitHandler = useCallback(async (data: any) => {
    try {
      await api.post('/api/v1/usuarios/forgot_password', {
        email: data.email,
      });
      toast.success(
        'As instruções para a recuperação de senha foram enviadas para o seu e-mail.',
      );
      setResetPassword(false);
    } catch (error) {
      toast.error(
        'Erro ao recuperar senha, por favor tente novamente mais tarde.',
      );
    }
  }, []);

  return (
    <Container>
      <Navbar>
        <div>
          <img src={logo} alt="logo" />
        </div>
      </Navbar>
      <main>
        <Content>
          <section>
            <div>
              <h2>
                O MaxiFrete é a melhor plataforma de precificação de frete e
                avaliação de rotas do país.
              </h2>
              <p>Conheça nossos planos.</p>
            </div>
            <div>
              {resetPassword ? (
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <h1>Faça o login</h1>
                  <Input name="user" placeholder="Usuário" border />
                  <Input
                    name="password"
                    type="password"
                    placeholder="Senha"
                    border
                  />
                  <Button type="submit">Acessar</Button>
                  <button type="button" onClick={() => setResetPassword(false)}>
                    Recuperar senha
                  </button>
                </Form>
              ) : (
                <Form ref={formRefForgot} onSubmit={handleSubmitForgot}>
                  <h1>Digite seu e-mail</h1>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Usuário"
                    border
                  />
                  <Button type="submit">Acessar</Button>
                  <button type="button" onClick={() => setResetPassword(true)}>
                    Fazer Login
                  </button>
                </Form>
              )}

              <article>
                <h4>Conheça o MaxiFrete!</h4>
                <p>Clique aqui para conhecer essa poderosa ferramenta.</p>
              </article>
            </div>
          </section>
        </Content>
      </main>
      <Footer>
        <section>
          <img src={logo} alt="logo" />
          <div>
            <a href="#f">
              <AiOutlineWhatsApp size={20} color="#fff" />
              (19) 98234-0511
            </a>
            <a href="#f">
              <AiFillMail size={20} color="#fff" />
              contato@maxifrete.com.br
            </a>
          </div>
          <div>
            <a href="#f">
              <AiFillFacebook size={25} color="#fff" />
            </a>
            <a href="#f">
              <AiFillLinkedin size={25} color="#fff" />
            </a>
            <a href="#f">
              <AiFillInstagram size={25} color="#fff" />
            </a>
            <a href="#f">
              <AiFillYoutube size={25} color="#fff" />
            </a>
          </div>
        </section>
      </Footer>
    </Container>
  );
};

export default SignIn;
