import styled, { css } from 'styled-components';
import Modal from 'react-modal';

interface ModalProps {
  icon?: 'edit' | 'delete' | 'add' | 'view' | 'book';
}
export const Container = styled.div<ModalProps>`
  color: #000;

  .labelDescription {
    background: transparent;
    color: #fff;
    border: 0;
  }

  .buttonIcon {
    border: 0;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
      props.icon === 'edit' &&
      css`
        background: #008f85;
      `}
    ${(props) =>
      props.icon === 'view' &&
      css`
        background: #008f85;
      `}

    ${(props) =>
      props.icon === 'book' &&
      css`
        background: #008f85;
      `}

    ${(props) =>
      props.icon === 'delete' &&
      css`
        background: #c53030;
      `}
    ${(props) =>
      props.icon === 'add' &&
      css`
        background: #28a745;
      `}

    svg {
      color: #fff;
    }
  }
`;

export const ModalSty = styled(Modal)<{ background?: string; width?: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 35px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  color: #000;
  font-size: 20px;
  line-height: 28px;
  @media screen and (max-width: 500px) {
    min-width: 90%;
    max-width: 90%;
    padding: 10px;
  }

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}

  ${(props) =>
    props.background &&
    css`
      width: ${props.width}%;
    `}
`;
