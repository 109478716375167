/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import InputMask from 'react-input-mask';

import { Container } from './styles';

const InputPayment: React.FC<any> = ({
  mask = '',
  getValueElement,
  ...props
}) => {
  const isSensible = props.isSensible || false;
  const [isActive, setIsActive] = useState(true);
  const extraAttr: any = {};
  if (!isSensible) {
    extraAttr.name = props.name;
    extraAttr.hidden = props.hidden;
  }

  let generatedInvalidMessage = null;
  if (props.invalidMessage && props.invalidMessage !== '') {
    generatedInvalidMessage = <p>{props.invalidMessage}</p>;
  }

  const onChangeChildHandler = (e: any): void => {
    getValueElement(e.target.value);
    props.onChange(props.index, e);
  };

  let input;

  if (props.type !== 'select') {
    input = (
      <div id="float-label">
        <InputMask
          id={props.id}
          mask={mask}
          type={props.type}
          value={props.value}
          data-checkout={props.dataCheckout}
          required
          {...extraAttr}
          onChange={onChangeChildHandler}
        />
        <label className={isActive ? 'Active' : ''} htmlFor={props.placeholder}>
          {props.placeholder}
        </label>
      </div>
    );
  }

  if (props.type === 'select') {
    input = (
      <select
        id={props.id}
        value={props.value}
        data-checkout={props.dataCheckout}
        placeholder={props.placeholder}
        {...extraAttr}
        onChange={onChangeChildHandler}
      >
        {props.options &&
          props.options.map((option: any) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
      </select>
    );
  }

  return (
    <Container hidden={props.type === 'hidden'}>
      {input}
      {generatedInvalidMessage}
    </Container>
  );
};

export default InputPayment;
