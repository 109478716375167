/* eslint-disable react/jsx-wrap-multilines */
import React, {
  useState,
  useEffect,
  useCallback,
  ButtonHTMLAttributes,
  useRef,
} from 'react';
import MaterialTable from 'material-table';
import { FormHandles, SubmitHandler } from '@unform/core';
import { FiMail, FiUser, FiTrash2, FiLock, FiUserPlus } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import api from '../../services/api';
import {
  Container,
  Header,
  Box,
  FormSty,
  ButtonIcon,
  CsvDownloader,
} from './styles';
import { useAuth } from '../../hooks/auth';
import { useMenu } from '../../hooks/menu';
import ModalTab from '../../components/Modal';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

export interface UserData {
  nome_first?: string;
  empresa?: string;
  email?: string;
  telefone?: string;
  edit?: ButtonHTMLAttributes<HTMLButtonElement>;
  remove?: any;
}

interface ReturnUser {
  cod?: number;
  nome_first?: string;
  perfil?: string;
  nome_last?: string;
  email?: string;
}

const rowHead = [
  {
    title: 'Nome',
    field: 'nome_first',
  },
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Editar',
    field: 'edit',
  },
  {
    title: 'Excluir',
    field: 'remove',
  },
];

const createData = ({
  nome_first,
  empresa,
  email,
  telefone,
  edit,
  remove,
}: UserData): UserData => {
  return { nome_first, empresa, email, telefone, edit, remove };
};

const Users: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const formAddRef = useRef<FormHandles>(null);
  const formEditRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { expansedFalse } = useMenu();

  const token = sessionStorage.getItem('@MaxiFrete:token');
  const { id, conta, perfil: userPerfil } = user;

  const list = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get(
        `/api/v1/usuarios?conta=${conta}&user=${id}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      setUsers(response.data.data);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao listar usuários.');
    }
  }, [conta, id, token]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        formAddRef.current?.setErrors({});
        console.log(data);
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('Email obrigatório'),
          firstName: Yup.string().required('Nome obrigatório'),
          secondName: Yup.string().required('Sobrenome obrigatório'),
          senha: Yup.string()
            .required('Senha obrigatória')
            .min(6, 'No mínimo 6 caracteres'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post(
          `/api/v1/usuarios/addUser`,
          {
            user_request: id,
            conta,
            nome_first: data.firstName,
            nome_last: data.secondName,
            email: data.email,
            senha: data.senha,
            perfil: 'usuario',
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setModal(true);
        list();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formAddRef.current?.setErrors(errors);
          return;
        }
        toast.error(
          'Ocorreu um erro ao atualizar este usuário, cheque as crendênciais.',
        );
      }
    },
    [conta, id, list, token],
  );

  const handleSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        formEditRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string().email('Digite um e-mail válido'),
          firstName: Yup.string().required('Nome obrigatório'),
          secondName: Yup.string().required('Sobrenome obrigatório'),
          old_password: Yup.string().required('Senha antiga obrigatória'),
          new_password: Yup.string().required('Nova senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(
          `/api/v1/usuarios/updateuser/${data.cod}`,
          {
            user_request: id,
            conta,
            nome_first: data.firstName,
            nome_last: data.secondName,
            email: data.email,
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        await api.put(
          `/api/v1/usuarios/updatepassword/${data.cod}`,
          {
            user_request: id,
            conta,
            old_password: data.old_password,
            new_password: data.new_password,
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setModal(true);
        list();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formEditRef.current?.setErrors(errors);

          return;
        }
        toast.error(
          'Ocorreu um erro ao atualizar este usuário, cheque as crendênciais.',
        );
      }
    },
    [list, conta, id, token],
  );

  const deleteUser = useCallback(
    async ({ cod }: ReturnUser): Promise<void> => {
      try {
        await api.delete(`/api/v1/usuarios/${cod}`, {
          headers: {
            'x-access-token': token,
          },
        });
        toast.info('Deletando usuário.');
        list();
      } catch (err) {
        toast.error('Erro ao deletar usuário.');
      }
    },
    [list, token],
  );

  useEffect(() => {
    if (window.screen.width <= 500) {
      expansedFalse();
    }
    list();
  }, [list, expansedFalse]);

  const FormAdd: React.FC<ReturnUser> = () => (
    <FormSty ref={formAddRef} onSubmit={handleAddSubmit}>
      <Header>Cadastro de usuário</Header>
      <Input type="email" icon={FiMail} name="email" placeholder="E-mail" />
      <Input icon={FiUser} name="firstName" placeholder="Nome" />
      <Input icon={FiUserPlus} name="secondName" placeholder="Sobrenome" />
      <Input placeholder="Senha" icon={FiLock} name="senha" type="password" />
      <Button type="submit">Adicionar</Button>
    </FormSty>
  );

  const FormEdit: React.FC<ReturnUser> = ({
    nome_first,
    nome_last,
    email,
    cod,
  }: ReturnUser) => (
    <FormSty ref={formEditRef} onSubmit={handleSubmit}>
      <Header>Alteração de usuário</Header>

      <Input
        type="email"
        placeholder="E-mail"
        icon={FiMail}
        defaultValue={email}
        name="email"
      />
      <Input
        icon={FiUser}
        defaultValue={nome_first}
        name="firstName"
        placeholder="Nome"
      />
      <Input
        icon={FiUserPlus}
        name="secondName"
        defaultValue={nome_last}
        placeholder="Sobrenome"
      />
      <Input icon={FiLock} name="old_password" placeholder="Senha Antiga" />
      <Input icon={FiLock} name="new_password" placeholder="Nova Senha" />
      <Input type="hidden" value={cod} name="cod" />
      <Button type="submit">Editar</Button>
    </FormSty>
  );

  const rowsList = users
    ? users.map((data: any) => {
        const { nome_first, nome_last, email, cod, perfil } = data;
        console.log(data);
        const row = createData({
          nome_first,
          email,
          edit: (
            <ModalTab
              close={modal}
              modalContent={
                <FormEdit
                  email={email}
                  nome_first={nome_first}
                  nome_last={nome_last}
                  cod={cod}
                />
              }
              icon="edit"
            />
          ),
          remove:
            perfil === 'usuario_admin' ? null : (
              <ButtonIcon
                onClick={() => {
                  return window.confirm('Deseja deletar o usuário?')
                    ? deleteUser({ cod })
                    : '';
                }}
                type="button"
              >
                <FiTrash2 size={18} />
              </ButtonIcon>
            ),
        });
        return row;
      })
    : [];

  const datas = users.map((user: any) => ({
    name: `${user.nome_first}  ${user.nome_last}`,
    email: user.email,
  })) || {
    cell1: 'row 1 - cell 1',
    cell2: 'row 1 - cell 2',
  };

  return (
    <Container>
      <Header>
        <h1>Usuários</h1>
        <ModalTab close={modal} modalContent={<FormAdd />} icon="add" />
      </Header>
      {userPerfil === 'admin' && (
        <CsvDownloader datas={datas} filename="users">
          Baixar CSV
        </CsvDownloader>
      )}

      <Box>
        <MaterialTable
          isLoading={loading}
          columns={rowHead}
          data={rowsList}
          title="Todos os usuários cadastrados"
          localization={{
            pagination: {
              labelRowsSelect: 'linhas',
            },
            toolbar: {
              searchPlaceholder: 'Pesquisar',
              searchTooltip: 'Pesquisar',
            },
            body: {
              emptyDataSourceMessage: 'Nenhum registro encontrado',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
            },
          }}
        />
      </Box>
    </Container>
  );
};

export default Users;
