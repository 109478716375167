import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { MenuProvider } from './menu';
import { Timeline } from './timeline';
import { TabsProvider } from './tabs';

const Hooks: React.FC = ({ children }) => (
  <AuthProvider>
    <ToastProvider>
      <MenuProvider>
        <Timeline>
          <TabsProvider>{children}</TabsProvider>
        </Timeline>
      </MenuProvider>
    </ToastProvider>
  </AuthProvider>
);

export default Hooks;
