import styled from 'styled-components';
import Modal from 'react-modal';

interface IModalProps {
  bg: string;
}

export const Container = styled.div<IModalProps>`
  button {
    border: 0;
    padding: 0.4rem 0.8rem;
    font-size: 18px;
    border-radius: 5px;
    color: #fff;
    background: ${(props) => props.bg};
    margin: 0 0.5rem;
  }
`;

export const ModalSty = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  transform: translate(-50%, -50%);
  min-width: 80%;
  max-width: 80%;
  max-height: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    min-width: 90%;
    max-width: 90%;

    padding: 10px;
  }

  main {
    padding: 2rem 4rem;

    @media screen and (max-width: 500px) {
      padding: 1rem;
    }

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
      button {
        color: #fff;
        font-size: 25px;
        text-transform: uppercase;
        height: 50px;
      }
    }

    form > div > article {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      flex: 1;

      @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
      }

      input[type='hidden'] {
        .input {
          display: none;
        }
      }

      .input {
        margin: 0;
        margin: 10px 0;
      }
    }
  }
`;

export const Header = styled.header`
  background: radial-gradient(
    circle,
    rgba(88, 200, 227, 1) 9%,
    rgba(18, 194, 237, 1) 46%,
    rgba(105, 217, 244, 1) 78%
  );
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 2rem 4rem;

  h3 {
    color: var(--color-white);
  }

  button {
    border: 0;
    font-size: 2rem;
    background: transparent;
  }
`;
