import React, { createContext, useContext, useCallback, useState } from 'react';

interface MenuContextData {
  handleExpansed(): void;
  expansedFalse(): void;
  expansed: boolean;
}

const MenuContext = createContext({} as MenuContextData);

export const MenuProvider: React.FC = ({ children }) => {
  const [expansed, setExpansed] = useState(false);
  console.log(expansed);
  const expansedFalse = useCallback(() => {
    setExpansed(false);
  }, []);

  const handleExpansed = useCallback(() => {
    if (expansed) {
      setExpansed(false);
    } else {
      setExpansed(true);
    }
  }, [expansed]);

  return (
    <MenuContext.Provider value={{ handleExpansed, expansed, expansedFalse }}>
      {children}
    </MenuContext.Provider>
  );
};

export function useMenu(): MenuContextData {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be user within an AuthProvider');
  }

  return context;
}
