import React from 'react';
import formatMoney from '../../../../../utils/formatMoney';


interface IResumoDespesas {
  resumo: {
    custo_fixo_km: number;
    custo_por_tonelada: number;
    custo_total_km: number;
    custo_variavel_km: number;
    participacao_despesas_fixas: number;
    participacao_despesas_variaveis: number;
    total_desp_fixa: number;
    total_desp_variaveis: number;
    total_geral: number;
  };
}

const ResumoDespesas: React.FC<IResumoDespesas> = ({ resumo }) => {
  return (
    <>
      <h2>Resumo</h2>

      <section>
        <h5>Total das despesas fixas</h5>
        <p>{formatMoney(resumo.total_desp_fixa)}</p>
      </section>
      <section>
        <h5>Total das despesas variáveis</h5>
        <p>{formatMoney(resumo.total_desp_variaveis)}</p>
      </section>
      <section>
        <h5>Despesas totais (DF + DV)</h5>
        <p>{formatMoney(resumo.total_geral)}</p>
      </section>
      <section>
        <h5>Custo por tonelada ou m³</h5>
        <p>{formatMoney(resumo.custo_por_tonelada)}</p>
      </section>
      <section>
        <h5>Custo fixo por km</h5>
        <p>{formatMoney(resumo.custo_fixo_km)}</p>
      </section>
      <section>
        <h5>Custo variável por km</h5>
        <p>{formatMoney(resumo.custo_variavel_km)}</p>
      </section>

      <hr></hr>
      <section>
        <h5><strong>Custo total por km</strong></h5>
        <p>{formatMoney(resumo.custo_total_km)}</p>
      </section>
      <hr></hr>
      <br></br>
      <section>
        <h5>Participação das despesas fixas sobre o faturamento
        </h5>
        <p>{resumo.participacao_despesas_fixas.toFixed(2)} %</p>
      </section>
      <section>
        <h5>Participação das despesas variáveis sobre o faturamento</h5>
        <p>{resumo.participacao_despesas_variaveis.toFixed(2)} %</p>
      </section>
    </>
  );
};

export default ResumoDespesas;
