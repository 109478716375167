import { InputHTMLAttributes } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Tooltip from '../../../../components/Tooltip';

interface IContainerProps extends InputHTMLAttributes<HTMLInputElement> {
  isFocused: boolean;
  isErrored: boolean;
  isBlur: boolean;
  border?: boolean;
}

export const Label = styled.p`
  position: absolute;
  top: 5px;
  left: 9px;
  color: var(--blue-b);
  font-weight: 700;
  font-size: 12px;
  color: #999 !important;
  margin-bottom: 0px;
`;

const pulse = keyframes`
  0% {
		transform: translateY(0px);
	}

	70% {
    transform: translateY(-4px);
	}

	100% {
    transform: translateY(0px);
	}
`;

export const Container = styled.div`
  p {
    color: var(--blue-logo);
    font-size: 18px;
  }
`;

export const ContainerInput = styled.div<IContainerProps>`
  position: relative;
  padding: 0px;
  padding-left: 0;
  width: 100%;
  border: 1px solid #999;
  border-radius: 10px;
  display: flex;
  color: #232129;
  align-items: center;
  padding: 10px 8px;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: var(--red);
    `}

  input {
    flex: 1;
    border: 0;
    width: 100%;
    background: transparent;
    font-weight: 500;
    padding: 10px 0px !important;

    &::placeholder {
      color: #232129;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Tool = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  animation: ${pulse} 0.3s linear;
  svg {
    margin: 0;
  }

  span {
    &::before {
      border-color: var(--red-error) transparent;
    }
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-left: 16px;
  animation: ${pulse} 0.3s linear;
  svg {
    margin: 0;
  }

  span {
    color: var(--red-error);
    margin-left: 4px;
    font-size: 12px;
    &::before {
      border-color: var(--red-error) transparent;
    }
  }
`;
