import React, { useState } from 'react';
import { FaMailBulk, FaWhatsapp } from 'react-icons/fa';
import { Modal } from './Modal';
import logo from './logo.png';

import { Container, PrivacyPolicy } from './styles';

const Footer: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Container>
      <Modal show={showModal}>
        <PrivacyPolicy>
          <button type="button" onClick={() => setShowModal(false)}>
            Sair
          </button>
          <h3>Pol&#237;tica de Privacidade e Prote&#231;&#227;o de Dados</h3>
          <br></br>
          <div className="row_203">
             <div className="row_204">
        <div className="column_39">
          <div className="row_206">
            
            <div className="row_209">
              <div className="row_211">
                <p className="paragraph4"> <span className="titulo" > Pol&#237;tica de privacidade </span> <br/>
          <br/>
          N&#243;s da Maxifrete prezamos pela privacidade dos dados dos usu&#225;rios que s&#227;o coletados por meio de nossos servi&#231;os. Pedimos para que, caso n&#227;o concorde com o conte&#250;do dessa pol&#237;tica, n&#227;o use nossos servi&#231;os ou materiais. <br/>
          <br/>
          <span className="titulo"> Os dados coletados e para que os usamos. </span> <br/>
          <br/>
          Em nossos servi&#231;os, coletamos dados de clientes, tais como: nome, e-mail, etc, para realizar agendamentos, or&#231;amentos, entre outras coisas. Vale lembrar que o cliente tem a liberdade de se descadastrar a qualquer momento e n&#227;o receber mais nossos contatos. Ressalvamos que esses dados n&#227;o ser&#227;o repassados ou comercializados para terceiros em nenhuma hip&#243;tese. <br/>
          <br/>
          <span className="titulo"> Uso dos dados para publicidade </span> <br/>
          <br/>
          Os dados coletados atrav&#233;s dos formul&#225;rios de contato de nosso website podem ser compartilhados com empresas parceiras para serem utilizados em campanhas de an&#250;ncios nas redes sociais e envios de e-mails com comunicados, novidades e ofertas para os clientes. <br/>
          <br/>
          O usu&#225;rio que desejar parar de receber os e-mails ou a exibi&#231;&#227;o de an&#250;ncios pode cancel&#225;-los a qualquer momento na pr&#243;pria plataforma, nossos e-mails cont&#233;m um link para descadastramento e as redes sociais em que anunciamos possibilitam que o pr&#243;prio usu&#225;rio solicite para que os an&#250;ncios n&#227;o sejam mais exibidos a ele, tamb&#233;m nos colocamos &#224; disposi&#231;&#227;o para efetuarmos o descadastramento caso o usu&#225;rio precise de aux&#237;lio, basta entrar em contato atrav&#233;s do e-mail contato@cavikconsultoria.com.br. 
		  <br/><br/>
          <span className="titulo"> Mudan&#231;as na Pol&#237;tica de Privacidade </span> <br/>
          <br/>
          O conte&#250;do dessa pol&#237;tica de privacidade pode ser atualizado a qualquer momento, pedimos para que visite esta p&#225;gina periodicamente. Antes de usarmos seus dados para algo n&#227;o especificado nessa pol&#237;tica, solicitaremos sua autoriza&#231;&#227;o. <br/>
          <br/>
          <span className="titulo"> O que s&#227;o cookies? </span> <br/>
          <br/>
          Cookies s&#227;o arquivos de textos criptografados geralmente encontrados nos diret&#243;rios de navegadores para ajudar usu&#225;rios a navegarem pelo site de maneira eficiente. <br/>
          <br/>
          Neste website os cookies foram suprimidos por 15 segundos, pois entendemos ser tempo suficiente para o usu&#225;rio ser informado ou at&#233; desistir da navega&#231;&#227;o. Por&#233;m informamos abaixo nesta mesma pol&#237;tica qual procedimento o usu&#225;rio dever&#225; seguir se desejar excluir quaisquer cookies ap&#243;s a navega&#231;&#227;o. <br/>
          <br/>
          <span className="titulo"> Para que servem os cookies? </span> <br/>
          <br/>
          Os cookies presentes no website da Maxifrete s&#227;o utilizados para registrar sua visita, acompanhar a maneira que a p&#225;gina est&#225; sendo encontrada dentro da web e entender suas prefer&#234;ncias atrav&#233;s das p&#225;ginas visitadas frequentemente e para desenvolver an&#250;ncios direcionados aos seus interesses reais. <br/>
          <br/>
          <span className="titulo"> Como gerenciar suas prefer&#234;ncias de cookies? </span> <br/>
          <br/>
          O navegador da Web permite que seus usu&#225;rios gerenciem as suas prefer&#234;ncias, recusando ou removendo os cookies conforme sua vontade. <br/>
          <br/>
          <span className="titulo"> O procedimento &#233; bem simples: </span> <br/>
          <br/>
          &#8226; Dentro do seu navegador, entre na aba de configura&#231;&#245;es e realize o gerenciamento dos cookies sobre as op&#231;&#245;es de recusar cookies ou exclu&#237;-los. <br/>
          &#8226; Tamb&#233;m existem softwares que detectam cookies e excluem com a autoriza&#231;&#227;o do usu&#225;rio. <br/>
          <br/>
          <span className="titulo"> Contato </span> <br/>
          <br/>
          Qualquer d&#250;vida em rela&#231;&#227;o &#224; nossa pol&#237;tica de privacidade pode ser esclarecida, entre em contato conosco. <br/></p>
              </div>
            </div>
            </div>
            </div>
            </div>
            </div>
        </PrivacyPolicy>
      </Modal>
      <section>
        <img src={logo} alt="logo" />
        <div>
          <a
            href="https://api.whatsapp.com/send?phone=5519982340511"
            target="__blank"
          >
            <FaWhatsapp color="#fff" />
            (19) 98234-0511
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=5519982340511"
            target="__blank"
          >
            <FaMailBulk color="#fff" />
            contato@cavikconsultoria.com.br
          </a>
        </div>
      </section>
      <section>
        
        <button type="button" onClick={() => setShowModal(!showModal)}>
          {' '}
          Política de Privacidade
        </button>
      </section>
    </Container>
  );
};

export default Footer;
