/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-this-alias */
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import postscribe from 'postscribe';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { border } from 'polished';
import InputMask from './InputMask';
import InputPayment from '../InputPayment';
import { Container } from './styled';
import Button from '../../../components/Button';
import Input from './Input';
import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationErrors';

interface FormDataMP {
  [index: string]: Data;
}

const initialState = [
  {
    value: '',
    name: 'paymentType',
    labelName: 'Forma de pagamento',
    isSensible: false,
    id: 'paymentType',
    type: 'select',
    dataCheckout: 'paymentType',
    options: [
      { id: 'Cartao', text: 'Cartão de crédito' },
      { id: 'Boleto', text: 'Boleto' },
    ],
  },

  {
    value: '',
    name: 'email',
    labelName: 'Email',
    isSensible: false,
    id: 'email',
    type: 'email',
    placeholder: 'Digite seu e-mail',
  },
  {
    value: '',
    name: 'docType',
    labelName: 'Tipo de documento',
    isSensible: false,
    id: 'docType',
    type: 'select',
    dataCheckout: 'docType',
    options: [{ id: 'none', text: 'Selecione um documento' }],
  },
  {
    value: '',
    name: 'docNumber',
    labelName: 'Número do documento',
    isSensible: false,
    id: 'docNumber',
    type: 'text',
    dataCheckout: 'docNumber',
    placeholder: 'Número do documento',
  },
  {
    value: '',
    labelName: 'Número do cartão',
    isSensible: true,
    id: 'cardNumber',
    type: 'text',
    dataCheckout: 'cardNumber',
    placeholder: 'Número do cartão',
  },
  {
    value: '',
    labelName: 'Vencimento - mês',
    isSensible: true,
    id: 'cardExpirationMonth',
    type: 'text',
    dataCheckout: 'cardExpirationMonth',
    placeholder: 'Mês',
  },
  {
    value: '',
    labelName: 'Vencimento - ano',
    isSensible: true,
    id: 'cardExpirationYear',
    type: 'text',
    dataCheckout: 'cardExpirationYear',
    placeholder: 'Ano',
  },

  {
    value: '',
    isSensible: false,
    labelName: 'Titular do cartão',
    id: 'cardholderName',
    dataCheckout: 'cardholderName',
    type: 'text',
    placeholder: 'Nome completo',
  },
  {
    value: '',
    labelName: 'Código de segurança',
    isSensible: true,
    id: 'securityCode',
    type: 'text',
    dataCheckout: 'securityCode',
    placeholder: 'CVV',
  },
  {
    value: '',
    labelName: 'Banco emissor',
    isSensible: true,
    id: 'issuer',
    type: 'hidden',
    dataCheckout: 'issuer',
  },
  {
    value: '',
    name: 'installments',
    labelName: 'Parcelas',
    isSensible: false,
    id: 'installments',
    type: 'hidden',
  },
  {
    value: '80',
    name: 'transactionAmount',
    isSensible: false,
    id: 'transactionAmount',
    type: 'hidden',
    hidden: true,
  },
  {
    value: '',
    name: 'paymentMethodId',
    isSensible: false,
    id: 'paymentMethodId',
    type: 'hidden',
    hidden: true,
    invalidMessage: '',
  },
  {
    value: '',
    name: 'description',
    isSensible: false,
    id: 'description',
    type: 'hidden',
    hidden: true,
  },
];

const maskValues = (id: string, value: string): string => {
  // Remove everything that is not digit from formData specific fields
  switch (id) {
    case 'docNumber':
    case 'securityCode':
    case 'cardNumber':
      return value.replaceAll(/\D/g, '');
    case 'cardExpirationYear':
    case 'cardExpirationMonth':
      return value.replaceAll(/\D/g, '').substr(0, 2);
    default:
      return value;
  }
};

const FormDataUser: React.FC = ({ dataForm }: any) => {
  const checkoutRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { paymentReturn, emailPayment, doc, meioPagamento, product } = dataForm;
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');

  const handleBuy = async (data: any): void => {
    try {
      checkoutRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Preenchimento obrigatório'),
        sobrenome: Yup.string().required('Preenchimento obrigatório'),
        phone: Yup.string().required('Preenchimento obrigatório'),
        company: Yup.string().required('Preenchimento obrigatório'),
        cidade: Yup.string().required('Preenchimento obrigatório'),
        bairro: Yup.string().required('Preenchimento obrigatório'),
        endereco: Yup.string().required('Preenchimento obrigatório'),
        uf: Yup.string().required('Preenchimento obrigatório'),
        password: Yup.string()
          .required('Senha obrigatória')
          .min(6, 'Mínimo de 6 dígitos'),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'A senha digitada é diferente.',
        ),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      setLoading(true);

    if(meioPagamento=='Boleto'){
      
     try {
       
        const response =   await api.post('/api/v1/payments/payment', {
              card_token_id: '',
              payer_email: emailPayment,
              cpf_cnpj: doc,
              produto: product,
              empresa: data.company,
              indicado_por: data.indicated,
              nome_first: data.name,
              nome_last: data.sobrenome,
              senha: data.password,
              telefone: data.phone,
              tipo_pagamento: 'boleto',
              endereco,
              cep: data.cep,
              bairro,
              cidade,
              uf,
              conta_juridica:
                doc.length > 11,
            });
          

        } catch (err) {
            console.log(err);
        } 
      }
      else{
        await api.post('/api/v1/payments/payment', {
            card_token_id: paymentReturn.id,
            payer_email: emailPayment,
            cpf_cnpj: paymentReturn.cardholder.identification.number,
            produto: product,
            empresa: data.company,
            indicado_por: data.indicated,
            nome_first: data.name,
            nome_last: data.sobrenome,
            senha: data.password,
            telefone: data.phone,
            tipo_pagamento: 'cartao',
            endereco,
            cep: data.cep,
            bairro,
            cidade,
            uf,
            conta_juridica:
              paymentReturn.cardholder.identification.number.length > 11,
          });
      }
      toast.success(
        'Bem vindo! Conta criada com sucesso. Notificação enviada por email.',
      );
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        checkoutRef.current?.setErrors(errors);
        console.log(errors);
        return;
      }

      setLoading(false);
      toast.error('Ocorreu um erro ao realizar a compra');
    }
  };

  return (
    <Form ref={checkoutRef} onSubmit={handleBuy}>
      <div style={{ flex: 1 }}>
        <article>
          <div id="float-label">
            <Input name="name" id="name" title="Nome" />
          </div>

          <div id="float-label">
            <Input name="sobrenome" id="sobrenome" title="Sobrenome" />
          </div>

          <div id="float-label">
            <Input name="phone" type="number" title="Telefone" />
          </div>

          <div id="float-label">
            <Input name="company" title="Empresa" />
          </div>

          <div id="float-label">
            <Input name="indicated" title="Indicado por" />
          </div>

          <div id="float-label">
            <Input title="Senha" name="password" type="password" />
          </div>

          <div id="float-label">
            <Input
              name="confirmPassword"
              type="password"
              title="Confirme sua senha"
            />
          </div>

          <div id="float-label">
            <InputMask
              name="cep"
              mask="99999-999"
              title="Cep"
              onBlur={async (e) => {
                if (e.target.value.length === 9) {
                  const response = await axios.get(
                    `https://viacep.com.br/ws/${e.target.value}/json/`,
                  );
                  setEndereco(response.data.logradouro);
                  setCidade(response.data.localidade);
                  setUf(response.data.uf);
                  setBairro(response.data.bairro);

                  if (response.data.erro) {
                    toast.error('CEP inválido.');
                    setEndereco('');
                    setCidade('');
                    setUf('');
                    setBairro('');
                  }
                }
              }}
            />
          </div>

          <div id="float-label">
            <Input
              title="Endereço"
              name="endereco"
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
            />
          </div>

          <div id="float-label">
            <Input
              title="Bairro"
              name="bairro"
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
            />
          </div>

          <div id="float-label">
            <Input
              title="Cidade"
              name="cidade"
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
            />
          </div>

          <div id="float-label">
            <Input
              title="UF"
              name="uf"
              value={uf}
              onChange={(e) => setUf(e.target.value)}
            />
          </div>
        </article>
      </div>

      <Button type="submit" loading={loading}>
        Comprar
      </Button>
    </Form>
  );
};

const FormPayment: React.FC = ({ product }: any) => {
  const [formData, setFormData] = useState<any>(initialState);
  const [loading, setLoading] = useState(false);
  const [changedForm, setChangedForm] = useState(false);
  const [step, setStep] = useState(false);
  const [cardData, setCardData] = useState('');
  const [emailPayment, setEmailPayment] = useState('');
  const [meioPagamento, setMeioPagamento] = useState('');
  const [doc,setDoc] = useState('');


  const updateStateFormData = (key, field, value): void => {
    const newFormState = [...formData];
    newFormState[key][field] = value;
    setFormData(newFormState);
  };

  const findIndexKeyById = (id): any => {
    return formData.map((data) => data.id).indexOf(id);
  };

  const cleanInvalidMessage = (field): void => {
    let key = findIndexKeyById(field);
    updateStateFormData(key, 'invalidMessage', '');
  };

  const setInstallments = (status, response): void => {
    let key = findIndexKeyById('installments');
    if (status === 200) {
      cleanInvalidMessage('installments'); // clean error message
      let options = response[0].payer_costs.map((installments) => ({
        text: installments.recommended_message,
        value: installments.installments,
      }));

      updateStateFormData(key, 'options', options);
      updateStateFormData(
        key,
        'value',
        String(response[0].payer_costs[0].installments),
      );
    } else {
      updateStateFormData(key, 'invalidMessage', response.message);
    }
  };

  const getInstallments = (
    paymentMethodId,
    transactionAmount,
    issuerId,
  ): void => {
    window.Mercadopago.getInstallments(
      {
        payment_method_id: paymentMethodId,
        amount: parseFloat(transactionAmount),
        // eslint-disable-next-line radix
        issuer_id: parseInt(issuerId),
      },
      setInstallments.bind(this),
    );
  };

  const setIssuers = (status, response): void => {
    let key = findIndexKeyById('issuer');
    if (status === 200) {
      cleanInvalidMessage('issuer'); // clean error message
      let options = response.map((issuer) => ({
        text: issuer.name,
        value: issuer.id,
      }));
      updateStateFormData(key, 'options', options);
      updateStateFormData(key, 'value', String(response[0].id));

      let auxPaymentMethodId;
      let auxTransactionAmount;
      formData.map((val) => {
        if (val.id === 'paymentMethodId') auxPaymentMethodId = val.value;
        if (val.id === 'transactionAmount') auxTransactionAmount = val.value;
        return null;
      });

      getInstallments(
        auxPaymentMethodId,
        auxTransactionAmount,
        formData[key].value,
      );
    } else {
      updateStateFormData(key, 'invalidMessage', response.message);
    }
  };

  const getIssuers = (paymentMethodId): void => {
    window.Mercadopago.getIssuers(paymentMethodId, setIssuers.bind(this));
  };

  const setPaymentMethod = (status, response): void => {
    let key = findIndexKeyById('paymentMethodId');
    if (status === 200) {
      let paymentMethod = response[0];
      // 10 is the paymentmethod object in array it is hardcoded for now, but it needs to be changed to
      // an more elegant solution
      updateStateFormData(key, 'value', paymentMethod.id);
      getIssuers(paymentMethod.id);
    } else {
      // Something went wrong, the problem is from the card number
      key = findIndexKeyById('cardNumber');
      updateStateFormData(key, 'invalidMessage', 'Cartão inválido');
    }
  };

  const onSetCardTokenErrorHandler = (resp): void => {
    let key;
    if (resp.cause && resp.cause.length > 0) {
      resp.cause.forEach((cause) => {
        switch (cause.code) {
          case 'E301':
            key = findIndexKeyById('cardNumber');
            updateStateFormData(key, 'invalidMessage', 'Cartão inválido');
            break;
          case '324':
            key = findIndexKeyById('docNumber');
            updateStateFormData(key, 'invalidMessage', 'Dados inválidos');
            break;
          default:
            break;
        }
      });
    }
  };

  const setCardToken = (status, response): void => {
    console.log(status);
    console.log('response', response);
    if (status === 200 || status === 201 || status === 202) {
      setCardData(response);
      setStep(true);
      setLoading(false);
    } else {
      onSetCardTokenErrorHandler(response);
      toast.error('Erro ao realizar a transação.');
    }
  };

  const onSubmitHandler = (e): void => {
    e.preventDefault();
    let form = document.getElementById('paymentForm');
    let email = document.getElementById('email')?.value;
    let el = form.elements;
    el.docNumber.value = el.docNumber.value.replace(/[^\w\s]/gi, '');
    let tipo_pagamento = document.getElementById('paymentType')?.value;
    setMeioPagamento(tipo_pagamento);
    setEmailPayment(email);
    setDoc(el.docNumber.value);
    
    if (changedForm && tipo_pagamento !== 'Boleto') {
      setLoading(true);
      window.Mercadopago.createToken(form, setCardToken.bind(this));
      setLoading(false);
    } else {
      setStep(true);
    }
  };

  const setReadyDocTypes = (): any => {
    // Helper: catch calls from MercadoPago, and identify update data from doc types
    let proxied = window.XMLHttpRequest.prototype.send;
    window.XMLHttpRequest.prototype.send = function () {
      // Proxy the call
      let pointer = this;
      // eslint-disable-next-line func-names
      var intervalId = window.setInterval(function () {
        let urlLikeLookingFor =
          'https://api.mercadopago.com/v1/identification_types';
        if (pointer.readyState !== 4) {
          return; // wait until be ready
        }
        if (pointer.responseURL.substr(0, 51) === urlLikeLookingFor) {
          // The response is from the docTypes "get"
          const parsedResponse = JSON.parse(pointer.response);
          let options = parsedResponse.map((docType) => ({
            text: docType.name,
            value: docType.id,
          }));

          let key = findIndexKeyById('docType');
          updateStateFormData(key, 'value', options[0].value);
        }

        clearInterval(intervalId);
      }, 1);
      return proxied.apply(this, [].slice.call(arguments));
    };
    window.Mercadopago.getIdentificationTypes();
  };

  useEffect(() => {
    setChangedForm(true);
    postscribe(
      '#root',
      '<script id="mercadoPagoScript" src="https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js"></script><script src="https://www.mercadopago.com/v2/security.js" view="home"></script>',
    );

    const mercadoPagoScript = document.getElementById('mercadoPagoScript');
    // @ts-ignore
    mercadoPagoScript.addEventListener('load', () => {
      window.Mercadopago.setPublishableKey(
        'APP_USR-1675ab08-ebee-4e34-9752-59d8381bd33b',
      );
      setReadyDocTypes();
    });
  }, [product]);

  const onChangeHandler = (key, e): void => {
    let eventValue = e.target.value;
    let value = maskValues(formData[key].id, eventValue); // Masked Value
    if (e.target.value === 'Boleto') {
      formData[4].type = 'hidden';
      formData[5].type = 'hidden';
      formData[6].type = 'hidden';
      formData[7].type = 'hidden';
      formData[8].type = 'hidden';
    }
    if (e.target.value === 'Cartão de crédito') {
      formData[4].type = 'text';
      formData[5].type = 'text';
      formData[6].type = 'text';
      formData[8].type = 'text';
    }
    if (
      formData[key].dataCheckout &&
      formData[key].dataCheckout === 'cardNumber'
    ) {
      if (value.length >= 6) {
        let bin = value.substring(0, 6);
        window.Mercadopago.getPaymentMethod(
          {
            bin,
          },
          setPaymentMethod,
        );
      }
    }
    cleanInvalidMessage(formData[key].id);
    setChangedForm(true);
    updateStateFormData(key, 'value', value);
  };

  const [mask, setMask] = useState('999.999.999-99');

  return (
    <Container>
      {step ? (
        <FormDataUser
          dataForm={{ paymentReturn: cardData, emailPayment,doc, meioPagamento, product }}
        />
      ) : (
        <form id="paymentForm">
          <span>Área de assinatura</span>

          <div style={{ flex: 1 }}>
            <article>
              {formData.map((inp, index) => (
                <InputPayment
                  {...inp}
                  mask={inp.name === 'docNumber' ? mask : ''}
                  index={index}
                  key={index}
                  getValueElement={(value) => {
                    if (value === 'CPF') {
                      setMask('999.999.999-99');
                    } else if (value === 'CNPJ') {
                      setMask('99.999.999/9999-99');
                    }
                  }}
                  onChange={(i, e) => onChangeHandler(i, e)}
                />
              ))}
            </article>
            <Button loading={loading} onClick={onSubmitHandler}>
              comprar
            </Button>
          </div>
        </form>
      )}
    </Container>
  );
};

export default FormPayment;
