import React from 'react';
import formatMoney from '../../../../../utils/formatMoney';

interface IParamDespesasProps {
  despesas_fixas: {
    Rateio_administrativo: number;
    beneficios_motoristas: number;
    deprec_semireboque: number;
    deprec_veiculo: number;
    desoneracao_folha: number;
    diarias_motoristas: number;
    encargos_sociais_trabalhistas_fixas: number;
    gerenciamento_riscos: number;
    impostos_despesas_fixas: number;
    ipva_dpvat: number;
    lucro_despesas_fixas: number;
    premios_variaveis: number;
    remuneracao_capital: number;
    salario_motorista: number;
    seguranca: number;
    seguro_carga_ambiental: number;
    seguro_do_casco: number;
    tempo_espera: number;
    total_despesas_fixas: number;
  };
}

const DespesasFixas: React.FC<IParamDespesasProps> = ({ despesas_fixas }) => {
  return (
    <>
      <h2>Despesas Fixas</h2>
      <section>
        <h5>Rateio das despesas administrativas</h5>
        <p>{formatMoney(despesas_fixas.Rateio_administrativo)}</p>
      </section>
      <section>
        <h5>Remuneração do motorista</h5>
        <p>{formatMoney(despesas_fixas.salario_motorista)}</p>
      </section>
      <section>
        <h5>Prêmios variáveis</h5>
        <p>{formatMoney(despesas_fixas.premios_variaveis)}</p>
      </section>
      <section>
        <h5>Tempo de espera</h5>
        <p>{formatMoney(despesas_fixas.tempo_espera)}</p>
      </section>
      <section>
        <h5>Diárias e pernoites</h5>
        <p>{formatMoney(despesas_fixas.diarias_motoristas)}</p>
      </section>
      <section>
        <h5>Benefícios do motorista</h5>
        <p>{formatMoney(despesas_fixas.beneficios_motoristas)}</p>
      </section>
      <section>
        <h5>Encargos sociais e trabalhistas</h5>
        <p>{formatMoney(despesas_fixas.encargos_sociais_trabalhistas_fixas)}</p>
      </section>
      <section>
        <h5>Desoneração da Folha de Pgto</h5>
        <p>{formatMoney(despesas_fixas.desoneracao_folha)}</p>
      </section>
      <section>
        <h5>Depreciação do veículo</h5>
        <p>{formatMoney(despesas_fixas.deprec_veiculo)}</p>
      </section>
      <section>
        <h5>Depreciação do semi-reboque</h5>
        <p>{formatMoney(despesas_fixas.deprec_semireboque)}</p>
      </section>
      <section>
        <h5>Gerenciamento de riscos</h5>
        <p>{formatMoney(despesas_fixas.gerenciamento_riscos)}</p>
      </section>
      <section>
        <h5>Seguro do casco</h5>
        <p>{formatMoney(despesas_fixas.seguro_do_casco)}</p>
      </section>
      <section>
        <h5>Seguro de carga e ambiental</h5>
        <p>{formatMoney(despesas_fixas.seguro_carga_ambiental)}</p>
      </section>
      <section>
        <h5>Documentação (IPVA/DPVAT/Licenciamento)</h5>
        <p>{formatMoney(despesas_fixas.ipva_dpvat)}</p>
      </section>
      <section>
        <h5>Remuneração de capital</h5>
        <p>{formatMoney(despesas_fixas.remuneracao_capital)}</p>
      </section>
      <section>
        <h5>Segurança</h5>
        <p>{formatMoney(despesas_fixas.seguranca)}</p>
      </section>
      <section>
        <h5>Impostos federais sobre despesas fixas</h5>
        <p>{formatMoney(despesas_fixas.impostos_despesas_fixas)}</p>
      </section>
      <section>
        <h5>Lucro sobre despesas fixas</h5>
        <p>{formatMoney(despesas_fixas.lucro_despesas_fixas)}</p>
      </section>
     
      <section>
        <h5>Total das despesas fixas</h5>
        <p>{formatMoney(despesas_fixas.total_despesas_fixas)}</p>
      </section>
    </>
  );
};

export default DespesasFixas;
