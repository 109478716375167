import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Async from 'react-select/async';
import debounce from 'debounce-promise';
import { Container } from './styles';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { useTimeline } from '../../../hooks/timeline';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useTabs } from '../../../hooks/tabs';
import getValidationErrors from '../../../utils/getValidationErrors';
import Select from '../../../components/Select';
import formatMoney from '../../../utils/formatMoney';

interface ReturnCalc {
  cod: number;
  veiculo: string;
  total_vl_km: number;
  obs: string;
}

const AdiconarRentabilidade: React.FC = () => {
  const formAddRef = useRef<FormHandles>(null);
  const [calculo, setCalculo] = useState<ReturnCalc[]>([]);
  const [custo_km, setCustoKm] = useState('');
  const [origem, setOrigem] = useState({ value: '', label: '' });
  const [destino, setDestino] = useState({ value: '', label: '' });
  const { next } = useTimeline();
  const { handleTemp } = useTabs();
  const { user } = useAuth();
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const { id, conta } = user;

  const list = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get(
        `api/v1/tabelas/calculos?user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      const formatSelectCompanies = response.data.data
        .filter((calc: ReturnCalc) => calc.total_vl_km > 0)
        .map((calc: ReturnCalc) => ({
          label: `${calc.veiculo} - ${calc.obs}`,
          value: { cod: calc.cod, total_vl_km: calc.total_vl_km },
        }));

      setCalculo(formatSelectCompanies);
    } catch (err) {
      toast.error('Erro ao listar usuários.');
    }
  }, [conta, id, token]);

  useEffect(() => {
    list();
  }, [list]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        formAddRef.current?.setErrors({});
        const schema = Yup.object().shape({
          calculo: Yup.object().shape({
            cod: Yup.number(),
            total_vl_km: Yup.number(),
          }),
          origem: Yup.string(),
          destino: Yup.string(),
          descricao: Yup.string(),
          custo_km: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await schema.validate(origem, {
          abortEarly: false,
        });
        await schema.validate(destino, {
          abortEarly: false,
        });
        const response = await api.post(
          `/api/v1/tabelas/rentabilidade`,
          {
            user_request: id,
            conta,
            calculo: data.calculo.cod,
            descricao: data.descricao,
            custo_km: data.calculo.total_vl_km,
            cliente: '',
            origem: origem.value,
            destino: destino.value,
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        // console.log(response.data.data);
        handleTemp(response.data.data);
        next();
        list();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formAddRef.current?.setErrors(errors);
          return;
        }

        toast.error(
          'Ocorreu um erro ao atualizar este usuário, cheque as crendênciais.',
        );
      }
    },
    [conta, id, list, token, handleTemp, next, origem, destino],
  );

  const loadSuggestions = async (
    inputValue: string,
    callback: any,
  ): Promise<any> => {
    try {
      const request = await api.get(
        `api/v1/utilities/cidades/?user_request=${id}&conta=${conta}&cidade=${inputValue}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      const returnData = request.data;
      const suggestions = returnData.data.map((sug: any) => {
        return {
          value: sug.cidade,
          label: sug.cidade,
        };
      });
      return suggestions;
    } catch (error) {
      console.log(error);
    }
  };

  const loadOptions = (inputValue: string, callback: any): any =>
    loadSuggestions(inputValue, callback);

  const debouncedLoadOptions = debounce(loadOptions, 2000, {
    leading: true,
  });

  return (
    <Container ref={formAddRef} onSubmit={handleAddSubmit}>
      <Async
        loadOptions={debouncedLoadOptions}
        cacheOptions
        noOptionsMessage={() => 'Nenhuma opção encontrada'}
        placeholder="Origem"
        onChange={(value: any) => {
          setOrigem(value);
        }}
        className="select"
      />
      <Async
        loadOptions={debouncedLoadOptions}
        cacheOptions
        noOptionsMessage={() => 'Nenhuma opção encontrada'}
        placeholder="Destino"
        onChange={(value: any) => {
          setDestino(value);
        }}
        className="select"
      />
      <Select
        name="calculo"
        options={calculo}
        placeholder="Cálculo"
        isClearable={false}
        onChange={(value: any) => {
          setCustoKm(value.value.total_vl_km);
        }}
      />
      <Input
        name="custo_km"
        type="text"
        placeholder="Custo KM"
        readOnly
        value={formatMoney(custo_km)}
      />
      <Input name="descricao" placeholder="Descrição" />
      <Button type="submit">Iniciar cálculo</Button>
    </Container>
  );
};

export default AdiconarRentabilidade;
