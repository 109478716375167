import styled, { css } from 'styled-components';

interface DropdownProps {
  isVisible: boolean | string;
}

export const Container = styled.div`
  background: transparent;
  position: relative;
  border: 0;
  display: flex;
  align-items: center;
  z-index: 9999;

  > svg {
    color: var(--primary);
  }
  margin-left: 16px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -5px;
    right: -7px;

    background: var(--yellow);
    border-radius: 50%;
    min-width: 15px;
    min-height: 15px;
    color: var(--primary);
    font-size: 10px;
  }
`;

export const Box = styled.div<DropdownProps>`
  display: block;
  width: 500px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  max-height: 400px;

  @media screen and (max-width: 500px) {
    width: 100%;
    position: fixed;
    top: 30px;
    right: 0;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ccc;
  }

  ${(props) =>
    props.isVisible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;
