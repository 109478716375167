import styled from 'styled-components';

import banner from '../../assets/0-2-index/banner-celular-maxi-frete.png';
import bannertablet from '../../assets/0-2-index/banner-tablet-maxi-frete.png';
import bannermanual from '../../assets/0-2-index/banner-manual-do-transportador-celular-maxi-frete.png';
import bannermanualtablet from '../../assets/0-2-index/banner-manual-do-transportador-tablet-maxi-frete.png';
import bannermaxifrete from '../../assets/0-2-index/banner-maxi-frete.png';
import manualtransp from '../../assets/0-2-index/banner-manual-do-transportador-maxi-frete.png';

export const Container = styled.main`
  .header {
    min-height: 300px;
  }

  .textModalContent {
    border: 1px solid #e6e6e6 !important;

    &:hover {
      border: 1px solid #15178f !important;
    }

    button {
      border: 0;
      padding: 0.8rem 0.8rem;
      font-size: 18px;
      border-radius: 5px;
      color: #fff;
      background: transparent;
      color: #000;
      text-align: center;
      margin: auto;
      width: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  caption {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  select {
    -webkit-appearance: menulist;
    appearance: menulist;
    -moz-appearance: menulist;
  }

  input,
  textarea {
    border-style: solid;
    border-width: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
    background: var(--white-b);
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption,
  th,
  td {
    text-align: left;
    font-weight: normal;
  }

  .liquidClearFix {
    clear: both;
  }

  .clear {
    clear: both;
  }

  div,
  ul,
  li,
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    zoom: 1;
  }

  div:before,
  ul:before,
  article:before,
  aside:before,
  details:before,
  figcaption:before,
  figure:before,
  footer:before,
  header:before,
  hgroup:before,
  menu:before,
  nav:before,
  section:before,
  div:after,
  ul:after,
  article:after,
  aside:after,
  details:after,
  figcaption:after,
  figure:after,
  footer:after,
  header:after,
  hgroup:after,
  menu:after,
  nav:after,
  section:after {
    content: '';
    display: table;
  }

  div:after,
  ul:after,
  article:after,
  aside:after,
  details:after,
  figcaption:after,
  figure:after,
  footer:after,
  header:after,
  hgroup:after,
  menu:after,
  nav:after,
  section:after {
    clear: both;
  }

  .liquid div:after,
  .liquid ul:after,
  .liquid article:after,
  .liquid aside:after,
  .liquid details:after,
  .liquid figcaption:after,
  .liquid figure:after,
  .liquid footer:after,
  .liquid header:after,
  .liquid hgroup:after,
  .liquid menu:after,
  .liquid nav:after,
  .liquid section:after {
    clear: none;
  }

  .cf {
    zoom: 1;
  }

  .cf:before,
  .cf:after {
    content: '';
    display: table;
  }

  .cf:after {
    clear: both;
  }

  .cfliquidrow {
    zoom: 1;
  }

  .cfliquidrow:before,
  .cfliquidrow:after {
    content: '';
    display: table;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  h2 {
    font-size: 1.5em;
    margin: 0.75em 0;
  }

  h3 {
    font-size: 1.17em;
    margin: 0.83em 0;
  }

  h4 {
    font-size: 1em;
    margin: 1.5em 0;
  }

  h4,
  p,
  blockquote,
  ul,
  fieldset,
  form,
  ol,
  dl,
  dir,
  menu {
    margin: 1.12em 0;
  }

  ol,
  ul,
  dir,
  menu,
  dd {
    padding-left: 40px;
  }

  ol ul,
  ul ol,
  ul ul,
  ol ol {
    margin-top: 0;
    margin-bottom: 0;
  }

  h5 {
    font-size: 0.83em;
    margin: 1.5em 0;
  }

  h6 {
    font-size: 0.67em;
    margin: 1.67em 0;
  }

  p {
    font-size: 1em;
  }

  span {
    font-family: inherit;
  }

  img {
    vertical-align: top;
  }
  hr {
    display: inline-block;
    width: 80%;
  }

  [class^='fm_css_'],
  [class^='fm_css_'] *,
  [class^='FM2_'],
  [class^='FM2_'] *,
  [class*='_tooltip_in'],
  [class*='_tooltip_in'] *,
  [id*='ContainerJS'],
  [id*='ContainerJS'] *,
  .jqlightbox,
  .jqlightbox *,
  .jQGalleryC,
  .jQGalleryC *,
  .DWMasonryGallery_container,
  .DWMasonryGallery_container *,
  .jQuerySlideshow,
  .jQuerySlideshow *,
  .jqslider,
  .jqslider *,
  .fb-outer-wrapper,
  .fb-outer-wrapper *,
  .PowerSlider2,
  .PowerSlider2 *,
  .DWCarousel_container,
  .DWCarousel_container *,
  .content-box-sizing,
  .content-box-sizing * {
    box-sizing: content-box !important;
  }

  .row_6 {
    clear: both;
    min-height: 100px;
  }
  .align-inline-wrap1 {
    text-align: center;
    top: 0px;
  }
  .row_1 {
    clear: both;
    min-height: 100px;
    width: 100%;
    top: 0px;
    background: var(--white-b);
    background: url(${banner}) center top no-repeat scroll transparent;
  }
  .row_8 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .column_3 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_10 {
    clear: both;
    min-height: 100px;
    margin-bottom: 30px;
  }
  .row_12 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_15 {
    clear: both;
    min-height: 0px;
  }
  .row_13 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_16 {
    clear: both;
    min-height: 100px;
  }
  .row_14 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_17 {
    clear: both;
    min-height: 100px;
  }
  .column_5 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_19 {
    clear: both;
    min-height: 0px;
    background-color: #ffb300;
    width: 50%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .row_19:hover {
    background-color: #ffffff;
  }
  .column_6 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_20 {
    clear: both;
    min-height: 5px;
  }
  .row_18 {
    clear: both;
    width: auto;
  }
  .column_4 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_11 {
    clear: both;
    min-height: 100px;
  }
  .customClass1 {
    padding: 20px;
  }
  .row_9 {
    clear: both;
    width: auto;
  }
  .row_2 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .column_1 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_4 {
    clear: both;
    min-height: 100px;
  }
  .column_2 {
    float: none;
    width: auto;
    min-height: 70px;
  }
  .row_5 {
    clear: both;
    min-height: 70px;
    padding-top: 10px;

    display: flex;
    align-items: center;
    justify-content: right;

    > div {
      margin-left: auto;
    }
  }
  .row_3 {
    clear: both;
    width: auto;
  }
  .page-content {
    float: none;
    width: 100%;
  }
  .row_25 {
    clear: both;
    min-height: 100px;
  }
  .row_26 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .column_7 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_28 {
    clear: both;
    min-height: 100px;
  }
  .row_31 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_40 {
    clear: both;
    min-height: 0px;
  }
  .row_32 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_41 {
    clear: both;
    min-height: 100px;
  }
  .row_33 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_42 {
    clear: both;
    min-height: 0px;
  }
  .column_8 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_29 {
    clear: both;
    min-height: 100px;
  }
  .row_34 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_43 {
    clear: both;
    min-height: 0px;
  }
  .row_35 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_44 {
    clear: both;
    min-height: 100px;
  }
  .row_36 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_45 {
    clear: both;
    min-height: 0px;
  }
  .column_9 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_30 {
    clear: both;
    min-height: 100px;
  }
  .row_37 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_46 {
    clear: both;
    min-height: 0px;
  }
  .row_38 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_47 {
    clear: both;
    min-height: 100px;
  }
  .row_39 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_48 {
    clear: both;
    min-height: 0px;
  }
  .row_27 {
    clear: both;
    width: auto;
  }
  .row_23 {
    clear: both;
    min-height: 100px;
  }
  .row_24 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .row_109 {
    clear: both;
    min-height: 5px;
    background-color: #15178f;
  }
  .row_95 {
    clear: both;
    min-height: 100px;
    background-color: #efefef;
  }
  .row_96 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .column_13 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_98 {
    clear: both;
    min-height: 100px;
  }
  .row_100 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_103 {
    clear: both;
    min-height: 100px;
  }
  .row_101 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_104 {
    clear: both;
    min-height: 100px;
  }
  .row_102 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_105 {
    clear: both;
    min-height: 100px;
    margin-right: 20px;
  }
  .column_15 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_107 {
    clear: both;
    min-height: 0px;
  }
  .column_16 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_108 {
    clear: both;
    min-height: 0px;
    background-color: #15178f;
    border-radius: 10px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
  }
  .row_108:hover {
    background-color: #ffb300;
  }
  .row_106 {
    clear: both;
    width: auto;
  }
  .column_14 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_99 {
    clear: both;
    min-height: 100px;
  }
  .align-inline-wrap2 {
    text-align: center;
  }
  .row_97 {
    clear: both;
    width: auto;
  }
  .row_85 {
    clear: both;
    min-height: 100px;
    background: url(${bannermanual}) center top no-repeat scroll transparent;
  }
  .row_86 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .row_87 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_91 {
    clear: both;
    min-height: 100px;
  }
  .row_88 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_92 {
    clear: both;
    min-height: 0px;
  }
  .row_89 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_93 {
    clear: both;
    min-height: 0px;
  }
  .row_90 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_94 {
    clear: both;
    min-height: 0px;
  }
  .row_49 {
    clear: both;
    min-height: 100px;
  }
  .row_50 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .column_10 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_52 {
    clear: both;
    min-height: 100px;
  }
  .row_55 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_70 {
    clear: both;
    min-height: 0px;
    background-color: #15178f;
    border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
  }
  .row_56 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_71,
  .row_71-c,
  .row_71-ca,
  .row_71-cav,
  .row_71-cavi {
    clear: both;
    min-height: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #e6e6e6;
  }
  .row_71:hover,
  .row_71-c:hover {
    border-color: #15178f;
  }
  .row_57 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_72 {
    clear: both;
    min-height: 100px;
  }
  .row_58 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_73 {
    clear: both;
    min-height: 100px;
  }
  .row_59 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_74 {
    clear: both;
    min-height: 100px;
  }
  .column_11 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_53 {
    clear: both;
    min-height: 100px;
  }
  .row_60 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_75 {
    clear: both;
    min-height: 0px;
    background-color: #15178f;
    border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
  }
  .row_61 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_76 {
    clear: both;
    min-height: 100px;
  }
  .row_71-c {
    border-color: #15178f;
    border-width: 3px;
    min-height: 0px;
  }
  .row_71-c:hover {
    border-color: #ffb300;
  }
  .row_62 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_77 {
    clear: both;
    min-height: 100px;
  }
  .row_71-ca {
    border-color: #ffb300;
  }
  .row_71-ca:hover {
    border-color: #15178f;
  }
  .row_63 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_78 {
    clear: both;
    min-height: 100px;
  }
  .row_71-cav {
    border-color: #ffb300;
  }
  .row_71-cav:hover {
    border-color: #15178f;
  }
  .row_64 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_79 {
    clear: both;
    min-height: 0px;
  }
  .row_71 {
    min-height: 0px;
  }
  .row_71-cavi {
    border-color: #ffb300;
  }
  .row_71-cavi:hover {
    border-color: #15178f;
  }
  .column_12 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_54 {
    clear: both;
    min-height: 100px;
  }
  .row_65 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_80 {
    clear: both;
    min-height: 0px;
    background-color: #15178f;
    padding-left: 10px;
    border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
  }
  .row_66 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_81 {
    clear: both;
    min-height: 100px;
  }
  .row_67 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_82 {
    clear: both;
    min-height: 100px;
  }
  .row_68 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_83 {
    clear: both;
    min-height: 100px;
  }
  .row_69 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_84 {
    clear: both;
    min-height: 0px;
  }
  .row_51 {
    clear: both;
    width: auto;
  }
  .row_121 {
    clear: both;
    background: none center top no-repeat scroll rgb(200, 221, 236);
  }
  .row_122 {
    clear: both;
    min-height: 0px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .column_19 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_124 {
    clear: both;
    min-height: 100px;
  }
  .row_126 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_129 {
    clear: both;
    min-height: 100px;
  }
  .row_127 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_130 {
    clear: both;
    min-height: 100px;
  }
  .row_128 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_131 {
    clear: both;
    min-height: 0px;
    background-color: #15178f;
    width: 100%;
    border-radius: 10px;
    margin: 10px auto 20px auto;
  }
  .row_131:hover {
    background-color: #ffb300;
  }
  .column_20 {
    float: none;
    width: auto;
    min-height: 5px;
  }
  .row_125 {
    clear: both;
    min-height: 5px;
  }
  .row_123 {
    clear: both;
    width: auto;
  }
  .row_110 {
    clear: both;
    min-height: 100px;
  }
  .row_111 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .column_17 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_113 {
    clear: both;
    min-height: 100px;
  }
  .row_115 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_118 {
    clear: both;
    min-height: 100px;
  }
  .row_116 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_119 {
    clear: both;
    min-height: 100px;
  }
  .row_117 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_120 {
    clear: both;
    min-height: 100px;
  }
  .column_18 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_114 {
    clear: both;
    min-height: 100px;
  }
  .row_112 {
    clear: both;
    width: auto;
  }
  .row_contato,
  .row_area,
  .row_plano {
    clear: both;
  }
  .row_148 {
    clear: both;
    min-height: 0px;
  }
  .row_149 {
    clear: both;
    min-height: 0px;
  }
  .row_22 {
    clear: both;
    min-height: 0px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .row_7 {
    clear: both;
    min-height: 5px;
    width: 100%;
    top: 0px;
    position: static;
  }
  img.image1 {
    display: none;
    width: auto;
    min-height: 0px;
  }
  img.image2 {
    display: block;
    margin: 15px auto 0 auto;
    padding: 5px 5px 5px 0;
  }
  h1.heading1 {
    color: #ffb300;
    font-size: 24px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 700;
    margin: 15px 0 10px 0;
    text-align: center;
  }
  p.paragraph1 {
    color: #ffffff;
    margin-top: 0px;
    text-align: center;
    margin-bottom: 10px;
    font: normal normal 400 18px/120% Trebuchet MS, sans-serif;
  }
  img.image3 {
    height: auto;
    width: auto;
    display: none;
  }
  img.image4 {
    height: auto;
    width: auto;
  }
  p.paragraph2 {
    color: #ffffff;
    margin: 10px;
    text-align: center;
    font: 400 18px/130% Trebuchet MS, sans-serif;
  }
  p.paragraph3 {
    color: #ffffff;
    text-align: center;
    margin: 10px;
    font: 700 18px/130% Trebuchet MS, sans-serif;
  }
  p.paragraph44 {
    color: #ffb300;
    text-align: center;
    margin: 0px;
    font-size:8px;
    vertical-align: middle;

    font: 700 18px/130% Trebuchet MS, sans-serif;
  }
  h2.heading2 {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    color: #15178f;
  }
  p.paragraph4 {
    font-weight: normal;
    font-family: Trebuchet MS, sans-serif;
    color: #4b4b4b;
    text-align: center;
  }
  span.span1 {
    font-weight: bold;
  }
  h3.heading3 {
    text-align: center;
    color: #15178f;
    font-size: 24px;
    margin: 10px;
  }
  img.image5 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 10px;
  }
  img.image6 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 10px;
  }
  img.image7 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 10px;
  }
  p.paragraph5 {
    color: #4b4b4b;
    font-size: 18px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    text-align: center;
    margin: 10px;
  }
  h3.heading4 {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 700;
  }
  p.paragraph44:hover {
    color: #ffz300;
  }
  
  p.paragraph6 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    color: #4b4b4b;
    font: 400 18px/130% Trebuchet MS, sans-serif;
  }
  h2.heading5 {
    color: #ffb300;
    text-align: center;
    margin-bottom: 10px;
    font: normal normal 700 40px/100% Montserrat, sans-serif;
    margin-top: 50px;
  }
  h3.heading6 {
    text-align: center;
    margin-top: 0px;
    font: normal normal 400 26px/100% Montserrat, sans-serif;
    color: #ffffff;
  }
  p.paragraph7 {
    text-align: center;
    font: 400 18px/100% Trebuchet MS, sans-serif;
    margin-bottom: 10px;
    color: #ffffff;
  }
  p.paragraph8 {
    text-align: center;
    color: #ffb300;
    margin-top: 10px;
    font: 700 20px/100% Trebuchet MS, sans-serif;
    margin-bottom: 50px;
  }
  h3.heading7 {
    font-size: 24px;
    color: #15178f;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 700;
    text-align: center;
  }
  p.paragraph9 {
    color: #4b4b4b;
    margin-right: 20px;
    font: 400 18px/150% Trebuchet MS, sans-serif;
    text-align: center;
  }
  p.paragraph10 {
    color: #4b4b4b;
    margin-top: 10px;
    margin-bottom: 10px;
    font: 400 18px/130% Trebuchet MS, sans-serif;
    text-align: center;
  }
  p.paragraph11 {
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 10px;
    font: 400 18px/130% Trebuchet MS, sans-serif;
  }
  span.span2 {
    font-weight: bold;
  }
  span.span3 {
    text-decoration: line-through;
  }
  img.image8 {
    height: auto;
    width: auto;
    display: inline-block;
    margin: 40px 0;
  }
  p.paragraph12 {
    color: #4b4b4b;
    font: 400 18px/130% Trebuchet MS, sans-serif;
    margin-bottom: 10px;
    text-align: center;
  }
  p.paragraph13 {
    color: #15178f;
    margin: 10px 0;
    font: normal normal 700 18px/130% Trebuchet MS, sans-serif;
    text-align: center;
  }
  p.paragraph14 {
    color: #15178f;
    margin: 10px 0 10px 10px;
    font: normal normal 700 18px/130% Trebuchet MS, sans-serif;
    text-align: center;
  }
  img.image9 {
    margin: 0 auto;
    padding: 5px;
    display: block;
  }
  img.image10 {
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  img.image11 {
    height: auto;
    display: block;
    margin: 60px auto 30px auto;
  }
  p.paragraph15 {
    color: #4b4b4b;
    font-size: 18px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0px;
  }
  p.paragraph16 {
    font-size: 18px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #ffffff;
    text-align: center;
  }
  p.paragraph16:hover {
    color: #15178f;
  }
  p.paragraph11:hover {
    color: #15178f;
  }
  .align-inline-wrap4 {
    text-align: center;
  }
  p.paragraph20 {
    font-weight: 400;
    font-family: Trebuchet MS, sans-serif;
    color: #9c9c9c;
    font-size: 18px;
    margin-bottom: 5px;
    text-align: center;
  }
  p.paragraph21 {
    margin: 10px;
    text-align: center;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    color: #4b4b4b;
    font-size: 18px;
  }
  @media only screen and (min-width: 768px) {
    .row_6 {
      clear: both;
      min-height: 100px;
    }
    .align-inline-wrap1 {
      width: 100%;
    }

    .row_1 {
      clear: both;
      min-height: 100px;
      background-image: url(${bannertablet});
      background-size: auto;
    }
    .row_8 {
      clear: both;
      min-height: 100px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
    }
    .column_3 {
      float: left;
      width: 100%;
      min-height: 100px;
    }
    .row_10 {
      clear: both;
      min-height: 100px;
      margin-bottom: 0;
    }
    .row_12 {
      min-height: 33px;
    }
    .row_15 {
      clear: both;
      min-height: 33px;
    }
    .row_13 {
      min-height: 33px;
    }
    .row_16 {
      clear: both;
      min-height: 33px;
    }
    .row_14 {
      min-height: 33px;
      margin-bottom: 40px;
    }
    .row_17 {
      clear: both;
      min-height: 33px;
    }
    .column_5 {
      float: none;
      width: 23%;
      min-height: 33px;
      margin-left: auto;
      margin-right: auto;
    }
    .row_19 {
      clear: both;
      min-height: 33px;
      width: 100%;
      right: auto;
      left: auto;
      margin-right: 0;
    }
    .column_6 {
      float: left;
      width: 100%;
      min-height: 33px;
    }
    .row_20 {
      clear: both;
      min-height: 33px;
    }
    .row_18 {
      clear: both;
      width: auto;
    }
    .column_4 {
      float: left;
      width: 100%;
      min-height: 100px;
      margin-top: 20px;
    }
    .row_11 {
      clear: both;
      min-height: 100px;
    }
    .row_9 {
      clear: both;
      width: auto;
    }
    .row_2 {
      clear: both;
      min-height: 100px;
      margin-left: auto;
      margin-right: auto;
    }
    .column_1 {
      float: left;
      width: 30%;
      min-height: 100px;
    }
    .row_4 {
      clear: both;
      min-height: 100px;
    }
    .column_2 {
      float: left;
      width: 70%;
      min-height: 100px;
    }
    .row_5 {
      clear: both;
      min-height: 100px;
    }
    .row_3 {
      clear: both;
      width: auto;
    }
    .page-content {
      min-height: 700px;
      width: 100%;
    }
    .row_25 {
      clear: both;
      min-height: 100px;
    }
    .row_26 {
      clear: both;
      min-height: 100px;
      margin-left: auto;
      margin-right: auto;
    }
    .column_7 {
      float: left;
      width: 33.33%;
      min-height: 100px;
    }
    .row_28 {
      clear: both;
      min-height: 100px;
    }
    .row_31 {
      min-height: 33px;
    }
    .row_40 {
      clear: both;
      min-height: 33px;
    }
    .row_32 {
      min-height: 33px;
    }
    .row_41 {
      clear: both;
      min-height: 33px;
    }
    .row_33 {
      min-height: 33px;
    }
    .row_42 {
      clear: both;
      min-height: 33px;
    }
    .column_8 {
      float: left;
      width: 33.33%;
      min-height: 100px;
    }
    .row_29 {
      clear: both;
      min-height: 100px;
    }
    .row_34 {
      min-height: 33px;
    }
    .row_43 {
      clear: both;
      min-height: 33px;
    }
    .row_35 {
      min-height: 33px;
    }
    .row_44 {
      clear: both;
      min-height: 33px;
    }
    .row_36 {
      min-height: 33px;
    }
    .row_45 {
      clear: both;
      min-height: 33px;
    }
    .column_9 {
      float: left;
      width: 33.34%;
      min-height: 100px;
    }
    .row_30 {
      clear: both;
      min-height: 100px;
    }
    .row_37 {
      min-height: 33px;
    }
    .row_46 {
      clear: both;
      min-height: 33px;
    }
    .row_38 {
      min-height: 33px;
    }
    .row_47 {
      clear: both;
      min-height: 33px;
    }
    .row_39 {
      min-height: 33px;
    }
    .row_48 {
      clear: both;
      min-height: 33px;
    }
    .row_27 {
      clear: both;
      width: auto;
    }
    .row_23 {
      clear: both;
      min-height: 100px;
    }
    .row_24 {
      clear: both;
      min-height: 100px;
      margin-left: auto;
      margin-right: auto;
    }
    .row_109 {
      clear: both;
      min-height: 5px;
    }
    .row_95 {
      clear: both;
      min-height: 100px;
    }
    .row_96 {
      clear: both;
      min-height: 100px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
    .column_13 {
      float: left;
      width: 70%;
      min-height: 100px;
    }
    .row_98 {
      clear: both;
      min-height: 100px;
    }
    .row_100 {
      min-height: 33px;
    }
    .row_103 {
      clear: both;
      min-height: 33px;
    }
    .row_101 {
      min-height: 33px;
    }
    .row_104 {
      clear: both;
      min-height: 33px;
    }
    .row_102 {
      min-height: 33px;
    }
    .row_105 {
      clear: both;
      min-height: 33px;
      margin-right: 20px;
    }
    .column_15 {
      float: left;
      width: 50%;
      min-height: 33px;
    }
    .row_107 {
      clear: both;
      min-height: 33px;
    }
    .column_16 {
      float: left;
      width: 50%;
      min-height: 33px;
    }
    .row_108 {
      clear: both;
      min-height: 33px;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
    .row_106 {
      clear: both;
      width: auto;
    }
    .column_14 {
      float: left;
      width: 30%;
      min-height: 100px;
    }
    .row_99 {
      clear: both;
      min-height: 100px;
    }
    .row_97 {
      clear: both;
      width: auto;
    }
    .row_85 {
      clear: both;
      min-height: 100px;
      background-image: url(${bannermanualtablet});
      background-size: auto;
    }
    .row_86 {
      clear: both;
      min-height: 100px;
      margin-left: auto;
      margin-right: auto;
    }
    .row_87 {
      min-height: 25px;
    }
    .row_91 {
      clear: both;
      min-height: 25px;
    }
    .row_88 {
      min-height: 25px;
    }
    .row_92 {
      clear: both;
      min-height: 25px;
    }
    .row_89 {
      min-height: 25px;
    }
    .row_93 {
      clear: both;
      min-height: 25px;
    }
    .row_90 {
      min-height: 25px;
    }
    .row_94 {
      clear: both;
      min-height: 25px;
    }
    .row_49 {
      clear: both;
      min-height: 0px;
    }
    .row_50 {
      clear: both;
      min-height: 0px;
      margin-left: auto;
      margin-right: auto;
    }
    .column_10 {
      float: left;
      width: 33.33%;
      min-height: 0px;
    }
    .row_52 {
      clear: both;
      min-height: 0px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .row_55 {
      min-height: 60px;
    }
    .row_70 {
      clear: both;
      min-height: 60px;
    }
    .row_56 {
      min-height: 60px;
    }
    .row_71,
    .row_71-c {
      clear: both;
      min-height: 60px;
      border-style: solid;
    }
    .row_57 {
      min-height: 60px;
    }
    .row_72 {
      clear: both;
      min-height: 60px;
    }
    .row_58 {
      min-height: 60px;
    }
    .row_73 {
      clear: both;
      min-height: 60px;
    }
    .row_59 {
      min-height: 60px;
    }
    .row_74 {
      clear: both;
      min-height: 60px;
    }
    .column_11 {
      float: left;
      width: 33.33%;
      min-height: 0px;
    }
    .row_53 {
      clear: both;
      min-height: 0px;
      margin-left: 10px;
    }
    .row_60 {
      min-height: 60px;
    }
    .row_75 {
      clear: both;
      min-height: 60px;
    }
    .row_61 {
      min-height: 60px;
    }
    .row_76 {
      clear: both;
      min-height: 60px;
    }
    .row_71-c {
      border-width: 3px;
      min-height: 40px;
    }
    .row_62 {
      min-height: 60px;
    }
    .row_77 {
      clear: both;
      min-height: 60px;
    }
    .row_71-ca {
      min-height: 60px;
    }
    .row_63 {
      min-height: 60px;
    }
    .row_78 {
      clear: both;
      min-height: 60px;
    }
    .row_71-cav {
      min-height: 60px;
    }
    .row_64 {
      min-height: 60px;
    }
    .row_79 {
      clear: both;
      min-height: 60px;
    }
    .row_71 {
      min-height: 40px;
    }
    .row_71-cavi {
      min-height: 60px;
    }
    .column_12 {
      float: left;
      width: 33.34%;
      min-height: 0px;
    }
    .row_54 {
      clear: both;
      min-height: 0px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .row_65 {
      min-height: 60px;
    }
    .row_80 {
      clear: both;
      min-height: 60px;
      padding-left: 0px;
    }
    .row_66 {
      min-height: 60px;
    }
    .row_81 {
      clear: both;
      min-height: 60px;
    }
    .row_67 {
      min-height: 60px;
    }
    .row_82 {
      clear: both;
      min-height: 60px;
    }
    .row_68 {
      min-height: 60px;
    }
    .row_83 {
      clear: both;
      min-height: 60px;
    }
    .row_69 {
      min-height: 60px;
    }
    .row_84 {
      clear: both;
      min-height: 60px;
    }
    .row_51 {
      clear: both;
      width: auto;
    }
    .row_121 {
      clear: both;
      min-height: 0px;
      background-image: none;
      background-size: auto;
    }
    .row_122 {
      clear: both;
      min-height: 0px;
      margin-left: auto;
      margin-right: auto;
    }
    .column_19 {
      float: left;
      width: 50%;
      min-height: 100px;
    }
    .row_124 {
      clear: both;
      min-height: 100px;
      margin-bottom: 30px;
    }
    .row_126 {
      min-height: 33px;
    }
    .row_129 {
      clear: both;
      min-height: 33px;
    }
    .row_127 {
      min-height: 33px;
    }
    .row_130 {
      clear: both;
      min-height: 33px;
    }
    .row_128 {
      min-height: 33px;
    }
    .row_131 {
      clear: both;
      min-height: 33px;
      margin-right: 0;
      margin-left: 0px;
      width: 80%;
      margin-top: 10px;
    }
    .column_20 {
      float: left;
      width: 50%;
      min-height: 100px;
    }
    .row_125 {
      clear: both;
      min-height: 100px;
    }
    .row_123 {
      clear: both;
      width: auto;
    }
    .row_110 {
      clear: both;
      min-height: 100px;
    }
    .row_111 {
      clear: both;
      min-height: 100px;
      margin-left: auto;
      margin-right: auto;
    }
    .column_17 {
      float: left;
      width: 100%;
      min-height: 100px;
    }
    .row_113 {
      clear: both;
      min-height: 100px;
    }
    .row_115 {
      min-height: 33px;
    }
    .row_118 {
      clear: both;
      min-height: 33px;
    }
    .row_116 {
      min-height: 33px;
    }
    .row_119 {
      clear: both;
      min-height: 33px;
    }
    .row_117 {
      min-height: 33px;
    }
    .row_120 {
      clear: both;
      min-height: 33px;
    }
    .column_18 {
      float: left;
      width: 100%;
      min-height: 100px;
    }
    .row_114 {
      clear: both;
      min-height: 100px;
    }
    .row_112 {
      clear: both;
      width: auto;
    }
    .row_contato,
    .row_area {
      clear: both;
      min-height: 0px;
    }
    .row_148 {
      clear: both;
      min-height: 0px;
    }
    .row_149 {
      clear: both;
      min-height: 0px;
    }
    .row_22 {
      clear: both;
      margin-left: auto;
      margin-right: auto;
    }
    .row_7 {
      clear: both;
      min-height: 0px;
    }
    img.image4 {
      display: none;
    }
    img.image3 {
      display: inline;
    }
    img.image1 {
      display: none;
    }
    p.paragraph1 {
      margin-right: 0px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 30px;
      line-height: 170%;
    }
    h1.heading1 {
      margin-right: 0px;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 20px;
      font-size: 30px;
    }
    p.paragraph2 {
      text-align: center;
      margin: 30px;
      line-height: 170%;
    }
    img.image2 {
      margin-right: auto;
      float: none;
      margin-left: 0px;
      display: block;
      margin-bottom: 15px;
    }
    p.paragraph4 {
      text-align: center;
    }
    h3.heading7 {
      text-align: left;
      line-height: 100%;
      margin-bottom: 10px;
    }
    p.paragraph9 {
      text-align: left;
      line-height: 100%;
      margin-top: 0px;
      margin-bottom: 10px;
    }
    p.paragraph10 {
      text-align: left;
      margin-top: 0px;
    }
    img.image8 {
      margin-right: 0px;
    }
    p.paragraph12 {
      text-align: center;
    }
    p.paragraph14 {
      text-align: center;
    }
    img.image9 {
      margin-right: auto;
      display: block;
    }
    img.image10 {
      margin-right: auto;
      display: block;
    }
    p.paragraph13 {
      text-align: center;
    }
    img.image11 {
      margin-right: auto;
      float: none;
      margin-left: 0px;
      display: block;
    }
    p.paragraph15 {
      text-align: left;
      margin-right: 20px;
      line-height: 130%;
      margin-left: 0px;
      margin-top: 20px;
    }
    p.paragraph3 {
      line-height: 170%;
    }
    p.paragraph20 {
      text-align: center;
    }
    p.paragraph6 {
      font-size: 17px;
    }
    h3.heading4 {
      font-size: 23px;
    }
    p.paragraph21 {
      font-size: 18px;
    }
  }
  @media only screen and (min-width: 1024px) {
    .row_6 {
      clear: both;
      min-height: 100px;
    }
    .align-inline-wrap1 {
      width: 100%;
    }
    .row_1 {
      clear: both;
      min-height: 0px;
      background-image: url(${bannermaxifrete});
    }
    .row_8 {
      clear: both;
      min-height: 100px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
    }
    .row_9 {
      clear: both;
      width: auto;
    }
    .column_3 {
      float: left;
      width: 55%;
    }
    .row_10 {
      margin-bottom: 0;
    }
    .column_4 {
      float: left;
      width: 45%;
      margin-top: 0;
    }
    .row_2 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .row_3 {
      clear: both;
      width: auto;
    }
    .column_1 {
      float: left;
      width: 50%;
    }
    .column_2 {
      float: left;
      min-height: 100px;
      width: 50%;
    }
    .row_5 {
      min-height: 100px;
    }
    .row_7 {
      clear: both;
      min-height: 50px;
    }
    .row_12 {
      clear: both;
      width: auto;
      min-height: 33px;
    }
    .row_15 {
      min-height: 33px;
    }
    .row_13 {
      clear: both;
      width: auto;
    }
    .row_14 {
      clear: both;
      width: auto;
      margin-bottom: 0;
    }
    img.image4 {
      display: none;
    }
    img.image3 {
      display: none;
    }
    img.image1 {
      display: block;
      clear: none;
    }
    .row_18 {
      clear: both;
      width: auto;
    }
    .column_5 {
      float: left;
      right: auto;
      left: auto;
      margin-right: 0;
      min-height: 33px;
    }
    .row_19 {
      margin-left: 0px;
      min-height: 33px;
      width: 100%;
      right: auto;
      left: auto;
      margin-right: 0;
    }
    .column_6 {
      float: left;
      width: 77%;
      min-height: 33px;
    }
    .row_20 {
      min-height: 33px;
    }
    .row_148 {
      clear: both;
      min-height: 0px;
    }
    .row_149 {
      min-height: 0px;
    }
    .row_22 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
    }
    p.paragraph1 {
      margin-right: 50px;
      text-align: left;
      margin-top: 0px;
      margin-bottom: 30px;
      line-height: 170%;
    }
    h1.heading1 {
      margin-right: 20px;
      text-align: left;
      margin-bottom: 30px;
      margin-top: 20px;
      font-size: 36px;
    }
    p.paragraph2 {
      text-align: left;
      margin: 10px;
      line-height: 170%;
    }
    img.image2 {
      margin-right: auto;
      float: none;
      margin-left: 0px;
      display: block;
      margin-bottom: 15px;
    }
    .page-content {
      min-height: 700px;
    }
    .row_25 {
      clear: both;
      min-height: 100px;
    }
    .row_26 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .row_27 {
      clear: both;
      width: auto;
    }
    .column_7 {
      float: left;
    }
    .column_8 {
      float: left;
    }
    .column_9 {
      float: left;
    }
    .row_23 {
      clear: both;
      min-height: 100px;
    }
    .row_24 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .row_109 {
      clear: both;
      min-height: 5px;
    }
    .row_95 {
      clear: both;
      min-height: 100px;
    }
    .row_96 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
    .row_97 {
      clear: both;
      width: auto;
    }
    .column_13 {
      float: left;
      width: 55%;
    }
    .column_14 {
      float: left;
      width: 45%;
    }
    .row_85 {
      clear: both;
      min-height: 100px;
      background: url(${manualtransp}) scroll transparent;
    }
    .row_86 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .row_87 {
      clear: both;
      width: auto;
    }
    .row_88 {
      clear: both;
      width: auto;
      min-height: 25px;
    }
    .row_92 {
      min-height: 25px;
    }
    .row_89 {
      clear: both;
      width: auto;
      min-height: 25px;
    }
    .row_93 {
      min-height: 25px;
    }
    .row_90 {
      clear: both;
      width: auto;
      min-height: 25px;
    }
    .row_94 {
      min-height: 25px;
    }
    .row_49 {
      clear: both;
    }
    .row_50 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
      min-height: 0px;
    }
    .row_51 {
      clear: both;
      width: auto;
    }
    .column_10 {
      float: left;
      min-height: 0px;
    }
    .row_52 {
      margin-right: 10px;
      min-height: 0px;
    }
    .column_11 {
      float: left;
      min-height: 0px;
    }
    .row_53 {
      margin-right: 0;
      min-height: 0px;
    }
    .column_12 {
      float: left;
      min-height: 0px;
    }
    .row_54 {
      margin-right: 10px;
      min-height: 0px;
    }
    .row_121 {
      clear: both;
      background-image: none;
      background-size: auto;
      min-height: 0px;
    }
    .row_122 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
      min-height: 0px;
    }
    .row_123 {
      clear: both;
      width: auto;
    }
    .column_19 {
      float: left;
    }
    .row_124 {
      margin-bottom: 40px;
    }
    .column_20 {
      float: left;
      min-height: 100px;
    }
    .row_125 {
      min-height: 100px;
    }
    .row_110 {
      clear: both;
      min-height: 100px;
    }
    .row_111 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .row_112 {
      clear: both;
      width: auto;
    }
    .column_17 {
      float: left;
      width: 40%;
    }
    .column_18 {
      float: left;
      width: 60%;
    }
    .row_contato,
    .row_area {
      min-height: 0px;
    }
    .row_31 {
      clear: both;
      width: auto;
      min-height: 33px;
    }
    .row_40 {
      min-height: 33px;
    }
    .row_32 {
      clear: both;
      width: auto;
    }
    .row_33 {
      clear: both;
      width: auto;
      min-height: 33px;
    }
    .row_42 {
      min-height: 33px;
    }
    .row_34 {
      clear: both;
      width: auto;
      min-height: 33px;
    }
    .row_43 {
      min-height: 33px;
    }
    .row_35 {
      clear: both;
      width: auto;
    }
    .row_36 {
      clear: both;
      width: auto;
      min-height: 33px;
    }
    .row_45 {
      min-height: 33px;
    }
    .row_37 {
      clear: both;
      width: auto;
      min-height: 33px;
    }
    .row_46 {
      min-height: 33px;
    }
    .row_38 {
      clear: both;
      width: auto;
    }
    .row_39 {
      clear: both;
      width: auto;
      min-height: 33px;
    }
    .row_48 {
      min-height: 33px;
    }
    p.paragraph4 {
      text-align: left;
    }
    .row_55 {
      clear: both;
      width: auto;
      min-height: 60px;
    }
    .row_70 {
      min-height: 60px;
    }
    .row_56 {
      clear: both;
      width: auto;
    }
    .row_71,
    .row_71-c {
      border-style: solid;
    }
    .row_57 {
      clear: both;
      width: auto;
    }
    .row_58 {
      clear: both;
      width: auto;
    }
    .row_59 {
      clear: both;
      width: auto;
      min-height: 60px;
    }
    .row_60 {
      clear: both;
      width: auto;
      min-height: 60px;
    }
    .row_75 {
      min-height: 60px;
    }
    .row_61 {
      clear: both;
      width: auto;
    }
    .row_62 {
      clear: both;
      width: auto;
    }
    .row_63 {
      clear: both;
      width: auto;
    }
    .row_64 {
      clear: both;
      width: auto;
      min-height: 60px;
    }
    .row_79 {
      min-height: 60px;
    }
    .row_65 {
      clear: both;
      width: auto;
      min-height: 60px;
    }
    .row_80 {
      padding-left: 0px;
      min-height: 60px;
    }
    .row_66 {
      clear: both;
      width: auto;
    }
    .row_67 {
      clear: both;
      width: auto;
    }
    .row_68 {
      clear: both;
      width: auto;
    }
    .row_69 {
      clear: both;
      width: auto;
      min-height: 60px;
    }
    .row_84 {
      min-height: 60px;
    }
    .row_100 {
      clear: both;
      width: auto;
    }
    .row_101 {
      clear: both;
      width: auto;
    }
    .row_102 {
      clear: both;
      width: auto;
    }
    .row_105 {
      margin-right: 20px;
    }
    .row_106 {
      clear: both;
      width: auto;
    }
    .column_15 {
      float: left;
      min-height: 33px;
    }
    .row_107 {
      min-height: 33px;
    }
    .column_16 {
      float: left;
      min-height: 33px;
    }
    .row_108 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
      min-height: 33px;
      width: 80%;
    }
    h3.heading7 {
      text-align: left;
      line-height: 120%;
      margin-bottom: 25px;
    }
    p.paragraph9 {
      text-align: left;
      line-height: 150%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    p.paragraph10 {
      text-align: left;
      margin-top: 10px;
    }
    img.image8 {
      display: inline-block;
    }
    .row_115 {
      clear: both;
      width: auto;
    }
    .row_116 {
      clear: both;
      width: auto;
    }
    .row_117 {
      clear: both;
      width: auto;
    }
    img.image9 {
      float: left;
      margin-right: 0px;
    }
    p.paragraph14 {
      float: left;
      margin-left: 5px;
      text-align: left;
    }
    img.image10 {
      float: left;
      margin-right: 0;
    }
    p.paragraph13 {
      float: left;
      margin-left: 5px;
      text-align: left;
    }
    p.paragraph12 {
      text-align: left;
    }
    .row_126 {
      clear: both;
      width: auto;
    }
    .row_127 {
      clear: both;
      width: auto;
    }
    .row_128 {
      clear: both;
      width: auto;
      min-height: 33px;
    }
    .row_131 {
      min-height: 33px;
      margin-right: 0;
      margin-left: 0px;
      right: auto;
      left: auto;
      width: 60%;
      margin-top: 30px;
    }
    img.image11 {
      margin-right: auto;
      float: none;
      margin-left: 0px;
      display: block;
    }
    p.paragraph15 {
      text-align: left;
      margin-right: 20px;
      line-height: 170%;
      margin-top: 20px;
    }
    p.paragraph3 {
      line-height: 170%;
    }
    p.paragraph20 {
      text-align: left;
    }
    .row_71-ca {
      min-height: 60px;
    }
    .row_71-cav {
      min-height: 60px;
    }
    .row_71-cavi {
      min-height: 60px;
    }
    .row_71-c {
      border-width: 2px;
      min-height: 0;
    }
    .row_71 {
      min-height: 0;
    }
    p.paragraph6 {
      font-size: 18px;
    }
    h3.heading4 {
      font-size: 24px;
    }
    p.paragraph21 {
      font-size: 18px;
    }
  }

  .footer {
  }
  .row_133 {
    clear: both;
    background-color: #000000;
  }
  .row_150 {
    clear: both;
    background-color: #009688;
    border-radius: 100px;
    position: fixed;
    right: 10px;
    bottom: 180px;
  }
  .row_134 {
    clear: both;
    min-height: 0px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .row_132 {
    clear: both;
    min-height: 100px;
    background-color: #120b4f;
  }
  #wp_widget0 {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: inline-block;
    cursor: pointer;
    background-image: url('../images/back_to_top_bkg.png');
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #c4c5c7;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }
  .row_135 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .column_21 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_137 {
    clear: both;
    min-height: 100px;
  }
  .column_22 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_138 {
    clear: both;
    min-height: 100px;
  }
  .column_24 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_141 {
    clear: both;
    min-height: 0px;
  }
  .column_25 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_142 {
    clear: both;
    min-height: 0px;
  }
  .row_140 {
    clear: both;
    width: auto;
  }
  .column_23 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_139 {
    clear: both;
    min-height: 100px;
    margin-bottom: 30px;
  }
  .column_26 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_144 {
    clear: both;
    min-height: 0px;
  }
  .column_27 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_145 {
    clear: both;
    min-height: 0px;
  }
  .column_28 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_146 {
    clear: both;
    min-height: 0px;
  }
  .column_29 {
    float: none;
    width: auto;
    min-height: 0px;
  }
  .row_147 {
    clear: both;
    min-height: 0px;
  }
  .row_143 {
    clear: both;
    width: auto;
  }
  .row_136 {
    clear: both;
    width: auto;
  }
  p.paragraph17 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #f0f0f0;
    text-align: center;
  }
  a.anchor1 {
    font-size: 14px;
    letter-spacing: 0px;
    color: #ffffff;
  }
  a.anchor2 {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0px;
  }
  img.image12 {
    display: block;
    margin: 20px auto;
  }
  img.image13 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 20px;
  }
  img.image14 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 20px;
  }
  img.image15 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 20px;
  }
  img.image16 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 20px;
  }
  p.paragraph18 {
    font-size: 16px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p.paragraph19 {
    font-size: 16px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  img.image17 {
    margin: 10px auto;
    display: block;
  }
  img.image18 {
    margin: 10px auto;
    display: block;
  }
  img.image19 {
    display: inline-block;
    margin: 10px;
  }
  p.paragraph22 {
    margin: 10px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    color: #ffffff;
    display: none;
  }
  @media only screen and (min-width: 768px) {
    .row_133 {
      clear: both;
      min-height: 0px;
    }
    .row_150 {
      clear: both;
      min-height: 0px;
      width: 150px;
      bottom: 80px;
    }
    .row_134 {
      clear: both;
      min-height: 0px;
      margin-left: auto;
      margin-right: auto;
    }
    .row_132 {
      clear: both;
      min-height: 100px;
    }
    .row_135 {
      clear: both;
      min-height: 100px;
      margin-left: auto;
      margin-right: auto;
    }
    .column_21 {
      float: left;
      width: 100%;
      min-height: 100px;
    }
    .row_137 {
      clear: both;
      min-height: 100px;
    }
    .column_22 {
      float: left;
      width: 100%;
      min-height: 100px;
    }
    .row_138 {
      clear: both;
      min-height: 100px;
    }
    .column_24 {
      float: left;
      width: 100%;
      min-height: 0px;
    }
    .row_141 {
      clear: both;
      min-height: 0px;
    }
    .column_25 {
      float: left;
      width: 100%;
      min-height: 0px;
    }
    .row_142 {
      clear: both;
      min-height: 100px;
    }
    .row_140 {
      clear: both;
      width: auto;
    }
    .column_23 {
      float: left;
      width: 100%;
      min-height: 100px;
    }
    .row_139 {
      clear: both;
      min-height: 100px;
      width: 40%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
    }
    .column_26 {
      float: left;
      width: 25%;
      min-height: 100px;
    }
    .row_144 {
      clear: both;
      min-height: 100px;
    }
    .column_27 {
      float: left;
      width: 25%;
      min-height: 100px;
    }
    .row_145 {
      clear: both;
      min-height: 100px;
    }
    .column_28 {
      float: left;
      width: 25%;
      min-height: 100px;
    }
    .row_146 {
      clear: both;
      min-height: 100px;
    }
    .column_29 {
      float: left;
      width: 25%;
      min-height: 100px;
    }
    .row_147 {
      clear: both;
      min-height: 100px;
    }
    .row_143 {
      clear: both;
      width: auto;
    }
    .row_136 {
      clear: both;
      width: auto;
    }
    img.image12 {
      margin-right: auto;
      display: block;
    }
    p.paragraph19 {
      float: none;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    img.image18 {
      float: none;
      display: block;
      margin: 10px auto;
    }
    p.paragraph18 {
      float: none;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    img.image17 {
      float: none;
      display: block;
      margin: 10px auto;
    }
    img.image13 {
      margin: 10px auto;
      display: block;
    }
    img.image14 {
      margin: 10px auto;
      display: block;
    }
    img.image15 {
      margin: 10px auto;
      display: block;
    }
    img.image16 {
      margin: 10px auto;
      display: block;
    }
    p.paragraph22 {
      margin: 5px 10px 10px 10px;
      text-align: center;
      display: block;
    }
    img.image19 {
      width: 30px;
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 5px;
    }
  }
  @media only screen and (min-width: 1024px) {
    .row_133 {
      clear: both;
      min-height: 0px;
    }
    .row_150 {
      clear: both;
      width: 180px;
      min-height: 0px;
      bottom: 80px;
    }
    .row_134 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
      min-height: 0px;
    }
    .row_135 {
      right: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .row_136 {
      clear: both;
      width: auto;
    }
    .column_21 {
      float: left;
      width: 25%;
    }
    .column_22 {
      float: left;
      width: 55%;
    }
    .column_23 {
      float: left;
      width: 20%;
    }
    .row_139 {
      width: auto;
      right: auto;
      left: auto;
      margin-right: 0;
      margin-bottom: 0;
    }
    .row_140 {
      clear: both;
      width: auto;
    }
    .column_24 {
      float: left;
      margin-left: 20px;
      width: 35%;
      min-height: 100px;
    }
    .row_141 {
      min-height: 100px;
    }
    .column_25 {
      float: left;
      width: 60%;
      min-height: 100px;
    }
    .row_142 {
      min-height: 100px;
    }
    .row_143 {
      clear: both;
      width: auto;
    }
    .column_26 {
      float: left;
      min-height: 100px;
    }
    .row_144 {
      min-height: 100px;
    }
    .column_27 {
      float: left;
      min-height: 100px;
    }
    .row_145 {
      min-height: 100px;
    }
    .column_28 {
      float: left;
      min-height: 100px;
    }
    .row_146 {
      min-height: 100px;
    }
    .column_29 {
      float: left;
      min-height: 100px;
    }
    .row_147 {
      min-height: 100px;
    }
    img.image17 {
      float: none;
      margin: 20px auto 10px auto;
      display: block;
    }
    img.image18 {
      float: none;
      margin: 20px auto 10px auto;
      display: block;
    }
    p.paragraph18 {
      float: none;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    p.paragraph19 {
      float: none;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    img.image12 {
      margin-right: auto;
      float: none;
      margin-left: 0px;
      display: block;
    }
    img.image13 {
      margin: 20px auto 0 auto;
    }
    img.image14 {
      margin: 20px auto 0 auto;
    }
    img.image15 {
      margin: 20px auto 0 auto;
    }
    img.image16 {
      margin: 20px auto 0 auto;
    }
    img.image19 {
      float: left;
      width: 30px;
      margin-right: 5px;
      margin-bottom: 10px;
      margin-left: 10px;
    }
    p.paragraph22 {
      margin: 10px 10px 10px 5px;
      float: left;
      text-align: left;
      display: block;
    }
  }

  body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    font-size: 19px;
    font-weight: 300;
    line-height: 170%;
    color: #777777;
  }
  h1 {
    font-size: 46px;
    font-weight: 900;
    line-height: 100%;
    margin: 40px 0px 40px 0px;
    color: #333333;
    letter-spacing: -1px;
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 110%;
    margin: 30px 0px 30px 0px;
    color: #666666;
    letter-spacing: -1px;
  }
  h3 {
    font-size: 23px;
    font-weight: 700;
    line-height: 120%;
    margin: 25px 0px 25px 0px;
    color: #333333;
  }
  h4 {
    font-size: 17px;
    font-weight: 900;
    line-height: 120%;
    margin: 25px 0px 25px 0px;
    color: #333333;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  h5 {
    font-size: 17px;
    font-weight: 700;
    line-height: 130%;
    margin: 25px 0px 25px 0px;
    color: #333333;
  }
  h6 {
    font-size: 14px;
    font-weight: 700;
    line-height: 130%;
    margin: 10px 0px 10px 0px;
    color: #333333;
  }
  a {
    line-height: 170%;
    color: #9bcb43;
    text-decoration: none;
    cursor: pointer;
    letter-spacing: -1px;
    font-weight: 300;
    font-size: 19px;
  }
  a:hover {
    color: #82ad30;
  }
  ul {
    font-weight: 400;
    line-height: 170%;
    margin: 10px 0px 10px 0px;
    color: #777777;
    padding: 0px 0px 0px 40px;
  }
  ol {
    font-weight: 400;
    line-height: 170%;
    margin: 10px 0px 10px 0px;
    color: #444444;
    padding: 0px 0px 0px 40px;
  }
  blockquote {
    padding: 10px 20px 10px 20px;
    margin: 10px 0px 20px 0px;
    border-left: 5px solid #eee;
    font-style: italic;
  }
  ol ul,
  ul ol,
  ul ul,
  ol ol {
    margin-top: 0;
    margin-bottom: 0;
  }
  body img {
    max-width: 100%;
    height: auto;
  }

  .sticky {
  }
  .gallery-caption {
  }
  .bypostauthor {
  }
  .alignnone {
    margin: 5px 20px 20px 0;
  }
  .aligncenter,
  div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
  }
  .alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }
  .alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }
  .aligncenter {
    display: block;
    margin: 5px auto 5px auto;
  }
  a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }
  a img.alignnone {
    margin: 5px 20px 20px 0;
  }
  a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }
  a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%;
    /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
  }
  .wp-caption.alignnone {
    margin: 5px 20px 20px 0;
  }
  .wp-caption.alignleft {
    margin: 5px 20px 20px 0;
  }
  .wp-caption.alignright {
    margin: 5px 0 20px 20px;
  }
  .wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
  }
  .wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
  }
  .blog-post {
    min-height: 100px;
    margin-bottom: 50px;
  }
  a:visited {
    color: #82ad30;
  }
  span a.post-category:hover {
    color: #9bcb43;
  }
  p {
    margin: 20px 0px 20px 0px;
    font-size: 19px;
    font-weight: 300;
    line-height: 170%;
  }
  li {
    line-height: 150%;
    color: #333333;
    font-weight: 300;
  }
  @media only screen and (min-width: 768px) {
    body {
      text-align: left;
    }
  }
  @media only screen and (min-width: 1024px) {
    body {
      text-align: left;
    }
  }

  ul.FM2_CSSMenu1,
  ul.FM2_reset,
  ul.FM2_CSSMenu1 div,
  ul.FM2_CSSMenu1 span,
  ul.FM2_CSSMenu1 ul,
  ul.FM2_CSSMenu1 li,
  ul.FM2_CSSMenu1 table,
  ul.FM2_CSSMenu1 tr,
  ul.FM2_CSSMenu1 th,
  ul.FM2_CSSMenu1 td,
  ul.FM2_CSSMenu1 a {
    background: none repeat top left scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 0 none #000000;
    border-right: 0 none #000000;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    color: Black;
    font-size: 100%;
  }
  .relativeDiv {
    position: relative;
  }
  /*************************************************************************************************/
  /* I.A. Global resets */
  /*************************************************************************************************/
  /* I.A.1. Remove list style */
  ul.FM2_CSSMenu1,
  ul.FM2_CSSMenu1 ul {
    list-style-type: none;
    list-style-position: outside;
    list-style-image: none;
  }
  /* I.A.2. Remove anchor outlines when clicked */
  ul.FM2_CSSMenu1 a:active,
  ul.FM2_CSSMenu1 a:focus {
    outline-style: none;
  }
  /* I.A.3. Force block display on lists and list items */
  ul.FM2_CSSMenu1,
  ul.FM2_CSSMenu1 ul {
    display: block;
  }
  ul.FM2_CSSMenu1 li {
    display: block;
  }
  /* I.A.4. Hide span overflow */
  ul.FM2_CSSMenu1 span,
  ul.FM2_CSSMenu1 font {
    /*overflow: hidden;*/
  }
  /* I.A.5. Display and positioning for containers */
  ul.FM2_CSSMenu1 {
    position: relative;
    display: inline-block;
    width: auto;
  }
  div.FM2_CSSMenu1_container {
    margin-left: auto;
    z-index: 9999;
    text-align: right;
  }
  div.FM2_CSSMenu1_container.stick {
    position: fixed;
    top: 0;
  }
  * + html ul.FM2_CSSMenu1 {
    display: inline;
  }
  ul.FM2_CSSMenu1 ul {
    position: absolute;
    z-index: 9999;
  }
  /* I.A.6. Reset margin to 0 for all menu containers */
  ul.FM2_CSSMenu1,
  ul.FM2_CSSMenu1 ul {
    margin: 0 0 0 0;
  }
  /* I.A.7. Reset padding to 0 for all menu containers */
  ul.FM2_CSSMenu1,
  ul.FM2_CSSMenu1 ul {
    padding: 0 0 0 0;
  }
  /* I.A.8. Display block for all anchors in list items */
  ul.FM2_CSSMenu1 a {
    display: block;
  }
  /* I.A.9. Display block for all spans in list items, also height 100% */
  ul.FM2_CSSMenu1 span,
  ul.FM2_CSSMenu1 font {
    display: block;
  }
  /*************************************************************************************************/
  /* I.B. Sub-menu visibility */
  /*************************************************************************************************/
  /* I.B.1. Hide by default all menus of level greater than one */
  ul.FM2_CSSMenu1 ul {
    /*display: none;*/
  }
  /* I.B.2. Establish containing blocks (position: relative) from menu items on hover to be able to position sub-menus */
  ul.FM2_CSSMenu1 li {
    position: relative;
  }
  ul.FM2_CSSMenu1 ul li {
    position: relative;
  }
  ul.FM2_CSSMenu1 ul ul li {
    position: relative;
  }
  /* I.B.3. Show submenu when hovering over menu item */
  ul.FM2_CSSMenu1 li > ul {
    display: none;
  }
  /*       * *      * *      * *    ||           ||
	    * * *    * * *    * * *   ||  LEVEL 1  ||
	     * *      * *      * *    ||           ||
*/
  /*************************************************************************************************/
  /* II.A. Positioning */
  /*************************************************************************************************/
  /* II.A.1 Left float for horizontal menus (more rows will be generated if available width is overpassed) */
  ul.FM2_CSSMenu1 li {
    float: left;
  }
  /* II.A.2. Offsets for submenus: left, top */
  ul.FM2_CSSMenu1 {
    top: auto;
    left: auto;
  }
  /*************************************************************************************************/
  /* II.B. Container design */
  /*************************************************************************************************/
  /* II.B.1. Background (color, image, image repeat, image position) */
  /* II.B.2. Border */
  /* II.B.3. Explicit container width/height (optional) */
  div.FM2_CSSMenu1_container {
    background: none repeat left top scroll;
    border-left: 0px none #000000;
    border-top: 0 none #000000;
    border-bottom: 0 none #000000;
    border-right: 0 none #000000;
    width: auto;
    height: 47px;
    float: left;
  }
  /* II.B.4. Container padding */
  div.FM2_CSSMenu1_container {
    padding: 0px 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* II.C. Menu item design (default state) */
  /*************************************************************************************************/
  /* II.C.1. Padding */
  /* II.C.2. Border */
  /* II.C.3. Background (color, image, image repeat, image position) */
  /* II.C.4. Text color */
  /* II.C.5. Font size */
  /* II.C.6. Font properties (family, weight, style, decoration) */
  /* II.C.7. Text align */
  /* II.C.8. Cursor */
  /* II.C.9. Explicit item width/height, optional */
  ul.FM2_CSSMenu1 li a {
    background: none repeat-x left center scroll;
    border-left: 0px none #000000;
    border-top: 0 none #000000;
    border-bottom: 0px none #000000;
    border-right: 0 none #000000;
    padding: 20px 0px 0px 0px;
    transition: background 0.5s, padding 0.5s, border 0.5s, border-radius 0.5s,
      color 0.5s;
    -webkit-transition: background 0.5s, padding 0.5s, border 0.5s,
      border-radius 0.5s, color 0.5s;
    width: auto;
    height: 27px;
    -moz-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    -webkit-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    margin-left: 28px;
    margin-right: 28px;
  }
  ul.FM2_CSSMenu1 li a,
  ul.FM2_CSSMenu1 li a span,
  ul.FM2_CSSMenu1 li a font {
    font: normal normal 700 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
  /* II.C.10. Menu item spacing */
  ul.FM2_CSSMenu1 li {
    padding: 0 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* II.D. Menu item design (hover state) */
  /*************************************************************************************************/
  /* II.D.*. Any of II.C. may be changed but colors should be the most common */
  ul.FM2_CSSMenu1 li.hovered > a {
    background: url('horizontal/skin02/dark_blue/main_button_over.png') repeat-x
      left center scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 0px solid #ff6633;
    border-right: 0 none #000000;
  }
  ul.FM2_CSSMenu1 li:hover > a,
  ul.FM2_CSSMenu1 li.hover > a {
    background: none repeat-x left center scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 2px solid #ffb300;
    border-right: 0 none #000000;
    -moz-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    -webkit-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  }
  ul.FM2_CSSMenu1 li.hovered > a,
  ul.FM2_CSSMenu1 li.hovered > a span,
  ul.FM2_CSSMenu1 li.hovered > a font {
    font: normal normal bold 13px/100% Tahoma, Geneva, sans-serif;
    color: #212121;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
  ul.FM2_CSSMenu1 li:hover > a,
  ul.FM2_CSSMenu1 li.hover > a,
  ul.FM2_CSSMenu1 li:hover > a span,
  ul.FM2_CSSMenu1 li.hover > a span,
  ul.FM2_CSSMenu1 li:hover > a font,
  ul.FM2_CSSMenu1 li.hover > a font {
    font: normal normal 700 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
  /*************************************************************************************************/
  /* II.E. Has children icon (default state) */
  /*************************************************************************************************/
  ul.FM2_CSSMenu1 li a span {
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 li a span,
  ul.FM2_CSSMenu1 li a font {
    padding: 0px 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* II.F. Has children icon (hover state) */
  /*************************************************************************************************/
  ul.FM2_CSSMenu1 li.hovered > a span {
    padding: 0px 0px 0px 0px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 li:hover > a span,
  ul.FM2_CSSMenu1 li.hover > a span {
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 li:hover > a span,
  ul.FM2_CSSMenu1 li.hover > a span,
  ul.FM2_CSSMenu1 li:hover > a font,
  ul.FM2_CSSMenu1 li.hover > a font {
    padding: 0px 0px 0px 0px;
  }
  ul.FM2_CSSMenu1 li a.sel span {
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 li a.sel span,
  ul.FM2_CSSMenu1 li a.sel font {
    padding: 0px 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* III.B. Item customization */
  /*************************************************************************************************/
  /* III.B.1. Custom default item style */
  ul.FM2_CSSMenu1 li a.sel {
    background: none repeat-x left center scroll;
    border-left: 0px solid #000000;
    border-top: 0px solid #000000;
    border-bottom: 2px solid #ffb300;
    border-right: 0px solid #000000;
    -moz-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    -webkit-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  }
  ul.FM2_CSSMenu1 li a.sel,
  ul.FM2_CSSMenu1 li a.sel span,
  ul.FM2_CSSMenu1 li a.sel font {
    font: normal normal 700 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
  /*       * *      * *      * *    ||           ||
	    * * *    * * *    * * *   ||  LEVEL 2  ||
	     * *      * *      * *    ||           ||
*/
  /*************************************************************************************************/
  /* II.A. Positioning */
  /*************************************************************************************************/
  /* II.A.1 Left float for horizontal menus (more rows will be generated if available width is overpassed) */
  ul.FM2_CSSMenu1 ul li {
    float: none;
  }
  /* II.A.2. Offsets for submenus: left, top */
  ul.FM2_CSSMenu1 li > .ui-effects-wrapper {
    top: 58px;
    left: 0px;
  }
  ul.FM2_CSSMenu1 li ul {
    top: 70px;
    left: 28px;
  }
  /*************************************************************************************************/
  /* II.B. Container design */
  /*************************************************************************************************/
  /* II.B.1. Background (color, image, image repeat, image position) */
  /* II.B.2. Border */
  /* II.B.3. Explicit container width/height (optional) */
  ul.FM2_CSSMenu1 ul {
    border-left: 0px solid #cdcdcd;
    border-top: 0px solid #cdcdcd;
    border-bottom: 0px solid #cdcdcd;
    border-right: 0px solid #cdcdcd;
    width: 165px;
    height: auto;
    background-color: #ffffff;
    background-image: none;
  }
  /* II.B.4. Container padding */
  ul.FM2_CSSMenu1 ul {
    padding: 0px 0 0px 0;
  }
  /*************************************************************************************************/
  /* II.C. Menu item design (default state) */
  /*************************************************************************************************/
  /* II.C.1. Padding */
  /* II.C.2. Border */
  /* II.C.3. Background (color, image, image repeat, image position) */
  /* II.C.4. Text color */
  /* II.C.5. Font size */
  /* II.C.6. Font properties (family, weight, style, decoration) */
  /* II.C.7. Text align */
  /* II.C.8. Cursor */
  /* II.C.9. Explicit item width/height, optional */
  ul.FM2_CSSMenu1 ul li a {
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 1px solid #00ace6;
    border-right: 0 none #000000;
    padding: 17px 20px 0px 20px;
    width: 126px;
    height: 28px;
    -moz-text-shadow: 0px 0px 0px #000000;
    -webkit-text-shadow: 0px 0px 0px #000000;
    text-shadow: none;
    background-color: #00bfff;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: left center;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  ul.FM2_CSSMenu1 ul li a,
  ul.FM2_CSSMenu1 ul li a span,
  ul.FM2_CSSMenu1 ul li a font {
    font: normal normal 400 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }
  /* II.C.10. Menu item spacing */
  ul.FM2_CSSMenu1 ul li {
    padding: 0 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* II.D. Menu item design (hover state) */
  /*************************************************************************************************/
  /* II.D.*. Any of II.C. may be changed but colors should be the most common */
  ul.FM2_CSSMenu1 ul li.hovered > a {
    background: #3399ff none no-repeat left center scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 0 none #000000;
    border-right: 0 none #000000;
  }
  ul.FM2_CSSMenu1 ul li:hover > a,
  ul.FM2_CSSMenu1 ul li.hover > a {
    background: #00ace6 url('horizontal/skin02/dark_blue/../none') no-repeat
      left center scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 1px solid transparent;
    border-right: 0 none #000000;
    padding: 17px 20px 0px 20px;
    -moz-text-shadow: 0px 0px 0px #000000;
    -webkit-text-shadow: 0px 0px 0px #000000;
    text-shadow: none;
    height: 28px;
    width: 126px;
  }
  ul.FM2_CSSMenu1 ul li.hovered > a,
  ul.FM2_CSSMenu1 ul li.hovered > a span,
  ul.FM2_CSSMenu1 ul li.hovered > a font {
    font: normal normal normal 12px/100% Arial, Helvetica, sans-serif;
    color: #000000;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }
  ul.FM2_CSSMenu1 ul li:hover > a,
  ul.FM2_CSSMenu1 ul li.hover > a,
  ul.FM2_CSSMenu1 ul li:hover > a span,
  ul.FM2_CSSMenu1 ul li.hover > a span,
  ul.FM2_CSSMenu1 ul li:hover > a font,
  ul.FM2_CSSMenu1 ul li.hover > a font {
    font: normal normal 400 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }
  /*************************************************************************************************/
  /* II.E. Has children icon (default state) */
  /*************************************************************************************************/
  ul.FM2_CSSMenu1 ul li a span {
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('horizontal/skin02/dark_blue/arrow-right-skin2.png');
  }
  ul.FM2_CSSMenu1 ul li a span,
  ul.FM2_CSSMenu1 ul li a font {
    padding: 0px 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* II.F. Has children icon (hover state) */
  /*************************************************************************************************/
  ul.FM2_CSSMenu1 ul li.hovered > a span {
    padding: 0px 0px 0px 0px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 ul li:hover > a span,
  ul.FM2_CSSMenu1 ul li.hover > a span {
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('horizontal/skin02/dark_blue/arrow-right-skin2.png');
  }
  ul.FM2_CSSMenu1 ul li:hover > a span,
  ul.FM2_CSSMenu1 ul li.hover > a span,
  ul.FM2_CSSMenu1 ul li:hover > a font,
  ul.FM2_CSSMenu1 ul li.hover > a font {
    padding: 0px 0px 0px 0px;
  }
  ul.FM2_CSSMenu1 ul li a.sel span {
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('horizontal/skin02/dark_blue/arrow-right-skin2.png');
  }
  ul.FM2_CSSMenu1 ul li a.sel span,
  ul.FM2_CSSMenu1 ul li a.sel font {
    padding: 0px 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* III.B. Item customization */
  /*************************************************************************************************/
  /* III.B.1. Custom default item style */
  ul.FM2_CSSMenu1 ul li a.sel {
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 1px solid transparent;
    border-right: 0 none #000000;
    padding: 17px 20px 0px 20px;
    -moz-text-shadow: 0px 0px 0px #000000;
    -webkit-text-shadow: 0px 0px 0px #000000;
    text-shadow: none;
    height: 28px;
    width: 126px;
    background-color: #00ace6;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: left center;
  }
  ul.FM2_CSSMenu1 ul li a.sel,
  ul.FM2_CSSMenu1 ul li a.sel span,
  ul.FM2_CSSMenu1 ul li a.sel font {
    font: normal normal 400 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }
  /*       * *      * *      * *    ||           ||
	    * * *    * * *    * * *   ||  LEVEL 3  ||
	     * *      * *      * *    ||           ||
*/
  /*************************************************************************************************/
  /* II.A. Positioning */
  /*************************************************************************************************/
  /* II.A.1 Left float for horizontal menus (more rows will be generated if available width is overpassed) */
  ul.FM2_CSSMenu1 ul ul li {
    float: none;
  }
  /* II.A.2. Offsets for submenus: left, top */
  ul.FM2_CSSMenu1 ul .ui-effects-wrapper {
    top: 0px;
    left: 120px;
  }
  ul.FM2_CSSMenu1 ul ul {
    top: 0px;
    left: 120px;
  }
  /*************************************************************************************************/
  /* II.B. Container design */
  /*************************************************************************************************/
  /* II.B.1. Background (color, image, image repeat, image position) */
  /* II.B.2. Border */
  /* II.B.3. Explicit container width/height (optional) */
  ul.FM2_CSSMenu1 ul ul {
    border-left: 0px solid #cdcdcd;
    border-top: 0px solid #cdcdcd;
    border-bottom: 0px solid #cdcdcd;
    border-right: 0px solid #cdcdcd;
    width: 165px;
    height: auto;
    background-color: #ffffff;
    background-image: none;
  }
  /* II.B.4. Container padding */
  ul.FM2_CSSMenu1 ul ul {
    padding: 0px 0 0px 0;
    top: 0;
    z-index: 9999;
  }
  /*************************************************************************************************/
  /* II.C. Menu item design (default state) */
  /*************************************************************************************************/
  /* II.C.1. Padding */
  /* II.C.2. Border */
  /* II.C.3. Background (color, image, image repeat, image position) */
  /* II.C.4. Text color */
  /* II.C.5. Font size */
  /* II.C.6. Font properties (family, weight, style, decoration) */
  /* II.C.7. Text align */
  /* II.C.8. Cursor */
  /* II.C.9. Explicit item width/height, optional */
  ul.FM2_CSSMenu1 ul ul li a {
    border-left: 0px none #000000;
    border-top: 0px none #000000;
    border-bottom: 1px solid #00ace6;
    border-right: 0px none #000000;
    padding: 17px 20px 0px 20px;
    width: 126px;
    height: 28px;
    background-color: #00bfff;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: left center;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 400;
  }
  ul.FM2_CSSMenu1 ul ul li a,
  ul.FM2_CSSMenu1 ul ul li a span,
  ul.FM2_CSSMenu1 ul ul li a font {
    color: #ffffff;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }
  /* II.C.10. Menu item spacing */
  ul.FM2_CSSMenu1 ul ul li {
    padding: 0 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* II.D. Menu item design (hover state) */
  /*************************************************************************************************/
  /* II.D.*. Any of II.C. may be changed but colors should be the most common */
  ul.FM2_CSSMenu1 ul ul li.hovered > a {
    background: #3399ff none no-repeat left center scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 0 none #000000;
    border-right: 0 none #000000;
  }
  ul.FM2_CSSMenu1 ul ul li:hover > a,
  ul.FM2_CSSMenu1 ul ul li.hover > a {
    background: #00ace6 url('horizontal/skin02/dark_blue/../none') no-repeat
      left center scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 1none solid transparent;
    border-right: 0 none #000000;
    padding: 17px 20px 0px 20px;
    width: 126px;
    height: 28px;
  }
  ul.FM2_CSSMenu1 ul ul li.hovered > a,
  ul.FM2_CSSMenu1 ul ul li.hovered > a span,
  ul.FM2_CSSMenu1 ul ul li.hovered > a font {
    font: normal normal normal 12px/100% Arial, Helvetica, sans-serif;
    color: #000000;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }
  ul.FM2_CSSMenu1 ul ul li:hover > a,
  ul.FM2_CSSMenu1 ul ul li.hover > a,
  ul.FM2_CSSMenu1 ul ul li:hover > a span,
  ul.FM2_CSSMenu1 ul ul li.hover > a span,
  ul.FM2_CSSMenu1 ul ul li:hover > a font,
  ul.FM2_CSSMenu1 ul ul li.hover > a font {
    font: normal normal 400 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }
  /*************************************************************************************************/
  /* II.E. Has children icon (default state) */
  /*************************************************************************************************/
  ul.FM2_CSSMenu1 ul ul li a span {
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('horizontal/skin02/dark_blue/arrow-right-skin2.png');
  }
  ul.FM2_CSSMenu1 ul ul li a span,
  ul.FM2_CSSMenu1 ul ul li a font {
    padding: 0px 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* II.F. Has children icon (hover state) */
  /*************************************************************************************************/
  ul.FM2_CSSMenu1 ul ul li.hovered > a span {
    padding: 0px 0px 0px 0px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 ul ul li:hover > a span,
  ul.FM2_CSSMenu1 ul ul li.hover > a span {
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('horizontal/skin02/dark_blue/arrow-right-skin2.png');
  }
  ul.FM2_CSSMenu1 ul ul li:hover > a span,
  ul.FM2_CSSMenu1 ul ul li.hover > a span,
  ul.FM2_CSSMenu1 ul ul li:hover > a font,
  ul.FM2_CSSMenu1 ul ul li.hover > a font {
    padding: 0px 0px 0px 0px;
  }
  ul.FM2_CSSMenu1 ul ul li a.sel span {
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('horizontal/skin02/dark_blue/arrow-right-skin2.png');
  }
  ul.FM2_CSSMenu1 ul ul li a.sel span,
  ul.FM2_CSSMenu1 ul ul li a.sel font {
    padding: 0px 0px 0px 0px;
  }
  /*************************************************************************************************/
  /* III.B. Item customization */
  /*************************************************************************************************/
  /* III.B.1. Custom default item style */
  ul.FM2_CSSMenu1 ul ul li a.sel {
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 1px solid transparent;
    border-right: 0 none #000000;
    -moz-text-shadow: 0px 0px 0px #000000;
    -webkit-text-shadow: 0px 0px 0px #000000;
    text-shadow: 0px 0px 0px #000000;
    background-color: #00ace6;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: left center;
  }
  ul.FM2_CSSMenu1 ul ul li a.sel,
  ul.FM2_CSSMenu1 ul ul li a.sel span,
  ul.FM2_CSSMenu1 ul ul li a.sel font {
    font: normal normal 400 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }
  /*************************************************************************************************/
  /* IV.A Mobile menu customization */
  /*************************************************************************************************/
  /*presetstart*/
  @media screen and (max-width: 648px) {
    .FM2_CSSMenu1_container {
      display: none;
    }
    a#FM2_CSSMenu1_mobile_button {
      display: block;
    }
  }
  @media screen and (min-width: 649px) {
    .FM2_CSSMenu1_container {
      display: block;
    }

    a#FM2_CSSMenu1_mobile_button {
      display: none;
    }
  }
  .FM2_CSSMenu1_mobile-overlay {
    background: rgba(0, 0, 0, 0.7) none repeat top left scroll;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    display: inline;
    z-index: 10050;
  }
  .FM2_CSSMenu1_mobile-overlay-hide {
    background: rgba(0, 0, 0, 0) none repeat top left scroll;
    display: none;
  }
  /************************* mobile button ************************/
  a#FM2_CSSMenu1_mobile_button {
    padding: 1em 0px 1em 0px;
    position: relative;
    width: auto;
    text-decoration: none;
    min-width: 50px;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #3377ff;
    background-color: #414141;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
  }
  a#FM2_CSSMenu1_mobile_button span {
    color: #ffffff;
  }
  a#FM2_CSSMenu1_mobile_button span.caption {
    text-transform: none;
  }
  a#FM2_CSSMenu1_mobile_button span.caption:before {
    background: url('mini_menu.png') no-repeat 0 center scroll;
    width: 18px;
  }
  a#FM2_CSSMenu1_mobile_button span.down-arrow {
    display: none;
  }
  a#FM2_CSSMenu1_mobile_button span.caption {
    font-size: 14px;
  }
  /************************* mobile menu ************************/
  div#FM2_CSSMenu1_jq_menu_back div.main-menu-header {
    background: url('close_icon.png') no-repeat center center scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 0 none #000000;
    border-right: 0 none #000000;
    padding: 14px 14px 14px 14px;
    position: relative;
    width: 14px;
    height: 14px;
    float: right;
    text-decoration: none;
    min-width: 14px;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
    margin-top: 13px;
    margin-left: 10px;
    margin-right: 10px;
  }
  div#FM2_CSSMenu1_jq_menu_back div.main-menu-header p span.caption:before {
    background: url('mini_menu.png') no-repeat -2px center scroll;
    width: 18px;
    content: ' ';
    height: 20px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 9px;
  }
  div#FM2_CSSMenu1_jq_menu_back {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0) !important;
    font-size: 0.9em;
  }
  div#FM2_CSSMenu1_jq_menu_back ul {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header {
    background: #000 none repeat top left scroll;
    position: relative;
    width: auto;
    display: block;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  div#FM2_CSSMenu1_jq_menu_back a {
    padding: 1em 0px 1em 0px;
    overflow: hidden;
    background-color: #3377ff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > a {
    background: none repeat left top scroll;
    /*margin: 5px 5px 5px 5px;*/
    display: inline-block;
    width: 50px;
    height: 42px;
    float: left;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > a:hover,
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > a.hover {
    background-color: lime;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > p {
    margin: 0 0 0 0;
    color: #fff;
    text-indent: -40px;
    /*line-height: 50px;*/
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > p:hover,
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > p.hover {
    color: #ccf;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > p a {
    background: none repeat left top scroll;
    text-decoration: none;
    display: block;
    width: auto;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container > .menu {
    float: left;
    width: 100%;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li {
    background: none repeat left top scroll;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    position: relative;
    line-height: 50px;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li p {
    margin: 0 0 0 0;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li a {
    background: none repeat left top scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 0 none #000000;
    border-right: 0 none #000000;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    text-decoration: none;
    font-weight: bold;
    font-style: normal;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .branch,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .leaf {
    margin: 0 5px 0 5px;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .branch:before {
    content: '+ ';
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .leaf:before {
    content: '- ';
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li.ellipsis:last-child {
    border-bottom: 2px solid #00bfff;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > p a {
    border-bottom: 2px solid #00bfff;
  }
  div#FM2_CSSMenu1_jq_menu_back ul {
    margin: 0px 10px 10px 10px;
    list-style-type: none;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container div.main-menu ul li.clicked,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container div.submenu ul li.clicked {
    background-color: #00bfff;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li.ellipsis a {
    width: auto;
    float: left;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li a {
    padding: 0 9px 0 9px;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li a,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li a span {
    color: white;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .leaf:before,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .branch:before {
    width: 10px;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .leaf:before {
    background: url('mini_circle.png') no-repeat top left scroll;
    opacity: 0.5;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .branch:before {
    background: url('mini_arrow.png') no-repeat top left scroll;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .branch:before,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .leaf:before,
  a#FM2_CSSMenu1_mobile_button span.caption:before {
    content: ' ';
    height: 20px;
    display: inline-block;
    vertical-align: text-bottom;
    background-position: -2px 1px;
    margin-right: 9px;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li.ellipsis,
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > p a {
    background-color: #414141;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header,
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > a {
    background-color: #00bfff;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > p a {
    text-align: center;
    color: #ffffff;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > a {
    background: url('mini_arrow_large.png') no-repeat 17px 15px scroll;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > a:hover,
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > a.hover {
    background-color: transparent;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li {
    height: 43px;
    line-height: 0;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li.ellipsis a {
    margin-top: 10px;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container > .main-menu:before {
    background: #00bfff none repeat top left scroll;
    margin: 0 10px 0 10px;
    display: block;
    width: auto;
    height: 2px;
    content: ' ';
  }
  /*presetend*/
  /*************************************************************************************************/
  /* IV.A Mobile menu skin customization */
  /*************************************************************************************************/
  div#FM2_CSSMenu1_jq_menu_back a span,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li a,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li a span,
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > p a {
    color: #ffffff;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li.ellipsis:last-child,
  div#FM2_CSSMenu1_jq_menu_back div.menu-header p > a {
    border-bottom-color: #ffb300;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-header,
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > a,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container > .main-menu:before,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container div.main-menu ul li.clicked,
  div#FM2_CSSMenu1_jq_menu_back div.menu-container div.submenu ul li.clicked {
    background-color: #ffb300;
    border-bottom-color: #ffb300;
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container ul li.ellipsis,
  div#FM2_CSSMenu1_jq_menu_back div.menu-header > p a,
  div#FM2_CSSMenu1_jq_menu_back div.main-menu-header,
  div#FM2_CSSMenu1_jq_menu_back {
    background-color: #414141;
  }
  a#FM2_CSSMenu1_mobile_button {
    background-color: #ffb300;
    border-left-color: #000;
    border-top-color: #000;
    border-bottom-color: #000;
    border-right-color: #000;
  }
  a#FM2_CSSMenu1_mobile_button.stick {
    position: fixed;
    top: 0;
  }
  a#FM2_CSSMenu1_mobile_button span {
    color: #ffffff;
  }
  a#FM2_CSSMenu1_mobile_button span.caption:before,
  div#FM2_CSSMenu1_jq_menu_back div.main-menu-header p span.caption:before {
    background-image: url('mini_menu.png');
  }
  div#FM2_CSSMenu1_jq_menu_back div.menu-container .branch:before {
    background: url('mini_arrow.png') no-repeat top left scroll;
  }
  ul.FM2_CSSMenu1 ul li ul {
    left: 170px;
    top: 0px;
  }
  /*************************************************************************************************/
  /* II.C. Menu item design (default state) */
  /*************************************************************************************************/
  /* II.C.1. Padding */
  /* II.C.2. Border */
  /* II.C.3. Background (color, image, image repeat, image position) */
  /* II.C.4. Text color */
  /* II.C.5. Font size */
  /* II.C.6. Font properties (family, weight, style, decoration) */
  /* II.C.7. Text align */
  /* II.C.8. Cursor */
  /* II.C.9. Explicit item width/height, optional */
  ul.FM2_CSSMenu1 li a.first {
    background: none repeat-x left center scroll;
    border-left: 0px none #000000;
    border-top: 0 none #000000;
    border-bottom: 0px none #000000;
    border-right: 0 none #000000;
    padding: 20px 0px 0px 0px;
    width: auto;
    height: 27px;
    -moz-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    -webkit-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    margin-left: 28px;
    margin-right: 28px;
  }
  ul.FM2_CSSMenu1 li a.first,
  ul.FM2_CSSMenu1 li a.first span,
  ul.FM2_CSSMenu1 li a.first font {
    font: normal normal 700 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
  /* II.C.10. Menu item spacing */
  ul.FM2_CSSMenu1 li.first {
    padding: 0 0px 0px 0px;
  }
  ul.FM2_CSSMenu1 li:hover > a.first,
  ul.FM2_CSSMenu1 li.hover > a.first {
    background: none repeat-x left center scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 4px solid #00bfff;
    border-right: 0 none #000000;
    -moz-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    -webkit-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  }
  ul.FM2_CSSMenu1 li:hover > a.first,
  ul.FM2_CSSMenu1 li.hover > a.first,
  ul.FM2_CSSMenu1 li:hover > a.first span,
  ul.FM2_CSSMenu1 li.hover > a.first span,
  ul.FM2_CSSMenu1 li:hover > a.first font,
  ul.FM2_CSSMenu1 li.hover > a.first font {
    font: normal normal 700 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
  /*************************************************************************************************/
  /* II.E. Has children icon (default state) */
  /*************************************************************************************************/
  ul.FM2_CSSMenu1 li a.first span {
    padding: 0px 0px 0px 0px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 li:hover > a.first span,
  ul.FM2_CSSMenu1 li.hover > a.first span {
    padding: 0px 0px 0px 0px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 li a.sel_first span {
    padding: 0px 0px 0px 0px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  /*************************************************************************************************/
  /* III.B. Item customization */
  /*************************************************************************************************/
  /* III.B.1. Custom default item style */
  ul.FM2_CSSMenu1 li a.sel_first,
  ul.FM2_CSSMenu1 li:hover > a.sel_first,
  ul.FM2_CSSMenu1 li.hover > a.sel_first {
    background: none repeat-x left center scroll;
    border-left: 0px solid #000000;
    border-top: 0px solid #000000;
    border-bottom: 4px solid #00bfff;
    border-right: 0px solid #000000;
    -moz-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    -webkit-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  }
  ul.FM2_CSSMenu1 li a.sel_first,
  ul.FM2_CSSMenu1 li a.sel_first span,
  ul.FM2_CSSMenu1 li a.sel_first font,
  ul.FM2_CSSMenu1 li:hover > a.sel_first,
  ul.FM2_CSSMenu1 li.hover > a.sel_first,
  ul.FM2_CSSMenu1 li:hover > a.sel_first span,
  ul.FM2_CSSMenu1 li.hover > a.sel_first span,
  ul.FM2_CSSMenu1 li:hover > a.sel_first font,
  ul.FM2_CSSMenu1 li.hover > a.sel_first font {
    font: normal normal 700 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
  /*************************************************************************************************/
  /* II.C. Menu item design (default state) */
  /*************************************************************************************************/
  /* II.C.1. Padding */
  /* II.C.2. Border */
  /* II.C.3. Background (color, image, image repeat, image position) */
  /* II.C.4. Text color */
  /* II.C.5. Font size */
  /* II.C.6. Font properties (family, weight, style, decoration) */
  /* II.C.7. Text align */
  /* II.C.8. Cursor */
  /* II.C.9. Explicit item width/height, optional */
  ul.FM2_CSSMenu1 li a.last {
    background: none repeat-x left center scroll;
    border-left: 0px none #000000;
    border-top: 0 none #000000;
    border-bottom: 0px none #000000;
    border-right: 0 none #000000;
    padding: 20px 0px 0px 0px;
    width: auto;
    height: 27px;
    -moz-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    -webkit-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    margin-left: 28px;
    margin-right: 28px;
  }
  ul.FM2_CSSMenu1 li a.last,
  ul.FM2_CSSMenu1 li a.last span,
  ul.FM2_CSSMenu1 li a.last font {
    font: normal normal 700 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
  /* II.C.10. Menu item spacing */
  ul.FM2_CSSMenu1 li.last {
    padding: 0 0px 0px 0px;
  }
  ul.FM2_CSSMenu1 li:hover > a.last,
  ul.FM2_CSSMenu1 li.hover > a.last {
    background: none repeat-x left center scroll;
    border-left: 0 none #000000;
    border-top: 0 none #000000;
    border-bottom: 4px solid #00bfff;
    border-right: 0 none #000000;
    -moz-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    -webkit-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  }
  ul.FM2_CSSMenu1 li:hover > a.last,
  ul.FM2_CSSMenu1 li.hover > a.last,
  ul.FM2_CSSMenu1 li:hover > a.last span,
  ul.FM2_CSSMenu1 li.hover > a.last span,
  ul.FM2_CSSMenu1 li:hover > a.last font,
  ul.FM2_CSSMenu1 li.hover > a.last font {
    font: normal normal 700 11px/100% 'Open Sans', Helvetica, Arial, sans-serif;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
  /*************************************************************************************************/
  /* II.E. Has children icon (default state) */
  /*************************************************************************************************/
  ul.FM2_CSSMenu1 li a.last span {
    padding: 0px 0px 0px 0px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 li:hover > a.last span,
  ul.FM2_CSSMenu1 li.hover > a.last span {
    padding: 0px 0px 0px 0px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  ul.FM2_CSSMenu1 li a.sel_last span {
    padding: 0px 0px 0px 0px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right center;
  }
  /*************************************************************************************************/
  /* III.B. Item customization */
  /*************************************************************************************************/
  /* III.B.1. Custom default item style */
  ul.FM2_CSSMenu1 li a.sel_last,
  ul.FM2_CSSMenu1 li:hover > a.sel_last,
  ul.FM2_CSSMenu1 li.hover > a.sel_last {
    background: none repeat-x left center scroll;
    border-left: 0px solid #000000;
    border-top: 0px solid #000000;
    border-bottom: 4px solid #00bfff;
    border-right: 0px solid #000000;
    -moz-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    -webkit-text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  }
  ul.FM2_CSSMenu1 ul ul ul li:hover > a,
  ul.FM2_CSSMenu1 ul ul ul li.hover > a {
    background-image: none;
  }

  .row_346 {
    clear: both;
  }
  .row_347 {
    clear: both;
    min-height: 0px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  p.paragraph50 {
    margin: 20px;
    text-align: center;
    color: #15178f;
    font: 700 30px/100% Trebuchet MS, sans-serif;
  }
  p.paragraph53 {
    color: #ffffff;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    text-align: center;
    line-height: 140%;
    margin: 10px;
  }
  p.paragraph54 {
    text-align: center;
    color: #ffd823;
    margin: 10px;
    font: 400 22px/140% Trebuchet MS, sans-serif;
  }
  p.paragraph55 {
    font-weight: 400;
    font-family: Trebuchet MS, sans-serif;
    color: #4b4b4b;
    font-size: 18px;
    margin: 10px;
    text-align: center;
  }
  p.paragraph56-b {
    font-size: 18px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    color: #4b4b4b;
    margin: 10px;
    text-align: center;
  }
  p.paragraph56 {
    font-size: 18px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    color: #4b4b4b;
    margin: 10px;
    text-align: center;
  }
  p.paragraph57 {
    font-size: 18px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    color: #15178f;
    margin: 10px 10px 10px 5px;
  }
  p.paragraph58 {
    color: #15178f;
    font-size: 18px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    margin: 10px 10px 10px 5px;
  }
  img.image38 {
    display: inline-block;
    margin: 0 5px 0 10px;
    float: left;
    padding: 5px 5px 5px 0;
  }
  img.image39 {
    display: inline-block;
    margin: 0 5px 0 10px;
    float: left;
    padding: 5px 5px 5px 0;
  }
  .align-inline-wrap5 {
    text-align: center;
  }
  p.paragraph59 {
    margin: 10px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #120b4f;
    text-align: center;
    letter-spacing: 0px;
  }
  p.paragraph59:hover {
    color: #120b4f;
    font-weight: 700;
    letter-spacing: 0px;
  }
  .column_67 {
    float: left;
    width: 50%;
    min-height: 0px;
  }
  .column_68 {
    float: left;
    width: 150px;
    min-height: 0px;
    background-color: #ffd823;
    border-radius: 10px;
  }
  .column_69 {
    float: left;
    width: 33.26384364820847%;
    min-height: 100px;
  }
  p.paragraph61 {
    font-size: 16px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 700;
    color: #120b4f;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p.paragraph61:hover {
    color: #ffd823;
  }
  p.paragraph62 {
    margin: 10px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #4b4b4b;
  }
  a.anchor4 {
    text-align: center;
    color: #ffffff;
  }
  .row_371 {
    clear: both;
    min-height: 0px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    background-color: #120b4f;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  .row_371:hover {
    background-color: #ffab00;
    margin-bottom: 30px;
  }
  p.paragraph63 {
    color: #120b4f;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p.paragraph64 {
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    font-weight: 900;
    margin: 10px;
    letter-spacing: 0px;
  }
  .row_348 {
    clear: both;
    min-height: 100px;
  }
  .row_349 {
    clear: both;
    min-height: 100px;
  }
  .column_62 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_351 {
    clear: both;
  }
  .row_351 {
    clear: both;
  }
  .row_368 {
    clear: both;
    min-height: 100px;
    display: none;
  }
  .row_355 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_358 {
    clear: both;
    min-height: 100px;
  }
  .row_356 {
    clear: both;
    width: 210px;
    margin-left: auto;
    margin-right: auto;
  }
  .row_359 {
    clear: both;
  }
  .row_357 {
    clear: both;
    width: 341px;
    margin-left: auto;
    margin-right: auto;
  }
  .row_360 {
    clear: both;
  }
  .column_63 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_352 {
    clear: both;
    min-height: 100px;
  }
  .row_353 {
    clear: both;
    width: auto;
  }
  .row_361 {
    clear: both;
  }
  .row_354 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_362 {
    clear: both;
    min-height: 100px;
  }
  .row_346 {
    clear: both;
  }
  .row_347 {
    clear: both;
    min-height: 0px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .row_308 {
    clear: both;
    min-height: 100px;
  }
  .row_309 {
    clear: both;
    min-height: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .column_56 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_311 {
    clear: both;
    min-height: 100px;
  }
  .row_314 {
    clear: both;
    width: auto;
    min-height: 0px;
  }
  .row_317 {
    clear: both;
    min-height: 0px;
  }
  .row_315 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_318 {
    clear: both;
    min-height: 100px;
  }
  .row_316 {
    clear: both;
    width: auto;
    min-height: 100px;
  }
  .row_319 {
    clear: both;
    min-height: 0px;
  }
  .column_57 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_312 {
    clear: both;
    min-height: 100px;
  }
  .column_58 {
    float: none;
    width: auto;
    min-height: 100px;
  }
  .row_313 {
    clear: both;
    min-height: 100px;
  }
  .row_310 {
    clear: both;
    width: auto;
  }
`;

export const Navbar = styled.header`
  background: #fff;
  border-bottom: 4px solid var(--blue-b);
  padding: 10px 0;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: auto;

    @media screen and (max-width: 768px) {
      max-width: 80%;
    }
  }

  a {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    background: var(--blue-b);
  }

  img {
    width: 100px;
  }
`;

export const Content = styled.section`
  background: #fff;
`;

export const Plans = styled.section`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255) 68%,
    rgba(255, 255, 255, 0.6) 100%
  );
  padding-bottom: 25px;
  color: var(--blue-b);
  box-shadow: 0 0px 54px 56px rgba(255, 255, 255, 0.6);

  > h2 {
    text-align: center;
    margin-bottom: 25px;
    font-size: 29px;
    @media screen and (max-width: 768px) {
      margin: 15px;
    }
  }

  ul {
    text-align: center;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: auto;

    @media screen and (max-width: 768px) {
      max-width: 80%;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }

    li {
      width: 32%;
      @media screen and (max-width: 425px) {
        width: 100%;
        margin: 10px;
      }
    }

    header {
      background: var(--blue-b);
      padding: 13px 15px;
      border-radius: 5px 5px 0 0;

      h2 {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        span {
          font-size: 15px;
        }
      }
    }

    div {
      background: #fff;
      padding: 6px 0;
      color: #000;
      & + div {
        background: #eee;
      }

      p {
        margin-bottom: 5px;
        font-weight: 700;
      }
    }

    main {
      background: transparent;
      div {
        margin-top: 10px;
      }
    }
  }
`;

export const Footer = styled.footer`
  background: var(--blue-b);
  padding: 20px 0;
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: auto;
    @media screen and (max-width: 768px) {
      max-width: 80%;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }
  img {
    width: 100px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      @media screen and (max-width: 425px) {
        margin: 10px 0;
      }

      svg {
        margin-right: 10px;
      }

      & + a {
        margin-left: 10px;
      }
    }
  }
`;

export const Contact = styled.section`
  min-height: 550px;
  background: #fff;
  padding: 30px 60px;

  h2 {
    color: #15178f;
    text-align: center;
    margin-bottom: 40px;
    font: 700 30px/100% Trebuchet MS, sans-serif;
  }

  p {
    color: #4b4b4b;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
  }

  a {
    margin-top: 10px;
    color: #15178f;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: 400;
    color: #15178f;
    text-decoration: none;
  }

  > section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    * {
      font-family: 'Roboto', sans-serif;
    }

    aside {
      width: 100%;
    }

    aside:nth-child(1) {
    }

    aside:nth-child(2) {
      .input,
      input {
        background: #f3f3f3;
        height: 40px;
        border: 0;
      }

      form {
        margin-top: 20px;
      }

      button {
        width: 105px;
        border: 0;
        border-radius: 5px;
        height: 42px;
        background: #120b4f;
        color: #fff;
        display: block;
        margin: auto;
      }

      textarea {
        background: #f3f3f3;
        border: 0;
        color: #777;
        font-weight: 700;
        padding: 5px 8px;
        font-size: 16px;
      }

      article,
      .input,
      input {
        width: 100%;
        border-radius: 0;
        &::placeholder {
          color: #777;
        }
      }
      .input,
      textarea {
        width: 100%;
        margin-bottom: 20px;

        input {
          font-weight: 700;
          color: #777;
        }
      }
    }
  }
`;
