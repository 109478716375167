import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  min-height: 400px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  svg {
    width: 50%;
    height: 50%;

    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  h1 {
    font-size: 25px;
    color: var(--blue-b);
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
`;
