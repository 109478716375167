import React from 'react';
import formatMoney from '../../../../../utils/formatMoney';

interface IParamDespesasProps {
  paramentros_despesas_fixas: {
    Rateio_Seguranca: number;
    Rateio_administrativo: number;
    beneficios_motoristas: number;
    capacidade_carga: number;
    desoneracao_folha: number;
    diarias_pernoites: number;
    elementos_adicionais_veiculo: number;
    encargos_sociais_trabalhistas: number;
    gerenciamento_riscos: number;
    idade_media_semireboque: number;
    idade_media_veiculo: number;
    perc_deprec_semireboque: number;
    perc_deprec_veiculo: number;
    perc_documentacao_ipva: number;
    perc_impostos_federais: number;
    perc_remuneracao_capital: number;
    perc_seguro_casco: number;
    perc_taxa_lucro_fixas: number;
    preco_semireboque: number;
    preco_veiculo: number;
    premio_variavel: number;
    quantidade_motoristas: number;
    remuneracao_motorista: number;
    seguro_carga_ambiental: number;
    tempo_espera: number;
  };
}

const ParamDespesas: React.FC<IParamDespesasProps> = ({
  paramentros_despesas_fixas,
}) => {
  return (
    <>
      <h2>Parâmetros das Despesas Fixas</h2>
     
      <section>
        <h5>Rateio dos custos Administrativos</h5>
        <p> {formatMoney(paramentros_despesas_fixas.Rateio_administrativo)}</p>
      </section>
      <section>
        <h5>Remuneração do motorista</h5>
        <p>{formatMoney(paramentros_despesas_fixas.remuneracao_motorista)}</p>
      </section>
      <section>
        <h5>Tempo de espera</h5>
        <p>{formatMoney(paramentros_despesas_fixas.tempo_espera)}</p>
      </section>
      <section>
        <h5>Prêmios variáveis</h5>
        <p>{formatMoney(paramentros_despesas_fixas.premio_variavel)}</p>
      </section>
      <section>
        <h5>Diárias e pernoites</h5>
        <p>{formatMoney(paramentros_despesas_fixas.diarias_pernoites)}</p>
      </section>
      <section>
        <h5>Benefícios do motorista</h5>
        <p>{formatMoney(paramentros_despesas_fixas.beneficios_motoristas)}</p>
      </section>
      <section>
        <h5>Encargos sociais trabalhistas</h5>
        <p>{paramentros_despesas_fixas.encargos_sociais_trabalhistas} %</p>
      </section>
      <section>
        <h5>Desoneração da Folha de Pgto</h5>
        <p>{formatMoney(paramentros_despesas_fixas.desoneracao_folha)}</p>
      </section>
      <section>
        <h5>Quantidade de motoristas</h5>
        <p>{paramentros_despesas_fixas.quantidade_motoristas}</p>
      </section>
      <section>
        <h5>Preço do veículo</h5>
        <p>{formatMoney(paramentros_despesas_fixas.preco_veiculo)}</p>
      </section>
      <section>
        <h5>Equipamentos adicionais ao veículo</h5>
        <p>{formatMoney(paramentros_despesas_fixas.elementos_adicionais_veiculo)}</p>
      </section>
      <section>
        <h5>Depreciação do veículo (%)</h5>
        <p>{paramentros_despesas_fixas.perc_deprec_veiculo} %</p>
      </section>
      <section>
        <h5>Período de depreciação do veículo (meses)</h5>
        <p>{paramentros_despesas_fixas.idade_media_veiculo}</p>
      </section>
      <section>
        <h5>Preço do semi-reboque ou complemento</h5>
        <p>{formatMoney(paramentros_despesas_fixas.preco_semireboque)}</p>
      </section>
      <section>
        <h5>Depreciação do semi-reboque ou complemento (%)</h5>
        <p>{paramentros_despesas_fixas.perc_deprec_semireboque} %</p>
      </section>
      <section>
        <h5>Período de depreciação do semi-reboque ou complemento (meses)</h5>
        <p>{paramentros_despesas_fixas.idade_media_semireboque}</p>
      </section>
      <section>
        <h5>Gerenciamento de riscos</h5>
        <p>{formatMoney(paramentros_despesas_fixas.gerenciamento_riscos)}</p>
      </section>
      <section>
        <h5>Seguro do casco</h5>
        <p>{paramentros_despesas_fixas.perc_seguro_casco} %</p>
      </section>
      <section>
        <h5>Seguro de carga e ambiental</h5>
        <p>{formatMoney(paramentros_despesas_fixas.seguro_carga_ambiental)}</p>
      </section>
      <section>
        <h5>Documentação (IPVA/DPVAT/Licenciamento)</h5>
        <p>{paramentros_despesas_fixas.perc_documentacao_ipva} %</p>
      </section>
      <section>
        <h5>Remuneração de capital</h5>
        <p>{paramentros_despesas_fixas.perc_remuneracao_capital.toFixed(2)} %</p>
      </section>
      <section>
        <h5>Rateio dos custos com Segurança</h5>
        <p>{formatMoney(paramentros_despesas_fixas.Rateio_Seguranca)}</p>
      </section>      
      <section>
        <h5>Impostos federais</h5>
        <p>{paramentros_despesas_fixas.perc_impostos_federais} %</p>
      </section>
      <section>
        <h5>Capacidade de carga do veículo em toneladas ou m³</h5>
        <p>{paramentros_despesas_fixas.capacidade_carga}</p>
      </section>
      <section>
        <h5>Taxa de lucro (%)</h5>
        <p>{paramentros_despesas_fixas.perc_taxa_lucro_fixas} %</p>
      </section>

     
     
     
    

    </>
  );
};

export default ParamDespesas;
