import React, { useState, useCallback, useRef } from 'react';
import { IconBaseProps } from 'react-icons';
import useOnClickOutside from 'use-onclickoutside';

import { Container, Box } from './styles';

interface DropDownProps {
  icon: React.ComponentType<IconBaseProps>;
  notification?: number;
}

const Dropdown: React.FC<DropDownProps> = ({
  icon: Icon,
  children,
  notification,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setIsVisible(false));

  const handleVisible = useCallback(() => {
    if (isVisible) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [isVisible]);

  return (
    <Container ref={ref}>
      <button type="button" onClick={() => handleVisible()}>
        {notification === 0 ? '' : <span>{notification}</span>}

        <Icon size={20} />
      </button>
      <Box isVisible={isVisible}>{children}</Box>
    </Container>
  );
};

export default Dropdown;
