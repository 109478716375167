import React, { useState, useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';
import { Container, Header } from './styles';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Button from '../../components/Button';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import subtipos from './Tabs';

interface AddField {
  value: string;
  label: string;
}

interface FormData {
  tab: string;
  subtipo: string;
  campo: string;
  infoCampo: string;
}

const AddField: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('Elementos');
  const [subTipoElemento, setSubTipoElemento] = useState('');
  const formRef = useRef<FormHandles>(null);
  const { conta } = useAuth().user;
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const tabelas: AddField[] = [
    {
      value: 'Elementos',
      label: 'Elementos',
    },
    {
      value: 'Motoristas',
      label: 'Motorista',
    },
    {
      value: 'Gerenciamento de Riscos',
      label: 'Gerenciamento de Riscos',
    },
    {
      value: 'Pneus',
      label: 'Pneus',
    },
  ];

  const handleChangeTabs = (e: any): void => {
    setSelectedTab(e.value);
  };

  const handleChangeSubtipo = (e: any): void => {
    setSubTipoElemento(e.value);
  };
  const handleSubmit: SubmitHandler<FormData> = useCallback(
    async (data): Promise<void> => {
      try {
        await api.post(
          '/api/v1/tabelas',
          {
            tabela: data.tab,
            subtipo: data.subtipo,
            tipo_campo: 'fixo',
            campo: data.campo,
            info_campo: data.infoCampo,
            agrupamento: 0,
            conta,
            read_only: false,
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        toast.success('Campo adicionado com sucesso');
      } catch {
        toast.error('Erro ao adicionar o campo');
      }
    },
    [conta, token],
  );

  return (
    <Container>
      <Header>
        <h1>Adicione campos</h1>

      </Header>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Select
          name="tab"
          placeholder="Select Option"
          value={tabelas.find((obj) => obj.label === selectedTab)}
          defaultValue={tabelas[0]}
          options={tabelas}
          onChange={handleChangeTabs}
        />

        {selectedTab === 'Elementos' && (
          <Select
            name="subtipo"
            value={subtipos.elementos.find(
              (obj) => obj.label === subTipoElemento,
            )}
            defaultValue={subtipos.elementos[0]}
            options={subtipos.elementos}
            onChange={handleChangeSubtipo}
          />
        )}
        {selectedTab === 'Motoristas' && (
          <Select
            name="subtipo"
            value={subtipos.motoristas.find(
              (obj) => obj.label === subTipoElemento,
            )}
            defaultValue={subtipos.motoristas[0]}
            options={subtipos.motoristas}
            onChange={handleChangeSubtipo}
          />
        )}
        {selectedTab === 'Gerenciamento de Riscos' && (
          <Select
            name="subtipo"
            value={subtipos.gerenciamentoDeRisco.find(
              (obj) => obj.label === subTipoElemento,
            )}
            defaultValue={subtipos.gerenciamentoDeRisco[0]}
            options={subtipos.gerenciamentoDeRisco}
            onChange={handleChangeSubtipo}
          />
        )}
        {selectedTab === 'Pneus' && (
          <Select
            name="subtipo"
            value={subtipos.pneu.find((obj) => obj.label === subTipoElemento)}
            defaultValue={subtipos.pneu[0]}
            options={subtipos.pneu}
            onChange={handleChangeSubtipo}
          />
        )}
        <Input name="campo" placeholder="Campo" />
        <Button type="submit">Enviar</Button>
        <h3>Atenção: Após a inserção de um campo ao sistema, esse não poderá ser removido.</h3>

      </Form>
    </Container>
  );
};

export default AddField;
