import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled.div`
  form {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 30px;

    div[type='text'] {
      padding: 6px;
      border-radius: 5px;
      width: 100%;
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }

    input{
      width: 190%
    }
    button[type='submit'] {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 0;
      width: 200px;
      padding: 10px 5px;
      border: 0;
      background-color: var(--text);
      border-radius: 5px;
      color: #fff;
    }
  }
`;

export const Table = styled(animated.div)``;

export const Header = styled.h2`
  text-align: center;
  color: var(--blue-b);
  font-size: 23px;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
