import React, { useEffect, useState, ButtonHTMLAttributes } from 'react';
import MaterialTable from 'material-table';
import { FiArrowLeft, FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { useTransition, animated } from 'react-spring';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/auth';
import { useTimeline } from '../../hooks/timeline';
import api from '../../services/api';
import AdiconarRentabilidade from './AdiconarRentabilidade';
import Rentabilidade from './Rentabilidade';

import { Container, Button, Header, ButtonEdit, ButtonIcon } from './styles';
import { useTabs } from '../../hooks/tabs';
import { useMenu } from '../../hooks/menu';

interface ITable {
  id?: number;
  calculo: number;
  cliente: string;
  custo_km: number;
  descricao: ButtonHTMLAttributes<HTMLButtonElement>;
  destino: string;
  eixos: number;
  obs: string;
  origem: string;
  veiculo: string;
  edit: ButtonHTMLAttributes<HTMLButtonElement>;
  remove: ButtonHTMLAttributes<HTMLButtonElement>;
}

const createData = ({
  id,
  calculo,
  cliente,
  custo_km,
  descricao,
  destino,
  eixos,
  obs,
  origem,
  veiculo,
  edit,
  remove,
}: ITable): ITable => {
  return {
    id,
    calculo,
    cliente,
    custo_km,
    descricao,
    destino,
    eixos,
    obs,
    origem,
    veiculo,
    edit,
    remove,
  };
};

const rowHead = [
  {
    title: 'ID',
    field: 'calculo',
    cellStyle: (): any => {
      return { fontSize: '12px', textAlign: 'left', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Descrição',
    field: 'descricao',
    cellStyle: (): any => {
      return { fontSize: '12px', textAlign: 'left', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Cliente',
    field: 'cliente',
    cellStyle: (): any => {
      return { fontSize: '12px', textAlign: 'left', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Origem',
    field: 'origem',
    cellStyle: (): any => {
      return { fontSize: '12px', textAlign: 'left', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Destino',
    field: 'destino',
    cellStyle: (): any => {
      return { fontSize: '12x', textAlign: 'left', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Veículo',
    field: 'veiculo',
    cellStyle: (): any => {
      return { fontSize: '12px', textAlign: 'left', whiteSpace: 'nowrap' };
    },
  },
  {
    title: 'Eixos',
    field: 'eixos',
    cellStyle: (): any => {
      return {
        fontSize: '10px',
        width: '10px',
        textAlign: 'left',
        whiteSpace: 'nowrap',
      };
    },
  },
  {
    title: 'Obs',
    field: 'obs',
  },
  {
    title: 'Custo KM',
    field: 'custo_km',
  },
  {
    title: 'Editar',
    field: 'edit',
  },
  {
    title: 'Deletar',
    field: 'remove',
  },
];

const Rentabilidades: React.FC = () => {
  const { conta, id } = useAuth().user;
  const token = sessionStorage.getItem('@MaxiFrete:token');
  const [line, setLine] = useState(false);
  const [rentablidades, setRentabilidades] = useState<ITable[]>([]);
  const [loading, setLoading] = useState(true);
  const { reset, transition, goTransition } = useTimeline();
  const { handleTemp } = useTabs();
  const { expansedFalse } = useMenu();

  useEffect(() => {
    console.log('Entrou');
  }, []);

  useEffect(() => {
    if (window.screen.width <= 500) {
      expansedFalse();
    }

    const getRentabilidades = async (): Promise<void> => {
      try {
        const response = await api.get(
          `/api/v1/tabelas/rentabilidade?user_request=${id}&conta=${conta}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setRentabilidades(response.data.data);
        setLoading(false);
      } catch (error) {
        toast.error('Ocorreu um erro erro ao listar.');
        setLoading(false);
      }
    };
    getRentabilidades();
  }, [conta, id, token]);

  const transitions = useTransition(transition, (p) => p, {
    from: { display: 'block' },
    leave: { display: 'none' },
  });

  const handelEdit = (cod = 0): void => {
    setLine(true);
    handleTemp(cod);
    goTransition(1);
  };

  const deleteRenta = async (idTable: number | undefined): Promise<void> => {
    await api.delete(`api/v1/tabelas/Rentabilidade/${idTable}`, {
      headers: {
        'x-access-token': token,
      },
    });
    window.location.reload();
  };
  const rowsList = rentablidades
    ? rentablidades.map((data) => {
        const {
          id: idTable,
          calculo,
          descricao,
          cliente,
          origem,
          destino,
          veiculo,
          eixos,
          obs,
          custo_km,
        } = data;
        const row = createData({
          calculo,
          descricao: (
            <ButtonEdit type="button" onClick={() => handelEdit(idTable)}>
              {descricao}
            </ButtonEdit>
          ),
          cliente,
          origem,
          destino,
          veiculo,
          eixos,
          obs,
          custo_km,
          edit: (
            <ButtonIcon onClick={() => handelEdit(idTable)} type="button">
              <FiEdit size={18} />
            </ButtonIcon>
          ),
          remove: (
            <ButtonIcon
              onClick={() => {
                return window.confirm(
                  'Deseja deletar esse cálculo de rentabilidade?',
                )
                  ? deleteRenta(idTable)
                  : '';
              }}
              type="button"
            >
              <FiTrash2 size={18} />
            </ButtonIcon>
          ),
        });
        return row;
      })
    : [];

  const tabs = [() => <AdiconarRentabilidade />, () => <Rentabilidade />];

  return (
    <Container bg={!!line}>
      {line && (
        <Header>
          <h1>Rentabilidade</h1>
          <button
            type="button"
            onClick={() => {
              window.location.reload();
            }}
          >
            <FiArrowLeft size={20} color="#fff" />
          </button>
        </Header>
      )}

      <>
        {line ? (
          <>
            {transitions.map(({ item, props, key }) => {
              const Screens: React.FC = tabs[item];
              return (
                <animated.div key={key} style={props}>
                  <Screens />
                </animated.div>
              );
            })}
          </>
        ) : (
          <>
            <MaterialTable
              isLoading={loading}
              columns={rowHead}
              data={rowsList}
              components={{
                Actions: () => {
                  return (
                    <Button type="button" onClick={() => setLine(true)}>
                      <FiPlus size={20} color="#fff" />
                    </Button>
                  );
                },
              }}
              options={{
                headerStyle: {
                  backgroundColor: '#4682B4',
                  color: '#fff',
                  textAlign: 'left',
                },
                pageSize: 7,
                sorting: true,
                searchFieldAlignment: 'left',
                rowStyle: (x: any): any => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: '#f2f2f2' };
                  }
                },
              }}
              title="Rentabilidades"
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                },
                toolbar: {
                  searchPlaceholder: 'Pesquisar',
                  searchTooltip: 'Pesquisar',
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum registro encontrado',
                  filterRow: {
                    filterTooltip: 'Filtrar',
                  },
                },
              }}
            />
          </>
        )}
      </>
    </Container>
  );
};

export default Rentabilidades;
