import styled from 'styled-components';
import { Breadcrumbs } from '@material-ui/core';

export const Container = styled(Breadcrumbs)`
  padding-bottom: 20px;
  width: 100%;

  .MuiBreadcrumbs-ol {
    overflow-y: hidden;
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 10px;
      background-color: var(--blue-b);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--blue-b);
    }
    &::-webkit-scrollbar-track {
      background-color: var(--yellow);
    }
  }

  .MuiBreadcrumbs-li {
    background: transparent;
    position: relative;

    height: 48px;
    padding: 0 20px;
  }

  button {
    background: var(--blue-b);
    border: 0;
    color: #fff;
    height: 100%;
    width: 162px;
    position: relative;

    &:before {
      content: '';
      border-top: 1rem solid transparent;
      border-bottom: 1rem solid transparent;
      border-left: 1rem solid #fff;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    &::after {
      content: '';
      border-top: 1rem solid transparent;
      border-bottom: 1rem solid transparent;
      border-left: 1rem solid var(--blue-b);
      position: absolute;
      right: -1rem;
      top: 0;
      z-index: 2;
    }
  }
`;
