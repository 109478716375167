/* eslint-disable react/jsx-wrap-multilines */
import React, {
  useState,
  useEffect,
  useCallback,
  ButtonHTMLAttributes,
  useRef,
} from 'react';
import MaterialTable from 'material-table';
import InputMask, { Props } from 'react-input-mask';
import { FormHandles, SubmitHandler } from '@unform/core';
import {
  FiUser,
  FiPhoneIncoming,
  FiTrash2,
  FiBookmark,
  FiCrosshair,
  FiGlobe,
} from 'react-icons/fi';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import Select from '../../components/Select';
import api from '../../services/api';
import { Container, Header, Box, FormSty, ButtonIcon } from './styles';
import { useAuth } from '../../hooks/auth';
import { useMenu } from '../../hooks/menu';
import ModalTab from '../../components/Modal';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

export interface CompanyData {
  empresa?: string;
  email?: string;
  cidade?: string;
  uf?: string;
  telefone?: string;
  tipo_pagamento?:string;
  status?:any;
  edit?: ButtonHTMLAttributes<HTMLButtonElement>;
  remove?: any;
}

interface ReturnCompany {
  cod?: number;
  nome?: string;
  empresa?: string;
  status?:any;
  email?: string;
  enquadramento_fiscal?: number;
  estado?: string;
  uf?: string;
  endereco?: string;
  cidade?: string;
  telefone?: string;
  tipo_pagamento?:string;
  conta?: number;
  created_at?: Date;
  updated_at?: Date;
}

const createData = ({
  empresa,
  email,
  cidade,
  telefone,
  uf,
  tipo_pagamento,
  status,
  edit,
  remove,
}: CompanyData): CompanyData => {
  return { empresa, email, cidade, uf, telefone, tipo_pagamento, status, edit, remove };
};

const rowHead = [
  {
    title: 'Empresa',
    field: 'empresa',
  },
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Cidade',
    field: 'cidade',
  },
  {
    title: 'Estado',
    field: 'uf',
  },
  {
    title: 'Telefone',
    field: 'telefone',
  },
  {
    title: 'Meio de pagamento',
    field: 'tipo_pagamento',
  },
  {
    title: 'Status',
    field: 'status',
  },
  {
    title: 'Editar',
    field: 'edit',
  },
  {
    title: '',
    field: 'remove',
  },
];

const Company: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [companys, setCompanys] = useState<ReturnCompany[]>();
  const formAddRef = useRef<FormHandles>(null);
  const formEditRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { expansedFalse } = useMenu();

  const token = sessionStorage.getItem('@MaxiFrete:token');
  const { id, conta } = user;

  const list = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get(
        `/api/v1/empresas?conta=${conta}&user=${id}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
console.log(response.data.data);
      setCompanys(response.data.data);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao listar empresas');
    }
  }, [conta, id, token]);

  const handleAddSubmit: SubmitHandler<ReturnCompany> = useCallback(
    async (data) => {
      try {
        formAddRef.current?.setErrors({});
        console.log(data);
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          endereco: Yup.string().required('Endereço obrigatório'),
          cidade: Yup.string().required('Cidade obrigatória'),
          estado: Yup.string().required('Estado obrigatório'),
          telefone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post(
          `/api/v1/empresas`,
          {
            user_request: id,
            conta,
            nome: data.nome,
            telefone: data.telefone,
            endereco: data.endereco,
            cidade: data.cidade,
            estado: data.estado,
            enquadramento_fiscal: 1,
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setModal(true);
        list();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formAddRef.current?.setErrors(errors);
          return;
        }
        toast.error('Ocorreu um erro ao adicionar esta empresa.');
      }
    },
    [conta, id, list, token],
  );

  const handleSubmit: SubmitHandler = useCallback(
    async (data) => {
      console.log(data);
      try {
        formEditRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string().email('Digite um e-mail válido'),
          nome: Yup.string(),
          empresa: Yup.string(),
          telefone: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await api.put(
          `/api/v1/empresas/${data.cod}`,
          {
            user_request: id,
            conta,
            empresa: data.empresa,
            cpf_cnpj: data.cpf_cnpj,
            nome: data.nome,
            cidade: data.cidade,
            uf: data.uf,
            numero: data.numero,
            bairro: data.bairro,
            cep: data.cep,
            endereco: data.endereco,
            telefone: data.telefone,
            enquadramento_fiscal: data.enquadramento_fiscal,
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setModal(true);
        toast.success('Conta atualizada.');
        list();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formEditRef.current?.setErrors(errors);

          return;
        }
        toast.error(
          'Ocorreu um erro ao atualizar este usuário, cheque as crendênciais.',
        );
      }
    },
    [list, conta, id, token],
  );

  const deleteUser = useCallback(
    ({ cod }: ReturnCompany) => {
      try {
        api.delete(`/api/v1/usuarios/${cod}`, {
          headers: {
            'x-access-token': token,
          },
        });
        list();
        toast.info('Deletando empresa.');
      } catch (err) {
        toast.error('Erro ao deletar empresa.');
      }
    },
    [list, token],
  );

  useEffect(() => {
    if (window.screen.width <= 500) {
      expansedFalse();
    }
    list();
  }, [list, expansedFalse]);

  const FormEdit: React.FC<{ data: any }> = ({ data }) => {
    const {
      empresa,
      cpf_cnpj,
      endereco,
      cidade,
      uf,
      numero,
      telefone,
      cod,
      bairro,
      enquadramento_fiscal,
      cep,
    } = data;

    const options = [
      {
        value: 1,
        label:
          '1. Encargos Sociais para empresa optante pela Desoneração da Folha',
      },
      {
        value: 2,
        label:
          '2. Encargos Sociais para empresa NÃO optante pela Desoneração da Folha',
      },
      {
        value: 3,
        label:
          '3. Encargos Sociais para empresa optante do Regime Simples Nacional',
      },
    ];

    const defaultSelect = options.find(
      (obj) => obj.value == enquadramento_fiscal,
    );
    return (
      <FormSty ref={formEditRef} onSubmit={handleSubmit}>
        <Select
          name="enquadramento_fiscal"
          placeholder="Encargos Sociais - Enquadramento de sua empresa"
          defaultValue={defaultSelect}
          options={options}
        />
        <Input
          placeholder={empresa || 'Empresa'}
          defaultValue={empresa}
          icon={FiUser}
          name="empresa"
        />
        <Input
          placeholder={cpf_cnpj || 'Documento'}
          icon={FiGlobe}
          defaultValue={cpf_cnpj}
          name="cpf_cnpj"
        />
        <Input
          defaultValue={endereco}
          placeholder={endereco || 'Endereço'}
          icon={FiGlobe}
          name="endereco"
        />
        <Input
          defaultValue={cidade}
          placeholder={cidade || 'Cidade'}
          icon={FiGlobe}
          name="cidade"
        />
        <Input
          placeholder={uf || 'UF'}
          defaultValue={uf}
          icon={FiGlobe}
          name="uf"
        />
        <Input
          placeholder={numero || 'Número'}
          defaultValue={numero}
          icon={FiGlobe}
          name="numero"
        />
        <Input
          placeholder={bairro || 'Bairro'}
          defaultValue={bairro}
          icon={FiGlobe}
          name="bairro"
        />
        <InputMask mask="99999-999" defaultValue={cep}>
          {(inputProps: Props) => (
            <Input
            icon={FiGlobe}
              {...inputProps}
              defaultValue={cep}
              placeholder={cep || 'cep'}
              name="cep"
            />
          )}
        </InputMask>
        <InputMask mask="(99) 9 9999-9999" defaultValue={telefone}>
          {(inputProps: Props) => (
            <Input
              icon={FiPhoneIncoming}
              {...inputProps}
              defaultValue={telefone}
              placeholder={telefone || 'Telefone'}
              name="telefone"
            />
          )}
        </InputMask>
      
        <Input type="hidden" value={cod} name="cod" />
        <Button type="submit">Editar</Button>
      </FormSty>
    );
  };

  const {
    user: { perfil },
  } = useAuth();

  const rowsList = companys
    ? companys.map((data) => {
        const { empresa, email, cidade, uf, telefone, tipo_pagamento, status, cod } = data;
        const row = createData({
          empresa,
          email,
          cidade,
          uf,
          telefone,
          tipo_pagamento,
          status: status==='authorized' ? 'Ativo':'Cancelado',
          edit: (
            <ModalTab
              close={modal}
              modalContent={<FormEdit data={data} />}
              icon="edit"
            />
          ),
          remove:   
          perfil === 'usuario' || perfil === 'usuario_admin'  ? null :(
            <button
            type="button"
            className="cancelar"
            onClick={() =>
              window.confirm('Deseja cancelar assinatura?') && handleCancelPlan(cod)
            }
          >
            Cancelar Assinatura
          </button>
          ),
        });
        return row;
      })
    : [];

  const handleCancelPlan = async (cod: any): Promise<void> => {
    try {
      
      await api.put(
        `/api/v1/payments/cancelpayment/${cod}`,
        {},
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      toast.success('Conta cancelada com sucesso.');

      setTimeout(() => {
        sessionStorage.clear();
        window.location.href = '/';
      }, 5000);
    } catch (error) {
      toast.error('Erro ao efetuar o cancelamento, notifique a Maxifrete.');
    }
  };
  const isAdm = perfil === 'usuario_admin'
  return (
    <Container>
      <Header>
        <h1>Conta</h1>
      </Header>

      <Box>
        <MaterialTable
          isLoading={loading}
          columns={rowHead}
          data={rowsList}
          title="Contas cadastradas"
          localization={{
            pagination: {
              labelRowsSelect: 'linhas',
            },
            toolbar: {
              searchPlaceholder: 'Pesquisar',
              searchTooltip: 'Pesquisar',
            },
            body: {
              emptyDataSourceMessage: 'Nenhum registro encontrado',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
            },
          }}
        />
      </Box>
      {isAdm && (
        <button
          type="button"
          className="cancel"
          onClick={() =>
            window.confirm('Deseja cancelar assinatura?') && handleCancelPlan(conta)
          }
        >
          Cancelar Assinatura
        </button>
      )}
    </Container>
  );
};

export default Company;
