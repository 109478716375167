import styled from 'styled-components';
import bg from '../../assets/fundo.png';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: url(${bg}) no-repeat;
  background-size: cover;
  background-position: 0 22%;
  @media screen and (max-width: 768px) {
    background-size: cover;
    background-position: center top;
  }
`;

export const Navbar = styled.header`
  background: #fff;
  border-bottom: 4px solid var(--blue-b);
  padding: 10px 0;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: auto;
    @media screen and (max-width: 768px) {
      max-width: 80%;
    }
  }
  a {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    background: var(--blue-b);
  }
  img {
    width: 100px;
  }
`;

export const Content = styled.section`
  height: 100%;
  max-width: 900px;
  margin: auto;
  display: flex;
  min-height: 600px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    max-width: 80%;
  }
  h2 {
    color: var(--blue-b);
    width: 80%;
    margin-top: 0px;
    font-size: 29px;
    line-height: 35px;
    letter-spacing: 3px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 15px;
      font-size: 23px;
      line-height: 39px;
    }
  }
  div > p {
    color: var(--yellow);
    font-size: 20px;
    margin-top: 20px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      margin: 15px;
    }
  }
  article {
    background: #eeeeee;
    padding: 10px;
    text-align: center;
    border-radius: 15px;
    margin-top: 10px;
    width: 300px;
    h4 {
      color: var(--blue-b);
      margin-bottom: 10px;
    }
    p {
      color: #000;
    }
  }
  section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  form {
    background: #eeeeee;
    display: flex;
    height: 300px;
    flex-direction: column;
    padding: 10px;
    width: 300px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    h1 {
      color: var(--blue-b);
      font-size: 25px;
      margin-top: 10px;
    }
    input {
      text-align: center;
      border: 0;
      min-height: 30px;
    }
    button[type='button'] {
      background: transparent;
      margin-top: 16px;
      border: 0;
    }
    button[type='submit'] {
      background: var(--blue-b);
      color: #fff;
      min-height: 40px;
      width: 250px;
      &:hover {
        background: var(--blue-b);
      }
    }
  }
`;

export const Plans = styled.section`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255) 68%,
    rgba(255, 255, 255, 0.6) 100%
  );
  padding-bottom: 25px;
  color: var(--blue-b);
  box-shadow: 0 0px 54px 56px rgba(255, 255, 255, 0.6);
  > h2 {
    text-align: center;
    margin-bottom: 25px;
    font-size: 29px;
    @media screen and (max-width: 768px) {
      margin: 15px;
    }
  }
  ul {
    text-align: center;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: auto;
    @media screen and (max-width: 768px) {
      max-width: 80%;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
    li {
      width: 32%;
      @media screen and (max-width: 425px) {
        width: 100%;
        margin: 10px;
      }
    }
    header {
      background: var(--blue-b);
      padding: 13px 15px;
      border-radius: 5px 5px 0 0;
      h2 {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        span {
          font-size: 15px;
        }
      }
    }
    div {
      background: #fff;
      padding: 6px 0;
      color: #000;
      & + div {
        background: #eee;
      }
      p {
        margin-bottom: 5px;
        font-weight: 700;
      }
    }
    main {
      background: transparent;
      div {
        margin-top: 10px;
      }
    }
  }
`;

export const Footer = styled.footer`
  background: var(--blue-b);
  padding: 20px 0;
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: auto;
    @media screen and (max-width: 768px) {
      max-width: 80%;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }
  img {
    width: 100px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      @media screen and (max-width: 425px) {
        margin: 10px 0;
      }
      svg {
        margin-right: 10px;
      }
      & + a {
        margin-left: 10px;
      }
    }
  }
`;
