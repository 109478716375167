import styled from 'styled-components';

export const Box = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--blue-b);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--yellow);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue-b);
  }

  padding: 20px;
  display: block;
  margin: 30px 0px;
  max-height: 400px;
  width: calc(100% - 53%);
  overflow: auto;
  border: 1px solid var(--blue-b);

  @media screen and (max-width: 959px) {
    width: 100%;
    max-height: max-content;
    overflow: hidden;
  }

  section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }

  input {
    width: 120px;
  }

  h2 {
    color: var(--blue-b);
    margin-bottom: 20px;
    text-align: center;
  }

  h5 {
    font-size: 18px;
    margin: 4px;
    flex: 1;
  }
`;
