import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import Autosuggest from 'react-autosuggest';
import { toast } from 'react-toastify';
import { Container } from './styles';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { useTimeline } from '../../../../hooks/timeline';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { useTabs } from '../../../../hooks/tabs';
import Select from '../../../../components/Select';
import formatDate from '../../../../utils/formatDate';


import getValidationErrors from '../../../../utils/getValidationErrors';

interface ReturnCalc {
  cod: number;
  veiculo: string;
  cliente: string;
  data: Date ;
}

const AddCa: React.FC = () => {
  const formAddRef = useRef<FormHandles>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [calcs, setCalcs] = useState<ReturnCalc[]>([]);
  const [tempSelectedNumber, setTempSelectedNumber] = useState(0);
  const [value, setValue] = useState('');
  const { next } = useTimeline();
  const { handleTemp } = useTabs();

  const { user } = useAuth();

  const token = sessionStorage.getItem('@MaxiFrete:token');
  const { id, conta } = user;
/*
  const lowerCasedCompanies = calcs.map((calc) => {
    return calc.veiculo.toLowerCase();
  });

  const getSuggestions = (values: string): string[] => {
    return lowerCasedCompanies.filter((calc) =>
   calc.toLocaleLowerCase().includes(values),
    );
  };

  const getSuggestion = (values: string): number => {
    const companySelected = calcs.filter(
      (calc) => calc.veiculo.toLocaleLowerCase() === values,
    );
    return companySelected[0].cod;
  };
*/
  const list = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get(
        `api/v1/tabelas/calculos?user_request=${id}&conta=${conta}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      const formatSelectCalc = response.data.data
      .filter((calcs: ReturnCalc) => calcs.veiculo != '')
      .map((calcs: ReturnCalc) => ({
        label: `${calcs.veiculo} - ${calcs.cliente} - ${formatDate(calcs.data)}`,
        value: { cod: calcs.cod },
      }));

    //setCalculo(formatSelectCompanies);
 
      setCalcs(formatSelectCalc);
    } catch (err) {
      toast.error('Erro ao listar usuários.');
    }
  }, [conta, id, token]);

  useEffect(() => {
    list();
    console.log(suggestions);    
  }, [list]);

  const handleAddSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        formAddRef.current?.setErrors({});
        const schema = Yup.object().shape({
          veiculo: Yup.string().required('Veículo obrigatório'),
          eixos: Yup.string(),
          descricao: Yup.string(),
          cliente: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const response = await api.post(
          `/api/v1/tabelas/calculo`,
          {
            user_request: id,
            conta,
            veiculo: data.veiculo,
            eixos: data.eixos,
            cliente: data.cliente,
            descricao: data.descricao,
            carregar_dados: tempSelectedNumber,
          },
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        //console.log(response.data.data);
        handleTemp(response.data.data.calculo);
        next();
        list();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formAddRef.current?.setErrors(errors);
          return;
        }

        toast.error(
          'Ocorreu um erro ao atualizar este usuário, cheque as crendênciais.',
        );
      }
    },
    [conta, id, list, token, tempSelectedNumber, handleTemp, next],
  );

  return (
    <Container ref={formAddRef} onSubmit={handleAddSubmit}>
      <Input name="veiculo" placeholder="Veículo" />
      <Input name="eixos" type='number'  placeholder="Eixos" />
      <Input name="descricao" placeholder="Descrição" />
      <Input name="cliente" placeholder="Cliente" />
      <br></br>
      <p>Você pode utilizar um cálculo já realizado como base. Caso deseje, escolha abaixo. </p>
        <Select
        name="calculo"
        options={calcs}
        placeholder="Cálculo"
        isClearable={true}
        onChange={(value: any) => {
          setTempSelectedNumber(value?value.value.cod:0);
        }}
      />
    
      <Button type="submit">Iniciar cálculo</Button>
    </Container>
  );
};
/*
 <Autosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value: values }) => {
          setValue(values);
          setSuggestions(getSuggestions(values));
        }}
        onSuggestionSelected={(_, { suggestion }) => {
          setTempSelectedNumber(getSuggestion(suggestion));
          console.log(getSuggestion(suggestion));
        }}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={(suggestion) => <span>{suggestion}</span>}
        inputProps={{
          placeholder: 'Digite para buscar um template caso queira usar',
          value,
          onChange: (_, { newValue }) => {
            setValue(newValue);
          },
        }}
        highlightFirstSuggestion
      />
*/
export default AddCa;
